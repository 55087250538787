import React, { useEffect, useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

const TimerCard = ({ totalTimer }) => {
  const { hours, minutes, seconds } = totalTimer;
  // console.log(hours, minutes, seconds);
  const totalSeconds = hours * 3600 + minutes * 60 + seconds;
  const [remainingTime, setRemainingTime] = useState(totalSeconds);

  let interval;

  useEffect(() => {
    interval = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [totalSeconds]);

  const remainingPercentage = (remainingTime / totalSeconds) * 100;

  let remainingHours = Math.floor(remainingTime / 3600)
    .toString()
    .padStart(2, "0");
  let remainingMinutes = Math.floor((remainingTime % 3600) / 60)
    .toString()
    .padStart(2, "0");
  let remainingSeconds = (remainingTime % 60).toString().padStart(2, "0");

  if (remainingTime <= 0) {
    remainingHours = "00";
    remainingMinutes = "00";
    remainingSeconds = "00";
    clearInterval(interval);
  }

  return (
    <div className="card timer-card">
      <div className="card-progress">
        <CircularProgressbar
          value={remainingPercentage.toFixed(2)}
          strokeWidth={50}
          styles={buildStyles({
            strokeLinecap: "butt",
            trailColor: "#fff",
            pathColor: "#ee4854",
          })}
        />
      </div>
      <h3>
        {remainingHours}:{remainingMinutes}:{remainingSeconds}
      </h3>
    </div>
  );
};

export default TimerCard;
