import React, { useState, useRef, useEffect } from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import TrainingLayout from "../components/Layout/TrainingLayout";
import TimerCard from "../components/common/TimerCard";
import CircularProgressCard from "../components/common/CircularProgressCard";
import Accordion from "react-bootstrap/Accordion";
import { FiCrosshair } from "react-icons/fi";
import { BASE_URL } from "../config";
import { useDispatch } from 'react-redux';
import { setPosId } from '../slices/posIdSlice';
import { toast } from "react-hot-toast";
import Cookies from "js-cookie";
const Training = () => {
  const [paramData, setParamData] = useState();

  const [isDisabled, setIsDisabled] = useState(false);
  const location = useLocation();

  const dispatch = useDispatch();
  useEffect(() => {
    getToken();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const param = searchParams.get('posId');
    setParamData(param);
    dispatch(setPosId(param));
    // setPosId(param2);

    // do something with myParam
  }, [location]);
  // useEffect(() => {
  //   console.log(paramData);
  // }, [paramData]);
  const getToken = async () => {
    const searchParams = new URLSearchParams(location.search);
    const param = searchParams.get('posId'); //Change post to get
    const tokenUrl = `${BASE_URL}/ambrela`;
    const tokenRes = await axios.get(tokenUrl, {
      headers: {
        "Content-Type": "application/json",
      }, withCredentials: true
    });
    // Cookies.set("cookieToken", tokenRes.data.token, { session: true, secure: true });//this is a patch
    const agentUrl = `${BASE_URL}/api/getAgentProfile?token=${tokenRes.data.token}&pospId=${param}`;
    const agentRes = await axios.get(agentUrl, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    })
      .then((response) => {
        // Handle success response here
        //Cookies.set("cookieId", response?.data?.id, { session: true, secure: true });
        // console.log(response.data); // Log the response data
        setIsDisabled(true);
      })
      .catch((error) => {
        if (error.response.status == 400) {
          toast.error(`Invalid pospId or Token`, {
            duration: 5000,
            position: "top-center",
          });
        } else {
          setIsDisabled(true);
        }
      });
  };
  const totalTimer = {
    hours: 15,
    minutes: 0,
    seconds: 0,
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [playPause, setPlayPause] = useState(false);
  const vidRef = useRef(null);

  const handleVideo = () => {
    if (!playPause) {
      vidRef.current.play();
      setPlayPause(true);
    } else {
      vidRef.current.pause();
      setPlayPause(false);
    }
  };
  return (
    <TrainingLayout currentTitle="Introduction of Insurance">
      <div className="col-lg-8 insurance-introduction">
        <div className="form-content">
          <h3 className="mb-2">Life Insurance</h3>
          <p className="mb-4">Training Module</p>
          <div className="row mb-5">
            <div className="col-lg-12">
              <Tabs
                defaultActiveKey="information"
                className="mb-3 info-content-tabs "
              >
                <Tab eventKey="information" title="">
                  <Accordion className="training-module">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <b>1. - Introduction to Insurance</b>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul className="pl-0">
                          <li>1.1 What is an asset?</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - An asset is something which has an economic value
                          </li>
                          <br />
                          <li>1.2 An Asset may be…</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Physical like a car or a house
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Non – Physical like brand name or goodwill
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Personal like a pair of eyes, pair of limbs or
                            even a human body
                          </li>
                          <br />
                          <li>1.3 What would happen…</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - If your car met with an accident
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - If your house caught fire
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Breadwinner of the family died prematurely
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - In each case there would be a financial loss to
                            the person or the family
                          </li>
                          <br />
                          <li>1.4 Life Insurance Defined</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Insurance protects against the financial loss
                            happening due to risk i.e. insurance compensates for
                            the loss occurred due to risk
                          </li>
                          <br />
                          <li>1.5 How does Insurance Work?</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - A common fund by a group used to compensate loss
                            suffered by a few unfortunate in that group
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Administered by the insurer
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Transfers the chance of loss from one to many
                          </li>
                          <br />
                          <li>1.6 Why Life Insurance?</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Children's education, Marriage, Life, style,
                            dreams for family
                          </li>
                          <br />
                          <li>1.7 Human Life – Risks and Concerns</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Insurance creates an asset first & then asks for
                            saving
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Reduces risk and enhances returns in long term
                          </li>
                          <br />
                          <li>1.8 Save and Create Concept</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Savings Account/Fixed Deposits Asset worth Rs.
                            400000 + applicable interest is payable to nominee
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Mutual Funds and Shares Applicable Fund Value or
                            market value of stocks payable to nominee
                          </li>
                          <br />
                          <li>1.9 Create and Save Concept</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Asset created is worth Rs. 1000000 (sum assured)
                            moment the policy is issued) while investment is
                            just Rs. 200000 <br />
                            Rs. 1000000 is payable to nominee
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Mutual Funds and Shares Applicable Fund Value or
                            market value of stocks payable to nominee
                          </li>
                          <br />
                          <li>1.10 Value of an asset</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - An asset is a kind of property that yields value
                            or returns
                          </li>
                          <br />
                          <li>1.11 Loss to an asset</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Similarly the amount of loss of value can also be
                            measured
                          </li>
                          <br />
                          <li>* For example</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - When a car meets with an accident, the amount of
                            damage or loss can be estimated say, Rs. 50,000,
                            which an insurance company will compensate
                          </li>
                          <br />
                          <li>1.12 Human Life Value</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Human beings are considered to be an income
                            generating asset
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - We all have a human life value and insuring this
                            value is the primary purpose of life insurance
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - How do you think human life can be valued?
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - How do we estimate the amount of loss to a family
                            when a person dies?
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Would it be Rs. 100000 or Rs. 500000 or
                            Rs.1000000?
                          </li>
                          <br />
                          <li>
                            1.13 Ever thought how much is your replacement
                            value?
                            <br />
                            What is Human Life Value?
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Human Life Value is that amount, which can ensure
                            that the standard of living of the family is not
                            affected even if the earning member is not around
                          </li>
                          <br />
                          <li>1.14 How to arrive at Human Live Value?</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - HLV of a person is equal to an amount, which when
                            invested in an instrument like fixed deposit,
                            generates annual return equivalent to the an amount
                            earned by the person when he was living
                          </li>
                          <br />
                          <li>1.15 Liabilities</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Any outstanding liabilities that the person has
                            needs to be added to that amount to arrive at final
                            HLV
                          </li>
                          <br />
                          <li>1.16 Let us see this through an example</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - 40 year old Daniel Fernandes earns Rs.1500000 per
                            annum
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - His family includes his wife, 10 year old son, 7
                            year old daughter and his retired parents
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - After paying taxes and his personal expenses,
                            Daniel contributes Rs. 1200000 per year to his
                            family
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - He has an outstanding housing loan of Rs. 20 lakh
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Let us calculate Daniel’s HLV assuming FD interest
                            rate at 6% per annum
                          </li>
                          <br />
                          <li>
                            1.17 In case of Daniel’s unfortunate death in 1st
                            year itself…
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Daniel’s wife would receive Rs. 22000000
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - She can pay off the outstanding loan of Rs.
                            2000000
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Balance Rs. 20000000 she can invest in fixed
                            deposit at 6% interest
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - This investment would fetch her an income which
                            would replace the income which Daniel brought home
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Let us calculate Daniel’s HLV assuming FD interest
                            rate at 6% per annum
                          </li>
                          <br />
                          <li>
                            * The thumb rule is that one should have life
                            insurance 12 to 15 times his annual income
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <b>2. - Indian Insurance Market</b>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul className="pl-0">
                          <li>2.1 History of Insurance</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Insurance has been known to exist in some form or
                            other since 3000 BC. Some of the ways in which this
                            concept was applied are given in next few slides
                          </li>
                          <br />
                          <li>2.2 Babylonian Traders</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The Babylonian traders had agreements where they
                            would pay additional sums to lenders, as a price for
                            writing off of their loans, in case a shipment was
                            lost or stolen : Called “Bottomry Loans‟
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The loan taken against the security of the ship or
                            its goods had to be repaid only if and when the ship
                            arrived safely, after the voyage, at its destination
                          </li>
                          <br />
                          <li>2.3 Traders from Bharuch and Surat</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Practices similar to Babylonian traders were
                            prevalent among traders from Bharuch and Surat,
                            sailing in Indian ships to Sri Lanka, Egypt and
                            Greece
                          </li>
                          <br />
                          <li>2.4 Greeks</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The Greeks had started benevolent societies in the
                            late 7th century AD, to take care of the funeral –
                            and families – of members who died
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The Friendly Societies of England were similarly
                            constituted
                          </li>
                          <br />
                          <li>2.5 Inhabitants of Rhodes</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The inhabitants of Rhodes adopted a practice
                            whereby, if some goods were lost due to jettisoning
                            during distress, the owners of goods (even those who
                            lost nothing) would bear the losses in some
                            proportion
                          </li>
                          <br />
                          <li>2.6 Chinese Traders</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Chinese traders in ancient days would keep their
                            goods In different boats or ships sailing over the
                            treacherous rivers.
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - They assumed that even if any of the boats
                            suffered such a fate, the loss of goods would be
                            only partial and not total.
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The loss could be distributed and thereby reduced
                          </li>
                          <br />
                          <li>2.7 History of Insurance in India</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - "The Oriental Insurance Co. Ltd." The first life
                            insurance company to be set up in India was an
                            English company
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - "Triton Insurance Co. Ltd." The first nonlife
                            insurer to be established in India
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - "Bombay Mutual Assurance Society Ltd." The first
                            Indian insurance company. It was formed in 1870 in
                            Mumbai
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - "National Insurance Company Ltd. " The oldest
                            insurance company in India. It was founded in 1906
                            and it is still in business
                          </li>
                          <br />
                          <li>2.8 Development of Insurance in India</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Modern insurance in India began in early 1800 with
                            agencies of foreign insurers starting marine
                            insurance business
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - In 1912, the Life Insurance Companies Act and the
                            Provident Fund Act were passed to regulate the
                            insurance business
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - It made compulsory that premium-rate tables and
                            periodical valuation of companies be certified by an
                            actuary
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The Insurance Act 1938 was the first legislation
                            enacted to regulate the conduct of insurance
                            companies in India. This Act continues to be in
                            force thereafter{" "}
                          </li>
                          <br />
                          <li>2.9 Nationalisation of Life Insurance</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Life insurance business was nationalised on 1st
                            September 1956 and the Life Insurance Corporation of
                            India (LIC) was formed
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - There were 170 companies and 75 provident fund
                            societies doing life insurance business in India at
                            that time
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - From 1956 to 1999, the LIC held exclusive rights
                            to do life insurance business in India
                          </li>
                          <br />
                          <li>2.10 Nationalisation of Non – Life Insurance</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - With the enactment of General Insurance Business
                            Nationalisation Act (GIBNA) in 1972, the non-life
                            insurance business was also nationalised
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The General Insurance Corporation of India (GIC)
                            and its four subsidiaries were set up
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - At that point of time, 106 insurers in India doing
                            non – life insurance business were amalgamated with
                            the formation of four subsidiaries of the GIC of
                            India
                          </li>
                          <br />
                          <li>2.11 Concept of Risk</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Every asset is exposed to risk of getting damaged
                            or lost
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Risk is an adverse event which may incur financial
                            loss to someone
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Risk is uncertainty about the future
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The chance of loss is called Risk e.g. financial
                            loss due to house getting burnt
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The cause of risk is called Peril e.g. fire
                          </li>
                          <br />
                          <li>2.12 Risk</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Life insurance provides protection against those
                            risk events that can destroy or diminish the value
                            of human life as an asset.There are three typical
                            concerns which ordinary people face
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - General insurance deals with risk that effect
                            propriety such as fire, loss of cargo, theft,
                            burglary, motor accident, liability insurance and
                            personal risk.
                          </li>
                          <br />
                          <li>2.13 Insurer</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - A company who, through a contractual agreement,
                            undertakes to compensate specified losses,
                            liability, or damages incurred by another individual
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - An insurer is frequently an insurance company
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - An insurance company which agrees to pay someone
                            who pays them for insurance for losses suffered
                            pursuant to the terms of an insurance policy
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - For this benefit the customer pays the company a
                            fee, called a premium
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Insurers hire intermediaries to sell insurance
                            policies
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - These intermediaries could be agents,
                            Bancassurance, Insurance brokers and even comparison
                            websites
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Insurers may also use direct sales channels or
                            even conduct e – sales or online sales of their
                            products
                          </li>
                          <br />
                          <li>2.14 Agents</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Agents contribute the major percentage of
                            insurance sales in India
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - It is the agent’s primary responsibility to meet
                            the prospective client, understand their needs, and
                            accordingly recommend suitable products
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - They are hired by insurance companies and given
                            the required training
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - After passing the prescribed examination and
                            getting their licence, these agents seek and gain
                            insurance business for the insurer
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Agents are not on the payroll of the insurance
                            company but are paid commission based on the sales
                            they make
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Current regulations in India mean that an
                            individual can act as an insurance agent for only
                            one life insurance company at a time
                          </li>
                          <br />
                          <li>2.15 Banks</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Insurance companies partner with banks to sell
                            their products through them.
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Current regulations in India state that a bank can
                            only act as an insurance agent for three life
                            insurance companies at a time
                          </li>
                          <br />
                          <li>2.16 Brokers</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Broker can sell the products of a number of life
                            insurance companies
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - They have the advantage of being able of compare
                            the insurance products of various insurance
                            companies then a plan that best suits the
                            requirements of the customer
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The broker represents the client; they keep in
                            mind the customer's requirement rather than favoring
                            any specific products of any specific insurance
                            company
                          </li>
                          <br />
                          <li>2.17 Point of Sales Persons</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Point of Sales Persons (POSP) is an individual who
                            possesses desired minimum qualifications, has
                            undergone training for 15 Hours and passed the
                            examination as specified in the guidelines
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Minimum Qualification: He should be above 18 years
                            of age and should be minimum 10th standard pass
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - He is allowed to market only specific products as
                            mentioned in the guidelines
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - POSP is identified either by his PAN Card or
                            Aadhaar Card
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Every policy sourced by him, along with collected
                            documents, would be tagged to the POSP to understand
                            who is selling it
                          </li>
                          <br />
                          <li>2.18 Malhotra Committee and IRDAI</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Setup in 1993 to explore and recommend changes for
                            development of the industry including the
                            reintroduction of competition
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - IRDA has been subsequently renamed as IRDAI in
                            2014
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The passing of the Insurance Regulatory&
                            Development Act, 1999(IRDAI) led to the formation of
                            Insurance Regulatory and Development Authority of
                            India (IRDAI) in April 2000 as a statutory
                            regulatory body both for life, nonlife and health
                            insurance industry
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The Committee submitted its report in 1994.In 1997
                            the Insurance Regulatory Authority (IRA) was
                            established
                          </li>
                          <br />
                          <li>2.19 Current Scenario</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Currently, there are 24 life insurance companies
                            operating in India as detailed hereunder:
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - 23 life insurance companies in the private
                              sector
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - The postal department, under the Government of
                              India, also transacts life insurance business via
                              Postal Life Insurance, but is exempt from the
                              purview of the regulator
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Life Insurance Corporation (LIC) of India is a
                              public sector company
                            </li>
                          </ul>
                          <br />
                          <li>2.20 IRDAI</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The Insurance Regulatory and Development Authority
                            (IRDA) Act 1999 was passed by Parliament in December
                            1999. The Act provided for the establishment of the
                            IRDA as a corporate body:
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - To regulate, promote and ensure orderly growth
                              of the insurance industry
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - For other related matters
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - To protect the interest of holders of insurance
                              policies
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The IRDA Act 1999 led to amendments in the
                            Insurance Act 1938, the Life Insurance Corporation
                            Act 1956, and also the General Insurance Business
                            (Nationalisation) Act 1972
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Section 4 of the IRDA Act 1999 specifies the
                            composition of the IRDAI. It consists of a
                            Chairperson, not more than five whole-time members
                            and not more than four part-time members. All the
                            members are appointed by the Government of India.
                            Section 14 of the Act lays down the duties, powers
                            and functions of the IRDA
                          </li>
                          <br />
                          <li>
                            * The thumb rule is that one should have life
                            insurance 12 to 15 times his annual income
                          </li>
                          <br />
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        <b>3. - Practice of Insurance</b>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul className="pl-0">
                          <li>3.1 Insurance as a Contract</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - An Insurance Contract is an agreement, enforceable
                            by Law and is between the Insurance Company and the
                            Insured Person
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Insured person pay premium (Consideration) to the
                            Insurer
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - On happening of the specified event, Insurer pays
                            the a sum of money (Sum Assured) to the Insured
                            Person / Nominee
                          </li>
                          <br />
                          <li>3.2 Features of a Valid Contract</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Offer and Acceptance
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - A Contract comes into existence when one party
                              makes an Offer which the other party accepts
                              unconditionally
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Consideration
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - A contract must be supported by consideration in
                              order to be valid
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - The payment of money is a common form of
                              consideration, although not the only form
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - In terms of insurance policies, we refer to the
                              premium as the insured’s consideration
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Capacity to Contract
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - An individual is said to be competent to enter
                              into a contract if they are of the age of majority
                              (age 18)
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Of sound mind and
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Not disqualified, by law, from entering into
                              contracts
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Any contracts entered into by other than the
                              above people will be null and void
                            </li>
                          </ul>
                          <br />
                          <li>3.3 Features of a Valid Contract</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Consensus ad idem
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - This means both the parties to the contract must
                              understand and agree upon the same thing, in the
                              same sense
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - The proposer should have understood the features
                              of the insurance policy in the same sense (manner)
                              in which it was explained to them by the agent
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Legality of Object or Purpose
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - The objective of both the parties to the
                              contract should be to create a legal relationship
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - The purpose of the contract should also be legal
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Capability of Performance
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - The contract must be capable of being performed
                              by both the parties
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - For example, a person requesting life insurance
                              for a very high amount should be capable of paying
                              the premium required
                            </li>
                          </ul>
                          <br />
                          <li>3.4 Invalid Contract</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Coercion
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Coercion means applying pressure through
                              criminal means
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Undue Influence
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - When a person who is able to dominate the will
                              of another
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Uses his / her position to obtain an undue
                              advantage over the other
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Fraud
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - When a person induces to act on a false belief
                              that is caused by a representation he or she does
                              not believe to be true
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - It can arise either from deliberate concealment
                              of facts or through misrepresenting them
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Mistake
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Error in one’s knowledge or belief or
                              interpretation of a thing or event
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - This can lead to an error in understanding and
                              agreement about the subject matter of contract
                            </li>
                          </ul>
                          <br />
                          <li>3.5 Insurance Contract</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - When both the insured person and the insurance
                            company are clear as to the terms that have been
                            agreed between them, a policy is issued
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The policy contains all the details of cover,
                            period of cover, exceptions, conditions, the premium
                            and other relevant information
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The policy is not the contract of insurance in
                            itself; rather, it is evidence of the contract
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The absence or loss of the policy does not
                            invalidate the contract, but the policy is useful as
                            proof in the event of a dispute over the terms
                            agreed
                          </li>
                          <br />
                          <li>3.6 Premium</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Premium is the price paid by the insured for an
                            insurance policy
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - It is expressed at the rate of per thousand sum
                            assured
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Premium rates are available in the form of tables
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The printed rates on the tables are called as
                            office premium
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - These are level premiums which need to be paid on
                            yearly basis
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - In some policies, the premiums need to be paid
                            either once or only for a few years
                          </li>
                          <br />
                          <li>3.7 Rebates</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - 50% of Rebate for sum assured
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - The gain that an insurer enjoys while servicing
                              policies of high value are passed to the customer.
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - The cost incurred in servicing a 50,000 policy
                              is the same as servicing a 5,00,000 policy
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - 50% of Rebate for mode of premium
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - The cost incurred in servicing policies in
                              quarterly and monthly mode is higher than the cost
                              in case of yearly and half yearly mode.
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - In case of yearly mode, the entire premium is
                              available with the insurer for the whole year.
                            </li>
                          </ul>
                          <br />
                          <li>3.8 Extra Charges</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The premium quoted in the premium table is for
                            normal healthy individuals called standard life
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - An individual with health ailments is likely to
                            have a higher death risk and is charged extra
                            premium
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Extra premium may also be imposed on the basis of
                            occupational hazards as well
                          </li>
                          <br />
                          <li>3.9 Mortality and Interest</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Mortality is a measure of the number of deaths in
                            a population, scaled to the size of that population,
                            per year
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Interest is the return expected to be earned on
                            investment
                          </li>
                          <br />
                          <li>
                            3.10 Mortality and Interest – How it impacts the
                            premium?
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Mortality is the element in the premium determined
                            using a mortality table
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - If the mortality rate for a 35 year old person is
                            0.0035, it means 35 out of 10,000 would die before
                            reaching the age of 36
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The table may be used to calculate the mortality
                            cost for different ages. For a 35 year old person,
                            cost would be Rs.3.50 per thousand sum assured
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Prudent practice followed by insurers requires
                            them to assess the present value of future claims
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - In simple terms, the company needs to know how
                            much money is required to fulfil its obligations
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Higher the mortality rate, higher would be the
                            premium
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Higher the interest rate, lower would be the
                            premium
                          </li>
                          <br />
                          <li>3.11 Different Types of Expenses</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Life insurers have to incur various types of
                            operating expenses including:
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Agents training and recruitment,
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Commissions of agents,
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Staff salaries
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Office accommodation,
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Office stationery,
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Electricity charges,
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - System, IT, Policy Administrative System Cost
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Other miscellaneous etc.
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - All these have to be paid from premiums that are
                            collected by insurers. These expenses are loaded to
                            the premium.
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - A life insurer incurs two types of expenses:
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - The first, known as “New Business Expenses”, are
                              incurred at the beginning stage of the contract
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - The second type of expenses, known as “Renewal
                              Expenses,” is incurred during subsequent years.
                            </li>
                          </ul>
                          <br />
                          <li>3.12 Expenses</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Initial or new business expenses can be
                            substantial
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Life insurers are also required by law to hold
                            certain margins as reserves to ensure they can meet
                            their obligations, even when their actual experience
                            is worse than assumed
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The initial expenses along with the margins
                            required to be maintained as reserves are typically
                            higher than the initial premiums received{" "}
                          </li>
                          <br />
                          <li>3.13 Reserves</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Bonus
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Mortality, Interest, Expenses are main component
                              of the basis for reserve calculations
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Reserve is the money which insurer set aside to
                              meet the future obligations
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Reserve required to be calculated on a cautious
                              basis
                            </li>
                          </ul>
                          <br />
                          <li>3.14 Assumptions Made</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Life insurers must also be prepared for the
                            eventuality that the assumptions on basis of which
                            they set their premiums differs from actual
                            experience. Such a contingency can arise from two
                            reasons:
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Firstly the assumptions themselves may have been
                              inappropriate. For example the life insurer may
                              use a mortality table that does not reflect the
                              current mortality or has not adequately factored
                              for inflation
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Secondly there are random fluctuations that may
                              belie the assumptions.
                            </li>
                          </ul>
                          <br />
                          <li>3.15 Loading for Bonus</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Bonus is expressed as percentage of Sum Assured
                            and applicable to withprofit policies
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Terminal bonus, if any is usually credited and
                            paid at the time of exit(e.g maturity){" "}
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - There are two main type of bonus Reversionary
                            bonus and Terminal Bonus{" "}
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Reversionary bonus, if any is credited to policies
                            on a yearly basis and payable in full at the time of
                            maturity
                          </li>
                          <br />
                          <li>3.16 In nutshell…</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Gross Premium = Net Premium + Loading for Expenses
                            + Loading for Contingencies + Bonus Loading
                          </li>
                          <br />
                          <li>3.16 Grace Period</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Every life insurance contract undertakes to pay
                            the death benefit on the condition that the premiums
                            have been paid up to date and the policy is the
                            force
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The “Grace Period” clause grants he policyholder
                            an additional period of time to pay the premium
                            after it has become due
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The premium however remains due and if the
                            policyholder dies during this, the insurer may
                            deduct the premium from the death benefit
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - If premium remain unpaid even after the grace
                            period is over, the policy would then be considered
                            lapsed and the company is not under obligation to
                            pay the death benefit.
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The only amount payable would be whatever is
                            applicable under the non-forfieture provisions. In a
                            sense the insured may thus be said to have recevied
                            free insrance during the grace period
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The standard length of the grace period is one
                            month or 31 days. The days of grace may be computed
                            from the next day after the due date is fixed for
                            payment of the premium
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The provision enables a policy that would
                            otherwise have lapsed for nonpayment of premium, to
                            continue in force during the grace period
                          </li>
                          <br />
                          <li>3.17 Lapse and Reinstatement / Revival</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Policy is said to be in lapse condition if premium
                            has not been paid even after the completion of grace
                            period
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Life insurance contracts permit reinstatement
                            (revival) of a lapsed policy
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Reinstatement is the process by which a life
                            insurance company puts back into force a policy that
                            has either been terminated because of non-payment of
                            premiums or has been continued under one of the non
                            – forfeiture provisions
                          </li>
                          <br />
                          <li>3.18 Revival of Policy</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - A revival of the policy can be done only under
                            certain conditions:
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - No increase in risk for insurer
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Creation of reserve
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Revival application within specific time period{" "}
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Satisfactory evidence of continued insurability
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Payment of overdue premiums with interest
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Payment of outstanding loan, if any
                            </li>
                          </ul>
                          <br />
                          <li>3.19 Non-Forfeiture Provision</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - A
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - The Policy conditions provide various safeguards
                              to policyholders, when there is a premium default.
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - These provisions are called Non Forfeiture
                              provisions
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - B
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - If three years’ premiums have been paid and
                              discontinued thereafter, the non forfeiture clause
                              comes into play{" "}
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - C
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Consequently, Insurers do not forfeit the
                              premiums once the policy lapses after three years
                              of payment.{" "}
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - The Insurance Act does not allow such forfeiture
                            </li>
                          </ul>
                          <br />
                          <li>3.20 Non-Forfeiture Provision</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Cash Value or Surrender Value
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - To return to the policyholder an amount that
                              represents the reserve
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Surrender Value arrived as a percentage of
                              premiums paid is called Guaranteed Surrender Value
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Policy loans
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - The right to borrow money from the insurer by
                              using the cash value of the policy as a security
                              for the loan
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - The policy loan is limited to a percentage of
                              the policy’s surrender value (say 90%)
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - The policyholder borrows from his own account.
                            </li>
                          </ul>
                          <br />
                          <li>3.21 Nomination</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - As per section 39 of insurance act 1938, the
                            holder of a policy on his own life may nominate the
                            person or persons to whom the money secured by
                            policy shall be paid in the event of his death{" "}
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - It can be made at the time of proposal or at any
                            time during the currency of the policy
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - A nomination can be changed by the policy holder
                            by making endorsement on the policy
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The nominee has the right to receive the claim
                            money on the event of lifeinsured’s death
                          </li>
                          <br />
                          <li>3.22 Assignment</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - A life insurance policy is a property, it
                            represents rights and as per Transfer of Property
                            Act 1882, it is an actionable claim
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - An assignment transfers the rights, title &
                            interest of the policy from assignor to assignee
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The assignment can be done by an endorsement on
                            policy or by a separate deed
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Separate deed has to be stamped
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The assignment is effective as soon as it is
                            executed
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - It must be sent to the insurer along with a notice
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - In India assignment is governed by Section 38 of
                            Insurance Act.
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - On execution of the assignment the assignee gets
                            all rights title and interest in respect of property
                            assigned and becomes the owner of the policy,
                            subject to the provision that the assignee cannot
                            have a better title than the assignor
                          </li>
                          <br />
                          <li>3.23 Assignment</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Insurance companies partner with banks to sell
                            their products through them.
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Current regulations in India state that a bank can
                            only act as an insurance agent for three life
                            insurance companies at a time
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Absolute Assignment
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - The title cannot be reverted back to the
                              assignor unless the assignee reassigns the policy
                              through a deed
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Conditional Assignment
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - The title automatically reverts back to the
                              assignor on fulfilling of the specified condition
                            </li>
                          </ul>
                          <br />
                          <li>3.24 Alteration</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Provision to alterations in policy terms and
                            conditions is allowed, subject to consent of both
                            the insurer and assured.
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Alterations may not be permitted during the first
                            year of the policy, except for change in the mode of
                            premium or alterations which are of a compulsory
                            nature like:
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - change in name or / address;
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - readmission of age in case it is proved higher
                              or lower;
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - request for grant of double accident benefit or
                              permanent disability benefit etc.
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Some alterations may be affected by placing a
                            suitable endorsement on the policy or on a separate
                            paper. Other alterations, may require the
                            cancellation of existing policies and issue of new
                            policies
                          </li>
                          <br />
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        <b>4. - Principles of Insurance</b>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul className="pl-0">
                          <li>4.1 Principles of Life Insurance</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Insurable Interest
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - UtmostGood Faith
                          </li>
                          <br />
                          <li>4.2 Insurable Interest</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Insurable interest is a very important principle
                            of insurance
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - In order to take out insurance, an individual has
                            to have insurable interest in the subject matter
                            they wish to insure
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The subject matter is the event insured and can be
                            a person’s own life, the life of others or property
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Insurable interest forms the legal basis for
                            deciding whether insurance can be taken out or not
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Insurable interest is the legal right of the
                            person to insure the subject matter with which they
                            have a legal relationship recognized by law
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Insurable Interest distinguishes insurance
                            contract from wagering contract as later is not
                            enforceable in court of law
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - It would be relevant here to make a distinction
                            between the subject matter of insurance and the
                            subject matter of an insurance contract
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Subject matter of insurance relates to property
                            being insured against, which has an intrinsic value
                            of its own
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Subject matter of an insurance contract is the
                            insured’s financial interest in that property
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - It is only when the insured has such an interest
                            in the property that he has the legal right to
                            insure
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The insurance policy actually covers the insured’s
                            financial interest in the property
                          </li>
                          <br />
                          <li>4.3 Existence of Insurable Interest</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Self
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Spouse
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Assets
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Parents
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Children
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Creditor in Debtor (to the extent of outstanding
                            loan amount)
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Employer in Employees
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Employees in Employer (to the extent of
                            outstanding payments from employer)
                          </li>
                          <br />
                          <li>4.4 Important Points</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Court judgments have established the circumstances
                            in which Insurable Interest is deemed to exist
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - In life insurance, Insurable Interest needs to
                            exist (be proven) at the time of taking out the
                            policy, i.e. at the inception of the policy
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - In event of a claim, Insurable Interest may or may
                            not exist and is not required to be proved
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Insurable interest is the legal right of the
                            person to insure the subject matter with which they
                            have a legal relationship recognised by law
                          </li>
                          <br />
                          <li>4.5 Case Study</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Imagine the following scenario…
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Ganesh is a 30-year-old man working for a
                              multinational company (MNC)
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - His wife works for a domestic firm and she is a
                              coapplicant in the loan on their home together
                              with Ganesh
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Whilst Ganesh has a well – paid job and is
                              managing the monthly living expenses, he has a
                              running home loan and a car loan to take care of
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - He has worked hard to build these assets and so
                              far everything has been going as Ganesh has
                              planned
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Scenario 1: Ganesh meets with an accident and is
                            hospitalised for a month
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - He will not be able to work for at least a month
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - He will not receive a salary for that time and
                              will also have to pay his hospital bills which
                              could be very expensive
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - To avoid this situation he should ensure that he
                              has adequate health insurance to cover him against
                              unexpected medical emergencies and to cover him
                              against loss of pay if he is absent from work due
                              to medical reasons
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Scenario 2: Ganesh’s wife dies unexpectedly
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Ganesh’s wife, apart from contributing to the
                              family income, also takes care of the family
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Following her unexpected death, Ganesh will face
                              financial difficulties in repaying the home loan
                              and meeting other financial commitments{" "}
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - To protect against the above scenario Ganesh can
                              take out life insurance on his wife’s life which
                              will pay out in the event of her unexpected death,
                              thus ensuring that the family’s finances are not
                              put in jeopardy
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Ganesh’s wife can also take out life insurance
                              on Ganesh’s life which would pay out on his
                              unexpected death
                            </li>
                          </ul>
                          <br />
                          <li>4.6 What is Principle of Utmost Good Faith?</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Concept of Uberrima Fides:
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - It is “a positive duty to voluntarily disclose,
                              accurately and fully, all facts material to the
                              risk being proposed, whether requested or not"
                            </li>
                          </ul>
                          <br />
                          <li>4.7 Why Utmost Good Faith?</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Insurance contracts stand on a different footing
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Firstly, the subject matter of the insurance is
                            intangible and cannot be easily known through direct
                            observation or experience by the insurer
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Again there are many facts, which by their very
                            nature, may be known only to the proposer. The
                            insurer has to often rely entirely on the latter for
                            information
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Hence the proposer has a legal duty to disclose
                            all material information about the subject matter of
                            insurance to the insurers who do not have this
                            information
                          </li>
                          <br />
                          <li>* Example</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - 30 year old David made a proposal for an insurance
                            policy
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - David was suffering from Diabetes which he did not
                            disclose this fact to the insurance company
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Being in thirties, insurance company issued the
                            policy without asking David to undergo medical tests
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Few years later, David’s health deteriorated and
                            was hospitalised
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - David could not recover and died. A claim was
                            raised on insurance company
                          </li>
                          <br />
                          <li>4.8 Why Utmost Good Faith?</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - To the surprise of David’s nominee, the claim was
                            rejected
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Upon investigation, insurance company found out
                            that David suffered from diabetes when he purchased
                            the policy
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - This fact was deliberately and intentionally
                            hidden by David
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Hence the insurance contract was declared null and
                            void and the claim was rejected
                          </li>
                          <br />
                          <li>
                            4.9 Utmost Good Faith applies to both the insured
                            and the insurer
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - If utmost good faith is not observed by either
                            party, the contract may be avoided by the other.
                            This essentially means that no one should be allowed
                            to take advantage of his own wrong especially while
                            entering into a contract of insurance
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - An individual has a congenital hole in the heart
                              and reveals it in the proposal form. The same is
                              accepted by the insurer and proposer is not
                              informed that pre-existing diseases are not
                              covered for at least 4 years. This is misleading
                              of facts by the insurer
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - An executive is suffering from hypertension and
                              had a mild heart attack recently following which
                              he decides to take a medical policy but does not
                              reveal the same. The insurer is thus duped into
                              accepting the proposal due to misrepresentation of
                              facts by insured
                            </li>
                          </ul>
                          <br />
                          <li>4.10 What is a Material Fact?</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - A fact that would affect the judgment of a prudent
                            insurance underwriter in deciding whether to accept
                            the risk and if so, the rate of premium and the
                            terms and conditions{" "}
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - An individual with health ailments is likely to
                            have a higher death risk and is charged extra
                            premium
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Extra premium may also be imposed on the basis of
                            occupational hazards as well
                          </li>
                          <br />
                          <li>
                            4.11 What material facts need to be disclosed?
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The law imposes an obligation to disclose all
                            material facts
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Facts that increase the risk being covered
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Past policies taken from all insurers and their
                              status
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - All questions in the proposal form are
                              considered to be material
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Questions are related to the life insured and
                              its exposure to risk
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - They need to be answered truthfully in all
                              respects
                            </li>
                          </ul>
                          <br />
                          <li>
                            4.12 What material facts need to be disclosed?
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Measures implemented to reduce the risk
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - The presence of a fire extinguisher
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Facts which the insured does not know or is
                            unaware of
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - An individual, who suffers from high blood
                              pressure but was unaware about the same at the
                              time of taking the policy, cannot be charged with
                              non-disclosure of this fact
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Which could be discovered, by reasonable
                            diligence?
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - It is not necessary to disclose every minute
                              material fact. The underwriters must be conscious
                              enough to ask for the same if they require further
                              information.
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Matters of law
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Everybody is supposed to know the law of the
                              land.
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - About which insurer appears to be indifferent (or
                            has waived the need for further information)
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - The insurer cannot later disclaim responsibility
                              on grounds that the answers
                            </li>
                          </ul>
                          <br />
                          <li>4.12 Utmost Good Faith</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Non – Disclosure
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - If the insured is in material breach of the duty
                              of disclosure, the insurer may avoid the contract
                              entirely, ab initio (from the beginning)
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - In other words, no claims are payable. If the
                              non-disclosure is fraudulent (often termed
                              ‘concealment’) the insurer may keep the premium
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Lapsed Policies
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - In the event that a lapsed policy is revived,
                              the insurance company may ask the insured to
                              disclose all material facts along with proof of
                              continued good health
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - In certain cases, the insurer may ask the
                              insured to undergo medical tests and decide about
                              reviving the policy
                            </li>
                          </ul>
                          <br />
                          <li>4.13 Representation</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Any statement made during negotiation of a
                            contract of insurance is called representation
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - A representation may be a definite statement of
                            fact or a statement of belief, intention or
                            expectation that must be substatially correct. When
                            it comes to Representations that concern matters of
                            belief or expectation, it is held that these must be
                            made in good faith
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Innocent Misrepresentation
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Innocent Misrepresentation relates to inaccurate
                              statements, which are made without any fraudulent
                              intention
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Fraudulent Misrepresentation on the other hand
                              refers to false statements that are made with
                              deliberate intent to deceive the insurer or are
                              made recklessly without due regard for truth
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - An insurance contract generally becomes void when
                            there is a clear case of concealment with intent to
                            deceive, or when there is fraudulent
                            misrepresentation
                          </li>
                          <br />
                          <li>4.14 Premium Payment Modes</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Annual
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Half - Yearly
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Quarterly
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Monthly
                          </li>
                          <br />
                          <li>4.14 Premium Payment Frequency</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Single Premium
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Limited Premium
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Regular Premium
                          </li>
                          <br />
                          <li>4.14 Principle of Indemnity</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Indemnity means that in the event of a loss the
                            insurance company indemnifies (compensates) the
                            insured for the loss they incur, under the terms and
                            conditions of the policy
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Insurance cannot be used to make a profit.
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The principle of indemnity makes sure that the
                            insured is compensated only to the extent to which
                            they have suffered a loss.
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Thus the insured cannot profit from insurance
                          </li>
                          <br />
                          <li>4.15 Example – General Insurance</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Suresh has taken out an individual health
                            insurance policy with a sum insured of Rs. 2,00,000.
                            Suresh falls ill and has to be hospitalized,
                            resulting in a hospital bill of Rs. 40,000. So in
                            this case the insurance company will compensate
                            (indemnify) Suresh with Rs. 40,000
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - General insurance policies and health insurance
                            policies are contracts of indemnity whereby the
                            insured is compensated for the loss incurred in line
                            with the principles explained above
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - But the same does not apply to life insurance
                          </li>
                          <br />
                          <li>4.16 Example – Life Insurance</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Indemnity
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - If Ajit has taken out an endowment policy of
                              Rs.1,00,000 for 10 years with an annual premium
                              payment of Rs. 10,000 and he dies in the fourth
                              year of the policy, the beneficiary will get the
                              full amount of Rs. 1,00,000 (plus the bonuses
                              accumulated up to that point), even though Ajit
                              has paid premiums for only four years
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Life Insurance contracts are also known as value
                              contracts and the principle of indemnity does not
                              apply to them. In the case of life insurance, even
                              if a person takes out multiple policies, the
                              insured’s death will result in all the insurance
                              companies paying the full sums insured
                            </li>
                          </ul>
                          <br />
                          <li>4.17 Section 64VB of Insurance Act, 1938</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Section 64VB
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - No insurer shall assume any risk in India in
                              respect of any insurance business on which premium
                              is not ordinarily payable outside India unless and
                              until the premium payable is received by him or is
                              guaranteed to be paid by such person in such
                              manner and within such time as may be prescribed
                              or unless and until deposit of such amount as may
                              be prescribed, is made in advance in the
                              prescribed manner
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - For the purposes of this section, in the case of
                              risks for which premium can be ascertained in
                              advance, the risk may be assumed not earlier than
                              the date on which the premium has been paid in
                              cash or by cheque to the insurer
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Explanation – Where the premium is tendered by
                              postal money order or cheque sent by post, the
                              risk may be assumed on the date on which the money
                              order is booked or the cheque is posted, as the
                              case may be
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Any refund of premium which may become due to an
                              insured on account of the cancellation of a policy
                              or alteration in its terms and conditions or
                              otherwise shall be paid by the insurer directly to
                              the insured by a crossed or order cheque or by
                              postal money order and a proper receipt shall be
                              obtained by the insurer from the insured, and such
                              refund shall in no case be credited to the account
                              of the agent
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Where an insurance agent collects a premium on a
                              policy of insurance on behalf of an insurer, he
                              shall deposit with, or despatch by post to, the
                              insurer, the premium so collected in full without
                              deduction of his commission within twenty four
                              hours of the collections excluding bank and postal
                              holidays
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - The Central Government may, by rules, relax the
                              requirements of sub section (1) in respect of
                              particular categories in insurance policies
                            </li>
                          </ul>
                          <br />
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        <b>5. - Documentation</b>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul className="pl-0">
                          <li>5.1 Prospectus</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - A prospectus is a formal legal document used by
                            insurance companies that provides details about the
                            product
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - It is like an introductory document that helps the
                            prospective policyholder to get familiar with the
                            company’s products
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - It contains all facts that are necessary for a
                            prospective policyholder to make an informed
                            decision regarding purchase of a policy
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The prospectus used by a life insurance company
                            should state the following, under each of its plans
                            of insurance:
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Terms & Conditions
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Scope of Benefits (Guaranteed and Non –
                              Guaranteed)
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Entitlements
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Exceptions
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Whether the plan is Participative or Non -
                              Participative
                            </li>
                          </ul>
                          <br />
                          <li>5.2 Proposal Form</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The proposal form has been defined under IRDA
                            Protection of Policyholders’ Interests, 2002, as:
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - “It means a form to be filled by the proposer for
                            furnishing all material information required by the
                            insurer in respect of a risk, in order to enable the
                            insurer to decide whether to accept or decline, to
                            undertake the risk, and in the event of acceptance
                            of the risk, to determine the rates, terms and
                            conditions of a cover period to be granted”
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - “Material” for the purpose of these regulations
                            shall mean and include all important, essential and
                            relevant information in the context of underwriting
                            the risk to be covered by the insurer
                          </li>
                          <br />
                          <li>5.3 Proposal Form – Important Information</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - While the IRDA defined the proposal form, the
                            design and content of the form was left open to the
                            discretion of the insurance company
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - However based on the feedback received from the
                            policyholders, intermediaries, ombudsman and
                            insurance companies, the IRDA felt it necessary to
                            standardise the form.
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The IRDA has issued the IRDA (Standard Proposal
                            Form for Life Insurance) Regulations, 2013
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - While the IRDA has prescribed the design and
                            content, it has provided flexibility to the
                            insurance companies for seeking additional
                            information.
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The proposal form carries detailed instructions
                            not only for the proposer and the proposed life
                            insured but also for the intermediary who solicits
                            the policy and assists in filling up the form
                          </li>
                          <br />
                          <li>5.4 Agent’s Report</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Agent is the primary underwriter should include
                            all material facts and particulars about the
                            policyholder, relevant to risk assessment
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Matter of health, habits, occupation, income and
                            family details need to be mentioned
                          </li>
                          <br />
                          <li>5.5 Medical Examiner's Report</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Details pertaining to physical features like
                            height, weight, blood pressure, cardiac status etc.
                            are recorded and mentioned by the doctor in his
                            report called the medical examiner’s report
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - It is required typically when the proposal cannot
                            be considered under non-medical underwriting as
                            either the sum proposed or the age of the life
                            insured is high or medical check-up is required
                          </li>
                          <br />
                          <li>5.6 Moral Hazard Report</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Moral hazard is the likelihood that a client's
                            behaviour might change as a result of purchasing a
                            life insurance policy and such a change would
                            increase the chance of a loss
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - It has to be submitted by an official of the
                            insurance company
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Before completion of the report the reporting
                            official should satisfy himself regarding the
                            identity of the proposer
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - He should meet him preferably at his residence
                            before completing the report{" "}
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The reporting official should make independent
                            enquiries about the life to be assureds’ health and
                            habits, occupation, income, social background and
                            financial position etc
                          </li>
                          <br />
                          <li>5.7 Age Proofs</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Standard Age Proof
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - School or College Certificate
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Passport
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - PAN Card
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Service Register
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Certificate of Baptism
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Certified extract from a family bible if it
                              contains date of birth
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Identity Card in case of Defence Personnel{" "}
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Marriage Certificate issued by a Roman Catholic
                              Church
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Non – Standard Age Proof
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Horoscope
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Ration Card
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - An affidavit by way of self – declaration
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Certificate from Village Panchayat
                            </li>
                          </ul>
                          <br />
                          <li>5.8 First Premium Receipt (FPR)</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - An insurance contract commences when the life
                            insurance company issues a first premium receipt
                            (FPR){" "}
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The FPR is the evidence that the policy contract
                            has begun
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The first premium receipt contains the following
                            information
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Name and address of the life assured
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Policy number
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Premium amount paid
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Method and frequency of premium payment
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Next due date of premium payment
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Date of commencement of the risk
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Date of final maturity of the policy
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Date of payment of the last premium
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Sum assured
                            </li>
                          </ul>
                          <br />
                          <li>5.9 Renewal Premium Receipt (RPR)</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - After the issue of the FPR, the insurance company
                            will issue subsequent premium receipts when it
                            receives further premiums from the proposer
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - These receipts are known as renewal premium
                            receipts (RPR)
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The RPRs act as proof of payment in the event of
                            any disputes related to premium payment
                          </li>
                          <br />
                          <li>5.10 Policy Document</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The policy document is the evidence of the
                            contract between the assured and the insurance
                            company
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - It is not the contract in itself
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - If the policy document is lost by the policy
                            holder, it does not affect the insurance contract
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The insurance company will issue a duplicate
                            policy without making any changes to the contract
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The policy document has to be signed by a
                            competent authority and should be stamped according
                            to the Indian Stamp Act
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Key Components
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              * Policy Schedule
                            </li>
                            <li
                              style={{
                                paddingLeft: "48px",
                                marginBottom: "3px",
                              }}
                            >
                              - Policy Schedule of life insurance contracts
                              would contain the following information:
                            </li>
                            <ul style={{ paddingLeft: "64px" }}>
                              <li
                                style={{
                                  paddingLeft: "24px",
                                  marginBottom: "3px",
                                }}
                              >
                                - Name of the insurance company
                              </li>
                              <li
                                style={{
                                  paddingLeft: "24px",
                                  marginBottom: "3px",
                                }}
                              >
                                - Some specific details for the particular
                                policy
                              </li>
                              <li
                                style={{
                                  paddingLeft: "24px",
                                  marginBottom: "3px",
                                }}
                              >
                                - The insurer’s promise to pay. This forms the
                                heart of the insurance contract
                              </li>
                              <li
                                style={{
                                  paddingLeft: "24px",
                                  marginBottom: "3px",
                                }}
                              >
                                - The signature of the authorised signatory and
                                policy stamp
                              </li>
                              <li
                                style={{
                                  paddingLeft: "24px",
                                  marginBottom: "3px",
                                }}
                              >
                                - The address of the local Insurance Ombudsman
                              </li>
                            </ul>
                            <li
                              style={{
                                paddingLeft: "48px",
                                marginBottom: "3px",
                              }}
                            >
                              - Some specific details for the particular policy
                              like:
                            </li>
                            <ul style={{ paddingLeft: "48px" }}>
                              <li
                                style={{
                                  paddingLeft: "24px",
                                  marginBottom: "3px",
                                }}
                              >
                                - Policy owner’s name and address
                              </li>
                              <li
                                style={{
                                  paddingLeft: "24px",
                                  marginBottom: "3px",
                                }}
                              >
                                - Date of birth and age last birthday
                              </li>
                              <li
                                style={{
                                  paddingLeft: "24px",
                                  marginBottom: "3px",
                                }}
                              >
                                - Plan and term of policy contract
                              </li>
                              <li
                                style={{
                                  paddingLeft: "24px",
                                  marginBottom: "3px",
                                }}
                              >
                                - Sum assured
                              </li>
                              <li
                                style={{
                                  paddingLeft: "24px",
                                  marginBottom: "3px",
                                }}
                              >
                                - Amount of premium
                              </li>
                              <li
                                style={{
                                  paddingLeft: "24px",
                                  marginBottom: "3px",
                                }}
                              >
                                - Premium paying term
                              </li>
                              <li
                                style={{
                                  paddingLeft: "24px",
                                  marginBottom: "3px",
                                }}
                              >
                                - Date of commencement, date of maturity and due
                                date of last premium
                              </li>
                              <li
                                style={{
                                  paddingLeft: "24px",
                                  marginBottom: "3px",
                                }}
                              >
                                - Whether policy is with or without profits
                              </li>
                              <li
                                style={{
                                  paddingLeft: "24px",
                                  marginBottom: "3px",
                                }}
                              >
                                - Name of nominee
                              </li>
                              <li
                                style={{
                                  paddingLeft: "24px",
                                  marginBottom: "3px",
                                }}
                              >
                                - Mode of premium payment: yearly, half –
                                yearly, quarterly, monthly or via deduction from
                                salary
                              </li>
                              <li
                                style={{
                                  paddingLeft: "24px",
                                  marginBottom: "3px",
                                }}
                              >
                                - The policy number – which is the unique
                                identity number of the policy contract
                              </li>
                            </ul>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              * Standard Provisions
                            </li>
                            <ul style={{ paddingLeft: "48px" }}>
                              <li
                                style={{
                                  paddingLeft: "24px",
                                  marginBottom: "3px",
                                }}
                              >
                                - The second component of the policy document is
                                made up of standard policy provisions, which are
                                normally present in all life insurance
                                contracts, unless specifically excluded
                              </li>
                              <li
                                style={{
                                  paddingLeft: "24px",
                                  marginBottom: "3px",
                                }}
                              >
                                - Some of the provisions may not be applicable
                                in the case of certain kinds of contracts, like
                                term, single premium or nonparticipating (in
                                profits) policies
                              </li>
                              <li
                                style={{
                                  paddingLeft: "24px",
                                  marginBottom: "3px",
                                }}
                              >
                                - Standard provisions define the rights and
                                privileges and other conditions, which are
                                applicable under the contract
                              </li>
                            </ul>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              * Specific Policy Provisions
                            </li>
                            <ul style={{ paddingLeft: "48px" }}>
                              <li
                                style={{
                                  paddingLeft: "24px",
                                  marginBottom: "3px",
                                }}
                              >
                                - The third part of the policy document consists
                                of specific policy provisions that are specific
                                to the individual policy contract
                              </li>
                              <li
                                style={{
                                  paddingLeft: "24px",
                                  marginBottom: "3px",
                                }}
                              >
                                - Specific policy provisions may be printed on
                                the face of the document or inserted separately
                                in the form of an attachment
                              </li>
                              <li
                                style={{
                                  paddingLeft: "24px",
                                  marginBottom: "3px",
                                }}
                              >
                                - While standard policy provisions, like days of
                                grace or nonforfeiture in case of lapse, are
                                often statutorily provided under the contract,
                                specific provisions generally are linked to the
                                particular contract between the insurer and
                                insured
                              </li>
                              <li
                                style={{
                                  paddingLeft: "24px",
                                  marginBottom: "3px",
                                }}
                              >
                                - Example: A clause precluding death due to
                                pregnancy for a lady who is expecting at the
                                time of writing the contract
                              </li>
                            </ul>
                          </ul>
                          <br />
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>
                        <b>6. - Point of Sales: Life Insurance Guidelines</b>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul className="pl-0">
                          <li>6.1 POSP – LI: Guidelines</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Every proposal form and insurance policy and other
                            related documents shall be tagged to POSP by
                            capturing Aadhaar Card number of PAN Card number of
                            POSP
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The life insurer shall be responsible to record
                            Aadhaar Card number or PAN Card number in proposal
                            form and insurance policy
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - For sales effected through insurance intermediary,
                            the intermediary shall be responsible to record
                            Aadhaar Card number or PAN Card number in proposal
                            form and the insurer shall record the same in the
                            insurance policy
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The insurer / intermediary appointing POSP shall
                            be responsible for the conduct of the POSP and any
                            misconduct by POSP shall be liable for penalty under
                            Section 102 of the Act
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - One of the factors to be considered while renewing
                            the certificate of registration of the insurance
                            intermediary shall be the conduct of the POSP
                            working with the intermediary
                          </li>
                          <br />
                          <li>6.2 Guidelines – Tagging of Policies</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - An insurer or insurance intermediary can appoint a
                            POSP
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - POSP engaged by an insurance intermediary can sell
                            products of all life insurers whose products the
                            intermediary is authorised to sell
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - POSP shall be identified by his Aadhaar Card or
                            PAN Card
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - POSP shall be at least 18 years of age and shall
                            have educational qualifications of 10th standard
                            pass
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - POSP – LI is authorised to source only POSP Life
                            Insurance products
                          </li>
                          <br />
                          <li>6.3 Role of POS Person</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - POS person should meet the proposer and life
                            assured personally
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - POS person should conduct need analysis and
                            recommend product based on need analysis
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - POS person should clearly explain all the benefits
                            and limitations of the product being offered
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - POS person should encourage the prospect to
                            disclose all the material information truthfully
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - POS person can only sell POS products and no other
                            products
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - POS person has to mandatorily undergo 15 hours
                            training and pass the required exam to sell the
                            products
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - POS person has to mandatorily undergo 15 hours
                            training and pass the required exam to sell the
                            products
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Conduct of Point of Sales Person (appointed by an
                            insurance intermediary) shall be one of the points
                            considered for the renewal of Certificate of
                            Registration of insurance intermediary
                          </li>
                          <br />
                          <li>6.4 Guidelines – Tagging of Policies</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Insurer or Intermediary shall conduct an in –
                            house 15 hours training
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Insurer or Intermediary shall conduct an
                            examination after completion of training
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Upon successfully passing the examination, he /
                            she shall be awarded certificate
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Insurer or Intermediary shall enter into a written
                            agreement with successful candidates specifying
                            terms and conditions
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Insurer or Intermediary shall maintain training
                            and examination records for at least 5 years
                          </li>
                          <br />
                          <li>6.5 POSP – LI: Product Categories</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Point of Sales (POS) Product means the simple
                            vanilla type of product wherein each and every
                            benefit is predefined and disclosed upfront clearly
                            at the time of sale itself and is very simple to
                            understand
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Pure term insurance product with or without
                              return of premium
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Non – Linked Non – Participating Endowment
                              Product (Money Back feature also allowed)
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Immediate Annuity Product
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Non – Linked Non – Par health insurance products
                              with fixed benefits
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Any other product / product category, if
                              permitted by the Authority
                            </li>
                          </ul>
                          <br />
                          <li>
                            6.6 Pure Term Plan (With or Without Return of
                            Premium)
                          </li>
                          <br />
                          <li>
                            6.7 Non – Linked Non – Participating Endowment
                            Product{" "}
                          </li>
                          <br />
                          <li>6.8 Immediate Annuity Product</li>
                          <br />
                          <li>
                            6.9 Non – Linked Non – Par Health Insurance Products
                            with fixed benefits
                          </li>
                          <br />
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                      <Accordion.Header>
                        <b>7. - Miscellaneous</b>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul className="pl-0">
                          <li>7.1 AML / KYC Guidelines</li>
                          <br />
                          <li>7.2 Do’s of POSP</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Meeting the Customer
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Meet both the proposer and life assured
                              personally during the solicitation.
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Conduct a proper assessment of the physical,
                              financial and general well - being of the prospect
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Perform Need Analysis
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Discuss the needs of the customer and intention
                              for a life insurance policy
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Discuss the available disposable income to
                              justify the proposed premium
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Discuss IndiaFirst Life POS products which will
                              satisfy customer needs and ensure that suggested
                              product is suitable as per Customer’s Age, Life
                              Stage, Risk Appetite, Existing Life Cover and
                              Occupation
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Key Disclosures{" "}
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Clearly explain the policy that is being offered
                              and the benefits associated with the same
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Disclosures
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Prospect to be encouraged to truthfully disclose
                              all information required in the proposal form and
                              also explain the benefits of doing the same,
                              ensuring that claim is not repudiated at a later
                              stage due to non – disclosure
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Prospect to be encouraged to personally fill or
                              cross – check all sections of the proposal form
                              truthfully
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - KYC Obligations
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Prospect should be encouraged to provide their
                              Aadhaar number for ekyc and explain the benefits
                              of the same for easier processing
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - POS Person should be satisfied about the
                              identity of the prospect and necessary
                              documentation related to KYC
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - POS Person must verify all documents collected
                              from the customer in case of non – e KYC process
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Premium Deposit
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - POS – Person must ensure that premium collected
                              to be deposited to IndiaFirst Life Office/Account
                              within 24 hours of collection
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            * Important Information
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Prospect must be informed about all relevant
                              information on the POS Product features, terms and
                              conditions, various taxes and tax benefits, if
                              any, under a product
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Prospect must be informed about their right to
                              cancel the policy within 15 days of receiving the
                              policy document in the event of dissatisfaction
                              over terms and condition
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Inform the prospect that if the proposal is not
                              accepted for any what so ever, the refund of
                              payment would be done to them within 7 days from
                              the date of decision
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - POS – Person must share the copy of Key Feature
                              Document with the prospect at point of sale
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Check for the complete information populated in
                              the Key Feature Document along with the reference
                              number
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - If required, POS – Person to show their
                              certification to the prospect
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Mis – selling
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Do not sell insurance as a fix deposit,
                              recurring deposit or mutual funds
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Do not sell regular premium plans as single
                              premium plans
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Do not provide any offer/rebate/discount/gift to
                              the prospect for buying an insurance
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Do not encourage customers to surrender or
                              discontinue their existing policies and buy a new
                              one
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Information
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Do not provide your contact/email/address/mobile
                              no. in the proposal form instead of customers
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Do not accept blank or incomplete application
                              forms
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Do not Conceal or misrepresent any material
                              information that might impact the underwriting
                              decision or claim
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Do not share unapproved marketing
                              collaterals/advertisements with the prospect
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Collection
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Do not collect blank cheques against premium
                              from the prospect
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Mis – Doings
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Do not use customer premium for personal
                              transactions
                            </li>
                          </ul>
                          <br />
                          <li>7.3 Complaint Escalation</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - If the complaint is not resolved within the
                            specified TAT or the customers feel that the closure
                            has been unsatisfactory, they can escalate the
                            complaint
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Please note, on behalf of the customer, you too
                            can escalate a customer complaint on your own
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - It is preferable that you take an active part in
                            resolving the complaint without asking the customer
                            to do it himself
                          </li>
                          <br />
                          <li>7.4 Steps Involved</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Once you receive the complaint, register the same
                            with the call centre and give the registration
                            number to the customer
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Follow – up with the customer in case any
                            additional details required to be provided and give
                            the same to the call centre
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Always keep the expectations of the customer
                            reasonable. Do not over promise
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Once TAT is over, check the status with the call
                            centre and update the customer
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - If the complaint is not resolved follow the
                            escalation matrix
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Once resolved either way, re-establish contact
                            with the customer
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Keep in mind what went wrong and make sure the
                            same things do not happen again, if within your
                            control
                          </li>
                          <br />
                          <li>7.5 Escalation Matrix</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - First Escalation: Manager – Customer Service;
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Name: Viral Joshi;
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Email: viral.joshi@indiafirstlife.com
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Second Escalation: Senior Manager – Customer
                            Service;
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Name: Sandeep Shetty;
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Email: sandeep.shetty@indiafirstlife.com
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Third Escalation: Head – Customer Service and
                            Channel Services;
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Name: Naman Gupta;
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Email: naman.gupta@indiafirstlife.com
                            </li>
                          </ul>
                          <br />
                          <li>7.6 IRDA’s Policy on Complaint Management</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Customers who have complaints are required to
                            first approach the Grievance/ Customer Complaints
                            Cell of our company
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - If the customers do not receive a response from us
                            within a reasonable period of time or if they are
                            dissatisfied with our response, they can then
                            approach the Grievance Cell of the IRDA
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The IRDA has set up a web-based complaint tracking
                            system.
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - This enables the complainant and the IRDA to check
                            the status of the complaint.
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The complaints registered by an insurer are
                            integrated with the IGMS (Integrated Grievance
                            Management system) which are monitored by the
                            regulator from time to time
                          </li>
                          <br />
                          <li>7.7 Insurance Ombudsman</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The Government of India has appointed 17 Insurance
                            Ombudsmen across the country
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - They have been assigned different areas of
                            jurisdiction.
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Their functions include –
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Receiving and considering complaints from any
                              individual with respect to personal line products
                              against us.
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Based on the case they make an award with us
                              that we need to honor within three months
                            </li>
                          </ul>
                          <br />
                          <li>7.8 Extra Charges</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The premium quoted in the premium table is for
                            normal healthy individuals called standard life
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - An individual with health ailments is likely to
                            have a higher death risk and is charged extra
                            premium
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Extra premium may also be imposed on the basis of
                            occupational hazards as well
                          </li>
                          <br />
                          <li>7.9 Mortality and Interest</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Mortality is a measure of the number of deaths in
                            a population, scaled to the size of that population,
                            per year
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Interest is the return expected to be earned on
                            investment
                          </li>
                          <br />
                          <li>
                            7.10 Mortality and Interest – How it impacts the
                            premium?
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Mortality is the element in the premium determined
                            using a mortality table
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - If the mortality rate for a 35 year old person is
                            0.0035, it means 35 out of 10,000 would die before
                            reaching the age of 36
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The table may be used to calculate the mortality
                            cost for different ages. For a 35 year old person,
                            cost would be Rs.7.50 per thousand sum assured
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Prudent practice followed by insurers requires
                            them to assess the present value of future claims
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - In simple terms, the company needs to know how
                            much money is required to fulfil its obligations
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Higher the mortality rate, higher would be the
                            premium
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Higher the interest rate, lower would be the
                            premium
                          </li>
                          <br />
                          <li>7.11 Different Types of Expenses</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Life insurers have to incur various types of
                            operating expenses including:
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Agents training and recruitment,
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Commissions of agents,
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Staff salaries
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Office accommodation,
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Office stationery,
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Electricity charges,
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - System, IT, Policy Administrative System Cost
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Other miscellaneous etc.
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - All these have to be paid from premiums that are
                            collected by insurers. These expenses are loaded to
                            the premium.
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - A life insurer incurs two types of expenses:
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - The first, known as “New Business Expenses”, are
                              incurred at the beginning stage of the contract
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - The second type of expenses, known as “Renewal
                              Expenses,” is incurred during subsequent years.
                            </li>
                          </ul>
                          <br />
                          <li>7.12 Expenses</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Initial or new business expenses can be
                            substantial
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Life insurers are also required by law to hold
                            certain margins as reserves to ensure they can meet
                            their obligations, even when their actual experience
                            is worse than assumed
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The initial expenses along with the margins
                            required to be maintained as reserves are typically
                            higher than the initial premiums received{" "}
                          </li>
                          <br />
                          <li>7.13 Reserves</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Bonus
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Mortality, Interest, Expenses are main component
                              of the basis for reserve calculations
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Reserve is the money which insurer set aside to
                              meet the future obligations
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Reserve required to be calculated on a cautious
                              basis
                            </li>
                          </ul>
                          <br />
                          <li>7.14 Assumptions Made</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Life insurers must also be prepared for the
                            eventuality that the assumptions on basis of which
                            they set their premiums differs from actual
                            experience. Such a contingency can arise from two
                            reasons:
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Firstly the assumptions themselves may have been
                              inappropriate. For example the life insurer may
                              use a mortality table that does not reflect the
                              current mortality or has not adequately factored
                              for inflation
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Secondly there are random fluctuations that may
                              belie the assumptions.
                            </li>
                          </ul>
                          <br />
                          <li>7.15 Loading for Bonus</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Bonus is expressed as percentage of Sum Assured
                            and applicable to withprofit policies
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Terminal bonus, if any is usually credited and
                            paid at the time of exit(e.g maturity){" "}
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - There are two main type of bonus Reversionary
                            bonus and Terminal Bonus{" "}
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Reversionary bonus, if any is credited to policies
                            on a yearly basis and payable in full at the time of
                            maturity
                          </li>
                          <br />
                          <li>7.16 In nutshell…</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Gross Premium = Net Premium + Loading for Expenses
                            + Loading for Contingencies + Bonus Loading
                          </li>
                          <br />
                          <li>7.16 Grace Period</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Every life insurance contract undertakes to pay
                            the death benefit on the condition that the premiums
                            have been paid up to date and the policy is the
                            force
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The “Grace Period” clause grants he policyholder
                            an additional period of time to pay the premium
                            after it has become due
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The premium however remains due and if the
                            policyholder dies during this, the insurer may
                            deduct the premium from the death benefit
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - If premium remain unpaid even after the grace
                            period is over, the policy would then be considered
                            lapsed and the company is not under obligation to
                            pay the death benefit.
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The only amount payable would be whatever is
                            applicable under the non-forfieture provisions. In a
                            sense the insured may thus be said to have recevied
                            free insrance during the grace period
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The standard length of the grace period is one
                            month or 31 days. The days of grace may be computed
                            from the next day after the due date is fixed for
                            payment of the premium
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The provision enables a policy that would
                            otherwise have lapsed for nonpayment of premium, to
                            continue in force during the grace period
                          </li>
                          <br />
                          <li>7.17 Lapse and Reinstatement / Revival</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Policy is said to be in lapse condition if premium
                            has not been paid even after the completion of grace
                            period
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Life insurance contracts permit reinstatement
                            (revival) of a lapsed policy
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Reinstatement is the process by which a life
                            insurance company puts back into force a policy that
                            has either been terminated because of non-payment of
                            premiums or has been continued under one of the non
                            – forfeiture provisions
                          </li>
                          <br />
                          <li>7.18 Revival of Policy</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - A revival of the policy can be done only under
                            certain conditions:
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - No increase in risk for insurer
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Creation of reserve
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Revival application within specific time period{" "}
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Satisfactory evidence of continued insurability
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Payment of overdue premiums with interest
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Payment of outstanding loan, if any
                            </li>
                          </ul>
                          <br />
                          <li>7.19 Non-Forfeiture Provision</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - A
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - The Policy conditions provide various safeguards
                              to policyholders, when there is a premium default.
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - These provisions are called Non Forfeiture
                              provisions
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - B
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - If three years’ premiums have been paid and
                              discontinued thereafter, the non forfeiture clause
                              comes into play{" "}
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - C
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Consequently, Insurers do not forfeit the
                              premiums once the policy lapses after three years
                              of payment.{" "}
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - The Insurance Act does not allow such forfeiture
                            </li>
                          </ul>
                          <br />
                          <li>7.20 Non-Forfeiture Provision</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Cash Value or Surrender Value
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - To return to the policyholder an amount that
                              represents the reserve
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - Surrender Value arrived as a percentage of
                              premiums paid is called Guaranteed Surrender Value
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Policy loans
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - The right to borrow money from the insurer by
                              using the cash value of the policy as a security
                              for the loan
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - The policy loan is limited to a percentage of
                              the policy’s surrender value (say 90%)
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - The policyholder borrows from his own account.
                            </li>
                          </ul>
                          <br />
                          <li>7.21 Nomination</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - As per section 39 of insurance act 1938, the
                            holder of a policy on his own life may nominate the
                            person or persons to whom the money secured by
                            policy shall be paid in the event of his death{" "}
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - It can be made at the time of proposal or at any
                            time during the currency of the policy
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - A nomination can be changed by the policy holder
                            by making endorsement on the policy
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The nominee has the right to receive the claim
                            money on the event of lifeinsured’s death
                          </li>
                          <br />
                          <li>7.22 Assignment</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - A life insurance policy is a property, it
                            represents rights and as per Transfer of Property
                            Act 1882, it is an actionable claim
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - An assignment transfers the rights, title &
                            interest of the policy from assignor to assignee
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The assignment can be done by an endorsement on
                            policy or by a separate deed
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Separate deed has to be stamped
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - The assignment is effective as soon as it is
                            executed
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - It must be sent to the insurer along with a notice
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - In India assignment is governed by Section 38 of
                            Insurance Act.
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - On execution of the assignment the assignee gets
                            all rights title and interest in respect of property
                            assigned and becomes the owner of the policy,
                            subject to the provision that the assignee cannot
                            have a better title than the assignor
                          </li>
                          <br />
                          <li>7.23 Assignment</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Insurance companies partner with banks to sell
                            their products through them.
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Current regulations in India state that a bank can
                            only act as an insurance agent for three life
                            insurance companies at a time
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Absolute Assignment
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - The title cannot be reverted back to the
                              assignor unless the assignee reassigns the policy
                              through a deed
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Conditional Assignment
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - The title automatically reverts back to the
                              assignor on fulfilling of the specified condition
                            </li>
                          </ul>
                          <br />
                          <li>7.24 Alteration</li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Provision to alterations in policy terms and
                            conditions is allowed, subject to consent of both
                            the insurer and assured.
                          </li>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Alterations may not be permitted during the first
                            year of the policy, except for change in the mode of
                            premium or alterations which are of a compulsory
                            nature like:
                          </li>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - change in name or / address;
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - readmission of age in case it is proved higher
                              or lower;
                            </li>
                            <li
                              style={{
                                paddingLeft: "24px",
                                marginBottom: "3px",
                              }}
                            >
                              - request for grant of double accident benefit or
                              permanent disability benefit etc.
                            </li>
                          </ul>
                          <li
                            style={{ paddingLeft: "20px", marginBottom: "3px" }}
                          >
                            - Some alterations may be affected by placing a
                            suitable endorsement on the policy or on a separate
                            paper. Other alterations, may require the
                            cancellation of existing policies and issue of new
                            policies
                          </li>
                          <br />
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Tab>
                {/* <Tab eventKey="content" title="Content">
                  <div className="content-tab">
                    <div className="row mb-3">
                      <div className="col-md-8 col-sm-12">
                        <p>First document and work environments</p>
                      </div>
                      <div className="col-md-4 col-sm-12 d-flex content-right-col justify-content-end">
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/timer-svg.svg`}
                          alt=""
                        />
                        <span> 3 minutes, 9 seconds</span>
                      </div>
                    </div>
                    <div className="content-video">
                      <video ref={vidRef} width="100%" height="100%" controls>
                        <source
                          src="https://www.w3schools.com/html/mov_bbb.mp4"
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                      <button onClick={handleVideo}>
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/images/play-pause.svg`}
                          alt=""
                        />
                      </button>
                    </div>
                    <p>
                      In this unit we will create our first file, save it and
                      get to know the different working environments in Cinema
                      4D.
                    </p>
                  </div>
                </Tab> */}
              </Tabs>
            </div>
          </div>
          <div className="d-flex">
            {isDisabled ?
              <button className="btn btn-primary ms-auto" onClick={handleShow}>
                Start
              </button>
              :
              <button disabled className="btn btn-primary ms-auto">
                Invalid Posid
              </button>
            }
          </div>
        </div>
      </div>
      <div className="col-lg-2">
        {/* <CircularProgressCard /> */}
        <TimerCard totalTimer={totalTimer} />
      </div>
      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        backdrop="static"
        keyboard={true}
        className="pospid_modal"
      >
        <div className="modal-body">
          <h2>
            Congratulations!! You have Successfully Completed 15 Hours Mandatory
            Training.
          </h2>
          {/* <button
            type="button"
            className="modal_close_btn"
            data-bs-dismiss="modal"
            aria-label="Close"
            ></button> */}
          <button
            type="button"
            class="btn-close modal_close_btn"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => handleClose(false)}
          ></button>
          <h3 className="text-danger">Important points to Remember!</h3>
          <ul
            style={{
              listStyle: "disc",
              paddingLeft: "24px",
              marginBottom: "24px",
            }}
          >
            <li>After starting the test do not refresh the page.</li>
            <li>Do not click back button or logout button.</li>
            <li>You are about to start your assessment.</li>
            <li>
              There are 30 questions in the test, minimum passing percentage is
              50%.
            </li>
            <li>There is no negative marking.</li>
          </ul>
          <h5 style={{ color: "#00A41A", marginBottom: "18px" }}>
            All the Best !
          </h5>
          <div className="text-center">
            {isDisabled ?
              <Link to="/assessment" id="start_submit" className="btn btn-primary">
                Start Now
              </Link>
              :
              <button disabled className="btn btn-primary ms-auto">
                Invalid Posid
              </button>
            }
          </div>
        </div>
      </Modal>
    </TrainingLayout>
  );
};
export default Training;
