import React from "react";

const AnsRadioBtn = ({ classProp, name, value, onChangeFn , isans }) => {
  var is_check = "";
  if(isans == value){
    var is_chec = 'checked';
  }
  return (
    <>
      <li key={value}>
        <input type="radio" checked={is_chec} value={value} name={name} onChange={onChangeFn} />
        <span></span>
      </li>
    </>
  );
};

export default AnsRadioBtn;
