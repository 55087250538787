import React from "react";
import { Link } from "react-router-dom";
import RegistrationLayout from "../components/Layout/RegistrationLayout";

const RegistrationUpload = () => {
  return (
    <RegistrationLayout currentTitle="Get Certified">
      <div className="form-content">
        <h3>Syllabus</h3>
        <div className="row gx-5 gy-3 mb-3">
          <div className="col-lg-3 col-sm-6">
            <div className="course-card">
              <figure>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/course-img-1.jpg`}
                  alt=""
                />
              </figure>
              <h5>Introduction of Insurance</h5>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="course-card active">
              <figure>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/course-img-2.jpg`}
                  alt=""
                />
              </figure>
              <h5>Indian Insurance Market </h5>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="course-card">
              <figure>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/course-img-3.jpg`}
                  alt=""
                />
              </figure>
              <h5>Principles and Practice of Insurance </h5>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="course-card">
              <figure>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/course-img-4.jpg`}
                  alt=""
                />
              </figure>
              <h5>Insurance Products </h5>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="course-card">
              <figure>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/course-img-5.jpg`}
                  alt=""
                />
              </figure>
              <h5>Grievance Redressal Mechanism </h5>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <Link to="/training" className="btn btn-primary ms-3">
            Start
          </Link>
        </div>
      </div>
    </RegistrationLayout>
  );
};
export default RegistrationUpload;
