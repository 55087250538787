import axios from "axios";
import React, { useEffect, useState } from "react";
import { Badge, Table } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { FaFilter } from "react-icons/fa";
import { FiArrowDown, FiMoreHorizontal } from "react-icons/fi";
import { FormInput, SelectDropdown } from "../components/common/form";
import FormCheckbox from "../components/common/form/FormCheckbox";
import DashboardLayout from "../components/Layout/DashboardLayout";
import { BASE_URL } from "../config";

const UserDashboardLead = () => {
  const [policyData, setPolicyData] = useState();

  const getPolicyData = async () => {
    const finalQuoteToast = toast.loading("Loading...");
    // const bodyData = {
    //   fName: "ShrikantTest",
    //   lName: "Ajmire",
    //   dateOfBirth: "1965-01-01",
    //   mobile: "9889013090",
    //   email: "soq988i@yomail.com",
    //   salesChannelCode: "",
    //   carrierCode: "HDFC",
    //   insurerName: "HDFC Life",
    //   frequency: "2",
    //   premium: "57000",
    //   term: "12",
    //   ppt: "10",
    //   payOutFrequency: "Annual",
    // };
    const url = `${BASE_URL}/getLeads`;
    const res = await axios.get(url);
    setPolicyData(res.data);
    toast.remove();
  };

  // useEffect(() => {
  //   console.log(policyData);
  // }, [policyData]);

  useEffect(() => {
    getPolicyData();
  }, []);

  // const getTableData = async () => {
  //   const url = `localhost:4000/status`;
  //   const res = await axios.post(url, { QuotationNo: "QLIFE01000000000592" });
  // };

  // useEffect(() => {
  //   getTableData();
  // }, []);

  const handleChange = (e) => {
    console.log(e);
  };
  return (
    <DashboardLayout currentTitle="Lead">
    <div className="container-fluid">
      <div className="row my-4">
        <div className="col-lg-3 col-12 d-flex">
          <SelectDropdown
            label={"Show :"}
            options={["All Project", "Option 2"]}
            value="All Project"
            variant="trans_grey filter-dropdown"
          />
        </div>
        <div className="col-lg-9 col-12 d-flex justify-content-end">
          <div className="table-filter">
            <button>
              {" "}
              <FiArrowDown className="me-2" /> Generate Report
            </button>
            <button className="filter-btn btn-outline-secondary">
              <FaFilter />{" "}
            </button>
            <SelectDropdown
              label={"Sort By :"}
              options={["1", "2"]}
              variant="trans_grey filter-dropdown w-25"
            />
          </div>
        </div>
      </div>
</div>
      <div className="form-content dasboard-lead">
        <div className="row">
          <div className="col-lg-9 mt-1">
            <FormInput placeholder="Search for ID, Name" />
          </div>
          <div className="col-lg-3">
            <SelectDropdown
              label="Action"
              options={["Delete", "Update"]}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div style={{ overflowX: "auto" }}>
              <Table hover>
                <thead>
                  <tr>
                    <th>
                      <FormCheckbox />
                    </th>
                    <th>Lead Id</th>
                    <th>Customer Name</th>
                    <th>Created On</th>
                    <th>Phone</th>
                    <th>Premium</th>
                    <th>INSURER </th>
                    {/* <th>Payout</th> */}
                    <th>Action</th>
                    <th>
                      <FiMoreHorizontal />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {policyData && policyData?.length > 0 ? (
                    policyData.map((policy, i) => {
                      if (policy?.ErrorMessage) {
                        return (
                          <tr key={i}>
                            <td>No Data Found</td>
                          </tr>
                        );
                      } else if (policy?.Age) {
                        return (
                          <tr key={i}>
                            <td>
                              <FormCheckbox />
                            </td>
                            <td>{policy?.QuoteId}</td>
                            <td>
                              {policy?.FirstName} {policy?.Lastname}
                            </td>
                            <td>{policy?.QuotationDate}</td>
                            <td>
                              +{policy?.MobileNoPre} {policy?.MobileNo}
                            </td>
                            <td>₹ {policy?.Premium}</td>
                            <td>
                              {" "}
                              <img
                                src={`${process.env.PUBLIC_URL}assets/images/${policy?.CarrierCode}.png`}
                                alt=""
                                style={{ width: 60 }}
                              />
                            </td>
                            {/* <td>₹910</td> */}
                            <td>
                              <Badge bg="warning">Unpaid</Badge>
                            </td>
                            <td>
                              <FiMoreHorizontal />
                            </td>
                          </tr>
                        );
                      } else {
                        return (
                          // <tr key={i}>
                          //   <td>
                          //     {policy?.error_details?.error_message}
                          //     56
                          //     </td>
                          // </tr>
                          <p key={i}></p>
                        );
                      }
                    })
                  ) : (
                    <tr>
                      <td>No Data Found</td>
                    </tr>
                  )}

                  {/* <tr>
              <td>
                <FormCheckbox />
              </td>
              <td>#1244</td>
              <td>Vikram P</td>
              <td>12-09-2022 </td>
              <td>9094554554</td>
              <td>₹9100</td>
              <td>
                {" "}
                <img
                  src={`${process.env.PUBLIC_URL}assets/images/HDFC.png`}
                  alt=""
                  style={{ width: 60 }}
                />
              </td>
              <td>₹910</td>
              <td>
                <Badge bg="success">Paid</Badge>
              </td>
              <td>
                <FiMoreHorizontal />
              </td>
            </tr>
            <tr>
              <td>
                <FormCheckbox />
              </td>
              <td>#1245</td>
              <td>Chanu L</td>
              <td>12-09-2022 </td>
              <td>9094554554</td>
              <td>₹9100</td>
              <td>
                {" "}
                <img
                  src={`${process.env.PUBLIC_URL}assets/images/HDFC.png`}
                  alt=""
                  style={{ width: 60 }}
                />
              </td>
              <td>-</td>
              <td>
                <Badge bg="warning">Unfulfilled</Badge>
              </td>
              <td>
                <FiMoreHorizontal />
              </td>
            </tr>
            <tr>
              <td>
                <FormCheckbox />
              </td>
              <td>#1246</td>
              <td>Deha K</td>
              <td>12-09-2022 </td>
              <td>9094554554</td>
              <td>₹9100</td>
              <td>
                {" "}
                <img
                  src={`${process.env.PUBLIC_URL}assets/images/HDFC.png`}
                  alt=""
                  style={{ width: 60 }}
                />
              </td>
              <td>₹910</td>
              <td>
                <Badge bg="success">Paid</Badge>
              </td>
              <td>
                <FiMoreHorizontal />
              </td>
            </tr>
            <tr>
              <td>
                <FormCheckbox />
              </td>
              <td>#1247</td>
              <td>Pooja A</td>
              <td>12-09-2022 </td>
              <td>9094554554</td>
              <td>₹9100</td>
              <td>
                {" "}
                <img
                  src={`${process.env.PUBLIC_URL}assets/images/HDFC.png`}
                  alt=""
                  style={{ width: 60 }}
                />
              </td>
              <td>₹910</td>
              <td>
                <Badge bg="success">Paid</Badge>
              </td>
              <td>
                <FiMoreHorizontal />
              </td>
            </tr>
            <tr>
              <td>
                <FormCheckbox />
              </td>
              <td>#1248</td>
              <td>Manasa</td>
              <td>12-09-2022 </td>
              <td>9094554554</td>
              <td>₹9100</td>
              <td>
                {" "}
                <img
                  src={`${process.env.PUBLIC_URL}assets/images/HDFC.png`}
                  alt=""
                  style={{ width: 60 }}
                />
              </td>
              <td>₹910</td>
              <td>
                <Badge bg="warning">Unfulfilled</Badge>
              </td>
              <td>
                <FiMoreHorizontal />
              </td>
            </tr> */}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
export default UserDashboardLead;
