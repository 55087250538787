import Cookies from "js-cookie";
import axios from "axios";
import React, { useEffect, useState } from "react";
import QuotesCard from "../components/common/QuotesCard";
import TrainingLayout from "../components/Layout/TrainingLayout";
import { BASE_URL } from "../config";
import "../styles/certificate.css";
import html2pdf from "html2pdf.js";
import { saveAs } from "file-saver";

const AssessmentComplete = () => {
  const [certificateContent, setCertificateContent] = useState();

  const getCertificate = async () => {
    const tokenUrl = `${BASE_URL}/ambrela`;
    const tokenRes = await axios.get(tokenUrl, {
      headers: {
        "Content-Type": "application/json",
      }, credentials: true
    });
    // console.log("token", tokenRes.data.token);
    Cookies.set("cookieToken", tokenRes.data.token, { session: true });
    const apiCookie = Cookies.get("cookieToken");
    // console.log("apiCookie", apiCookie);
    const agentUrl = `${BASE_URL}/api/getAgentProfile`;
    const agentRes = await axios.get(agentUrl, {
      headers: {
        "Content-Type": "application/json",
      },
      params: { token: tokenRes.data.token, pospId: localStorage.getItem('posId') },
      withCredentials: true,
    });
    console.log("getAgent", agentRes);
    Cookies.set("cookieId", agentRes.data.id, { session: true });
    const apiCookieId = Cookies.get("cookieId");
    // console.log("apiCookieId", apiCookieId);

    const url = `${BASE_URL}/certificate`;
    const res = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    // console.log("certificate", res.data);
    setCertificateContent(res.data);
    // console.log(certificateContent);
  };

  useEffect(() => {
    getCertificate();
  }, []);

  const downloadPdf = async () => {
    const element = document.querySelector(".training-certificate");
    const opt = {
      margin: [0, 0, 0, 0],
      filename: "RIA Certificate.pdf",
      html2canvas: { scale: 5 },
      pagebreak: { avoid: [] },
      jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
    };
    html2pdf()
      .set(opt)
      .from(element)
      .save()
      .then((blob) => {
        saveAs(blob, "certificate.pdf");
      });
  };

  function createMarkup() {
    return { __html: certificateContent };
  }

  return (
    <TrainingLayout currentTitle="Certificate">
      <div className="col-lg-8">
        {" "}
        <div className="form-content exam_passed_content">
          <h4 className="content_name">Certificate</h4>
          <p className="content_heading">
            Congratulations you've passed the exam
          </p>
          <div className="training-certificate">
            <div dangerouslySetInnerHTML={createMarkup()} />
            {/* dangerouslySetInnerHTML={createMarkup()} */}
            {/* <div class="main">
              <div class="content">
                <div class="broker-name">
                  <span>RIA</span>
                  <p>Insurance Brokers</p>
                </div>
                <p class="certificate-heading">
                  ONLINE TRAINING & EXAMINATION CERTIFICATE
                </p>
                <p class="certificate-para">
                  This is to certify that <b>Aadil Khan</b> from Location having
                  login id <b>RIA20500001</b> was sponsored for Online
                  <b>Point of Sale Person - Life</b> Insurance Training by
                  <b>RIA Insurance Brokers Pvt Ltd.</b> The Training was held
                  online at
                  <b>online.riainsurance.com</b> from
                  <b>21-December-2021 to 23- December-2021.</b> He / She has
                  completed
                  <b>15 hours</b> of training as stipulated under IRDAI POS-P
                  Guidelines.
                </p>
                <br />
                <br />
                <p class="doc">PAN NO: ALJOH9788B</p>
                <p class="doc">ADHAR NO: 2718</p>
                <br />
                <br />
                <p class="result-desc">
                  The candidate appeared for Online examination at
                  <b>www.online.riainsurance.com</b> on <b>24-December-2021.</b>{" "}
                  He / She has passed the examination.
                </p>
                <br />
                <br />
                <p class="letter-desc">
                  This letter authorizes you to act as point of Sales for
                  <b>RIA Insurance Brokers Pvt Ltd.</b> to market products
                  categorized and identified under the Guidelines only. In case
                  you wish to work for another company, you are required to
                  obtain a fresh letter from the new insurer/insurance
                  intermediary in order to act as Point of Sales Person for that
                  entity.
                </p>
                <br />
                <br />
                <br />
                <p class="for-broker">For RIA Insurance Brokers Pvt Ltd</p>
                <br />
                <br />
                <br />
                <br />
                <span class="sign">Authorised Signatory</span>
                <br />
                <br />
                <br />
                <p class="broker-address">
                  <b>RIA Insurance Brokers Pvt Ltd</b>
                  <br />
                  Om Plaza, 430/7, 1st Floor, Sant Nagar, East of Kailash, New
                  Delhi-110065
                </p>
              </div>
            </div> */}
          </div>
          <button onClick={downloadPdf} className="btn mt-4 btn-primary">
            Download
          </button>
        </div>
      </div>
      <div className="col-lg-2">
        <QuotesCard text="Find the right trusted Insurance for your family!" />
      </div>
    </TrainingLayout>
  );
};
export default AssessmentComplete;
