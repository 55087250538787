import React from "react";

const RadioBox = ({ name, label, value, ...props }) => {
  return (
    <label class="radio-box">
      <input {...props} name={name} value={value} class="radio" type="radio" />
      <span class="plan-details">
        <span class="plan-amount">{label}</span>
      </span>
    </label>
  );
};

export default RadioBox;
