import React, { useEffect, useState } from "react";
import TrainingLayout from "../components/Layout/TrainingLayout";
import TimerCard from "../components/common/TimerCard";
import CircularProgressCard from "../components/common/CircularProgressCard";
import { Link, useNavigate } from "react-router-dom";
import AnsRadioBtn from "../components/common/form/AnsRadioBtn";
import { BASE_URL } from "../config";
import axios from "axios";
import { toast } from "react-hot-toast";

const Assessment = () => {
  const navigate = useNavigate();

  const [mcq, setMcq] = useState([]);
  // console.log(mcq);
  const [submitAns, setSubmitAns] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState([]);

  const getMcq = async () => {
    const url = `${BASE_URL}/showTest`;
    const res = await axios.get(url, {withCredentials:true});
    setMcq(res.data.data);
  };

  useEffect(() => {
    getMcq();
  }, []);

  function handleAnswerSelect(question, answerIndex) {
    const newSelectedAnswer = {
      uid: question,
      correctAnswer: answerIndex + 1,
    };
    setCorrectAnswers([...correctAnswers, newSelectedAnswer]);
  }

  const handleSubmit = async () => {
    // console.log("correctAnswers", correctAnswers);
    if (correctAnswers.length > 0) {
      toast.remove();
      // toast.loading("Loading...");
      const url = `${BASE_URL}/ans`;
      const res = await axios.post(url, correctAnswers, {withCredentials: true});
      // console.log(res.data.marks);
      toast.remove();
      if (res.data.marks >= 12) {
        navigate("/certificate");
      } else if (res.data.marks <= 12) {
        navigate("/training");
        toast.error(`You need atleat 12 marks to pass and get certificate.`, {
          duration: 10000,
        });
      }
    }
  };

  function getCompletionPercentage() {
    const attemptedQuestions = correctAnswers.map((answer) => answer.uid);
    const uniqueQuestions = mcq.map((question) => question.uid);
    const completedQuestions = [...new Set(attemptedQuestions)];
    const completionPercentage =
      Math.trunc((completedQuestions.length / uniqueQuestions.length) * 100);
    return completionPercentage;
  }

  return (
    <TrainingLayout classname="assessment" currentTitle="POSP Assessment">
      <div className="col-md-8 assessment-col">
        <div className="form-content posp-assesment-content">
          <h3>
            POSP Assessment
            <div className="total-question-time">
              <p>Total Question : {mcq.length}</p>
              {/* <div>
                  <span>Time</span>
                  <span>50:00</span>
                </div> */}
            </div>
          </h3>
          <ul className="questions-list">
            {mcq.map((q) => (
              <li key={q.uid} className="question-block">
                <h3>{q.question}</h3>
                <ul className="question-options">
                  {q.answers.map((opt, optId) => (
                    <li key={optId}>{opt}</li>
                  ))}
                </ul>
                <div className="question-block-action">
                  <ul>
                    {q.answers.map((answer, answerIndex) => (
                      <AnsRadioBtn
                        value={answerIndex}
                        name={`question${q.uid}`}
                        onChangeFn={() =>
                          handleAnswerSelect(q.uid, answerIndex)
                        }
                      />
                    ))}
                  </ul>
                  <span>1 Mark</span>
                </div>
              </li>
            ))}
          </ul>
          <hr />
          <div className="d-flex">
            <Link
              to="#"
              onClick={handleSubmit}
              className="btn btn-primary ms-auto"
            >
              Submit
            </Link>
          </div>
        </div>
      </div>
      <div className=" col-lg-2 col-md-4">
        <CircularProgressCard completed={getCompletionPercentage()} />
      </div>
    </TrainingLayout>
  );
};
export default Assessment;
