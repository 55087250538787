import { createSlice } from "@reduxjs/toolkit";

export const posIdSlice = createSlice({
  name: "posId",
  initialState: {
    posId: localStorage.getItem('posId') || null
  },
  reducers: {
    setPosId: (state, action) => {
      const posId = action.payload;
      localStorage.setItem('posId', posId); // Update localStorage
      state.posId = localStorage.getItem('posId');
    }
  }
});

// Action creators are generated for each case reducer function
export const { setPosId } = posIdSlice.actions;
export default posIdSlice.reducer;