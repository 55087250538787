import React, { useState } from "react";
import { FormInput, SelectDropdown } from "../components/common/form";
import { Link } from "react-router-dom";
import RegistrationLayout from "../components/Layout/RegistrationLayout";
import FormRadio from "../components/common/form/FormRadio";
// import axios from "axios";
// import _ from "lodash";
// import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { BASE_URL } from "../config";
// import {
//   registerUserRequest,
//   registerUserResponse,
// } from "../slices/registerUserSlice";

const Registration = () => {
  const [formData, setFormData] = useState({});
  // const dispatch = useDispatch();
  // const navigate = useNavigate();

  // const handleSubmit = () => {
  //   let registerData = {
  //     title: formData.title,
  //     fName: formData.fName,
  //     mName: formData.mName,
  //     lName: formData.lName,
  //     address: formData.address,
  //     state: formData.state,
  //     city: formData.city,
  //     pinCode: formData.pinCode,
  //     mobile: formData.mobile,
  //     email: formData.email,
  //     panCardNo: formData.panCardNo,
  //     gstNumber: formData.gstNumber,
  //     adharNumber: formData.adharNumber,
  //     dateOfBirth: formData.dateOfBirth,
  //     bankName: formData.bankName,
  //     accountName: formData.accountName,
  //     accountNumber: formData.accountNumber,
  //     ifsc: formData.ifsc,
  //     qualification: formData.qualification,
  //     nomineeDetails: formData.nomineeDetails,
  //   };
  //   dispatch(registerUserRequest(registerData));
  //   axios.post(`${BASE_URL}/register`, ...formData).then((res) => {
  //     dispatch(registerUserResponse(res.data));
  //   });
  // };

  const handleChange = (label, value) => {
    setFormData({ ...formData, [label]: value });
  };

  return (
    <RegistrationLayout currentTitle="Registration">
      <div className="form-content">
        <h3>Partner Registration Form</h3>
        <div className="form-container">
          <form>
            <div className="row">
              <div className="col-lg-2 col-md-3 col-sm-12">
                <SelectDropdown
                  label="Title"
                  name="title"
                  options={[
                    { label: 1, value: 1 },
                    { label: 2, value: 2 },
                    { label: 3, value: 3 },
                    { label: 4, value: 4 },
                  ]}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-4 col-md-9 col-sm-12">
                <FormInput
                  label="First Name"
                  name="fName"
                  type="text"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <FormInput
                  label="Middle Name"
                  name="mName"
                  type="text"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <FormInput
                  label="Last Name"
                  name="lName"
                  type="text"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <FormInput
                  label="Address"
                  name="address"
                  type="text"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <SelectDropdown
                  label="State"
                  name="state"
                  options={[
                    { label: "Maharashtra", value: "Maharashtra" },
                    { label: "Rajasthan", value: "Rajasthan" },
                    { label: "Gujarat", value: "Gujarat" },
                  ]}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <SelectDropdown
                  label="City"
                  name="city"
                  options={[
                    { label: "Mumbai", value: "Mumbai" },
                    { label: "Jaipur", value: "Jaipur" },
                    { label: "Rajkot", value: "Rajkot" },
                    { label: "Goa", value: "Goa" },
                  ]}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <FormInput
                  label="PIN Code"
                  name="pinCode"
                  type="text"
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <FormInput
                  label="Mobile"
                  name="mobile"
                  type="tel"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <FormInput
                  label="E-Mail"
                  name="email"
                  type="email"
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <FormInput
                  label="PAN"
                  name="pan"
                  type="text"
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <FormInput
                  label="GST Number"
                  name="gstNumber"
                  type="text"
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <FormInput
                  label="Aadhaar Number"
                  name="aadhaarNumber"
                  type="text"
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <FormInput
                  label="Date of Birth"
                  name="dateOfBirth"
                  type="Date"
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <FormInput
                  label="Bank Name"
                  name="bankName"
                  type="text"
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <FormInput
                  label="Account Holder Name"
                  name="accountHolderName"
                  type="text"
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <FormInput
                  label="Account Number"
                  name="accountNumber"
                  type="text"
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <FormInput
                  label="IFSC"
                  name="ifsc"
                  type="text"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <FormInput
                  label="Educational Qualification"
                  name="educationalQualification"
                  type="text"
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <FormInput
                  label="Nominee Details"
                  name="nomineeDetails"
                  type="text"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "700",
                    marginBottom: "10px",
                  }}
                >
                  Are you already IRDA Registered POSP ?{" "}
                  <span className="text-danger" style={{ fontSize: "16px" }}>
                    *
                  </span>
                </div>
                <FormRadio
                  onClick={(e) => handleChange("posp", e.target.value)}
                  label="Yes"
                  value="Yes"
                  name="posp"
                />
                <FormRadio
                  onClick={(e) => handleChange("posp", e.target.value)}
                  label="No"
                  value="No"
                  name="posp"
                />
              </div>
            </div>
            <div className="d-flex mt-4 justify-content-end">
              <Link
                to="/registration-upload"
                className="btn btn-primary register"
              >
                Register
              </Link>
            </div>
          </form>
        </div>
      </div>
    </RegistrationLayout>
  );
};
export default Registration;
