import {
  Box,
  Container,
  Typography,
  Button,
  Alert,
  AlertTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import _ from "lodash";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FiFilter } from "react-icons/fi";
import { MdDownloadForOffline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormInput, SelectDropdown } from "../components/common/form";
import RadioBox from "../components/common/form/RadioBox";
import { BASE_URL } from "../config";
import {
  addQuoteRequest,
  addQuoteResponse,
  finalInsurance,
} from "../slices/insureSlice";
import { addUser } from "../slices/userSlice";
import { toast } from "react-hot-toast";
import "../styles/btn.css";

const InsuranceListing = () => {
  const { userData } = useSelector((state) => state.user);
  // console.log("userData", userData);
  const { insuranceList, insuranceQuote } = useSelector(
    (state) => state.insure
  );

  const genderValueHDFC = {
    Male: "GEN_M",
    Female: "GEN_F",
    Others: "GEN_O"
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filterDropdown, setFilterDropdown] = useState(false);
  const [filterForm, setFilterForm] = useState({ ...insuranceQuote });
  const [editForm, setEditForm] = useState();
  const [newData, setNewData] = useState();

  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [gender, setGender] = useState("");
  const [residentstatus, setResidentstatus] = useState("");

  // console.log(editForm);
  const [selectedInsurance, setSelectInsurance] = useState(null);
  const [open, setOpen] = useState(false);


  // Download PDF URl

  const [currentUrlIndex, setCurrentUrlIndex] = useState(0);
  const urls = [
    'https://api.iciciprulife.com/quote/pdf//EBI/KFD_New08092023171941257_E33.pdf',
    'https://api.iciciprulife.com/quote/pdf//EBI/KFD_New08092023171942058_E33.pdf'
  ];


  useEffect(() => {
    if (insuranceList.length === 0) {
      navigate("/");
    }

  }, [insuranceList]);
  const st_state = localStorage.getItem("state");
  const st_city = localStorage.getItem("city");
  const st_gender = localStorage.getItem("gender");
  const st_residentstatus = localStorage.getItem("residentstatus");
  useEffect(() => {
    if (st_residentstatus) {
      setResidentstatus(st_residentstatus);
    }
    if (st_state) {
      setState(st_state);
    }
    if (st_city) {
      setCity(st_city);
    }
    if (st_gender) {
      setGender(st_gender);
    }
  }, []);

  const handleFilterDropdown = () => {
    setFilterDropdown(!filterDropdown);
  };

  const numberWithCommas = (x) => {
    const formatter = new Intl.NumberFormat("en-IN", {
      style: "decimal",
      currency: "INR",
      minimumFractionDigits: 0,
    });
    return formatter.format(x);
  };

  const handleValue = (label, value) => {
    setFilterForm({ ...filterForm, [label]: value });
  };
  const handleEditValue = (label, value) => {
    setEditForm({ ...editForm, [label]: value });
  };
  const handleSubmit = () => {
    console.log(userData)
    const finalQuoteToast = toast.loading("Loading...");
    const currIns = selectedInsurance;
    //  insuranceList.filter(
    //   (insure) => insure.QuoteId === selectedInsurance
    // )[0];

    console.log(currIns);
    let genderValue = gender;
    if (currIns.CarrierCode === "HDFC") {
      genderValue = genderValueHDFC[gender];
    };

    let policyRiskList = {
      FirstName: currIns.PolicyCustomerList[0].FirstName,
      LastName: currIns.PolicyCustomerList[0].LastName,
      Email: "",
      MobileNo: currIns.MobileNo || userData.mobile,
      DateOfBirth: currIns.PolicyCustomerList[0].DateOfBirth,
      Gender: genderValue || userData.gender,
      State: state,
      City: city,
    }
    if (currIns.CarrierCode === "HDFC") {
      policyRiskList.ResidentStatus = residentstatus
    }

    const requestPayload = {
      EffectiveDate: "2022-10-27",
      ProductCode: "LIFE01",
      PayoutTerm: currIns.PayoutTerm, // need to change this
      DefermentPeriod: currIns.DefermentPeriod,
      CarrierProductId: currIns.Id,
      CarrierPlanId: currIns.CarrierPlanId,
      IncomeTerm: currIns.IncomeTerm,
      Plan: currIns.Plan,
      CarrierCode: currIns.CarrierCode,
      InsurerName: currIns.InsurerName ? "ICICI PRE" : "HDFC LIFE",
      Frequency: insuranceQuote.frequency,
      SumAssured: currIns.SumAssured,
      MaturityAmount: currIns.MaturityAmount,
      ChannelPartner: currIns.ChannelPartner,
      Premium: currIns.Premium,
      Term: currIns.Term,
      PPT: currIns.PPT,
      TouchPoint: "1",
      AgentCode: currIns.UserId,
      QniProduct: currIns.QniProduct,
      PayoutFrequency: currIns.PayoutFrequency || "Annual",
      Nri: currIns.Nri || "N",
      PayMethodCode: "",
      PPTOption: "Limited",
      UserId: "01059487",
      // EBIPdfDigital: currIns.EBIPdfDigital,
      PolicyLobList: [
        {
          PolicyRiskList: [policyRiskList],
        },
      ],
    };
    axios
      .post(`${BASE_URL}/fQuote`, requestPayload, { withCredentials: true })
      .then((res) => {
        dispatch(
          finalInsurance({
            applicationNo: res.data.PolicyObject.AppNo,
            quotationNo: res.data.PolicyObject.QuotationNo,
          })
        );
        navigate("/insurance-review");
        toast.remove();
      })
      .catch((err) => {
        toast.error(`Error: ${err.response.data.message}`, {
          duration: 5000,
          position: "top-center",
          id: finalQuoteToast,
        });
      });
  };

  const handleFilterSubmit = () => {

    const filterQuoteToast = toast.loading("Loading...");
    setFilterDropdown(!filterDropdown);
    let user = {
      fName: userData.fName,
      lName: userData.lName,
      dateOfBirth: userData.dateOfBirth,
      mobile: userData.mobile,
      email: userData.email,
      gender: userData.gender
      // Gender: userData.gender,
    };

    axios
      .post(`${BASE_URL}/filter`, {
        ...user,
        ...filterForm,
      })
      .then((res) => {
        dispatch(addQuoteRequest({ ...filterForm }));
        dispatch(addQuoteResponse(res.data));
        toast.remove();
      })
      .catch((err) => {
        toast.error(`Error: ${err.response.data.message}`, {
          duration: 5000,
          position: "top-center",
          id: filterQuoteToast,
        });
      });
  };


  const handleEditSubmit = () => {
    const filterQuoteToast = toast.loading("Loading...");
    setOpen(!open);
    let newUserData = {
      fName: userData.fName,
      lName: userData.lName,
      dateOfBirth: userData.dateOfBirth,
      mobile: editForm.mobile ? editForm.mobile : userData.mobile,
      email: editForm.email ? editForm.email : userData.email,
      gender: userData.gender
    };
    let newQuoteData = {
      frequency: insuranceQuote.frequency,
      incomeTerm: insuranceQuote.incomeTerm,
      term: insuranceQuote.term,
      ppt: insuranceQuote.ppt,
      payOutFrequency: insuranceQuote.payOutFrequency,
      premium: editForm.premium ? editForm.premium : userData.premium,
      residentstatus: insuranceQuote.residentstatus,
      state: insuranceQuote.state,
      city: insuranceQuote.city,
    };
    setNewData({ ...newUserData, ...newQuoteData });
    // console.log(newData);
    dispatch(addUser(newUserData));
    dispatch(addQuoteRequest(newQuoteData));
    axios
      .post(`${BASE_URL}/createQuote`, {
        // ...initialForm,
        ...newData,
        carrierCode: "HDFC",
        insurerName: "HDFC Life",
        salesChannelCode: "",
      })
      .then((res) => {
        dispatch(addQuoteResponse(res.data));
        toast.remove();
      })
      .catch((err) => {
        toast.error(`Error: ${err.response.data.message}`, {
          duration: 5000,
          position: "top-center",
          id: filterQuoteToast,
        });
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // <<=====================================\\==========================>
  const handleDownloadPdf = async () => {
    try {
      // Make an HTTP request to the current PDF URL
      const response = await axios.get(urls[currentUrlIndex], {
        responseType: 'arraybuffer',
      });

      // Create a Blob from the response data
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

      // Open a new window to display the PDF
      const newWindow = window.open('', '_blank');
      newWindow.document.write(`${urls}`);
      newWindow.document.write('<embed width="100%" height="100%" name="plugin" src="' + URL.createObjectURL(pdfBlob) + '" type="application/pdf">');
      newWindow.document.write('</body></html>');

      // Toggle to the next URL
      setCurrentUrlIndex((prevIndex) =>
        prevIndex === 0 ? 1 : 0
      );
    } catch (error) {
      console.error('Error:', error);
    }
  };



  return (
    <>
      <div className="insurance-listing">
        <div className="edit-info d-flex justify-content-end">

        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="term-plans-form">
            <h6 style={{ fontWeight: "600" }}>Edit Your Details</h6>
            <div className="row">
              {/* <div
                className="col-md-6 col-sm-12 "
                style={{ marinBottom: "10px" }}
              >
                <FormInput
                  label="Mobile"
                  type="number"
                  name="mobile"
                  required
                  onChange={handleEditValue}
                  validationSpan={
                    errors.mobile ? (
                      <span
                        style={{
                          color: "#EE4854",
                          marginTop: "-10px",
                          fontSize: "13px",
                        }}
                        className="d-block mb-3 px-3"
                      >
                        {errors.mobile}
                      </span>
                    ) : null
                  }
                />
              </div>
              <div className="col-md-6 col-sm-12 ">
                <FormInput
                  label="Email Id"
                  type="email"
                  name="email"
                  required
                  onChange={handleEditValue}
                  validationSpan={
                    errors.email ? (
                      <span
                        style={{
                          color: "#EE4854",
                          marginTop: "-10px",
                          fontSize: "13px",
                        }}
                        className="d-block mb-3 px-3"
                      >
                        {errors.email}
                      </span>
                    ) : null
                  }
                />
              </div>
              <div className="col-md-6 col-sm-12 ">
                <FormInput
                  label="Premium"
                  type="number"
                  name="premium"
                  onChange={handleEditValue}
                  validationSpan={
                    errors.premium ? (
                      <span
                        style={{
                          color: "#EE4854",
                          marginTop: "-10px",
                          fontSize: "13px",
                        }}
                        className="d-block mb-3 px-3"
                      >
                        {errors.premium}
                      </span>
                    ) : null
                  }
                  required
                />
              </div> */}
              <div
                className="col-md-6 col-sm-12 "
                style={{ marinBottom: "10px" }}
              >
                {/* <FormInput
                  label="Mobile"
                  type="number"
                  name="mobile"
                  onChange={handleEditValue}
                  value={editForm.mobile ? editForm.mobile : userData.mobile}
                /> */}
                <div className={`input_content`}>
                  <label>
                    Mobile
                  </label>
                  <input
                    type='number'
                    name='mobile'
                    onChange={(e) => handleEditValue('mobile', e.target.value)}
                    value={editForm?.mobile ? editForm.mobile : userData.mobile}
                  />
                  {/* {validationSpan} */}
                  <img
                    src={`${process.env.PUBLIC_URL}assets/images/verified_svg.svg`}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12 ">
                <div className={`input_content`}>
                  <label>
                    Email
                  </label>
                  <input
                    type='email'
                    name='email'
                    onChange={(e) => handleEditValue('email', e.target.value)}
                    value={editForm?.email ? editForm.email : userData.email}
                  />
                  {/* {validationSpan} */}
                  <img
                    src={`${process.env.PUBLIC_URL}assets/images/verified_svg.svg`}
                    alt=""
                  />
                </div>
                {/* <FormInput
                  label="Email Id"
                  type="email"
                  name="email"
                  onChange={handleEditValue}
                  value={userData.email}
                /> */}
              </div>
              <div className="col-md-6 col-sm-12 ">
                <div className={`input_content`}>
                  <label>
                    Premium
                  </label>
                  <input
                    type='number'
                    name='premium'
                    onChange={(e) => handleEditValue('premium', e.target.value)}
                    value={editForm?.premium ? editForm.premium : insuranceQuote.premium}
                  />
                  {/* {validationSpan} */}
                  <img
                    src={`${process.env.PUBLIC_URL}assets/images/verified_svg.svg`}
                    alt=""
                  />
                </div>
                {/* <FormInput
                  label="Premium"
                  type="number"
                  name="premium"
                  onChange={handleEditValue}
                  value={insuranceQuote.premium}
                /> */}
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12 mt-3">
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleEditSubmit}
                  disabled={_.isEmpty(editForm)}
                  fullWidth
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </Dialog>
        <ul className="insurance-listing-dropdown">
          <li className="filter-dropdown" onClick={handleFilterDropdown}>
            <FiFilter /> &nbsp; Filter
          </li>
          {filterDropdown && (
            <div className="listing-dropdown">
              <div className="row">
                <div className="col-12">
                  <p className="dropdown-heading">Frequency</p>
                </div>
                <div className="col-12 mt-3 mb-4">
                  <ul className="row">
                    <li className="col-6 mb-2">
                      <RadioBox
                        defaultChecked={insuranceQuote?.frequency === "1"}
                        onClick={(e) =>
                          handleValue("frequency", e.target.value)
                        }
                        name={"frequency"}
                        label={"Annual"}
                        value="1"
                      />
                    </li>
                    <li className="col-6 mb-2">
                      <RadioBox
                        defaultChecked={insuranceQuote?.frequency === "2"}
                        onClick={(e) =>
                          handleValue("frequency", e.target.value)
                        }
                        name={"frequency"}
                        label={"Half Yearly"}
                        value="2"
                      />
                    </li>
                    <li className="col-6 mt-1">
                      <RadioBox
                        defaultChecked={insuranceQuote?.frequency === "3"}
                        onClick={(e) =>
                          handleValue("frequency", e.target.value)
                        }
                        name={"frequency"}
                        label={"Quaterly"}
                        value="3"
                      />
                    </li>
                    <li className="col-6 mt-1">
                      <RadioBox
                        defaultChecked={insuranceQuote?.frequency === "4"}
                        onClick={(e) =>
                          handleValue("frequency", e.target.value)
                        }
                        name={"frequency"}
                        label={"Monthly"}
                        value="4"
                      />
                    </li>
                  </ul>
                </div>
                <div className="col-12">
                  <p className="dropdown-heading">Income Term</p>
                </div>
                <div className="col-12 mb-4">
                  <SelectDropdown
                    label=" "
                    options={[
                      { label: 10, value: 10 },
                      { label: 12, value: 12 },
                      { label: 15, value: 15 },
                      { label: 25, value: 25 },
                      { label: 30, value: 30 },
                      { label: 99, value: 99 },
                    ]}
                    onChange={(e, f) => handleValue(e, f)}
                    name="incomeTerm"
                    value={filterForm.incomeTerm}
                  />
                </div>
                <div className="col-12">
                  <p className="dropdown-heading">Policy Term</p>
                </div>
                <div className="col-12 mb-4">
                  <SelectDropdown
                    label=" "
                    options={[
                      { label: 5, value: 5 },
                      { label: 6, value: 6 },
                      { label: 7, value: 7 },
                      { label: 8, value: 8 },
                      { label: 9, value: 9 },
                      { label: 10, value: 10 },
                      { label: 11, value: 11 },
                      { label: 12, value: 12 },
                      { label: 13, value: 13 },
                      { label: 14, value: 14 },
                      { label: 15, value: 15 },
                      { label: 16, value: 16 },
                      { label: 17, value: 17 },
                      { label: 18, value: 18 },
                      { label: 19, value: 19 },
                      { label: 20, value: 20 },
                    ]}
                    onChange={(e, f) => handleValue(e, f)}
                    name="term"
                    value={filterForm.term}
                  />
                </div>
                <div className="col-12">
                  <p className="dropdown-heading">Premium Paying Term</p>
                </div>
                <div className="col-12 mb-4">
                  <SelectDropdown
                    label=" "
                    options={[
                      { label: 5, value: 5 },
                      { label: 6, value: 6 },
                      { label: 7, value: 7 },
                      { label: 8, value: 8 },
                      { label: 9, value: 9 },
                      { label: 10, value: 10 },
                      { label: 11, value: 11 },
                      { label: 12, value: 12 },
                    ]}
                    onChange={handleValue}
                    name="ppt"
                    value={filterForm.ppt}
                  />
                </div>

                <div className="col-12">
                  <p className="dropdown-heading">Payout Term</p>
                </div>
                <div className="col-12 mb-4">
                  <SelectDropdown
                    label=" "
                    options={[
                      { label: 15, value: 15 },
                      { label: 20, value: 20 },
                      { label: 25, value: 25 },
                      { label: 30, value: 30 }
                    ]}
                    onChange={(e, f) => handleValue(e, f)}
                    name="payoutTerm"
                    value={filterForm.payoutTerm}
                  />
                </div>

                <div className="col-12">
                  <p className="dropdown-heading">Payout frequency</p>
                </div>
                <div className="col-12 mt-3 mb-4">
                  <ul className="row">
                    <li className="col-6 mb-2">
                      <RadioBox
                        defaultChecked={
                          insuranceQuote?.payOutFrequency === "Monthly"
                        }
                        onClick={(e) =>
                          handleValue("payOutFrequency", e.target.value)
                        }
                        name={"income-term"}
                        value={"Monthly"}
                        label={"Monthly"}
                      />
                    </li>
                    <li className="col-6 mb-2">
                      <RadioBox
                        defaultChecked={
                          insuranceQuote?.payOutFrequency === "Quarterly"
                        }
                        onClick={(e) =>
                          handleValue("payOutFrequency", e.target.value)
                        }
                        name={"income-term"}
                        label={"Quaterly"}
                        value={"Quaterly"}
                      />
                    </li>
                    <li className="col-6 mt-1">
                      <RadioBox
                        defaultChecked={
                          insuranceQuote?.payOutFrequency === "Half Yearly"
                        }
                        onClick={(e, f) => handleValue(e, f)}
                        name={"income-term"}
                        label={"Half Yearly"}
                        value={"Half Yearly"}
                      />
                    </li>
                    <li className="col-6 mt-1">
                      <RadioBox
                        defaultChecked={
                          insuranceQuote?.payOutFrequency === "Annual"
                        }
                        onClick={(e) =>
                          handleValue("payOutFrequency", e.target.value)
                        }
                        name={"income-term"}
                        label={"Annual"}
                        value={"Annual"}
                      />
                    </li>
                  </ul>
                </div>
                <div className="col-12 mt-3">
                  <button
                    onClick={handleFilterSubmit}
                    className="listing-btn-primary w-100"
                  >
                    Apply Filters
                  </button>
                </div>
              </div>
            </div>
          )}
        </ul>
        <Container sx={{ marginTop: 3 }}>
          {insuranceList.length > 0 ? (
            insuranceList.map((insurance) =>
              insurance?.ProdName || insurance.ProductCode ? (
                <Box
                  key={insurance?.QuoteId}
                  sx={{
                    borderRadius: "0.8rem / 1rem",
                    border:
                      (insurance?.QuoteId && insurance?.QuoteId === selectedInsurance?.QuoteId) || (insurance?.ClientRequestId && insurance?.ClientRequestId === selectedInsurance?.ClientRequestId)
                        ? "1px solid #ee4854"
                        : "1px solid #D5D5D5",
                    backgroundColor: "#fff",
                    padding: "14px 16px",
                    marginBottom: 2,
                  }}
                  onClick={() => setSelectInsurance(insurance)}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "stretch",
                      gap: "10px",
                    }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}assets/images/${insurance?.CarrierCode}.png`}
                      alt=""
                      style={{ width: 54 }}
                    />
                    <Box
                      sx={{
                        width: "72%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 15,
                          fontWeight: "500",
                          color: "#212121",
                        }}
                      >
                        {insurance?.Plan }
                        {insurance?.CarrierCode == "ICICI" && <p>{insurance?.CarrierProductName}</p>}
                        {/* {insurance?.Plan || insurance?.CarrierProductName} */}
                      </Typography>
                      {/* <Typography
                        component="span"
                        sx={{
                          fontSize: 13,
                          color: "#717171",
                          borderBottom: "1px dashed #d5d5d5",
                        }}
                      >
                        Sum Assured:
                        <b style={{ color: "#555" }}>
                          {" "}
                          ₹ {numberWithCommas(insurance?.SumAssured)}
                        </b>
                      </Typography> */}
                      {/* <Typography
                        component="span"
                        sx={{
                          fontSize: 13,
                          color: "#717171",
                          borderBottom: "1px dashed #d5d5d5",
                        }}
                      >
                        Price are inclusive of GST:
                        <b style={{ color: "#555" }}>
                          {" "}
                          {(insurance?.Premium * 0.05)}(Monthly)
                        </b>
                      </Typography> */}
                    </Box>
                    <Box
                      className="insurance-plan-right-side"
                      sx={{ width: "26%" }}
                    >
                      <Typography
                        sx={{
                          fontSize: 15,
                          fontWeight: "bold",
                          color: "#ee4854",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {insurance?.CarrierCode == "ICICI" && <p>TotalPremium :</p>}
                        <b>
                          &nbsp;₹ {numberWithCommas(insurance?.TotalPremium || insurance?.Premium)}
                        </b>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 11,
                          color: "#999",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {insurance?.Frequency}
                      </Typography>

                      {/* <Typography
                        sx={{
                          fontSize: 11,
                          color: "#999",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Include GST {(insurance?.Premium * 0.05)}(Monthly)
                      </Typography> */}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display:
                        (insurance?.QuoteId && insurance?.QuoteId === selectedInsurance?.QuoteId) || (insurance?.ClientRequestId && insurance?.ClientRequestId === selectedInsurance?.ClientRequestId)
                          ? "flex"
                          : "none",
                      borderTop: "1px solid #d5d5d5",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "12px",
                      marginTop: 2,
                      paddingTop: 1,
                    }}
                  >
                    <Typography
                      component="span"
                      sx={{
                        fontSize: 13,
                        color: "#717171",
                        display: "block",
                      }}
                    >
                      Sum Assured :
                      <b style={{ color: "#555" }}>
                        &nbsp;₹ {numberWithCommas(insurance?.SumAssured)}
                      </b>
                    </Typography>

                    <Typography
                      component="span"
                      sx={{
                        fontSize: 13,
                        color: "#717171",
                        display: "block",
                        margin: "3px 0",
                      }}
                    >
                      Payout Term :
                      <b style={{ color: "#555" }}> {insurance?.PayoutTerm}</b>
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display:
                        (insurance?.QuoteId && insurance?.QuoteId === selectedInsurance?.QuoteId) || (insurance?.ClientRequestId && insurance?.ClientRequestId === selectedInsurance?.ClientRequestId)
                          ? "flex"
                          : "none",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "12px",
                      marginTop: "4px",
                    }}
                  >
                    <Typography
                      component="span"
                      sx={{
                        fontSize: 13,
                        color: "#717171",
                        display: "block",
                      }}
                    >
                      Maturity Benefits :
                      <b style={{ color: "#555" }}>
                        &nbsp;{insurance?.MaturityBenefitRemarks}
                      </b>
                    </Typography>

                    {/* <Typography
                      component="span"
                      sx={{
                        fontSize: 13,
                        color: "#717171",
                        display: "block",
                        margin: "3px 0",
                      }}
                    >
                      Term:
                      <b style={{ color: "#555" }}> {insurance?.Term}</b>
                    </Typography> */}
                  </Box>
                  <Box
                    sx={{
                      display:
                        (insurance?.QuoteId && insurance?.QuoteId === selectedInsurance?.QuoteId) || (insurance?.ClientRequestId && insurance?.ClientRequestId === selectedInsurance?.ClientRequestId)
                          ? "flex"
                          : "none",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "12px",
                      marginTop: "4px",
                    }}
                  >
                    <Typography
                      component="span"
                      sx={{
                        fontSize: 13,
                        color: "#717171",
                        display: "block",
                      }}
                    >
                      Maturity Amount :
                      <b style={{ color: "#555" }}>
                        &nbsp;₹ {numberWithCommas(insurance?.MaturityAmount)}
                        {insurance?.CarrierCode == "HDFC" && <small>per annum</small>}
                      </b>
                    </Typography>
                    {/* <Typography
                      component="span"
                      sx={{
                        fontSize: 13,
                        color: "#717171",
                        display: "block",
                      }}
                    >
                      <span>
                        Maturity Amount :
                        <b style={{ color: "#555" }}>
                          &nbsp;₹ {numberWithCommas(JSON.stringify(insurance?.MaturityAmount))}
                          {insurance?.CarrierCode == "HDFC" && <small>per annum</small>}
                        </b>
                      </span>
                    </Typography> */}
                    <Typography
                      component="span"
                      sx={{
                        fontSize: 13,
                        color: "#717171",
                        display: "block",
                        margin: "3px 0",
                      }}
                    >
                      {insurance?.CarrierCode == "ICICI" &&
                        <button
                          className="download-btn"
                          onClick={handleDownloadPdf}
                        >
                          <MdDownloadForOffline />
                          Download PDF
                        </button>
                      }
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <>
                  {insurance?.CarrierCode == "HDFC" || insurance?.CarrierCode == "ICICI" ? (
                    <Alert
                      severity="warning"
                      sx={{ marginBottom: 2 }}
                      key={insurance?.ClientRequestId}
                      icon={false}
                    >
                      <AlertTitle>{insurance?.Plan}</AlertTitle>
                      {insurance?.CarrierCode == "HDFC" || insurance?.CarrierCode == "HDFC" ? (
                        <Typography>{insurance?.error_details?.error_message}</Typography>
                      ) : (
                        <></>
                      )}

                    </Alert>
                  ) : (
                    <></>
                  )}
                </>
              )
            )
          ) : (
            <Typography>No Match Found</Typography>
          )}
        </Container>
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          {selectedInsurance && (
            <Button
              variant="contained"
              size="large"
              onClick={handleSubmit}
              fullWidth
              sx={{ borderRadius: 0, minHeight: 48 }}
            >
              Final Quote
            </Button>
          )}
        </Box>
      </div>
    </>
  );
};

export default InsuranceListing;
