import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Jun",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Jul",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Aug",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Sep",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Oct",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Nov",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export default function Barchart() {
  return (
    <div style={{ width: "100%", height: 365 }}>
      <ResponsiveContainer>
        <BarChart
          width={500}
          height={250}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis yAxisId="left" orientation="left" stroke="#DE350B" />
          <YAxis yAxisId="right" orientation="right" stroke="#2772F0" />
          <Tooltip />
          <Legend />
          <Bar yAxisId="left" dataKey="pv" fill="#DE350B" />
          <Bar yAxisId="right" dataKey="uv" fill="#2772F0" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
