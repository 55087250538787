import React from "react";
import "./registration.scss";
import SideNavigation from "../common/SideNavigation";
import PopOver from "../common/PopOver";
import { useSelector } from 'react-redux';

const TrainingLayout = ({ classname, currentTitle, children }) => {
  const posId = useSelector((state) => state.posId.posId);
  // console.log(posId);

  return (
    <div className={`registration-layout ${classname}`}>
        <div className="training-posid container-fluid">
          <div className="row">
            <div className="col-12 d-flex justify-content-end align-items-center">
              <h6>POS ID : {posId}</h6>
            </div>
          </div>
        </div>
      <div className="container-fluid">
        <div className="registration-header">
          <div className="row mt-4 justify-content-end">
            <div className="col-lg-10 col-md-7 col-8 d-flex align-items-center">
              <div className="contact-info">
                <h5 className="contact-number">{currentTitle}</h5>
                {/* <p className="contact-timing">
                  10 AM to 8 PM (Monday - Sunday)
                </p> */}
              </div>
            </div>
            {/* <div className="col-lg-2 col-md-5 col-4 d-flex justify-content-end align-items-center">
              <PopOver />
            </div> */}
          </div>
        </div>
        <div className="registration-body">
          <div className="row">
            <div className="col-lg-2">
              <SideNavigation type="training" currentTitle={currentTitle} />
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingLayout;
