import React from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

const AverageSaleChart = () => {
  return (
    <>
      <div style={{ width: "100%", height: 365 }}>
        <div className="average-product-sale-progess">
          <div>
            <div className="product-sale-progress">
              <CircularProgressbar
                value={85}
                strokeWidth={6}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  pathColor: "#2772F0",
                })}
              />
              <h5>84.4K</h5>
              <p>Motor</p>
            </div>
            <div className="product-sale-progress mx-4">
              <CircularProgressbar
                value={85}
                strokeWidth={6}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  pathColor: "#DE350B",
                })}
              />
              <h5>20.9K</h5>
              <p>Health</p>
            </div>
            <div className="product-sale-progress">
              <CircularProgressbar
                value={85}
                strokeWidth={6}
                styles={buildStyles({
                    strokeLinecap: "butt",
                    pathColor: "#4B9F47",
                })}
                />
                <h5>32.5K</h5>
                <p>Term</p>
            </div>
          </div>
          <p className="sale-desc">
            Net sales, found on the income statement are used to calculate this
            ration returns and refunds must be backed out of total sales to
            measure the truly measure the firm’s assets abillity to generate
            sales.
          </p>
        </div>
      </div>
    </>
  );
};

export default AverageSaleChart;
