import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { FiEdit2, FiPlus, FiTrash2 } from "react-icons/fi";
import DashboardLayout from "../components/Layout/DashboardLayout";
import { BASE_URL } from "../config";
import axios from "axios";
import { toast } from "react-hot-toast";

const UserDashboardTest = () => {
  const [questions, setQuestions] = useState([]);
  const [currentId, setCurrentId] = useState(1);
  const [editingId, setEditingId] = useState(null);
  const [questionText, setQuestionText] = useState("");
  const [answerTexts, setAnswerTexts] = useState(["", "", "", ""]);
  const [correctAnswerText, setCorrectAnswerText] = useState("");
  const latestFormRef = useRef(null);

  // console.log(questions);

  const getQuestions = async () => {
    const url = `${BASE_URL}/showQue`;
    const res = await axios.get(url);
    // console.log(res.data.data);
    setQuestions(res.data.data);
  };

  useEffect(() => {
    getQuestions();
  }, []);

  const handleEditQuestion = (uid) => {
    setQuestionText(
      questions.find((question) => question.uid === uid).question
    );
    setAnswerTexts(questions.find((question) => question.uid === uid).answers);
    setCorrectAnswerText(
      questions.find((question) => question.uid === uid).correctAnswer
    );
    setEditingId(uid);
  };

  const handleSaveQuestion = (uid) => {
    const question = questions.find((question) => question.uid === uid);
    if (!question) return;
    const questionIndex = questions.indexOf(question);
    const existingQuestion = questions.find(
      (question) => question.question === questionText
    );
    if (existingQuestion && existingQuestion.uid !== uid) {
      alert("Question already exists");
      return;
    }
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex] = {
      uid: uid,
      question: questionText,
      answers: answerTexts,
      correctAnswer: correctAnswerText,
    };
    setQuestions(updatedQuestions);
    setEditingId(null);
    setQuestionText("");
    setAnswerTexts(["", "", "", ""]);
    setCorrectAnswerText("");
  };

  const handleAddQuestion = () => {
    const generateNewId = uuidv4();
    setQuestions([
      ...questions,
      {
        uid: generateNewId,
        question: "",
        answers: ["", "", "", ""],
        correctAnswer: "",
      },
    ]);
    setCurrentId(generateNewId);
    latestFormRef.current.focus();
  };

  const handleDeleteQuestion = async (uid) => {
    const remainingQuestions = questions.filter(
      (question) => question.uid !== uid
    );
    // const url = `${BASE_URL}/delQue`;
    // const res = await axios.post(url, { uid: uid });
    // console.log(res);
    setQuestions(remainingQuestions);
    setCurrentId(currentId - 1);
  };

  const handleSubmit = async () => {
    // console.log(questions);
    const url = `${BASE_URL}/uploadQue`;
    const res = await axios.post(url, questions);
    // console.log(res);
    if (questions.length === 40) {
      // alert("40 questions added");
      toast.success(`40 questions added`, {
        duration: 5000,
        position: "top-center",
        // id: mcqSubmitSuccess,
      });
      return;
    }
    toast.error(`${20 - questions.length} questions remaining out of 20`, {
      duration: 5000,
      position: "top-center",
      // id: questionSubmitFail,
    });
  };

  return (
    <DashboardLayout currentTitle="Test">
      <div className="form-content">
        <div className="row">
          <div className="col-12 d-flex mb-3">
            <p className="d-flex align-items-center my-auto question-count">
              <b>Questions:</b> <span>{questions.length}</span>
            </p>
            <div className="ms-auto">
              <FiPlus
                onClick={handleAddQuestion}
                role="button"
                className="fa-2x320. my-auto w-auto"
              />
              <button onClick={handleSubmit} className="btn btn-primary ms-4">
                Submit
              </button>
            </div>
          </div>
          <ul>
            {questions.map((question) => (
              <li
                key={question.uid}
                className="col-12 add-question-card question-card"
              >
                <div className="col-12 d-flex flex-column">
                  <h5>
                    <div className={`input_content input-question`}>
                      <input
                      ref={question.uid === editingId ? latestFormRef : null}
                        label=""
                        placeholder="Enter Correct Option"
                        name=""
                        type="text"
                        value={
                          question.uid === editingId
                            ? questionText
                            : question.question
                        }
                        onChange={(event) => {
                          if (question.uid === editingId) {
                            setQuestionText(event.target.value);
                          }
                        }}
                      />
                    </div>
                  </h5>
                </div>
                {question.answers.map((answer, index) => (
                  <div key={index} className="col-12 px-3 d-flex">
                    <div className="col-1 justify-content-center align-items-center d-flex">
                      {index + 1}
                    </div>
                    <div className="col-11">
                      <div className={`input_content input-question`}>
                        <input
                          label=""
                          name="answer"
                          type="text"
                          placeholder={`Enter answer ${index + 1}`}
                          value={
                            question.uid === editingId
                              ? answerTexts[index]
                              : answer
                          }
                          onChange={(event) => {
                            if (question.uid === editingId) {
                              setAnswerTexts((prevAnswerTexts) => {
                                const updatedAnswerTexts = [...prevAnswerTexts];
                                updatedAnswerTexts[index] = event.target.value;
                                return updatedAnswerTexts;
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <div className="col-12 px-3 d-flex">
                  <div className="col-lg-1 col-sm-2 d-flex align-items-center">
                    Answer
                  </div>
                  <div className="col-lg-3 col-sm-7">
                    <div className={`input_content input-question`}>
                      <input
                        label=""
                        placeholder="Enter Correct Option"
                        name=""
                        type="text"
                        value={
                          question.uid === editingId
                            ? correctAnswerText
                            : question.correctAnswer
                        }
                        onChange={(event) => {
                          if (question.uid === editingId) {
                            setCorrectAnswerText(event.target.value);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 d-flex">
                  {question.uid === editingId ? (
                    <button
                      onClick={() => handleSaveQuestion(question.uid)}
                      className="listing-btn-primary ms-auto"
                    >
                      Save
                    </button>
                  ) : (
                    <FiEdit2
                      role="button"
                      className="ms-auto my-auto w-auto"
                      onClick={() => handleEditQuestion(question.uid)}
                    />
                  )}
                  <FiTrash2
                    role="button"
                    className="ms-4"
                    onClick={() => handleDeleteQuestion(question.uid)}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </DashboardLayout>
  );
};
export default UserDashboardTest;
