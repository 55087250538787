/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Box, Button, Container, IconButton, Typography } from "@mui/material";
import { MdContentCopy } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../config";
import { useSelector } from "react-redux";

function InsuranceReview() {
  const [url, setUrl] = useState("");
  const navigate = useNavigate();
  const { applicationNo, quotationNo } = useSelector(
    (state) => state.insure.selectedInsurance
  );

  useEffect(() => {
    if (quotationNo) {
      handleRedirectUrl(quotationNo);
    } else {
      navigate("/insurance-landing");
    }
  }, [quotationNo]);

  function copyToClipboard(e) {
    navigator.clipboard.writeText(applicationNo);
  }

  const handleRedirectUrl = async (quoteNo) => {
    const res = await axios.post(`${BASE_URL}/redirect`, {
      QuotationNo: quoteNo,
    });
    setUrl(res.data.Url); // url variable here
  };

  const handleRedirectBtn = () => {
    if (url) {
      window.location.href = url;
    }
  };

  return (
    <Container>
      <Box
        sx={{
          backgroundColor: "#fff",
          marginTop: 5,
          borderRadius: 3,
          padding: 5,
        }}
      >
        <Typography
          component="div"
          variant="h6"
          sx={{ textAlign: "center", marginBottom: 5 }}
        >
          Please find below Application Number which will be used for Tracking
          your Insurance Status
        </Typography>
        <Typography
          component="div"
          sx={{ textAlign: "center", color: "#707070", marginBottom: 2 }}
        >
          Insurance Application Number
        </Typography>
        <Box
          sx={{
            border: "1px solid #d5d5d5",
            position: "relative",
            borderRadius: 2,
            padding: 1,
            gap: 2,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              fontSize: "18px",
            }}
          >
            {applicationNo}
          </Typography>
          <IconButton
            aria-label="fingerprint"
            color="primary"
            variant="outlined"
            onClick={copyToClipboard}
            sx={{ position: "absolute", right: 3, top: 4 }}
          >
            <MdContentCopy size={24} />
          </IconButton>
        </Box>
        <Button
          variant="contained"
          size="large"
          fullWidth
          sx={{ marginTop: 4 }}
          disabled={url ? false : true}
          onClick={handleRedirectBtn}
        >
          Redirect
        </Button>
      </Box>
    </Container>
  );
}

export default InsuranceReview;
