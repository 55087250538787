import React from "react";
import { FiArrowUp } from "react-icons/fi";
import { AreaChart, Area, ResponsiveContainer } from "recharts";

const data = [
  {
    name: "Page E",
    uv: 1590,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 1000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 3000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page F",
    uv: 2100,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Page D",
    uv: 1000,
    pv: 3908,
    amt: 2000,
  },
];

export default function TinyAreaChart() {
  return (
    <>
      <h5 className="total-premium-heading">Total Premium</h5>
      <div style={{ width: "100%", height: 100 }}>
        <ResponsiveContainer>
          <AreaChart
            width={445}
            height={90}
            data={data}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <Area
              type="monotone"
              dataKey="uv"
              stroke="#DE350B"
              fill="#DE350B10"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      <div className="row mt-4">
        <div className="col-8 total-premium-amount">
          <span>₹3408978.00</span>
          <p>Hit Rate this year</p>
        </div>
        <div className="col-4 d-flex total-premium-percent">
          <span className="m-auto">
            <FiArrowUp /> 105.23 %
          </span>
        </div>
      </div>
    </>
  );
}
