import React from "react";
import { useNavigate } from "react-router-dom";

const QuotesCard = ({ text }) => {
  const navigate = useNavigate();

  return (
    <div className="get_quotes_card">
      <p>{text}</p>
      <button onClick={() => navigate("/insurance-landing")} className="btn btn-white">GET QUOTES</button>
    </div>
  );
};

export default QuotesCard;
