import React from "react";
// import { Link } from "react-router-dom";
import DashboardLayout from "../components/Layout/DashboardLayout";

const UserDashboard = () => {
  return (
    <DashboardLayout currentTitle="Dashboard">
      <div className="form-content mb-0">
        <div className="row">
          <div className="col-lg-3 my-3">
            <div className="dashboard-card policies">
              <div className="dashboard-card-name">
                <div className="dash-name">Total Policies</div>
                <div className="dash-number">14</div>
              </div>
              <div className="dash-icon">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/dashboard-policies.png`}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 my-3">
            <div className="dashboard-card total-renewal">
              <div className="dashboard-card-name">
                <div className="dash-name">Total Renewals</div>
                <div className="dash-number">210</div>
              </div>
              <div className="dash-icon">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/dashboard-renewal.png`}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 my-3">
            <div className="dashboard-card premium">
              <div className="dashboard-card-name">
                <div className="dash-name">Total Premium</div>
                <div className="dash-number">₹ 9,12,028.00</div>
              </div>
              <div className="dash-icon">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/dashboard-premium.png`}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 my-3">
            <div className="dashboard-card payout">
              <div className="dashboard-card-name">
                <div className="dash-name">Total Premium</div>
                <div className="dash-number">₹ 910</div>
              </div>
              <div className="dash-icon">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/dashboard-payout.png`}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-footer">
        <div>
          <h2 className="upper-heading">Download Our Mobile App</h2>
          <h2 className="lower-heading">Sample, faster, Easy to use</h2>
          <div className="apple-android-links">
            <a href="!" className="apple-store my-2">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/apple-play.png`}
                alt=""
              />
            </a>
            <a href="!" className="android-store my-2">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/google-play.png`}
                alt=""
              />
            </a>
          </div>
        </div>
        <div className="dashboard-footer-img">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/dashboard-footer-img.png`}
            alt=""
          />
        </div>
      </div>
    </DashboardLayout>
  );
};
export default UserDashboard;
