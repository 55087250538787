import React , {useState} from "react";
import { FiFileText, FiHome, FiInfo, FiLogOut, FiRepeat, FiUser, FiUsers } from "react-icons/fi";
import { BiLineChart, BiUser,BiQuestionMark,BiMenuAltLeft } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";

const DashboardSideNavigation = ({ currentTitle, icon }) => {
  const navigate = useNavigate();
  const logout = () => {
    navigate('/login');
    localStorage.removeItem('token');
  }

  const sideNavigationData = [
    // { label: "Dashboard", icon: <FiHome size="24" />, url: "/admin-dashboard" },
    // { label: "Renewals", icon: <FiRepeat size="24" />, url: "/" },
    // {
    //   label: "Test",
    //   icon: <FiFileText size="24" />,
    //   url: "/test",
    // },
    {
      label: "Lead",
      icon: <FiFileText size="24" />,
      url: "/lead",
    },
    { label: "POS Agent", icon: <BiUser size="24" />, url: "/pos-agent" },
    { label: "MIS Report", icon: <BiLineChart size="24" />, url: "/mis-report" },
    // { label: "Tickets", icon: <FiInfo size="24" />, url: "/" },
    // { label: "Employee", icon: <FiUser size="24" />, url: "/" },
    // { label: "POS Agent", icon: <FiUsers size="24" />, url: "/" },
    // { label: "Training", icon: <FiUsers size="24" />, url: "/" },
    { label: "Exam Questions", icon: <BiQuestionMark size="24" />, url: "/exam-questions" },
    { label: "Logout", icon: <FiLogOut size="24" />, url: "/", onClick : logout },
  ];
  const [showDiv, setShowDiv] = useState(false);
  const mobilemenu = () => {
    if(showDiv){
      setShowDiv(false)
    }else{
      setShowDiv(true);
    }
    console.log(showDiv);
  }
  return (
    
    <div>
    <div className="menu_close" onClick={mobilemenu}>
    <BiMenuAltLeft size="24" />
    </div>
      <div className="dashboard-list" style={{
          display: showDiv ? 'block' : 'none',
          transition: showDiv ? '' : 'opacity 0.5s ease-out',
        }}>
        <h2 className="mb-5">Dashboard</h2>
        {sideNavigationData.map(({ label, icon, url, onClick=null }, index) => (
          <Link
            to={url}
            key={`dashboard-list-${index}`}
            className={`dashboard-list-content ${
              currentTitle === label ? "active" : ""
            }`}
            onClick={onClick}
          >
            <span className="list-item-icon">{icon}</span>
            <span className="list-item-name">{label}</span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default DashboardSideNavigation;
