import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, Badge, Button, Table, Alert } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { FaFilter } from "react-icons/fa";
import { FiArrowDown, FiMoreHorizontal } from "react-icons/fi";
import { BiTrash, BiEdit } from "react-icons/bi";
import { FormInput, SelectDropdown } from "../components/common/form";
import AnsRadioBtn from "../components/common/form/AnsRadioBtn";
import FormCheckbox from "../components/common/form/FormCheckbox";
import DashboardLayout from "../components/Layout/DashboardLayout";
import { BASE_URL } from "../config";

const Questionview = () => {
  const [load, setload] = useState(false);
  const [qload, setQload] = useState(false);
  const [mcq, setMcq] = useState([]);
  const [question, setQuestion] = useState("");
  const [Optiona, setOptiona] = useState("");
  const [Optionb, setOptionb] = useState("");
  const [Optionc, setOptionc] = useState("");
  const [Optiond, setOptiond] = useState("");
  const [answear, setAnswear] = useState(0);
  const [quid, setQuid] = useState("");
  const [countq, setCountq] = useState(0);
  const getMcq = async () => {
    setload(true);
    const url = `${BASE_URL}/showQue`;
    const res = await axios.get(url);
    setMcq(res.data.data);
    setCountq(res.data.data.length);
    setload(false);
  };
  function generateUniqueId() {
    const timestamp = new Date().getTime().toString(36);
    const randomVar = Math.random().toString(36).substring(2, 8);
    const uniqueId = timestamp + randomVar;
    return uniqueId;
  }
  function deletequestion(uid) {
    setload(true);
    fetch(BASE_URL + `/delQue`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        uid: uid,
      })
    })
      .then(response => {
        toast.success('Question deleted successfully!');
        getMcq();
        setload(true)
      })
      .catch(error => {
        console.log(error);
        setload(false)
      });
  }
  async function empty_form() {
    getMcq();
    setQuestion('');
    setOptiona('');
    setOptionb('');
    setOptionc('');
    setOptiond('');
    setAnswear('');
    setQuid('');
  }
  //   function checker_id(id){
  //  if(id == 1){
  //             $('input[name="ansida"]').prop('checked', true);
  //           }else if(id == 2){
  //             $('input[name="ansidb"]').prop('checked', true);
  //           }else if(id == 3){
  //             $('input[name="ansidc"]').prop('checked', true);
  //           }else if(id == 4){
  //             $('input[name="ansidd"]').prop('checked', true);
  //           }
  //   }
  async function editquestion(ques) {
    //setQload(true);
    // const url = `${BASE_URL}/showSingleQue/` + ques;
    // try {
    //   const res = await axios.get(url);

    setQuestion(ques.question);
    setOptiona(ques.answers[0]);
    setOptionb(ques.answers[1]);
    setOptionc(ques.answers[2]);
    setOptiond(ques.answers[3]);
    setAnswear(ques.correctAnswer);
    // var ii = res.data.correctAnswer;
    // checker_id(ii);
    setQuid(ques.uid);
    //   setQload(false);

    // } catch (error) {
    //   console.log(error);
    //   setload(false);
    // }
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if (Optiona.length > 0 && Optionb.length > 0 && Optionc.length > 0 && Optiond.length > 0 && answear.length > 0 && question.length > 0) {
      // console.log("-------");
      // console.log(question);
      // console.log(Option);
      // console.log(answear);
      // const formData = new FormData()
      // formData.append('uid',generateUniqueId());
      // formData.append('question',question);
      // formData.append('answers',[Optiona,Optionc,Optionb,Optiond]);
      // formData.append('correctAnswer',answear);
      // console.log(formData);
      // axios.post(BASE_URL+`/uploadQue`, formData).then(({data})=>{
      //   console.log(data);
      // })
      setload(true);
      if (quid) {
        fetch(BASE_URL + `/editQue/` + quid, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            question: question,
            answers: [Optiona, Optionc, Optionb, Optiond],
            correctAnswer: answear
          })
        })
          .then(response => {
            toast.success('Question Updated successfully!');
            empty_form();
            setload(false);
          })
          .catch(error => {
            console.log(error);
            setload(false);
            toast.error('Someting went wrong!');
          });
      } else {
        fetch(BASE_URL + `/uploadQue`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },

          body: JSON.stringify([{
            uid: generateUniqueId(),
            question: question,
            answers: [Optiona, Optionc, Optionb, Optiond],
            correctAnswer: answear
          }])
        })
          .then(response => {
            toast.success('Question Added successfully!');
            empty_form();
            setload(false);
          })
          .catch(error => {
            console.log(error);
            setload(false);
            toast.error('Someting went wrong!');
          });
      }
    }
    else {
      toast.error('All field required');
    }
  };
  function handleAnswerSelect(question, answerIndex) {
    const newSelectedAnswer = {
      uid: question,
      correctAnswer: answerIndex + 1,
    };
  }
  // useEffect(() => {
  //   console.log(policyData);
  // }, [policyData]);

  useEffect(() => {
    getMcq();
  }, []);

  // const getTableData = async () => {
  //   const url = `localhost:4000/status`;
  //   const res = await axios.post(url, { QuotationNo: "QLIFE01000000000592" });
  // };

  // useEffect(() => {
  //   getTableData();
  // }, []);

  const handleChange = (e) => {
    // console.log(e);
  };
  const flexItem = {
    display: "flex",
  };
  return (
    <DashboardLayout currentTitle="Exam Questions">

      <div className="form-content dasboard-lead">
        <div className="row">

          {countq > 30 ? <Alert variant="success">
            30 Question uploaded !
          </Alert>
            :
            <div className="col-12 form_sec">
              {qload ? <div className="linear-background w-100"> </div>
                :
                <Form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12 mb-2">
                      <Form.Group controlId="formName">
                        <Form.Label>Add Question</Form.Label>
                        <Form.Control
                          type="text"
                          required
                          value={question}
                          placeholder="Enter question"
                          onChange={(e) => setQuestion(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-12">
                      <Form.Label>Add Answers</Form.Label>
                    </div>
                    <div className="col-3 form_ans mb-2" >
                      <Form.Group controlId="formName" style={flexItem}>
                        <input className="form-check-input" onChange={(e) => setAnswear(e.target.value)} type="radio" name="ansida" id="ansid" value="1" checked={answear == 1}></input>
                        <input className="form-control" value={Optiona} onChange={(e) => setOptiona(e.target.value)} type="text" name="ansa" id="" placeholder="Enter option"></input>
                      </Form.Group>
                    </div>
                    <div className="col-3 form_ans mb-2" >
                      <Form.Group controlId="formName" style={flexItem}>
                        <input className="form-check-input" type="radio" name="ansidb" id="ansid" value="2" onChange={(e) => setAnswear(e.target.value)} checked={answear == 2}></input>
                        <input className="form-control" value={Optionb} onChange={(e) => setOptionb(e.target.value)} type="text" name="ansb" id="" placeholder="Enter option"></input>
                      </Form.Group>
                    </div>
                    <div className="col-3 form_ans mb-2" >
                      <Form.Group controlId="formName" style={flexItem}>
                        <input className="form-check-input" type="radio" name="ansidc" id="ansid" value="3" onChange={(e) => setAnswear(e.target.value)} checked={answear == 3}></input>
                        <input className="form-control" value={Optionc} onChange={(e) => setOptionc(e.target.value)} type="text" name="ansc" id="" placeholder="Enter option"></input>
                      </Form.Group>
                    </div>
                    <div className="col-3 form_ans mb-2" >
                      <Form.Group controlId="formName" style={flexItem}>
                        <input className="form-check-input" type="radio" name="ansidd" id="ansid" value="4" onChange={(e) => setAnswear(e.target.value)} checked={answear == 4}></input>
                        <input className="form-control" value={Optiond} onChange={(e) => setOptiond(e.target.value)} type="text" name="ansd" id="" placeholder="Enter option"></input>
                      </Form.Group>
                    </div>
                  </div>
                  <Button variant="primary" onClick={handleSubmit} type="submit">
                    Submit
                  </Button>
                </Form>
              }
            </div>
          }
          <div className="col-12">
            <h3 className="title_h">All question</h3>
          </div>
          <div className="col-12">
            {load ? <div className="linear-background w-100"> </div>
              :
              <ul className="questions-list">
                {mcq.map((q) => (
                  <li key={q.uid} className="question-block">
                    <h3>{q.question} <span> <span className="ans_delete_btn"><BiTrash onClick={() => deletequestion(q.uid)} size="24" /></span> <span className="ans_edit_btn"><BiEdit onClick={() => editquestion(q)} size="24" /></span> </span></h3>
                    <ul className="question-options">
                      {q.answers.map((opt, optId) => (
                        <li key={optId}>{opt}</li>
                      ))}
                    </ul>
                    <div className="question-block-action">
                      <ul>
                        {q.answers.map((answer, answerIndex) => (
                          <AnsRadioBtn
                            value={answerIndex + 1}
                            name={`question${q.uid}`}
                            isans={q.correctAnswer}
                          />
                        ))}
                      </ul>
                    </div>
                  </li>
                ))}
              </ul>
            }
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
export default Questionview;
