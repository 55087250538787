import React, { useState } from "react";
import { FormInput } from "../components/common/form";
import { Link } from "react-router-dom";
import RegistrationLayout from "../components/Layout/RegistrationLayout";
import FileUpload from "../components/common/form/FileUpload";
import { Button, Modal } from "react-bootstrap";

const RegistrationUpload = () => {
  const [show, setShow] = useState(false);
  const [trainingModal, setTrainingModal] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleChange = (e) => console.log(e.target.value);
  return (
    <RegistrationLayout currentTitle="Upload Documents">
      <div className="form-content">
        <h3>Upload Documents</h3>
        <div className="row mb-5">
          <div className="col-lg-4 col-sm-6">
            <FileUpload label="PAN Number" />
          </div>
          <div className="col-lg-4 col-sm-6">
            <FileUpload label="Aadhaar Card" />
          </div>
          <div className="col-lg-4 col-sm-6">
            <FileUpload label="Choose Your Photo" />
          </div>
        </div>
        <h3>
          Upload Bank Documents{" "}
          <small>(Passbook/Cancel Cheque/Account Statement)</small>
        </h3>
        <div className="row mb-5">
          <div className="col-lg-4 col-sm-6">
            <FileUpload label="Bank Details" />
          </div>
        </div>
        <h3>
          Qualification <small>(Marksheet/Degree Certificate)</small>
        </h3>
        <div className="row mb-2">
          <div className="col-lg-4 col-sm-6">
            <FileUpload label="Qualification Certificate" />
          </div>
        </div>
        <div className="d-flex mt-4 justify-content-end">
          <Link to="/registration" className="btn btn-primary btn-back">
            Back
          </Link>
          <Button onClick={handleShow} className="btn btn-primary ms-3">
            Register
          </Button>
        </div>
      </div>
      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        backdrop="static"
        keyboard={true}
        className="pospid_modal"
      >
        <div className="modal-body">
          <h3>POSP ID (Please enter your sub broker code)</h3>
          <button
            type="button"
            className="modal_close_btn"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => handleClose(false)}
          >
            <img
              src={`${process.env.PUBLIC_URL}assets/images/modal_close.svg`}
              alt=""
            />
          </button>
          <form>
            <div className="form-check px-0 mb-0">
              <FormInput
                label="Enter SB ID"
                type="text"
                onChange={handleChange}
                classProp="verified_tag"
                required
              />
              <label
                className="form-check-label sbid_label"
                htmlFor="form2Example3"
              >
                Note:- Your POSP ID is sent to your email. Your POSP ID Case
                Senstive.
              </label>
              <br />
              <button className="btn btn-primary">Verify</button>
            </div>
          </form>
          <h2>Terms & Conditions</h2>
          <p>
            Insurance Brokers Private Limited (hereinafter referred to as
            “Insurance” or “We” or “Us” or “Our”) welcomes you at
            ‘www.insurance.com or mobile applications thereof accessible through
            various internet enabled smart devices (individually and
            collectively referred to as “Platform”) for providing access and
            choiceto buy insurance products from various insurance companies,
            and related services and any offerings that are incidental and
            ancillary thereto (‘Services’), as available on the Platform.
            Insurance is not an insurance company but a licensed Insurance
            Broking Company holding a Broking licence from the Insurance
            Regulatory and Development Authority of India.
          </p>
          <div className="d-flex justify-content-between align-items-center">
            <div className="form-check mb-0">
              <input
                className="form-check-input me-2"
                type="checkbox"
                value=""
                id="form2Example3"
              />
              <label
                className="form-check-label tnc_label"
                htmlFor="form2Example3"
              >
                I accept terms & conditions.
              </label>
            </div>
          </div>
          <button
            onClick={() => {
              setShow(false);
              setTrainingModal(true);
            }}
            className="btn btn-primary agree_btn"
          >
            I Agree
          </button>
        </div>
      </Modal>

      <Modal
        show={trainingModal}
        size="lg"
        onHide={() => setTrainingModal(false)}
        backdrop="static"
        keyboard={true}
        className="training_certify_modal"
      >
        <div className="modal-body">
          <button
            type="button"
            className="modal_close_btn"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => setTrainingModal(false)}
          >
            <img
              src={`${process.env.PUBLIC_URL}assets/images/modal_close.svg`}
              alt=""
            />
          </button>
          <h2>Mandatory 15 hours POSP Training & Certification</h2>
          <p>
            Do you want to start your 15 hours training or would like to start
            later.
          </p>
          <div className="vector_img_container">
            <img
              src={`${process.env.PUBLIC_URL}assets/images/training_certify_svg.svg`}
              alt=""
            />
          </div>
          <div className="button_container">
            <button className="btn btn-primary">Will Do Later</button>
            <Link to="/registration-certified" className="btn btn-primary">
              Start Now
            </Link>
          </div>
        </div>
      </Modal>
    </RegistrationLayout>
  );
};
export default RegistrationUpload;
