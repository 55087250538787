import React from "react";
import { FiMessageCircle, FiMessageSquare } from "react-icons/fi";

const NewAgentsAdmin = () => {
  return (
    <>
      <div className="row mb-4 new-agent-stripcard">
        <div className="col-10 d-flex justify-content-start">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/agent1.png`}
            alt=""
          />
          <div className=" new-agent-info">
            <p>Lilly Ortiz</p>
            <p>@lillyortiz</p>
          </div>
        </div>
        <div className="col-2 d-flex justify-content-end align-items-center">
          <FiMessageCircle />
        </div>
      </div>
      <div className="row mb-4 new-agent-stripcard">
        <div className="col-10 d-flex justify-content-start">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/agent2.png`}
            alt=""
          />
          <div className=" new-agent-info">
            <p>Tom Stone</p>
            <p>@tomstone</p>
          </div>
        </div>
        <div className="col-2 d-flex justify-content-end align-items-center">
          <FiMessageCircle />
        </div>
      </div>
      <div className="row mb-4 new-agent-stripcard">
        <div className="col-10 d-flex justify-content-start">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/agent3.png`}
            alt=""
          />
          <div className=" new-agent-info">
            <p>Isabelle Higgins</p>
            <p>@isabellehiggins</p>
          </div>
        </div>
        <div className="col-2 d-flex justify-content-end align-items-center">
          <FiMessageCircle />
        </div>
      </div>
      <div className="row mb-4 new-agent-stripcard">
        <div className="col-10 d-flex justify-content-start">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/agent4.png`}
            alt=""
          />
          <div className=" new-agent-info">
            <p>Rosalie Bowman</p>
            <p>@rosaliebowman</p>
          </div>
        </div>
        <div className="col-2 d-flex justify-content-end align-items-center">
          <FiMessageCircle />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-2 viewall-transaction-btn">
          <button className="w-100">View more POS</button>
        </div>
      </div>
    </>
  );
};

export default NewAgentsAdmin;
