import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Jan",
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Feb",
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Mar",
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Apr",
    pv: 3908,
    amt: 2000,
  },
  {
    name: "May",
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Jun",
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Jul",
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Aug",
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Sep",
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Oct",
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Nov",
    pv: 4300,
    amt: 2100,
  },
  {
    name: "Dec",
    pv: 4300,
    amt: 2100,
  },
];

export default function SalesChart() {
  return (
    <div style={{ width: "100%", height: 400 }}>
      <ResponsiveContainer>
        <AreaChart
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="6 12" horizontal={false} />
          <XAxis
            dataKey="name"
            stroke="transparent"
            tick={{ fill: "#909090" }}
            tickLine={{ stroke: "transparent" }}
          />
          <YAxis
            tick={{ fill: "#909090" }}
            tickLine={{ stroke: "transparent" }}
            stroke="transparent"
          />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="pv"
            stackId="1"
            stroke="#DE350B"
            fill="rgba(222, 53, 11, 0.1)"
          />
          <Area
            type="monotone"
            dataKey="amt"
            stackId="1"
            stroke="#2772F0"
            fill="rgba(39, 114, 240, 0.1)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
