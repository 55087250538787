import React from "react";
import InsuranceFooter from "../components/common/InsuranceFooter";
import TermPlanForm from "../components/TermPlanForm";

const InsuranceLanding = () => {
  return (
    <main className="term-plans">
      <div className="container-fluid">
        <h2 className="page-title">
          Saving Plan that safeguards
          <br /> your family's future
        </h2>
        <p className="page-description hide-in-mobile">
          We need a few details to find the right plan for your Savings.
          Shouldn't take more than few minutes!
        </p>
        <div className="w-100">
          {/* <div className="card-strip card-strip1 hide-in-mobile">
            Covid-19 Covered
          </div>
          <div className="card-strip card-strip2 hide-in-mobile">
            Save Tax Upto 70,000
          </div>
          <div className="card-strip card-strip3 hide-in-mobile">
            Guaranteed Claims Assistance
          </div> */}
          <TermPlanForm />
        </div>
      </div>
      {/* <InsuranceFooter /> */}
    </main>
  );
};

export default InsuranceLanding;
