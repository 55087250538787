import React from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

const CircularProgressCard = ({completed}) => {
  return (
    <div className="card circular-progress-card">
      <div className="card-progress">
        <CircularProgressbar
          value={completed}
          strokeWidth={6}
          styles={buildStyles({
            strokeLinecap: "butt",
            pathColor: "#ee4854",
          })}
        />
      </div>
      <h5>{completed}%</h5>
      <span>Completed</span>
    </div>
  );
};

export default CircularProgressCard;
