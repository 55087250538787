import React, { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import { AiFillQuestionCircle } from 'react-icons/ai';


const CustomTooltip = (props) => {
    const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false);

    return (
        <Tooltip open={tooltipIsOpen}
            onOpen={() => setTooltipIsOpen(true)}
            onClose={() => setTooltipIsOpen(false)}
            title={props.title}
            arrow >
            <div onClick={() => setTooltipIsOpen(!tooltipIsOpen)} > <AiFillQuestionCircle color="crimson"></AiFillQuestionCircle></div>
        </Tooltip>
    );
};

export default CustomTooltip;
