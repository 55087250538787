import React from "react";
import "./registration.scss";
import SideNavigation from "../common/SideNavigation";
import PopOver from "../common/PopOver";

const RegistrationLayout = ({ currentTitle, children }) => {
  return (
    <div className="registration-layout">
      <div className="container-fluid">
        <div className="registration-header">
          <div className="row justify-content-end">
            <div className="col-lg-6 col-md-7 col-8 d-flex align-items-center">
              <div className="contact-info">
                <h5 className="contact-number">1800 999 9999</h5>
                <p className="contact-timing">
                  10 AM to 8 PM (Monday - Sunday)
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-5 col-4 d-flex justify-content-end align-items-center">
              <PopOver />
            </div>
          </div>
        </div>
        <div className="registration-body">
          <div className="row">
            <div className="col-lg-2">
              <SideNavigation type="registration" currentTitle={currentTitle} />
            </div>
            <div className="col-lg-10">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationLayout;
