import React from "react";
import "./registration.scss";
import PopOver from "../common/PopOver";
import DashboardSideNavigation from "../common/DashboardSideNavigation";

const DashboardLayout = ({ currentTitle, children }) => {
  return (
    <div className="registration-layout  dashboard-layout">
      <div className="container-fluid">
        <div className="registration-body mt-4">
          <div className="row">
            <div className="col-lg-2">
              <DashboardSideNavigation currentTitle={currentTitle} />
            </div>
            <div className="col-lg-10">
              <div className="d-flex justify-content-between mb-4">
                <h3 className="contact-number">{currentTitle}</h3>
                {/* <PopOver /> */}
              </div>
              <div>{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
