import React, { useState } from "react";
import { FaAngleRight, FaChevronDown, FaEnvelope } from "react-icons/fa";
import { Link } from "react-router-dom";

function PopOver() {
  const [open, setOpen] = useState(false);
  return (
    <div className="advisors-info" onClick={() => setOpen(!open)}>
      <span>your advisors</span>
      <div className="advisors-images">
        <img src={`${process.env.PUBLIC_URL}/assets/images/04.png`} alt="" />
        <img src={`${process.env.PUBLIC_URL}/assets/images/08.png`} alt="" />
      </div>
      <FaChevronDown />
      {open && (
        <div className="popover-box">
          <div className="popover-box-body">
            <div className="advisors-images">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/04.png`}
                alt=""
              />
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/08.png`}
                alt=""
              />
            </div>
            <h4>+49 231 2866 200</h4>
            <p>
              <span className="text-success">Open</span> Closes at 22:00
            </p>
            <div className="popover-waiting-time">
              Current wait time <span>2 mins</span>
            </div>
          </div>
          <div className="popover-box-footer">
            <a href="mailto:dummy@mail.com" className="btn">
              <FaEnvelope />
              Write e-mail
            </a>
            <Link to="/">
              frequently asked Questions <FaAngleRight />
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default PopOver;
