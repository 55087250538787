import React from "react";
import { FiArrowLeft, FiCheck, FiCheckSquare, FiX } from "react-icons/fi";

const TransactionHistoryAdmin = () => {
  return (
    <>
      <div className="row mb-3 history-heading">
        <div className="col-7">Payment Number</div>
        <div className="col-2 text-center">Amount</div>
        <div className="col-3 text-end">Status</div>
      </div>
      <div className="row transaction-strip history-payment-scuccess">
        <div className="col-7 payment-number">
            <div className="transaction-icon"><FiCheck /></div>
            <div className="transaction-info">
                <p>Payment from <span> #888888</span></p>
                <span>Today, 10:30 AM</span>
            </div>
        </div>
        <div className="col-2 payment-amount">+ ₹650.00</div>
        <div className="col-3 payment-status">
            <span>Completed</span>
        </div>
      </div>
      <div className="row transaction-strip history-refund-scuccess">
        <div className="col-7 payment-number">
            <div className="transaction-icon"><FiArrowLeft /></div>
            <div className="transaction-info">
                <p>Payment refund to <span> #999999</span></p>
                <span>Today, 1:05 zPM</span>
            </div>
        </div>
        <div className="col-2 payment-amount">- ₹250.00</div>
        <div className="col-3 payment-status">
            <span>Completed</span>
        </div>
      </div>
      <div className="row transaction-strip history-payment-fail">
        <div className="col-7 payment-number">
            <div className="transaction-icon"><FiX /></div>
            <div className="transaction-info">
                <p>Payment failed from <span> #666666</span></p>
                <span>Today, 05:05 PM</span>
            </div>
        </div>
        <div className="col-2 payment-amount">+ ₹128.00</div>
        <div className="col-3 payment-status">
            <span>Declined</span>
        </div>
      </div>
      <div className="row mt-3 viewall-transaction-btn">
        <button>View all transactions</button>
      </div>
    </>
  );
};

export default TransactionHistoryAdmin;
