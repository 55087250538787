import React from "react";

const sideNavigationData = {
  registration: [
    "Registration",
    "Upload Documents",
    "Terms & Conditions",
    "Get Certified",
  ],
  training: [
    "Introduction of Insurance",
    "POSP Assessment",
    "Certificate"
  ],
};

const SideNavigation = ({ currentTitle, type }) => {
  const currIndex = sideNavigationData[type].indexOf(currentTitle);
  return (
    <div className="side-wizards">
      <h3>Get Started</h3>
      <ul>
        {sideNavigationData[type].map((title, index) =>
          currIndex < index ? (
            <li>
              <span>{title}</span>
            </li>
          ) : (
            <li className={`active ${currIndex === index ? "current" : ""}`}>
              <span>{title}</span>
            </li>
          )
        )}
      </ul>
    </div>
  );
};

export default SideNavigation;
