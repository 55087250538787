import React from "react";
import { useDropzone } from "react-dropzone";

function FileUpload({ label }) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <div className="file-upload-box">
      <label className="file-upload-label">{label}</label>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/fileupload-icon.png`}
          alt=""
        />
        <p>
          Drag and Drop or <span>Browse</span> to upload
        </p>
      </div>
      <div className="file-uploaded-box">{files}</div>
    </div>
  );
}

export default FileUpload;
