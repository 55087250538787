import React from "react";

function FormRadio({ label, name, value={label} }) {
  return (
    <label className="radio-container">
      <input type="radio" value={value} name={name} />
      <div className="radio-circle"></div>
      {label}
    </label>
  );
}

export default FormRadio;
