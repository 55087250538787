import React from "react";
import LoginForm from "../components/LoginForm";

const Login = () => {
  return (
    <>
      {/* <header className="before-login">
        <div className="container">
          <img
            src={`${process.env.PUBLIC_URL}assets/images/insurepro.svg`}
            alt=""
          />
        </div>
      </header> */}
      <section className="login_section">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
              <p className="login_para_quote">
                Quickly search the best insurance product for your customers and
                sell online.
              </p>
              <h6 className="login_branding_quote">Zero investment required</h6>
              <img
                className="hide-in-tab"
                src={`${process.env.PUBLIC_URL}assets/images/login-lady.png`}
                alt=""
              />
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
              <LoginForm />
              <img
                className="show-in-tab"
                src={`${process.env.PUBLIC_URL}assets/images/login-lady.png`}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Login;
