import React from "react";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import {
  RadialBarChart,
  RadialBar,
  Legend,
  ResponsiveContainer,
  PolarAngleAxis,
} from "recharts";

const data = [
  { uv: 50, fill: "#2772F0" },
  { uv: 75, fill: "#DE350B" },
];

export default function RadialChart() {
  return (
    <>
      <div className="col-xl-5 col-lg-12 col-md-5 col-sm-5 col-xs-5 d-flex">
        <RadialBarChart
          style={{
            transform: "rotate(270deg)",
            width: "100%",
            margin: "auto",
            height: "100%",
            maxHeight: "180px",
            maxWidth: "180px",
          }}
          width={300}
          height={300}
          cx={90}
          cy={90}
          innerRadius={75}
          outerRadius={125}
          barSize={8}
          startAngle={360}
          endAngle={0}
          data={data}
        >
          <PolarAngleAxis
            type="number"
            domain={[0, 100]}
            angleAxisId={0}
            tick={false}
          />
          <PolarAngleAxis
            type="number"
            domain={[0, 100]}
            angleAxisId={1}
            tick={false}
          />
          <RadialBar background dataKey="uv" angleAxisId={0} data={[data[0]]} />
          <RadialBar background dataKey="uv" angleAxisId={1} data={[data[1]]} />
        </RadialBarChart>
      </div>
      <div className="col-xl-7 col-lg-12 col-md-7 col-sm-7 col-xs-7">
        <div className="row radial-chart-non-certify-info mb-4">
          <div className="col-7 d-flex flex-column">
            <div>
                <h6>Non Certified POS </h6>
                <h4>75%</h4>
            </div>
            <p className="year-status">On board this year</p>
          </div>
          <div className="col-5 d-flex">
            <span className="m-auto profit-signal"> <FiArrowUp /> 105.23 %</span>
          </div>
        </div>
        <div className="row radial-chart-certify-info">
          <div className="col-7 d-flex flex-column">
            <div>
                <h6>Certified POS </h6>
                <h4>50%</h4>
            </div>
            <p className="year-status mt-auto">Active this year</p>
          </div>
          <div className="col-5 d-flex">
            <span className="m-auto loss-signal"> <FiArrowDown /> 20.4 %</span>
          </div>
        </div>
      </div>
    </>
  );
}
