import { configureStore } from "@reduxjs/toolkit";
import insureSlice from "./slices/insureSlice";
import userSlice from "./slices/userSlice";
import posIdSlice from "./slices/posIdSlice"

export const store = configureStore({
  reducer: {
    insure: insureSlice,
    user: userSlice,
    posId: posIdSlice,
  },
});
