import React, { useEffect, useLayoutEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import PrivateRoutes from "./utils/PrivateRoutes";

// Styles
import "./App.scss";
import "./styles/_media.scss";

// Pages
import Home from "./components/Home";
import Login from "./pages/Login";
import Registration from "./pages/Registration";
import RegistrationUpload from "./pages/RegistrationUpload";
import RegistrationCertified from "./pages/RegistrationCertified";
import Training from "./pages/Training";
import Assessment from "./pages/Assessment";
import UserDashboard from "./pages/UserDashboard";
import AssessmentComplete from "./pages/AssessmentComplete";
import InsuranceLanding from "./pages/InsuranceLanding";
import InsuranceListing from "./pages/InsuranceListing";
import NotFound from "./pages/NotFound";
import AdminDashboard from "./pages/AdminDashboard";
import UserDashboardLead from "./pages/UserDashboardLead";
import { ThemeProvider } from "@mui/material";
import { themeConfig } from "./themeConfig";
import MISReport from "./pages/MISReport";
import Questionview from "./pages/Questionview";
import InsuranceReview from "./pages/InsuranceReview";
import UserDasboardTest from "./pages/UserDashboardTest";
import { Toaster } from "react-hot-toast";
import Dummy from "./pages/Dummy";
import TrainingRoutes from "./utils/TrainingRoutes";
import PosAgent from "./pages/PosAgent";

const ScrollToTop = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function App() {
  useEffect(() => {
    // localStorage.setItem("token", "qwertyui");
  }, []);
  return (
    <div className="App">
      <ThemeProvider theme={themeConfig}>
        <BrowserRouter>
          <ScrollToTop>
            <Toaster />
            <Routes>
              {/* <Route path="/" element={<Home />} /> */}

              <Route path="/insurance-landing" element={<InsuranceLanding />} />
              <Route path="insurance-listing" element={<InsuranceListing />} />
              <Route path="insurance-review" element={<InsuranceReview />} />

              {/* Working Routes */}
              <Route
                path="registration-certified"
                element={<RegistrationCertified />}
              />
              <Route
                path="registration-upload"
                element={<RegistrationUpload />}
              />
              <Route path="registration" element={<Registration />} />
              <Route path="user-dashboard" element={<UserDashboard />} />
              <Route path="test" element={<UserDasboardTest />} />
              <Route path="dummy" element={<Dummy />} />
              <Route path="/" element={<Home />} />

              <Route path="training" element={<Training />} />
              <Route element={<TrainingRoutes />}>
                <Route path="assessment" element={<Assessment />} />
                <Route path="certificate" element={<AssessmentComplete />} />
              </Route>

              <Route element={<PrivateRoutes />}>
                <Route path="admin-dashboard" element={<AdminDashboard />} />
                <Route path="pos-agent" element={<PosAgent />} />
                <Route path="mis-report" element={<MISReport />} />
                <Route path="exam-questions" element={<Questionview />} />
                <Route path="lead" element={<UserDashboardLead />} />
              </Route>

              <Route path="/login" element={<Login />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </ScrollToTop>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
