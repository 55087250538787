import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  insuranceQuote: null,
  insuranceList: [],
  selectedInsurance: {
    quotationNo: null,
    applicationNo: null,
  },
};

export const insureSlice = createSlice({
  name: "insure",
  initialState,
  reducers: {
    addQuoteRequest: (state, { payload }) => {
      state.insuranceQuote = { ...payload };
    },
    addQuoteResponse: (state, { payload }) => {
      state.insuranceList = [...payload];
    },
    finalInsurance: (state, { payload }) => {
      state.selectedInsurance = { ...payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const { addQuoteRequest, addQuoteResponse, finalInsurance } =
  insureSlice.actions;

export default insureSlice.reducer;
