import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="container mt-5">
      <ul>
        {/* <li className="mt-3">
          Login => &nbsp;
          <Link to="/login">/login</Link>
        </li>
        <li className="mt-3">
          Registration => &nbsp;
          <Link to="/registration">/registration</Link>
        </li>
        <li className="mt-3">
          Registration Upload => &nbsp;
          <Link to="/registration-upload">/registration-upload</Link>
        </li>
        <li className="mt-3">
          Terms & Conditions (click on "Submit") => &nbsp;
          <Link to="/registration-upload">/registration-upload</Link>
        </li>
        <li className="mt-3">
          Mandatory 15 hours POSP Training & Certification (click on "Submit"
          and than on "I Agree") => &nbsp;
          <Link to="/registration-upload">/registration-upload</Link>
        </li>
        <li className="mt-3">
          Registration Certified => &nbsp;
          <Link to="/registration-certified">/registration-certified</Link>
        </li>
        <li className="mt-3">
          POSP Insurance Training => &nbsp;
          <Link to="/training">/training</Link>
        </li>
        <li className="mt-3">
          POSP Insurance Training Complete Popup => &nbsp;
          <Link to="/training">/training</Link>
        </li>
        <li className="mt-3">
          Assessment => &nbsp;
          <Link to="/assessment">/assessment</Link>
        </li>
        <li className="mt-3">
          Assessment Complete => &nbsp;
          <Link to="/assessment-complete">/assessment-complete</Link>
        </li> */}
        <li className="mt-3">
          Insurance Landing = &nbsp;
          <Link to="/insurance-landing?customerId=AC2301000024&posId=12345">/insurance-landing</Link>
        </li>
        {/* <li className="mt-3">
          Insurance Listing => &nbsp;
          <Link to="/insurance-listing">/insurance-listing</Link>
        </li>
        <li className="mt-3">
          Admin Dashboard => &nbsp;
          <Link to="/admin-dashboard">/admin-dashboard</Link>
        </li>
        <li className="mt-3">
          User Dashboard => &nbsp;
          <Link to="/user-dashboard">/user-dashboard</Link>
        </li>
        <li className="mt-3">
          User Dashboard Lead => &nbsp;
          <Link to="/user-dashboard-lead">/user-dashboard-lead</Link>
        </li>
        <li className="mt-3">
          MIS Report => &nbsp;
          <Link to="/mis-report">/mis-report</Link>
        </li> */}
      </ul>
    </div>
  );
};
export default Home;
