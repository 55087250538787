import axios from "axios";
import React, { useEffect, useState } from "react";
import { Badge, Table, Button, Modal, Form } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { FaFilter } from "react-icons/fa";
import {
  FiArrowDown,
  FiArrowLeft,
  FiArrowRight,
  FiMoreHorizontal,
} from "react-icons/fi";
import { FormInput, SelectDropdown } from "../components/common/form";
import FormCheckbox from "../components/common/form/FormCheckbox";
import FormRadio from "../components/common/form/FormRadio";
import DashboardLayout from "../components/Layout/DashboardLayout";
import { BASE_URL } from "../config";

const MISReport = () => {
  const [policyData, setPolicyData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(2);
  const [currentItems, setCurrentItems] = useState([]);

  const [show, setShow] = useState(false);
  const [file, setFile] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFileUpload = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    const url = `${BASE_URL}/retriveDataFromExcel`;
    try {
      const response = await axios.post(url, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.data.message) {
        toast.success("Imported successfully")
      }
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  // data api call start
  const getPolicyData = async () => {
    const finalQuoteToast = toast.loading("Loading...");
    const bodyData = {
      fName: "ShrikantTest",
      lName: "Ajmire",
      dateOfBirth: "1965-01-01",
      mobile: "9889013090",
      email: "soq988i@yomail.com",
      salesChannelCode: "",
      carrierCode: "HDFC",
      insurerName: "HDFC Life",
      frequency: "2",
      premium: "57000",
      term: "12",
      ppt: "10",
      payOutFrequency: "Annual",
    };
    const url = `${BASE_URL}/createQuote`;
    const res = await axios.post(url, bodyData);
    // console.log(res.data);
    setPolicyData(res.data);
    toast.remove();
  };

  useEffect(() => {
    // console.log(policyData);
  }, [policyData]);
  useEffect(() => {
    getPolicyData();
  }, []);
  // data api call end

  // pagination and page range start
  useEffect(() => {
    setCurrentItems(
      policyData &&
      policyData?.slice((currentPage - 1) * pageSize, currentPage * pageSize)
    );
  }, [currentPage, pageSize, policyData]);

  const totalPages = Math.ceil(policyData && policyData?.length / pageSize);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  const pageNumbers = getPageNumbers();

  const startItemIndex = (currentPage - 1) * pageSize + 1;
  const endItemIndex = Math.min(currentPage * pageSize, policyData && policyData?.length);
  const totalItems = policyData && policyData?.length;
  // pagination and page range end

  const handleChange = (e) => {
    // console.log(e);
  };
  return (
    <DashboardLayout currentTitle="MIS Report">
      <div className="form-content mis-report">
        <div className="form-container">
          <form>
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-12">
                <FormInput
                  label="From Date"
                  type="Date"
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <FormInput
                  label="To Date"
                  type="Date"
                  onChange={handleChange}
                />
              </div>

              <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12">
                <SelectDropdown
                  label="Product Name"
                  options={["All", "Popular"]}
                  onChange={handleChange}
                />
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12">
                <SelectDropdown
                  label="Payment Status"
                  options={["Success", "Fail", "Pending"]}
                  onChange={handleChange}
                />
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4 col-sm-12">
                <SelectDropdown
                  label="Policy Status"
                  options={["Success", "Fail", "Pending"]}
                  onChange={handleChange}
                />
              </div>
              {/* <div className="col-xl-2 col-lg-3 col-md-3 col-sm-12">
                <SelectDropdown
                  label="User Role"
                  options={["All.", "All", "All"]}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12">
                <SelectDropdown
                  label="Region"
                  options={["Select Region", "Select Region", "Select Region"]}
                  onChange={handleChange}
                />
              </div> */}
              <div className="col-xl-5 col-lg-9 col-md-12 col-sm-12 d-flex flex-column">
                <div>
                  <FormRadio label="Policy Number" name="policy-number" />
                  {/* <FormRadio label="Vehicle Number" name="policy-number" /> */}
                  <FormRadio label="Order Number" name="policy-number" />
                </div>
                <FormInput
                  label=""
                  type="text"
                  placeholder="Policy Number"
                  classProp="label-d-none"
                  onChange={handleChange}
                />
              </div>
              {/* <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12 d-flex">
                <FormInput
                  label=""
                  type="text"
                  placeholder="Search"
                  classProp="label-d-none"
                  onChange={handleChange}
                />
              </div> */}
            </div>
            <div className="d-flex mt-3 justify-content-start">
              <button className="btn btn-primary SEARCH_btn">SEARCH</button>
              <button className="btn btn-secondary RESET_btn" disabled>
                RESET
              </button>
            </div>


          </form>
        </div>
        <div className="row mt-4 mb-1">
          {/* <div className="col-lg-3 d-flex">
            <SelectDropdown
              label={"Show :"}
              options={["All Project", "Option 2"]}
              value="All Project"
              variant="trans_grey filter-dropdown"
            />
          </div> */}
          <div className="col-lg-12 d-flex justify-content-end">
            <div className="table-filter">
              <button className="py-3" onClick={handleShow}>
                {" "}
                <FiArrowDown className="me-2" /> Import
              </button>
              <button className="py-3">
                {" "}
                <FiArrowDown className="me-2" /> Generate Report
              </button>

              {/* <button className="filter-btn btn-outline-secondary">
                <FaFilter />{" "}
              </button>
              <SelectDropdown
                label={"Sort By :"}
                options={["1", "2"]}
                variant="trans_grey filter-dropdown w-25"
              /> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-9">
            <FormInput placeholder="Search for ID, Name" />
          </div>
          <div className="col-lg-3">
            <SelectDropdown
              label="Action"
              options={["Delete", "Update"]}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div style={{ overflowX: "auto" }}>
              <Table hover>
                <thead>
                  <tr>
                    <th>
                      <FormCheckbox />
                    </th>
                    <th>Product</th>
                    <th>Company</th>
                    <th>Policy Name</th>
                    <th>Policy Holder Name</th>
                    <th>Policy No.</th>
                    <th>Policy Status</th>
                    <th>Sum Assured (Rs)</th>
                    <th>Premium (Rs)</th>
                    <th>Type of Premium</th>
                    <th>Start Date</th>
                    <th>Expiry Date</th>
                    <th>Next Premium due</th>
                    <th>
                      <FiMoreHorizontal />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems && currentItems?.length > 0 ? (
                    currentItems.map((policy, i) => {
                      if (policy?.ErrorMessage) {
                        return (
                          <tr key={i}>
                            <td>No Data Found</td>
                          </tr>
                        );
                      } else if (policy?.Age) {
                        return (
                          <tr>
                            <td>{(currentPage - 1) * pageSize + i + 1}</td>
                            <td>{policy?.Plan}</td>
                            <td>
                              {" "}
                              <img
                                src={`${process.env.PUBLIC_URL}assets/images/${policy?.CarrierCode}.png`}
                                alt=""
                                style={{ width: 60 }}
                              />
                            </td>
                            <td>{policy?.ProdName}</td>
                            <td>
                              {policy?.PolicyCustomerList[0].FirstName}{" "}
                              {policy?.PolicyCustomerList[0].LastName}
                            </td>
                            <td>-</td>
                            <td>
                              <Badge bg="success"></Badge>
                            </td>
                            <td>₹ {policy?.SumAssured}</td>
                            <td>₹ {policy?.Premium}</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>{policy?.NextPremDate}</td>
                            <td>
                              <FiMoreHorizontal />
                            </td>
                          </tr>
                        );
                      } else {
                        return (
                          <tr key={i}>
                            <td>{(currentPage - 1) * pageSize + i + 1}</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                          // <p key={i}></p>
                        );
                      }
                    })
                  ) : (
                    <tr>
                      <td>No Data Found</td>
                    </tr>
                  )}

                  {/* <tr>
              <td>
                <FormCheckbox />
              </td>
              <td>Long Term Income</td>
              <td>
                {" "}
                <img
                  src={`${process.env.PUBLIC_URL}assets/images/HDFC.png`}
                  alt=""
                  style={{ width: 60 }}
                />
              </td>
              <td>HDFC Life Sanchay Plus</td>
              <td>Vijay Singh</td>
              <td>338383838383838 </td>
              <td>
                <Badge bg="warning">Expired</Badge>
              </td>
              <td>₹ 1 Cr</td>
              <td>₹ 20,000</td>
              <td>Monthly</td>
              <td>23 Nov 2019</td>
              <td>24 Nov 2019</td>
              <td>24 Nov 2019</td>
              <td>
                <FiMoreHorizontal />
              </td>
            </tr>
            <tr>
              <td>
                <FormCheckbox />
              </td>
              <td>Long Term Income</td>
              <td>
                {" "}
                <img
                  src={`${process.env.PUBLIC_URL}assets/images/HDFC.png`}
                  alt=""
                  style={{ width: 60 }}
                />
              </td>
              <td>HDFC Life Sanchay Plus</td>
              <td>Vijay Singh</td>
              <td>338383838383838 </td>
              <td>
                <Badge bg="success">Inforce</Badge>
              </td>
              <td>₹ 1 Cr</td>
              <td>₹ 65,000</td>
              <td>Monthly</td>
              <td>23 Nov 2019</td>
              <td>24 Nov 2019</td>
              <td>24 Nov 2019</td>
              <td>
                <FiMoreHorizontal />
              </td>
            </tr> */}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <b className="ms-4">{`Showing ${startItemIndex} to ${endItemIndex} of ${totalItems} items`}</b>
        </div>
        <div className="col-md-6 d-flex mb-5 pagination-btns justify-content-end align-items-center">
          <button className="ms-4"
            onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
          >
            <FiArrowLeft />
          </button>
          {pageNumbers.map((pageNumber) => (
            <button
              className={`ms-4 ${currentPage === pageNumber ? "active" : ""
                }`}
              onClick={() => handlePageChange(pageNumber)}
            >
              {pageNumber}
            </button>
          ))}
          {/* <button className="active">1</button>
          <button>2</button>
          <button>3</button>
          <button>...</button>
          <button>32</button> */}
          <button className="mx-4"
            onClick={() =>
              currentPage < totalPages && handlePageChange(currentPage + 1)
            }
          >
            <FiArrowRight />
          </button>
        </div>
      </div>


      <Modal show={show} onHide={handleClose} size="sm" className="mis_file_im_mod">
        <Modal.Header closeButton>
          <Modal.Title>Upload Excel File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formFile">
              <Form.Label>Select an Excel File</Form.Label>
              <Form.Control type="file" onChange={handleFileUpload} />
            </Form.Group>
            <Button className="mt-3" variant="primary" type="submit">
              Upload
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </DashboardLayout>
  );
};

export default MISReport;
