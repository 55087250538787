import React from "react";
import { FiArrowUp } from "react-icons/fi";
import AverageSaleChart from "../components/AverageSaleChart";
import Barchart from "../components/Barchart";
import DashboardLayout from "../components/Layout/DashboardLayout";
import NewAgentsAdmin from "../components/NewAgentsAdmin";
import RadialChart from "../components/RadialChart";
import SalesCharts from "../components/SalesChart";
import TinyAreaChart from "../components/TinyAreaChart";
import TransactionHistoryAdmin from "../components/TransactionHistoryAdmin";

function AdminDashboard() {
  return (
    <DashboardLayout currentTitle="">
      <div className="form-content">
        <div className="row mt-5">
          <div className="col-lg-3">
            <div className="dashboard-card policies">
              <div className="dashboard-card-name">
                <div className="dash-name">Total Policies</div>
                <div className="dash-number">₹ 14</div>
              </div>
              <div className="dash-icon">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/dashboard-policies.png`}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="dashboard-card total-renewal">
              <div className="dashboard-card-name">
                <div className="dash-name">Total Renewals</div>
                <div className="dash-number">₹ 210</div>
              </div>
              <div className="dash-icon">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/dashboard-renewal.png`}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="dashboard-card premium">
              <div className="dashboard-card-name">
                <div className="dash-name">Total Premium</div>
                <div className="dash-number">₹ 19202</div>
              </div>
              <div className="dash-icon">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/dashboard-premium.png`}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="dashboard-card payout">
              <div className="dashboard-card-name">
                <div className="dash-name">Total Premium</div>
                <div className="dash-number">₹ 910</div>
              </div>
              <div className="dash-icon">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/dashboard-payout.png`}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <h3 className="my-4">Number of POS</h3>
        </div>
        <div className="col-lg-6 d-flex justify-content-end">
          <div className="orange-curve-indicator">
            <span></span>
            On board
          </div>
          <div className="blue-curve-indicator">
            <span></span>
            Active
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-content">
            <SalesCharts />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="row px-0 white-box radial-chart">
            <RadialChart />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="white-box py-3">
            <TinyAreaChart />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="white-box">
            <AverageSaleChart />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="white-box px-3">
            <Barchart />
          </div>
        </div>
        <div className="col-lg-12">
          <div className="white-box">Sales Location</div>
        </div>
        <div className="col-lg-4">
          <div>
            <h4>New POS Agents On Board</h4>
          </div>
          <div className="white-box">
            <NewAgentsAdmin />
          </div>
        </div>
        <div className="col-lg-8">
          <div>
            <h4>New POS Agents On Board</h4>
          </div>
          <div className="white-box">
            <TransactionHistoryAdmin />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default AdminDashboard;
