import { Button, Tooltip } from "@mui/material";
import { AiFillInfoCircle } from 'react-icons/ai';

import axios from "axios";
import Cookies from "js-cookie";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../config";
import { addQuoteRequest, addQuoteResponse } from "../slices/insureSlice";
import { addUser } from "../slices/userSlice";
import { FormInput, SelectDropdown } from "./common/form";
import toast from "react-hot-toast";
import CustomTooltip from "./common/CustomTooltip";

const TermPlanForm = () => {
  const [formData, setFormData] = useState({});
  const [paramData, setParamData] = useState({});
  const location = useLocation();

  // auto selct conts
  const [selectfrequency, setSelectfrequency] = useState("");
  const [selectincomeTerm, setSelectincomeTerm] = useState("");
  const [selectterm, setSelectterm] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [gender, setGender] = useState("");
  const [selectpayoutTerm, setSelectPayoutTerm] = useState("");
  const [selectdefermentPeriod, setSelectdefermentPeriod] = useState("");
  const [residentstatus, setResidentstatus] = useState("");
  const [selectpremium, setSelectpremium] = useState("");
  const [selectppt, setSelectppt] = useState("");
  const [selectpayoutFrequency, setSelectpayoutFrequency] = useState("");
  useEffect(() => {
    const storefrequency = localStorage.getItem("frequencyval");
    const storeincomeTerm = localStorage.getItem("incomeTermval");
    const storeterm = localStorage.getItem("termval");
    const storepremium = localStorage.getItem("premiumval");
    const storeppt = localStorage.getItem("pptval");
    const storepayoutTerm = localStorage.getItem("payoutTermval");
    const storedefermentPeriod = localStorage.getItem("defermentPeriodval");
    const storepayoutFrequency = localStorage.getItem("payoutFrequencyval");
    const storeresidentstatus = localStorage.getItem("residentstatusval")
    if (storefrequency) {
      setSelectfrequency(storefrequency);
    }
    if (storeincomeTerm) {
      setSelectincomeTerm(storeincomeTerm);
    }
    if (storeterm) {
      setSelectterm(storeterm);

    }
    if (storepremium) {
      setSelectpremium(storepremium);
    }
    if (storeppt) {
      setSelectppt(storeppt);
    }

    if (storepayoutTerm) {
      setSelectPayoutTerm(storepayoutTerm);
    }
    if (storedefermentPeriod) {
      setSelectdefermentPeriod(storedefermentPeriod);
    }

    if (storepayoutFrequency) {
      setSelectpayoutFrequency(storepayoutFrequency);
    }
    if (storeresidentstatus) {
      setResidentstatus(storeresidentstatus);
    }

  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const param1 = searchParams.get('customerId');
    const param2 = searchParams.get('posId');
    setParamData({
      customerId: param1,
      posId: param2
    });
    let paramDataGte = { customerId: param1, posId: param2 }
    // setPosId(param2);
    getInitialData(paramDataGte);
    // do something with myParam
  }, [location]);

  // console.log(paramData);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    return `${year}-${month}-${day}`;
  };

  const getInitialData = async (paramDataGte) => {
    const finalQuoteToast = toast.loading("Loading...");
    const tokenUrl = `${BASE_URL}/ambrela`;
    const tokenRes = await axios.get(tokenUrl, { withCredentials: true });
    console.log("token", tokenRes.data.token);
    // Cookies.set("cookieToken", tokenRes.data.token, { session: true });
    const apiCookie = Cookies.get("cookieToken");
    console.log("apiCookie", apiCookie);

    const customerUrl = `${BASE_URL}/api/preFill`;

    try {
      const customerRes = await axios.get(customerUrl, {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          token: tokenRes.data.token,
          ...paramDataGte
          // customerId: customerId,
          // posId: posId,
        },
        withCredentials: true,
      });
      if (customerRes?.data?.statusCode == 401) {
        toast.error(`Try again...`, {
          duration: 10000,
          position: "top-center",
          id: finalQuoteToast,
        });
      }

      // console.log("customerRes", customerRes.data.data);
      const storefrequency = localStorage.getItem("frequencyval");
      const storeincomeTerm = localStorage.getItem("incomeTermval");
      const storeterm = localStorage.getItem("termval");
      const storepremium = localStorage.getItem("premiumval");
      const storeppt = localStorage.getItem("pptval");
      const storepayoutTerm = localStorage.getItem("payoutTermval");
      const storedefermentPeriod = localStorage.getItem("defermentPeriodval");
      const storepayoutFrequency = localStorage.getItem("payoutFrequencyval");
      const storeresidentstatus = localStorage.getItem("residentstatusval")


      setFormData({
        ...formData,
        fName: customerRes.data.data.firstName,
        lName: customerRes.data.data.lastName,
        dateOfBirth: formatDate(customerRes.data.data.dob),
        mobile: customerRes.data.data.phoneNumber,
        email: customerRes.data.data.email,
        gender: customerRes.data.data.gender,
        frequency: customerRes.data.data.frequency || storefrequency,
        incomeTerm: customerRes.data.data.incomeTerm || storeincomeTerm,
        term: customerRes.data.data.term || storeterm,
        ppt: customerRes.data.data.ppt || storeppt,
        payoutTerm: customerRes.data.data.payoutTerm || storepayoutTerm,
        defermentPeriod: customerRes.data.data.defermentPeriod || storedefermentPeriod,
        payoutFrequency: customerRes.data.data.payoutFrequency || storepayoutFrequency,
        premium: customerRes.data.data.premium || storepremium,
        residentstatus: customerRes?.data?.data?.residentStatus || storeresidentstatus,
      });
    } catch (error) {
      console.log(error);
      toast.error(`${error.response.data.message}`, {
        duration: 5000,
        position: "top-center",
        id: finalQuoteToast,
      });
    }

    toast.remove();
    // console.log(formData);
  };
  //   useEffect(()=>{
  //     reloadonetime()
  // },[])
  // const reloadonetime = () =>{
  //   if(!window.location.hash) {
  //     window.location = window.location+'##';
  //     window.location.reload();
  //   }
  //   getInitialData()
  // }
  // useEffect(() => {
  //   getInitialData();    
  // }, [paramData]);

  // useEffect(() => {
  //   console.log(formData);
  // }, [formData]);

  const [errors, setErrors] = useState({
    fName: "",
    lName: "",
    dateOfBirth: "",
    email: "",
    mobile: "",
    frequency: "",
    incomeTerm: "",
    premium: "",
    ppt: "",
    payoutTerm: "",
    defermentPeriod: "",
    payoutFrequency: "",
    gender: "",
    term: "",
    state: "",
    residentstatus: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validateForm = () => {
    let newErrors = {};

    if (!state) {
      newErrors.state = "State is required";
    }
    if (!city) {
      newErrors.city = "City is required";
    }
    if (!gender) {
      newErrors.gender = "Gender is required";
    }
    if (!residentstatus) {
      newErrors.residentstatus = "Resident status is required";
    }

    if (!formData.fName) {
      newErrors.fName = "First name is required";
    } else if (!/^[a-zA-Z]{2,20}$/.test(formData.fName)) {
      newErrors.fName = "First name is not valid";
    }
    if (!formData.lName) {
      newErrors.lName = "Last name is required";
    } else if (!/^[a-zA-Z]{2,20}$/.test(formData.lName)) {
      newErrors.lName = "Last name is not valid";
    }
    if (!formData.dateOfBirth) {
      newErrors.dateOfBirth = "Date of Birth";
    }
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formData.email)
    ) {
      newErrors.email = "Email is not valid";
    }
    if (!formData.mobile) {
      newErrors.mobile = "Mobile number is required";
    } else if (!/^(\+91[\-\s]?)?[0]?(91)?[123456789]\d{9}$/.test(formData.mobile)) {
      newErrors.mobile = "Mobile number is not valid";
    }
    if (!formData.frequency) {
      newErrors.frequency = "Frequency is not valid";
    }
    if (!formData.incomeTerm) {
      newErrors.incomeTerm = "Income Term is not valid";
    }
    if (!formData.term) {
      newErrors.term = "Policy Term is not valid";
    }
    if (!formData.premium) {
      newErrors.premium = "Premium is required";
    } else if (formData.premium <= 2499) {
      newErrors.premium = "Premium must be minimum 2499";
    }
    if (!formData.ppt) {
      newErrors.ppt = "Premium Paying Term is not valid";
    }
    if (!formData.payoutFrequency) {
      newErrors.payoutFrequency = "Payout Frequency is not valid";
    }
    if (!formData.term) {
      newErrors.term = "Income Term is not valid";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const getQuoteToast = toast.loading("Loading...");
      // Perform API call or other logic here
      let userData = {
        fName: formData.fName,
        lName: formData.lName,
        dateOfBirth: formData.dateOfBirth,
        mobile: formData.mobile,
        email: formData.email,
        gender: formData.gender,
      };
      let quoteData = {
        frequency: formData.frequency,
        incomeTerm: formData.incomeTerm,
        premium: formData.premium,
        ppt: formData.ppt,
        term: formData.term,
        payoutTerm: formData.payoutTerm,
        defermentPeriod: formData.defermentPeriod,
        payoutFrequency: formData.payoutFrequency,
        residentstatus: formData.residentstatus,
      };
      let allData = { ...userData, ...quoteData };
      dispatch(addUser(userData));
      dispatch(addQuoteRequest(quoteData));
      axios.post(`${BASE_URL}/createQuote`, {
        ...formData,
        carrierCode: "HDFC",
        insurerName: "HDFC Life",
        salesChannelCode: "",
      })
        .then((res) => {
          console.log("===================");
          console.log(res.data);
          dispatch(addQuoteResponse(res.data));
          navigate("/insurance-listing");
          toast.remove();
        })
        .catch((err) => {
          console.log(err);
          toast.error(`${err.response.data.message}`, {
            duration: 5000,
            position: "top-center",
            id: getQuoteToast,
          });

        });
    }
  };
  const handleforFnl = (label, value) => {
    localStorage.setItem(label, value);
    console.log(label);
    if (label === "state") {
      setState(value);
    }
    if (label === "city") {
      setCity(value);
    }
    if (label === "gender") {
      setGender(value);
      setFormData({ ...formData, [label]: value });
    }
    if (label === "residentstatus") {
      setResidentstatus(value);
      setFormData({ ...formData, [label]: value });
    }
  }

  const handleChange = (label, value) => {
    setFormData({ ...formData, [label]: value });
    if (label == "frequency") {
      localStorage.setItem('frequencyval', value);
    }
    if (label == "incomeTerm") {
      localStorage.setItem('incomeTermval', value);
    }
    if (label == "term") {
      localStorage.setItem('termval', value);
    }
    if (label == "ppt") {
      localStorage.setItem('pptval', value);
    }
    if (label == "payoutTerm") {
      localStorage.setItem('payoutTermval', value);
    }
    if (label == "defermentPeriod") {
      localStorage.setItem('defermentPeriodval', value);
    }
    if (label == "payoutFrequency") {
      localStorage.setItem('payoutFrequencyval', value);
      setSelectfrequency(value);
    }
    if (label == "premium") {
      localStorage.setItem('premiumval', value);
    }
    if (label == "residentstatus") {
      localStorage.setItem('residentstatusval', value);
    }
  };

  return (
    <>
      <div className="term-plans-form">
        <h6>
          We need a few details to find the right plan for your Savings.
          Shouldn't take more than few minutes!
        </h6>
        <div className="row">
          <div className="col-md-6 col-sm-12 ">
            <div className={`input_content`}>
              <label>First Name</label>
              <input
                type="text"
                name="fName"
                onChange={(e) => handleChange("fName", e.target.value)}
                value={formData?.fName}
              />
              {errors.fName ? (
                <span
                  style={{
                    color: "#EE4854",
                    marginTop: "-10px",
                    fontSize: "13px",
                  }}
                  className="d-block mb-3 px-3"
                >
                  {errors.fName}
                </span>
              ) : null}
              <img
                src={`${process.env.PUBLIC_URL}assets/images/verified_svg.svg`}
                alt=""
              />
            </div>
            {/* <FormInput
              label="What's your First Name?"
              type="text"
              name="fName"
              onChange={handleChange}
              validationSpan={
                errors.fName ? (
                  <span
                    style={{
                      color: "#EE4854",
                      marginTop: "-10px",
                      fontSize: "13px",
                    }}
                    className="d-block mb-3 px-3"
                  >
                    {errors.fName}
                  </span>
                ) : null
              }
            /> */}
          </div>
          <div className="col-md-6 col-sm-12 ">
            <div className={`input_content`}>
              <label>Last Name</label>
              <input
                type="text"
                name="lName"
                onChange={(e) => handleChange("lName", e.target.value)}
                value={formData?.lName}
              />
              {errors.lName ? (
                <span
                  style={{
                    color: "#EE4854",
                    marginTop: "-10px",
                    fontSize: "13px",
                  }}
                  className="d-block mb-3 px-3"
                >
                  {errors.lName}
                </span>
              ) : null}
              <img
                src={`${process.env.PUBLIC_URL}assets/images/verified_svg.svg`}
                alt=""
              />
            </div>
            {/* <FormInput
              label="What's your Last Name?"
              type="text"
              name="lName"
              onChange={handleChange}
              validationSpan={
                errors.lName ? (
                  <span
                    style={{
                      color: "#EE4854",
                      marginTop: "-10px",
                      fontSize: "13px",
                    }}
                    className="d-block mb-3 px-3"
                  >
                    {errors.lName}
                  </span>
                ) : null
              }
            /> */}
          </div>
          <div className="col-md-6 col-sm-12 label_margin_top" style={{ marinBottom: "10px" }}>
            <div className={`input_content`}>
              <div >
                <label>Date of Birth</label>
              </div>
              <input
                type="date"
                name="dateOfBirth"
                onChange={(e) => handleChange("dateOfBirth", e.target.value)}
                value={formData?.dateOfBirth}
              />
              {errors.dateOfBirth ? (
                <span
                  style={{
                    color: "#EE4854",
                    marginTop: "-10px",
                    fontSize: "13px",
                  }}
                  className="d-block mb-3 px-3"
                >
                  {errors.dateOfBirth}
                </span>
              ) : null}
              <img
                src={`${process.env.PUBLIC_URL}assets/images/verified_svg.svg`}
                alt=""
              />
            </div>
            {/* <FormInput
              label="What's your Date of Birth?"
              type="Date"
              name="dateOfBirth"
              onChange={handleChange}
              validationSpan={
                errors.dateOfBirth ? (
                  <span
                    style={{
                      color: "#EE4854",
                      marginTop: "-10px",
                      fontSize: "13px",
                    }}
                    className="d-block mb-3 px-3"
                  >
                    {errors.dateOfBirth}
                  </span>
                ) : null
              }
            /> */}
          </div>

          <div className="col-md-6 col-sm-12 label_margin_top" style={{ marinBottom: "10px" }}>
            <div className={`input_content`}>
              <div >
                <label>Mobile</label>
              </div>
              <input
                type="number"
                name="mobile"
                onChange={(e) => handleChange("mobile", e.target.value)}
                value={formData?.mobile}
              />
              {errors.mobile ? (
                <span
                  style={{
                    color: "#EE4854",
                    marginTop: "-10px",
                    fontSize: "13px",
                  }}
                  className="d-block mb-3 px-3"
                >
                  {errors.mobile}
                </span>
              ) : null}
              <img
                src={`${process.env.PUBLIC_URL}assets/images/verified_svg.svg`}
                alt=""
              />
            </div>
            {/* <FormInput
              label="Mobile"
              type="number"
              name="mobile"
              required
              onChange={handleChange}
              validationSpan={
                errors.mobile ? (
                  <span
                    style={{
                      color: "#EE4854",
                      marginTop: "-10px",
                      fontSize: "13px",
                    }}
                    className="d-block mb-3 px-3"
                  >
                    {errors.mobile}
                  </span>
                ) : null
              }
            /> */}
          </div>

          <div className="col-md-6 col-sm-12 label_margin_top" >
            <div className={`input_content`}>
              <div className="d-flex flex-row input_content_margin_top ">
                <label>Email Id</label>
              </div>
              <input
                className="input_content_margin_top"
                type="email"
                name="email"
                onChange={(e) => handleChange("email", e.target.value)}
                value={formData?.email}
              />
              {errors.email ? (
                <span
                  style={{
                    color: "#EE4854",
                    marginTop: "-10px",
                    fontSize: "13px",
                  }}
                  className="d-block mb-3 px-3"
                >
                  {errors.email}
                </span>
              ) : null}
              <img
                src={`${process.env.PUBLIC_URL}assets/images/verified_svg.svg`}
                alt=""
              />
            </div>
            {/* <FormInput
              label="Email Id"
              type="email"
              name="email"
              required
              onChange={handleChange}
              validationSpan={
                errors.email ? (
                  <span
                    style={{
                      color: "#EE4854",
                      marginTop: "-10px",
                      fontSize: "13px",
                    }}
                    className="d-block mb-3 px-3"
                  >
                    {errors.email}
                  </span>
                ) : null
              }
            /> */}
          </div>

          <div className="col-md-6 col-sm-12 flex-row" >
            <div className="d-flex flex-row">
              <label>Frequency</label>
              <CustomTooltip title="Frequency determines how often you pay your premium - once a year for Annually, twice a year for Half Yearly, every three months for Quarterly, or monthly throughout your policy. Opting for Annually gives you maximum benefit" />
            </div>
            <SelectDropdown
              className="input_content_margin_top_tooltip"
              name="frequency"
              value={selectfrequency}
              defaultValue={{ label: "Annual", value: 1 }}
              options={[
                { label: "Annual", value: "1" },
                { label: "Half Yearly", value: "2" },
                { label: "Quarterly", value: "3" },
                { label: "Monthly", value: "4" },
              ]}
              onChange={
                handleChange
              }
              validationSpan={
                errors.frequency ? (
                  <span
                    style={{
                      color: "#EE4854",
                      marginTop: "-10px",
                      fontSize: "13px",
                    }}
                    className="d-block mb-3 px-3"
                  >
                    {errors.frequency}
                  </span>
                ) : null
              }
            />
            {/* <Tooltip title="Frequency determines how often you pay your premium - once a year for Annually, twice a year for Half Yearly, every three months for Quarterly, or monthly throughout your policy. Opting for Annually gives you maximum benefit">
              <div><AiFillInfoCircle color="red"></AiFillInfoCircle></div>
            </Tooltip> */}
          </div>

          <div className="col-md-6 col-sm-12 " style={{ marinBottom: "10px" }}>
            <div className="d-flex flex-row">
              <label>Income Term</label>
              <CustomTooltip title="Selecting your Income Term determines the duration of policy payouts. Opting for a longer term like 25 or 30 Years means reaping maximum benefits from your policy" />
            </div>
            <SelectDropdown
              name="incomeTerm"
              value={selectincomeTerm}
              options={[
                { label: 10, value: 10 },
                { label: 12, value: 12 },
                { label: 15, value: 15 },
                { label: 25, value: 25 },
                { label: 30, value: 30 },
                { label: 99, value: 99 },
              ]}
              onChange={handleChange}
              validationSpan={
                errors.incomeTerm ? (
                  <span
                    style={{
                      color: "#EE4854",
                      marginTop: "-10px",
                      fontSize: "13px",
                    }}
                    className="d-block mb-3 px-3"
                  >
                    {errors.incomeTerm}
                  </span>
                ) : null
              }
            />
            {/* <Tooltip title="Selecting your Income Term determines the duration of policy payouts. Opting for a longer term like 25 or 30 Years means reaping maximum benefits from your policy">
              <div><AiFillInfoCircle color="red" width="100"></AiFillInfoCircle></div>
            </Tooltip> */}
          </div>

          <div className="col-md-6 col-sm-12 " style={{ marinBottom: "10px" }}>
            <div className="d-flex flex-row">
              <label>Policy Term</label>
              <CustomTooltip title="The Policy Term determines your life coverage period. Opt for a longer term to maximize your policy benefits" />
            </div>
            <SelectDropdown
              name="term"
              value={selectterm}
              options={[
                { label: 5, value: 5 },
                { label: 6, value: 6 },
                { label: 7, value: 7 },
                { label: 8, value: 8 },
                { label: 9, value: 9 },
                { label: 10, value: 10 },
                { label: 11, value: 11 },
                { label: 12, value: 12 },
                { label: 13, value: 13 },
                { label: 14, value: 14 },
                { label: 15, value: 15 },
                { label: 16, value: 16 },
                { label: 17, value: 17 },
                { label: 18, value: 18 },
                { label: 19, value: 19 },
                { label: 20, value: 20 },
              ]}
              onChange={handleChange}
              validationSpan={
                errors.term ? (
                  <span
                    style={{
                      color: "#EE4854",
                      marginTop: "-10px",
                      fontSize: "13px",
                    }}
                    className="d-block mb-3 px-3"
                  >
                    {errors.term}
                  </span>
                ) : null
              }
            />
            {/* <Tooltip title="The Policy Term determines your life coverage period. Opt for a longer term to maximize your policy benefits">
              <div><AiFillInfoCircle color="red"></AiFillInfoCircle></div>
            </Tooltip> */}
          </div>

          <div className="col-md-6 col-sm-12 input_content" >
            <div className="d-flex flex-row label_margin_top primium_label_mt">
              <label>Premium</label>
              <CustomTooltip title="Premium is the cost of insurance coverage, determined by factors like risk level and type of policy. It's the amount paid to the insurer for protection against unforeseen events." />
            </div>
            <input
              className="input_content_margin_top "
              label="Premium"
              type="text"
              name="premium"
              onChange={(e) => handleChange("premium", e.target.value)}
              validationSpan={
                errors.premium ? (
                  <span
                    style={{
                      color: "#EE4854",
                      marginTop: "-10px",
                      fontSize: "13px",
                    }}
                    className="d-block mb-3 px-3"
                  >
                    {errors.premium}
                  </span>
                ) : null
              }
              required
            >
            </input>
            {/* <Tooltip title="Premium is the cost of insurance coverage, determined by factors like risk level and type of policy. It's the amount paid to the insurer for protection against unforeseen events.">
              <div><AiFillInfoCircle color="red"></AiFillInfoCircle></div>
            </Tooltip> */}
          </div>

          <div className="col-md-6 col-sm-12 " style={{ marinBottom: "10px" }}>
            <div className="d-flex flex-row">
              <label>Premium paying term</label>
              <CustomTooltip title="Premium Payment Term sets the period during which the policyholder is obligated to make premium payments to keep their insurance policy active. For better returns select a Premium Payment term of more than 10 Years." />
            </div>
            <SelectDropdown
              name="ppt"
              value={selectppt}
              options={[
                { label: 5, value: 5 },
                { label: 6, value: 6 },
                { label: 7, value: 7 },
                { label: 8, value: 8 },
                { label: 9, value: 9 },
                { label: 10, value: 10 },
                { label: 11, value: 11 },
                { label: 12, value: 12 },
              ]}
              onChange={handleChange}
              validationSpan={
                errors.ppt ? (
                  <span
                    style={{
                      color: "#EE4854",
                      marginTop: "-10px",
                      fontSize: "13px",
                    }}
                    className="d-block mb-3 px-3"
                  >
                    {errors.ppt}
                  </span>
                ) : null
              }
            />
            {/* <Tooltip title="Premium Payment Term sets the period during which the policyholder is obligated to make premium payments to keep their insurance policy active. For better returns select a Premium Payment term of more than 10 Years.">
              <div><AiFillInfoCircle color="red"></AiFillInfoCircle></div>
            </Tooltip> */}
          </div>

          <div className="col-md-6 col-sm-12 " style={{ marinBottom: "10px" }}>
            <div className="d-flex flex-row">
              <label>Payout term</label>
            </div>
            <SelectDropdown
              name="payoutTerm"
              value={selectpayoutTerm}
              options={[
                { label: 15, value: 15 },
                { label: 20, value: 20 },
                { label: 25, value: 25 },
                { label: 30, value: 30 },
              ]}
              onChange={handleChange}
              validationSpan={
                errors.payoutTerm ? (
                  <span
                    style={{
                      color: "#EE4854",
                      marginTop: "-10px",
                      fontSize: "13px",
                    }}
                    className="d-block mb-3 px-3"
                  >
                    {errors.payoutTerm}
                  </span>
                ) : null
              }
            />
          </div>

          <div className="col-md-6 col-sm-12 " style={{ marinBottom: "10px" }}>
            <div className="d-flex flex-row">
              <label>Deferment Period</label>
              <CustomTooltip title='Deferment period is the period between the due date of first GI pay-out and the specific date chosen.' />
            </div>
            <SelectDropdown
              name="defermentPeriod"
              value={selectdefermentPeriod}
              options={[
                { label: 1, value: 1 },
                { label: 2, value: 2 },
              ]}
              onChange={handleChange}
              validationSpan={
                errors.defermentPeriod ? (
                  <span
                    style={{
                      color: "#EE4854",
                      marginTop: "-10px",
                      fontSize: "13px",
                    }}
                    className="d-block mb-3 px-3"
                  >
                    {errors.defermentPeriod}
                  </span>
                ) : null
              }
            />
          </div>


          <div className="col-md-6 col-sm-12 d-flex flex-column" style={{ marinBottom: "10px" }}>
            <div className="d-flex flex-row">
              <label>Payout Frequency</label>
              <CustomTooltip title="Payout Frequency determines benefit payment intervals - annually, semi-annually, quarterly, or monthly. We recommend Annual Payout for most optimum return" />
            </div>
            <SelectDropdown
              name="payoutFrequency"
              value={selectpayoutFrequency}
              options={[
                { label: "Annual", value: "Annual" },
                { label: "Half Yearly", value: "Half Yearly" },
                { label: "Quarterly", value: "Quarterly" },
                { label: "Monthly", value: "Monthly" },
              ]}
              onChange={handleChange}
              validationSpan={
                errors.payoutFrequency ? (
                  <span
                    style={{
                      color: "#EE4854",
                      marginTop: "-10px",
                      fontSize: "13px",
                    }}
                    className="d-block mb-3 px-3"
                  >
                    {errors.payoutFrequency}
                  </span>
                ) : null
              }
            />
          </div>
          {/* <div className="col-md-6 col-sm-12 " style={{ marinBottom: "10px" }}>
            <SelectDropdown
              label="Income Term"
              name="incomeTerm"
              options={[
                { label: 10, value: 10 },
                { label: 12, value: 12 },
                { label: 25, value: 25 },
                { label: 30, value: 30 },
              ]}
              onChange={handleChange}
              validationSpan={
                errors.term ? (
                  <span
                    style={{
                      color: "#EE4854",
                      marginTop: "-10px",
                      fontSize: "13px",
                    }}
                    className="d-block mb-3 px-3"
                  >
                    {errors.term}
                  </span>
                ) : null
              }
            />
          </div> */}
          <div className="col-md-6 col-sm-12 " style={{ marinBottom: "10px" }}>
            <div className="d-flex flex-row">
              <label>Gender</label>
            </div>
            <SelectDropdown
              name="gender"
              options={[
                { label: "Male", value: "Male" },
                { label: "Female", value: "Female" },
                { label: "Others", value: "Others" },
              ]}
              onChange={handleforFnl}
              validationSpan={
                errors.gender ? (
                  <span
                    style={{
                      color: "#EE4854",
                      marginTop: "-10px",
                      fontSize: "13px",
                    }}
                    className="d-block mb-3 px-3"
                  >
                    {errors.gender}
                  </span>
                ) : null
              }
            />
          </div>
          <div className="col-md-6 col-sm-12 ">
            <div className="d-flex flex-row">
              <label>State</label>
            </div>
            <SelectDropdown
              name="state"
              options={[
                { label: "Andaman & Nicobar Islands", value: "AAN" },
                { label: "Andhra Pradesh", value: "ANDH" },
                { label: "Arunachal Pradesh", value: "ARUN" },
                { label: "Assam", value: "ASSM" },
                { label: "Bihar", value: "BIHA" },
                { label: "Chandigarh", value: "CHAN" },
                { label: "Chattisgarh", value: "CHHA" },
                { label: "Dadra & Nagar Haveli", value: "DNH" },
                { label: "Daman & Diu", value: "DAD" },
                { label: "Delhi", value: "DELH" },
                { label: "Goa", value: "GOA" },
                { label: "Gujarat", value: "GUJA" },
                { label: "Haryana", value: "HARY" },
                { label: "Himachal Pradesh", value: "HP" },
                { label: "Jammu & Kashmir", value: "JAK" },
                { label: "Jharkhand", value: "JHAR" },
                { label: "Karnataka", value: "KARN" },
                { label: "Kerala", value: "KERA" },
                { label: "Lakshadweep", value: "LAKS" },
                { label: "Madhya Pradesh", value: "MP" },
                { label: "Maharashtra", value: "MAHA" },
                { label: "Manipur", value: "MANI" },
                { label: "Meghalaya", value: "MEGH" },
                { label: "Mizoram", value: "MIZO" },
                { label: "Nagaland", value: "NAGA" },
                { label: "Odisha", value: "ORIS" },
                { label: "Pondicherry", value: "POND" },
                { label: "Punjab", value: "PUNJ" },
                { label: "Rajasthan", value: "RAJA" },
                { label: "Sikkim", value: "SIKK" },
                { label: "Tamil Nadu", value: "TN" },
                { label: "Telangana", value: "TNG" },
                { label: "Tripura", value: "TRIP" },
                { label: "Uttar Pradesh", value: "UP" },
                { label: "Uttarakhand", value: "UTTD" },
                { label: "West Bengal", value: "WB" }
              ]}
              onChange={handleforFnl}
              validationSpan={
                errors.state ? (
                  <span
                    style={{
                      color: "#EE4854",
                      marginTop: "-10px",
                      fontSize: "13px",
                    }}
                    className="d-block mb-3 px-3"
                  >
                    {errors.state}
                  </span>
                ) : null
              }
            />
          </div>
          <div className="col-md-6 col-sm-12 ">
            <div className="d-flex flex-row">
              <label>City</label>
            </div>
            <SelectDropdown
              name="city"
              options={[
                { label: "Aluva", value: "CTY_ALUV" },
                { label: "Alwar", value: "CTY_ALWA90" },
                { label: "Alwaye", value: "CTY_ALWA5369" },
                { label: "Amalapuram", value: "CTY_AMAL164" },
                { label: "Amalner", value: "CTY_AMAL135" },
                { label: "Amalsad", value: "CTY_AMAL_NB" },
                { label: "Amangala ", value: "CTY_Aman_8376" },
                { label: "Amargarh", value: "CTY_AMAR_NB" },
                { label: "Amarkot", value: "CTY_AMAR_NB1" },
                { label: "Amb", value: "CTY_AMB_NB" },
                { label: "Ambaji", value: "CTY_AMBA" },
                { label: "Ambakapalle ", value: "CTY_Amba_8424" },
                { label: "Ambala", value: "CTY_AMBA91" },
                { label: "Ambala Cantt", value: "CTY_AMBA_NB" },
                { label: "Ambala City", value: "CTY_AMBA_NB1" },
                { label: "Ambalathadi ", value: "CTY_Amba_8728" },
                { label: "Ambarnath", value: "CTY_AMBRTH" },
                { label: "Ambedkar Nagar", value: "CTY_AMBE92" },
                { label: "AmbedkarNagar", value: "CTY_Ambe_8864" },
                { label: "Ambejogai", value: "CTY_AMBE136" },
                { label: "Ambelim", value: "CTY_AMBE_NB" },
                { label: "Ambernath", value: "CTY_AMBE137" },
                { label: "Ambikapur", value: "CTY_AMBI" },
                { label: "Ambur", value: "CTY_AMBU_NB" },
                { label: "Amdada", value: "CTY_AMDA_NB" },
                { label: "Amethi", value: "CTY_AMET_NB" },
                { label: "Amethi", value: "CTY_AMET_5450" },
                { label: "Amnan", value: "CTY_AMNA_NB" },
                { label: "Ampati", value: "CTY_AMPT" },
                { label: "ampati", value: "CTY_Ampa_7966" },
                { label: "Amrapur", value: "CTY_AMRA_NB" },
                { label: "Amravati", value: "CTY_AMRA93" },
                { label: "Amreli", value: "CTY_AMRE94" },
                { label: "Amritsar", value: "CTY_AMRI95" },
                { label: "Amroha", value: "CTY_AMRO" },
                { label: "Amroli", value: "CTY_AMRO_NB" },
                { label: "Amruth Nagar ", value: "CTY_Amru_8348" },
                { label: "Amtala", value: "CTY_AMTA_NB" },
                { label: "Anakapalle", value: "CTY_ANAK" },
                { label: "Anand", value: "CTY_ANAN96" },
                { label: "Anand Nagar", value: "CTY_ANAN_NB" },
                { label: "Anandpur", value: "CTY_ANAN_NB1" },
                { label: "Anandpur Sahib", value: "CTY_ANAN5408" },
                { label: "Anantagiripally ", value: "CTY_Anan_8402" },
                { label: "Anantapur", value: "CTY_ANAN" },
                { label: "Ananthapur", value: "CTY_ANAN11" },
                { label: "Anantharam ", value: "CTY_Anan_8377" },
                { label: "Ananthnag", value: "CTY_ANAN97" },
                { label: "Anantnag", value: "CTY_ANAN5407" },
                { label: "Anasarapur", value: "CTY_ANAS_NB" },
                { label: "Anatharam ", value: "CTY_Anat_8297" },
                { label: "Andal Gram", value: "CTY_ANDA_NB" },
                { label: "Andheri", value: "CTY_ANDRI" },
                { label: "Andul", value: "CTY_ANDU_NB" },
                { label: "Angamaly", value: "CTY_ANGA" },
                { label: "Angul", value: "CTY_ANGU98" },
                { label: "Anjad", value: "CTY_ANJAD" },
                { label: "Anjad", value: "CTY_Anja_7986" },
                { label: "Anjanapally ", value: "CTY_Anja_8349" },
                { label: "Anjar", value: "CTY_ANJA" },
                { label: "Anjuna", value: "CTY_ANJU_NB" },
                { label: "Ankapoor ", value: "CTY_Anka_8312" },
                { label: "Ankleshwar", value: "CTY_ANKL" },
                { label: "Annaram ", value: "CTY_Anna_8378" },
                { label: "Annavaram ", value: "CTY_Anna_8461" },
                { label: "Antarjar", value: "CTY_ANTA_NB" },
                { label: "Antpara", value: "CTY_ANTP_NB" },
                { label: "Anugul", value: "CTY_ANUG_NB" },
                { label: "Anupgarh", value: "CTY_ANUP" },
                { label: "Anuppur", value: "CTY_ANUP99" },
                { label: "Anupshahr", value: "CTY_ANUP_NB" },
                { label: "Aonla", value: "CTY_AONL_NB" },
                { label: "Apparajpalli ", value: "CTY_Appa_8379" },
                { label: "Apra", value: "CTY_APRA_NB" },
                { label: "Ara", value: "CTY_ARA5327" },
                { label: "Arakonam", value: "CTY_ARAK_NB" },
                { label: "Arambagh", value: "CTY_ARAM" },
                { label: "Arampoondi ", value: "CTY_Aram_8732" },
                { label: "Aranthangi", value: "CTY_ARAN5428" },
                { label: "Araria", value: "CTY_ARAR11" },
                { label: "Arasikere", value: "CTY_ARAS_NB" },
                { label: "Arcot", value: "CTY_ARCO" },
                { label: "Argul ", value: "CTY_Argu_8313" },
                { label: "Arikarevula ", value: "CTY_Arik_8488" },
                { label: "Ariyalur", value: "CTY_ARIY12" },
                { label: "Arkkuparamba ", value: "CTY_Arkk_8591" },
                { label: "Armoor", value: "CITY_Armo_8157" },
                { label: "Armoor", value: "CTY_ARMO" },
                { label: "Arni", value: "CTY_ARNI_NB" },
                { label: "Arniwala Sheikh Subha", value: "CTY_ARNI" },
                { label: "Arniwala sheikh subha", value: "CTY_ARNI_NB1" },
                { label: "Arniwala Waziran", value: "CTY_ARNI_NB2" },
                { label: "Arpora", value: "CTY_ARPO_NB" },
                { label: "Arrah", value: "CTY_ARRA_NB" },
                { label: "Arryan Wala ", value: "CTY_Arry_8685" },
                { label: "Arsikere", value: "CTY_ARSI5373" },
                { label: "Aruppukkottai", value: "CTY_ARUP_NB" },
                { label: "Arvi", value: "CTY_ARVI" },
                { label: "Arwal", value: "CTY_ARWA5275" },
                { label: "Asafpur", value: "CTY_ASAF_NB" },
                { label: "Asansol", value: "CTY_ASAN" },
                { label: "Ashok Nagar", value: "CTY_ASHO13" },
                { label: "Ashok Nagar", value: "CTY_ASHO5253" },
                { label: "Ashoknagar", value: "CTY_ASHO" },
                { label: "Ashta", value: "CTY_ASHT" },
                { label: "Aska", value: "CTY_ASKA" },
                { label: "Asola", value: "CTY_ASLA" },
                { label: "Assandh", value: "CTY_ASSA_NB" },
                { label: "Assonora", value: "CTY_ASSO_NB" },
                { label: "Asta", value: "CTY_ASTA_NB" },
                { label: "Ateli", value: "CTY_ATEL_NB" },
                { label: "Athani", value: "CTY_ATHA57" },
                { label: "Athani", value: "CTY_ATHA46" },
                { label: "Athgarh", value: "CTY_ATHG_NB" },
                { label: "Athni", value: "CTY_ATHN_NB" },
                { label: "Atmakur", value: "CTY_ATMA_NB" },
                { label: "Attapur", value: "CTY_ATPR" },
                { label: "Attari", value: "CTY_ATTA_NB" },
                { label: "Attungal", value: "CTY_ATTU" },
                { label: "Attur", value: "CTY_ATTU5349" },
                { label: "Atveli ", value: "CTY_Atve_8403" },
                { label: "Aujla Jogi ", value: "CTY_Aujl_8714" },
                { label: "Aundh", value: "CTY_AUNDH" },
                { label: "Aur", value: "CTY_AUR_NB" },
                { label: "Auraiya", value: "CTY_AURA14" },
                { label: "Aurangabad", value: "CTY_AURA12" },
                { label: "Aurangabad", value: "CTY_AURA_NB1" },
                { label: "Aurangabad(Bh)", value: "CTY_AURA15" },
                { label: "Avadi", value: "CTY_AVAD" },
                { label: "Avanashi", value: "CTY_AVAN_NB" },
                { label: "Avathi", value: "CTY_Avat_8572" },
                { label: "Awantipora", value: "CTY_AWAN_NB" },
                { label: "Ayyappanthangal", value: "CTY_AYYA_NB" },
                { label: "Azad Nagar", value: "CTY_ADN" },
                { label: "Azamgarh", value: "CTY_AZAM16" },
                { label: "Azhiyannur", value: "CTY_AZHI_NB" },
                { label: "B.S.Puram ", value: "CTY_BSPu_8500" },
                { label: "Baba Bakala", value: "CTY_BABA_NB" },
                { label: "Baba Saheb Peta ", value: "CTY_Baba_8350" },
                { label: "Babar Pur", value: "CTY_BBPUR" },
                { label: "Babhanan", value: "CTY_BABH_NB" },
                { label: "Babina", value: "CTY_BABI_NB" },
                { label: "Babra", value: "CTY_BABR_NB" },
                { label: "Babuganj", value: "CTY_BABU_NB" },
                { label: "Babura ", value: "CTY_Babu_8762" },
                { label: "Bachhrawan", value: "CTY_BACH_NB" },
                { label: "Badakera", value: "CTY_BADA_NB" },
                { label: "Badal", value: "CTY_BADA_NB1" },
                { label: "Badali Ala Singh", value: "CTY_BADA_NB2" },
                { label: "Badauli", value: "CTY_BADA_NB3" },
                { label: "Badaun", value: "CTY_BDU" },
                { label: "Baddi", value: "CTY_BADD" },
                { label: "Badgam", value: "CTY_BADG_NB" },
                { label: "Badhaiu", value: "CTY_BDI" },
                { label: "Badhni Kalan", value: "CTY_BADH" },
                { label: "Badhoi", value: "CTY_BDH" },
                { label: "Badlapur", value: "CTY_BADL_NB" },
                { label: "Badnagar", value: "CTY_BADN_NB" },
                { label: "Badnawar", value: "CTY_BADN_NB1" },
                { label: "Badol ", value: "CTY_Bado_8551" },
                { label: "Badud", value: "CTY_BADU_NB" },
                { label: "Badvel", value: "CTY_BADV_NB" },
                { label: "Bagaha-i", value: "CTY_BGH" },
                { label: "Bagalkot", value: "CTY_BAGA17" },
                { label: "Bagalkot UHS Campus ", value: "CTY_Baga_8578" },
                { label: "Bagalkote", value: "CTY_BAGA_NB" },
                { label: "Bagasara", value: "CTY_BGS" },
                { label: "Bagdehi", value: "CTY_BAGD_NB" },
                { label: "Bageshwar", value: "CTY_BAGE18" },
                { label: "Bagha", value: "CTY_BAGH_NB" },
                { label: "Bagha Purana", value: "CTY_BAGH161" },
                { label: "Baghpat", value: "CTY_BAGH101" },
                { label: "Bagnan", value: "CTY_BAGN_NB" },
                { label: "Bagpat", value: "CTY_BAGP19" },
                { label: "Bagpur", value: "CTY_BAGP_NB" },
                { label: "Bagrian ", value: "CTY_Bagr_8670" },
                { label: "Bagru", value: "CTY_BAGR" },
                { label: "Bahadurgarh", value: "CTY_BAHA13" },
                { label: "Bahadurpur", value: "CTY_BAHA_NB" },
                { label: "Baheri", value: "CTY_BAHE_NB" },
                { label: "Bahraich", value: "CTY_BAHR20" },
                { label: "Bahrain", value: "CTY_BAHRAIN" },
                { label: "Bahu Jamalpur", value: "CTY_BAHU_NB" },
                { label: "Baidpura", value: "CTY_BAID_NB" },
                { label: "Baijnath", value: "CTY_BAIJ_NB" },
                { label: "Baikunthpur", value: "CTY_BAIK_NB" },
                { label: "Baina", value: "CTY_BAIN5413" },
                { label: "Bairagarh", value: "CTY_BAIR" },
                { label: "Bajahi ", value: "CTY_Baja_8745" },
                { label: "Bakarpur", value: "CTY_BAKA_NB" },
                { label: "Bakhtiarpur", value: "CTY_BAKH_NB" },
                { label: "Baksa", value: "CTY_BAKS_5446" },
                { label: "Balachaur", value: "CTY_BALA162" },
                { label: "Balaghat", value: "CTY_BALA21" },
                { label: "Balagunda (Belagula)", value: "CTY_BLGND" },
                { label: "Balana", value: "CTY_BALA_NB" },
                { label: "Balangir", value: "CTY_BALA22" },
                { label: "Balasinor", value: "CTY_BALA23" },
                { label: "Balasore", value: "CTY_BALA151" },
                { label: "Baler Khanpur ", value: "CTY_Bale_8715" },
                { label: "Baleswar", value: "CTY_BALE20" },
                { label: "Balibat", value: "CTY_BLB" },
                { label: "Balighattam ", value: "CTY_Bali_8462" },
                { label: "Balla", value: "CTY_BALL_NB" },
                { label: "Ballabhgarh", value: "CTY_BALL_NB1" },
                { label: "Ballard Estate", value: "CTY_BLDEST" },
                { label: "Ballarpur", value: "CTY_BALL" },
                { label: "Ballary", value: "CTY_BALL_NB2" },
                { label: "Ballia", value: "CTY_BALL188" },
                { label: "Ballia", value: "CTY_BALL21" },
                { label: "Balmatta", value: "CTY_BALM" },
                { label: "Balod", value: "CTY_BALO_NB" },
                { label: "Baloda", value: "CTY_BALO_NB1" },
                { label: "Baloda Bazar", value: "CTY_BALO14" },
                { label: "Balotra", value: "CTY_BALO79" },
                { label: "Balrampur", value: "CTY_BALR5259" },
                { label: "Balrampur", value: "CTY_BALR22" },
                { label: "Balugaon", value: "CTY_BALU_NB" },
                { label: "Balurghat", value: "CTY_BALU_NB1" },
                { label: "Bamaila", value: "CTY_BAMA_NB" },
                { label: "Bamosana", value: "CTY_BAMO_NB" },
                { label: "Bamrauli Katara", value: "CTY_BAMR_NB" },
                { label: "Bamunara", value: "CTY_BAMU_NB" },
                { label: "Banaganapalle", value: "CTY_BANA_NB" },
                { label: "Banapura", value: "CTY_BANA5336" },
                { label: "Banas Kantha", value: "CTY_BANA_NB1" },
                { label: "Banasinga", value: "CTY_BANA_NB2" },
                { label: "Banaskantha", value: "CTY_BANA23" },
                { label: "Banda", value: "CTY_BAND24" },
                { label: "Bandel", value: "CTY_BND" },
                { label: "Bandikui", value: "CTY_BAND_NB" },
                { label: "Bandipora", value: "CTY_BAND_5440" },
                { label: "Bandipore", value: "CTY_BAND_NB1" },
                { label: "Bandipur", value: "CTY_BAND5246" },
                { label: "Bandra", value: "CTY_BANDRA" },
                { label: "Banga", value: "CTY_BANG" },
                { label: "Bangalore", value: "CTY_BANG25" },
                { label: "Bangalore Rural", value: "CTY_BANG26" },
                { label: "Bangarapet", value: "CTY_BANG_NB" },
                { label: "Bangarigadda ", value: "CTY_Bang_8351" },
                { label: "Bangarmau", value: "CTY_BANG_NB1" },
                { label: "Bango Dam ", value: "CTY_Bang_8526" },
                { label: "Banka", value: "CTY_BANK27" },
                { label: "Bankati ", value: "CTY_Bank_8746" },
                { label: "Bankner", value: "CTY_BAKNR" },
                { label: "Bankura", value: "CTY_BANK28" },
                { label: "Bannur", value: "CTY_BANN_NB" },
                { label: "Bans Kheri", value: "CTY_BANS" },
                { label: "Bans kheri", value: "CTY_BANS_NB" },
                { label: "Bansi", value: "CTY_BANS_NB1" },
                { label: "Bansur Alwar", value: "CTY_BANS1" },
                { label: "Bansur-Alwar", value: "CTY_BANS_NB2" },
                { label: "Banswada", value: "CITY_Bans_8228" },
                { label: "Banswada", value: "CTY_BANS_NB3" },
                { label: "Banswara", value: "CTY_BANS29" },
                { label: "Bantval", value: "CTY_BANT_NB" },
                { label: "Bantwal", value: "CTY_BANT" },
                { label: "Banur", value: "CTY_BANU" },
                { label: "Bapatla", value: "CTY_BAPA" },
                { label: "Bapurasapuram", value: "CTY_BAPU_NB" },
                { label: "Barabanki", value: "CTY_BARA30" },
                { label: "Barachirka ", value: "CTY_Bara_8560" },
                { label: "Barakar", value: "CTY_BARA5316" },
                { label: "Barama", value: "CTY_BARA_NB" },
                { label: "Baramati", value: "CTY_BARA138" },
                { label: "Baramulla", value: "CTY_BARA31" },
                { label: "Baran", value: "CTY_BARA32" },
                { label: "Barana", value: "CTY_BARA_NB1" },
                { label: "Barara", value: "CTY_BARA_NB2" },
                { label: "Barasat", value: "CTY_BARA113" },
                { label: "Barauli", value: "CTY_BARA_NB3" },
                { label: "Baraut", value: "CTY_BARA_NB4" },
                { label: "Barbil", value: "CTY_BARB" },
                { label: "Bardez", value: "CTY_BARD19" },
                { label: "Bardhaman", value: "CTY_BARD33" },
                { label: "Bardoli", value: "CTY_BARD23" },
                { label: "Bareilly", value: "CTY_BARE34" },
                { label: "Bareja", value: "CTY_BRJA" },
                { label: "Bareli", value: "CTY_Bare_8790" },
                { label: "Barethi", value: "CTY_BARE_NB" },
                { label: "Barewal Awanna", value: "CTY_BARE_NB1" },
                { label: "Barfung", value: "CTY_BARF_NB" },
                { label: "Bargarh", value: "CTY_BARG35" },
                { label: "Bargari", value: "CTY_BARG_NB" },
                { label: "Barh", value: "CTY_BARH_NB" },
                { label: "Barhaj", value: "CTY_BARH_NB1" },
                { label: "Barhalganj", value: "CTY_BARH_NB2" },
                { label: "Barheri Badi", value: "CTY_BARH_NB3" },
                { label: "Barhi", value: "CTY_BARH_NB4" },
                { label: "Bari Brahmana", value: "CTY_BARI_NB" },
                { label: "Barian Kalan", value: "CTY_BARI_NB1" },
                { label: "Barik", value: "CTY_BRK" },
                { label: "Baripada", value: "CTY_BARI" },
                { label: "Bariwala", value: "CTY_BARI_NB2" },
                { label: "Barkatabad", value: "CTY_BARK_NB" },
                { label: "Barmer", value: "CTY_BARM36" },
                { label: "Barnala", value: "CTY_BARN" },
                { label: "Baroda", value: "CTY_BARO5339" },
                { label: "Barpeta", value: "CTY_BARP37" },
                { label: "Barrackpore", value: "CTY_BARR" },
                { label: "Barsar", value: "CTY_Bars_8849" },
                { label: "Barshi", value: "CTY_BARS" },
                { label: "Barwaha", value: "CTY_BARW5393" },
                { label: "Barwala", value: "CTY_BARW_NB" },
                { label: "Barwala", value: "CTY_BARW" },
                { label: "Barwani", value: "CTY_BARW38" },
                { label: "Basantpura ", value: "CTY_Basa_8657" },
                { label: "Basar", value: "CTY_BASA" },
                { label: "Basavakalyan", value: "CTY_BASA_NB" },
                { label: "BASIRHAT", value: "CTY_BSRT" },
                { label: "Baskhari", value: "CTY_BASK_NB" },
                { label: "Baskushla", value: "CTY_BASK_NB1" },
                { label: "Basna", value: "CTY_BASN_NB" },
                { label: "Basni Silawantan", value: "CTY_BASN_NB1" },
                { label: "Basoda", value: "CTY_BASO_NB" },
                { label: "Bassi Pathana", value: "CTY_Bass_8283" },
                { label: "Bassi Pathtana", value: "CTY_BPT" },
                { label: "Bastar", value: "CTY_BAST39" },
                { label: "Basti", value: "CTY_BAST40" },
                { label: "Baswapur ", value: "CTY_Basw_8298" },
                { label: "Batala", value: "CTY_BATA" },
                { label: "Bathinda", value: "CTY_BATH41" },
                { label: "Batlagundu", value: "CTY_BATL_NB" },
                { label: "Baudhgarh", value: "CTY_BAUD_NB" },
                { label: "Bavla", value: "CTY_BAVL" },
                { label: "Bawal", value: "CTY_BAWA_NB" },
                { label: "Bawana", value: "CTY_BWNA" },
                { label: "Bawani Khera", value: "CTY_BAWA_NB1" },
                { label: "Bawne Pangri ", value: "CTY_Bawn_8598" },
                { label: "Bazpur", value: "CTY_BAZP_NB" },
                { label: "Beas", value: "CTY_BEAS_NB" },
                { label: "Beawar", value: "CTY_BEAW" },
                { label: "Bebad Ohol", value: "CTY_BEBA_NB" },
                { label: "Becharaji", value: "CTY_BECH_NB" },
                { label: "Bedi Apmc", value: "CTY_BEDI" },
                { label: "BEDI-APMC", value: "CTY_BEDI_NB" },
                { label: "Beed", value: "CTY_BEED42" },
                { label: "Begowal", value: "CTY_BEGO" },
                { label: "Begum Pur", value: "CTY_BEGPUR" },
                { label: "Begumabad Budhana", value: "CTY_BGMBD_BDHNA" },
                { label: "Begun", value: "CTY_BEGU_NB" },
                { label: "Begusarai", value: "CTY_BEGU43" },
                { label: "Behat", value: "CTY_BEHA_NB" },
                { label: "Behraich", value: "CTY_BEHR5419" },
                { label: "Behram", value: "CTY_BEHR_NB" },
                { label: "Behrampur", value: "CTY_BEHR152" },
                { label: "Behrampur", value: "CTY_BEHR_NB1" },
                { label: "Behror", value: "CTY_BEHR81" },
                { label: "Behta Hajipur", value: "CTY_BHTHJPR" },
                { label: "Beldih ", value: "CTY_Beld_8561" },
                { label: "Belgaum", value: "CTY_BELG44" },
                { label: "Bellampalle", value: "CITY_Bell_8229" },
                { label: "Bellampalle", value: "CTY_BELL_NB" },
                { label: "Bellary", value: "CTY_BELL45" },
                { label: "Belthangady", value: "CTY_BELT" },
                { label: "Belthara Road", value: "CTY_BELT_NB" },
                { label: "Beltola", value: "CTY_BELT_NB1" },
                { label: "Bembal ", value: "CTY_Bemb_8641" },
                { label: "Bemetara", value: "CTY_BEME_NB" },
                { label: "Ben", value: "CTY_BEN_NB" },
                { label: "Benapur", value: "CTY_BENA_NB" },
                { label: "Benaulim", value: "CTY_BENA_NB1" },
                { label: "Bengaluru", value: "CTY_BENG_NB" },
                { label: "Bennabhupalapatnam ", value: "CTY_Benn_8463" },
                { label: "Berhampore", value: "CTY_BERH" },
                { label: "BESA Road ", value: "CTY_BESA_8654" },
                { label: "Betasing", value: "CTY_Beta_7971" },
                { label: "Betasing", value: "CTY_BTSNG" },
                { label: "Betiah", value: "CTY_BETI_NB" },
                { label: "Bettiah", value: "CTY_BETT5287" },
                { label: "Betul", value: "CTY_BETU46" },
                { label: "Bhachau", value: "CTY_BHAC_NB" },
                { label: "Bhadaur", value: "CTY_BHAD_NB" },
                { label: "Bhaderwah", value: "CTY_BHAD_NB1" },
                { label: "Bhadkodra", value: "CTY_BHAD_NB2" },
                { label: "Bhadohi", value: "CTY_BHAD103" },
                { label: "Bhadra", value: "CTY_BHA" },
                { label: "Bhadrachalam", value: "CITY_Bhad_8165" },
                { label: "Bhadrachalam", value: "CTY_BHAD165" },
                { label: "Bhadrak", value: "CTY_BHAD47" },
                { label: "Bhadravati", value: "CTY_BHAD48" },
                { label: "Bhadrawati", value: "CTY_BHAD_NB3" },
                { label: "Bhadson", value: "CTY_BHAD_NB4" },
                { label: "Bhagalpur", value: "CTY_BHAG48" },
                { label: "Bhagan", value: "CTY_BHAG_NB" },
                { label: "Bhago Majra", value: "CTY_BHAG_NB1" },
                { label: "Bhagoke", value: "CTY_BHAG_NB2" },
                { label: "Bhagrana", value: "CTY_BHAG_NB3" },
                { label: "Bhagta", value: "CTY_BHAG_NB4" },
                { label: "Bhagthala Kalan ", value: "CTY_Bhag_8686" },
                { label: "Bhainsa", value: "CITY_Bhai_8179" },
                { label: "Bhainsa", value: "CTY_BHAI5432" },
                { label: "Bhainsori ", value: "CTY_Bhai_8763" },
                { label: "Bhakarwadi ", value: "CTY_Bhak_8599" },
                { label: "Bhalgamda", value: "CTY_BHGMDA" },
                { label: "Bhalgamda", value: "CTY_Bhal_7965" },
                { label: "Bhaliabholkateni", value: "CTY_BHAL_NB" },
                { label: "Bhalki", value: "CTY_BHAL_NB1" },
                { label: "Bhalona ", value: "CTY_Bhal_8552" },
                { label: "Bhalswa Jahangir Pur", value: "CTY_BLW_JHGR_PUR" },
                { label: "Bhamarsi", value: "CTY_BHAM_NB" },
                { label: "Bhamboli", value: "CTY_BHAM_NB1" },
                { label: "Bhandara", value: "CTY_BHAN49" },
                { label: "Bhandup", value: "CTY_BHNDUP" },
                { label: "Bhangala", value: "CTY_BHAN_NB" },
                { label: "Bhanjanagar", value: "CTY_BHAN_NB1" },
                { label: "Bhanpuri", value: "CTY_BHAN_NB2" },
                { label: "Bhanvad", value: "CTY_BHAN_5448" },
                { label: "Bhanvad", value: "CTY_BHAN_NB3" },
                { label: "Bhapal ", value: "CTY_Bhap_8658" },
                { label: "Bharatpur", value: "CTY_BHAR50" },
                { label: "Bharmour", value: "CTY_BHAR_NB" },
                { label: "Bharni", value: "CTY_BHAR_NB1" },
                { label: "Bharthana", value: "CTY_BHAR_NB2" },
                { label: "Bharuch", value: "CTY_BHAR51" },
                { label: "Bhatapara", value: "CTY_BHAT15" },
                { label: "Bhatepuri ", value: "CTY_Bhat_8600" },
                { label: "Bhati", value: "CTY_BHTI" },
                { label: "Bhatinda", value: "CTY_BHAT163" },
                { label: "Bhatkal", value: "CTY_BHAT48" },
                { label: "Bhatpara", value: "CTY_BHAT114" },
                { label: "Bhatramarenahalli", value: "CTY_BHAT_NB" },
                { label: "Bhattian", value: "CTY_BHAT_NB1" },
                { label: "Bhattu Kalan", value: "CTY_BHAT_NB2" },
                { label: "Bhavanagar", value: "CTY_BHAV5399" },
                { label: "Bhavanipet ", value: "CTY_Bhav_8314" },
                { label: "Bhavnagar", value: "CTY_BHAV52" },
                { label: "Bhawai ", value: "CTY_Bhaw_8553" },
                { label: "Bhawanigarh", value: "CTY_BHAW" },
                { label: "Bhawanipatna", value: "CTY_BHAW5400" },
                { label: "Bhawanipur ", value: "CTY_Bhaw_8716" },
                { label: "Bhayander", value: "CTY_BHAY" },
                { label: "Bhedwal ", value: "CTY_Bhed_8659" },
                { label: "Bhejgaon ", value: "CTY_Bhej_8642" },
                { label: "Bheriwada ", value: "CTY_Bher_8380" },
                { label: "Bhikhi", value: "CTY_BHIK_NB" },
                { label: "Bhikhiwind", value: "CTY_BHIK" },
                { label: "Bhilai", value: "CTY_BHIL" },
                { label: "Bhilai Charoda", value: "CTY_BHIL_NB" },
                { label: "Bhiloda", value: "CTY_BLD" },
                { label: "Bhilwara", value: "CTY_BHIL53" },
                { label: "Bhimavaram", value: "CTY_BHIM" },
                { label: "Bhind", value: "CTY_BHIN54" },
                { label: "Bhinga", value: "CTY_BHIN_NB" },
                { label: "Bhinmal", value: "CTY_BHIN_NB1" },
                { label: "Bhiwadi", value: "CTY_BHIW" },
                { label: "Bhiwandi", value: "CTY_BWD" },
                { label: "Bhiwani", value: "CTY_BHIW55" },
                { label: "Bhodipura", value: "CTY_BHOD_NB" },
                { label: "Bhoga Samudram", value: "CTY_BHOG_NB" },
                { label: "Bhogpur", value: "CTY_BHOG" },
                { label: "Bhojpur", value: "CTY_BHOJ56" },
                { label: "Bhokhra", value: "CTY_BHOK_NB" },
                { label: "Bholath", value: "CTY_BHOL" },
                { label: "Bhongir", value: "CITY_Bhon_8155" },
                { label: "Bhongir", value: "CTY_BHON" },
                { label: "Bhongir Gunj ", value: "CTY_Bhon_8299" },
                { label: "Bhonrka ", value: "CTY_Bhon_8764" },
                { label: "Bhoora ", value: "CTY_Bhoo_8747" },
                { label: "Bhopal", value: "CTY_BHOP57" },
                { label: "Bhopani", value: "CTY_BHOP_NB" },
                { label: "Bhoring", value: "CTY_BHOR_NB" },
                { label: "Bhosari", value: "CTY_BHS" },
                { label: "Bhowanipore", value: "CTY_BWP" },
                { label: "Bhubaneshwar", value: "CTY_BHUB" },
                { label: "Bhubaneswar", value: "CTY_BHUBA" },
                { label: "Bhucho Mandi", value: "CTY_BHUC_NB" },
                { label: "Bhuj", value: "CTY_BHUJ" },
                { label: "Bhujodi", value: "CTY_BHUJ_NB" },
                { label: "Bhulath", value: "CTY_BHUL_NB" },
                { label: "Bhundri", value: "CTY_BHUN_NB" },
                { label: "Bhuntar", value: "CTY_BHUN_NB1" },
                { label: "BHUSAWAL", value: "CTY_BHUS_NB" },
                { label: "Bhusawal", value: "CTY_BHUS" },
                { label: "Biaora", value: "CTY_BIAO" },
                { label: "Bicholim", value: "CTY_BICH" },
                { label: "Bid", value: "CTY_BID_NB" },
                { label: "Bidadi", value: "CTY_BIDA" },
                { label: "Bidalur ", value: "CTY_Bida_8573" },
                { label: "Bidar", value: "CTY_BIDA5352" },
                { label: "Bidar", value: "CTY_BIDA58" },
                { label: "Bidhan Nagar", value: "CTY_BIDH_NB" },
                { label: "Bidhuna", value: "CTY_BIDH_NB1" },
                { label: "Bihar", value: "CTY_BIHA5353" },
                { label: "Biharsharif", value: "CTY_BHSR" },
                { label: "Bihat Biram", value: "CTY_BIHA_NB" },
                { label: "Bija", value: "CTY_BIJA_NB" },
                { label: "Bijainagar", value: "CTY_BIJA" },
                { label: "Bijapur", value: "CTY_BIJA59" },
                { label: "Bijapur", value: "CTY_BIJA182" },
                { label: "Bijapur(Cgh)", value: "CTY_BIJA5245" },
                { label: "Bijapur(Kar)", value: "CTY_BIJA5252" },
                { label: "Bijbehara", value: "CTY_BIJB_NB" },
                { label: "Bijjawara", value: "CTY_BIJJ_NB" },
                { label: "Bijjawara ", value: "CTY_Bijj_8574" },
                { label: "Bijnor", value: "CTY_BIJN60" },
                { label: "Bijoynagar", value: "CTY_BIJO_NB" },
                { label: "Bikaner", value: "CTY_BIKA61" },
                { label: "Bikramganj", value: "CTY_BIKR_NB" },
                { label: "Bilara", value: "CTY_BILA5415" },
                { label: "Bilari", value: "CTY_BILA_NB" },
                { label: "Bilaspur", value: "CTY_BILA_NB1" },
                { label: "Bilaspur", value: "CTY_BILA5374" },
                { label: "Bilaspur", value: "CTY_BILA_NB2" },
                { label: "Bilaspur", value: "CTY_BILA62" },
                { label: "Bilaspur (Hp)", value: "CTY_BILA63" },
                { label: "Bilaspur(Cgh)", value: "CTY_BILA5281" },
                { label: "Bilga", value: "CTY_BILG_NB" },
                { label: "Bilgi", value: "CTY_BILG_NB1" },
                { label: "Bilha", value: "CTY_BILH_NB" },
                { label: "Bilimora", value: "CTY_BILI" },
                { label: "Billawar", value: "CTY_BWR" },
                { label: "Billimora", value: "CTY_BILL_NB" },
                { label: "Bina", value: "CTY_BINA" },
                { label: "Bindki", value: "CTY_BIND_5435" },
                { label: "Bindki", value: "CTY_BIND_NB" },
                { label: "Binola", value: "CTY_BINO_NB" },
                { label: "Binovanagar", value: "CTY_BINO_5436" },
                { label: "Biramitrapur", value: "CTY_BIRA_NB" },
                { label: "Birbhum", value: "CTY_BIRB64" },
                { label: "Birkona", value: "CTY_BIRK_NB" },
                { label: "Birmitrapur", value: "CTY_BIRM5366" },
                { label: "Bisalpur", value: "CTY_BISA_NB" },
                { label: "Bishalgarh", value: "CTY_BSG" },
                { label: "Bishna", value: "CTY_BISH_NB" },
                { label: "Bishnupur", value: "CTY_BSPR" },
                { label: "Bishnupur", value: "CTY_BISH5264" },
                { label: "Bishnupur", value: "CTY_BISH65" },
                { label: "BISHNUPUR", value: "CTY_BSNP" },
                { label: "Bisokhar", value: "CTY_BSKHR" },
                { label: "Bistauli Khurd", value: "CTY_BIST_NB" },
                { label: "Biswanath Chairali", value: "CTY_Bisw_8845" },
                { label: "Biswanath Chariali", value: "CTY_BISW_NB" },
                { label: "Bobbili", value: "CTY_BOB" },
                { label: "Bodakdev", value: "CTY_BODA_NB" },
                { label: "Bodeli", value: "CTY_BODE" },
                { label: "Bodhan", value: "CITY_Bodh_8232" },
                { label: "Bodhan", value: "CTY_BODH_NB" },
                { label: "Bodhgaya", value: "CTY_BODH_NB1" },
                { label: "Bodhi Bigha ", value: "CTY_Bodh_8521" },
                { label: "Bodinayakkannur", value: "CTY_BODI_NB" },
                { label: "Boha", value: "CTY_BOHA_NB" },
                { label: "Boisar", value: "CTY_BOIS" },
                { label: "Boisar II Chitralaya", value: "CTY_BOISAR" },
                { label: "Boisar II, Chitralaya", value: "CTY_BOIS_NB" },
                { label: "Bokakhat", value: "CTY_Boka_7973" },
                { label: "Bokakhat", value: "CTY_BOKKHT" },
                { label: "Bokaro", value: "CTY_BOKA66" },
                { label: "Bokkudupalle ", value: "CTY_Bokk_8425" },
                { label: "Bol", value: "CTY_BOL_NB" },
                { label: "Bolagarh", value: "CTY_BOLA_NB" },
                { label: "Bolangir", value: "CTY_BOLA5416" },
                { label: "Bolpur", value: "CTY_BOLP" },
                { label: "Bomdila", value: "CTY_BOMD" },
                { label: "Bommakal", value: "CITY_Bomm_8233" },
                { label: "Bommakal", value: "CTY_BOMM_NB" },
                { label: "Bongaigaon", value: "CTY_BONG67" },
                { label: "Bopal", value: "CTY_BOPA_NB" },
                { label: "Boram ", value: "CTY_Bora_8562" },
                { label: "Borbata ", value: "CTY_Borb_8748" },
                { label: "Borbhatha Bet", value: "CTY_BORB_NB" },
                { label: "Borgaon ", value: "CTY_Borg_8601" },
                { label: "Borivali (W)", value: "CTY_BORV" },
                { label: "Boronilpur", value: "CTY_BORO_NB" },
                { label: "Borsad", value: "CTY_BORS_NB" },
                { label: "Botad", value: "CTY_BOTA" },
                { label: "Boudh", value: "CTY_BOUD68" },
                { label: "Boytawala", value: "CTY_BOYT_NB" },
                { label: "Brahmanapalle ", value: "CTY_Brah_8426" },
                { label: "Brahmanpalli ", value: "CTY_Brah_8315" },
                { label: "Brahmapore", value: "CTY_BRAH_NB" },
                { label: "Brahmapur", value: "CTY_BRAH_NB1" },
                { label: "Brajraj nagar", value: "CTY_BRJN" },
                { label: "Bramhapuri", value: "CTY_BRAM_NB" },
                { label: "Budaun", value: "CTY_BUDA69" },
                { label: "Buddugonda ", value: "CTY_Budd_8381" },
                { label: "Budgam", value: "CTY_BUDG70" },
                { label: "Budge Budge", value: "CTY_BUDG_NB" },
                { label: "Budh Singh Wala ", value: "CTY_Budh_8687" },
                { label: "Budhalda", value: "CTY_BUDH_NB" },
                { label: "Budhana", value: "CTY_BUDH_NB1" },
                { label: "Budhera", value: "CTY_BUDH_NB2" },
                { label: "Budhlada", value: "CTY_BUDH" },
                { label: "Budhwaria", value: "CTY_BDW" },
                { label: "Bulandshahar", value: "CTY_BULA5301" },
                { label: "Bulandshahr", value: "CTY_BULA71" },
                { label: "Bulandshare", value: "CTY_BULA5283" },
                { label: "Buldana", value: "CTY_BULD" },
                { label: "Buldhana", value: "CTY_BULD72" },
                { label: "Bulhowal", value: "CTY_BULH_NB" },
                { label: "Bundi", value: "CTY_BUND73" },
                { label: "Burari", value: "CTY_BRRI" },
                { label: "Burdwan", value: "CTY_BURD" },
                { label: "Burhanpur", value: "CTY_BURH74" },
                { label: "Burhar", value: "CTY_BURH" },
                { label: "Burla", value: "CTY_BURL_NB" },
                { label: "Burnihat", value: "CTY_BURN_NB" },
                { label: "Burnpur", value: "CTY_BURN5396" },
                { label: "Butala", value: "CTY_BUTA_NB" },
                { label: "Butchampeta ", value: "CTY_Butc_8464" },
                { label: "Butibori", value: "CTY_BUTI_NB" },
                { label: "Buxar", value: "CTY_BUXA75" },
                { label: "Byrnihat", value: "CTY_BYRN_5444" },
                { label: "Byrnihat", value: "CTY_BYRN_5445" },
                { label: "Cachar", value: "CTY_CACH76" },
                { label: "Calangute", value: "CTY_CALA_NB" },
                { label: "Calicut", value: "CTY_CALI" },
                { label: "Canacona", value: "CTY_CANA_NB" },
                { label: "Candolim", value: "CTY_Cand_7981" },
                { label: "Candolim", value: "CTY_CNDLM" },
                { label: "Cannanore", value: "CTY_CANN" },
                { label: "Cansaulim", value: "CTY_CANS_NB" },
                { label: "Carambolim", value: "CTY_CARA_NB" },
                { label: "Carterpuri", value: "CTY_CTRPUR" },
                { label: "Cavelosim", value: "CTY_CAVE_NB" },
                { label: "CBD Belapur", value: "CTY_CBDLPR" },
                { label: "Central Delhi", value: "CTY_CENT77" },
                { label: "Central University of Karnataka,Kad", value: "CTY_Cent_8582" },
                { label: "Chabal Kalan", value: "CTY_CHAB_NB" },
                { label: "Chabewal", value: "CTY_CHAB_NB1" },
                { label: "Chadura", value: "CTY_CHAD_NB" },
                { label: "Chaibasa", value: "CTY_CHAI_NB" },
                { label: "Chak Kalan", value: "CTY_CHAK_NB" },
                { label: "Chak Pakhi", value: "CTY_CHAK_NB1" },
                { label: "CHAKAN", value: "CTY_CHAKN" },
                { label: "Chakarbhatha", value: "CTY_CHAK_NB2" },
                { label: "Chakdana", value: "CTY_CHAK_NB3" },
                { label: "Chakradharpur", value: "CTY_CHAK_NB4" },
                { label: "Chaksu", value: "CTY_CHAK" },
                { label: "Chalakudy", value: "CTY_CHAL58" },
                { label: "Chalheri ", value: "CTY_Chal_8660" },
                { label: "Chalikkavattom", value: "CTY_CHAL59" },
                { label: "Chalisgaon", value: "CTY_CHAL139" },
                { label: "Challakere", value: "CTY_CHAL49" },
                { label: "Challavarigudem ", value: "CTY_Chal_8444" },
                { label: "Chamarajanagar", value: "CTY_CHAM_NB" },
                { label: "Chamaru ", value: "CTY_Cham_8661" },
                { label: "Chamba", value: "CTY_CHAM78" },
                { label: "Chamkaur Sahib", value: "CTY_CHAM_NB1" },
                { label: "Chamoli", value: "CTY_CHAM11" },
                { label: "Champa", value: "CTY_CHAM" },
                { label: "Champadanga", value: "CTY_CHAM5314" },
                { label: "Champawat", value: "CTY_CHAM12" },
                { label: "Champhai", value: "CTY_CHAM5257" },
                { label: "Champrajpur", value: "CTY_CHAM_NB2" },
                { label: "Chamrajnagar", value: "CTY_CHAM13" },
                { label: "Chamyari", value: "CTY_CHAM_NB3" },
                { label: "Chand Nau ", value: "CTY_Chan_8688" },
                { label: "Chandai Eco ", value: "CTY_Chan_8602" },
                { label: "Chandan Chauki ", value: "CTY_Chan_8749" },
                { label: "Chandanagar", value: "CITY_Chan_8234" },
                { label: "Chandanagar", value: "CTY_CHAN_NB" },
                { label: "Chandangaon ", value: "CTY_Chan_8595" },
                { label: "Chandannagar", value: "CTY_CHAN_NB1" },
                { label: "Chandauli", value: "CTY_CHAN14" },
                { label: "Chandausi", value: "CTY_CHAN5312" },
                { label: "Chandbaja ", value: "CTY_Chan_8689" },
                { label: "Chandel", value: "CTY_CHAN15" },
                { label: "Chander Nagar", value: "CTY_CHAN_NB2" },
                { label: "Chandgad", value: "CTY_CHAN_NB3" },
                { label: "Chandigarh", value: "CTY_CHAN5417" },
                { label: "Chandigarh", value: "CTY_CHAN5394" },
                { label: "Chandigarh", value: "CTY_CHAN16" },
                { label: "Chandikhol", value: "CTY_CHAN_NB4" },
                { label: "Chandimandir", value: "CTY_CHAN_NB5" },
                { label: "Chandor", value: "CTY_CHAN_NB6" },
                { label: "Chandpur", value: "CTY_CHAN_NB7" },
                { label: "Chandranagar", value: "CTY_CHAN_NB8" },
                { label: "Chandrapur", value: "CTY_CHAN185" },
                { label: "Chandrapur", value: "CTY_CHAN17" },
                { label: "Chandrasekharpur", value: "CTY_CHDR" },
                { label: "Chandvad", value: "CTY_CHAN_NB9" },
                { label: "Changanacherry", value: "CTY_CHAN" },
                { label: "Changlang", value: "CTY_CHAN18" },
                { label: "Changodar", value: "CTY_CHAN_NB10" },
                { label: "CHANNAGIRI", value: "CTY_CHNGRI" },
                { label: "CHANNAGIRI", value: "CTY_Chan_7976" },
                { label: "Channapatna", value: "CTY_CNPT" },
                { label: "Chapad", value: "CTY_CHAP_NB" },
                { label: "Chapra", value: "CTY_CHAP" },
                { label: "Charik ", value: "CTY_Char_8690" },
                { label: "Charkhi Dadri", value: "CTY_CHAR5345" },
                { label: "Charkhi Dadri", value: "CTY_CHAR5319" },
                { label: "Charna ", value: "CTY_Char_8554" },
                { label: "Chatarsal", value: "CTY_CHAT_NB" },
                { label: "Chatra", value: "CTY_CHAT19" },
                { label: "Chattarpur", value: "CTY_CHAT_NB1" },
                { label: "Chaupal", value: "CTY_CHAU_NB" },
                { label: "Chavakkad", value: "CTY_CHAV_NB" },
                { label: "Chawinda Devi", value: "CTY_CHAW_NB" },
                { label: "Cheedigummala ", value: "CTY_Chee_8465" },
                { label: "Cheeka", value: "CTY_CHEE_NB" },
                { label: "Cheema", value: "CTY_CHEE_NB1" },
                { label: "Cheemalavalasa ", value: "CTY_Chee_8501" },
                { label: "Chembur", value: "CTY_CHMBR" },
                { label: "Chemmad", value: "CTY_CHEM_NB" },
                { label: "Chendrappinni", value: "CTY_CHEN_NB" },
                { label: "Chengalpattu", value: "CTY_CHEN87" },
                { label: "Chengam", value: "CTY_CHNGM" },
                { label: "Chengam", value: "CTY_Chen_7978" },
                { label: "Chengannur", value: "CTY_CHEN60" },
                { label: "Chennai", value: "CTY_CHEN20" },
                { label: "Chennaipalem ", value: "CTY_Chen_8352" },
                { label: "Cheppad", value: "CTY_CHEP_NB" },
                { label: "Chepur ", value: "CTY_Chep_8316" },
                { label: "Cherai", value: "CTY_CHER_NB" },
                { label: "Cherpalachery", value: "CTY_CHER_NB1" },
                { label: "Cherthala", value: "CTY_CHER_NB2" },
                { label: "Cherukunnu", value: "CTY_CHER_NB3" },
                { label: "Chettikulangara", value: "CTY_CHET_NB" },
                { label: "Chettupalle ", value: "CTY_Chet_8466" },
                { label: "Chhabra", value: "CTY_CHHA_NB" },
                { label: "Chhachharauli", value: "CTY_CHHA_NB1" },
                { label: "CHHANI", value: "CTY_CHHANI" },
                { label: "Chhat", value: "CTY_CHHA_NB2" },
                { label: "Chhatarpur", value: "CTY_CHHA21" },
                { label: "Chhatral", value: "CTY_CHHA_NB3" },
                { label: "Chhatrapur", value: "CTY_CHHA_NB4" },
                { label: "Chhawla", value: "CTY_CHHWL" },
                { label: "Chhedia Pashchim ", value: "CTY_Chhe_8750" },
                { label: "Chheherta", value: "CTY_Chhe_8880" },
                { label: "Chhendipada", value: "CTY_CHHE_NB" },
                { label: "CHHIBRAMAU", value: "CTY_CHHI_NB" },
                { label: "Chhibramau", value: "CTY_CHHI" },
                { label: "Chhindwara", value: "CTY_CHHI22" },
                { label: "Chhota Bangurda ", value: "CTY_Chho_8563" },
                { label: "CHHOTA UDAIPUR", value: "CTY_CHHO_NB" },
                { label: "Chhota Udaipur", value: "CTY_CHHO" },
                { label: "Chhutmalpur", value: "CTY_CHHU_NB" },
                { label: "Chickmagalur", value: "CTY_CHIC23" },
                { label: "Chidambaram", value: "CTY_CHID89" },
                { label: "Chikalim", value: "CTY_CHIK20" },
                { label: "Chikatimamidi ", value: "CTY_Chik_8300" },
                { label: "Chikballapura", value: "CTY_CHIK_NB" },
                { label: "Chikhali ", value: "CTY_Chik_8603" },
                { label: "Chikhil", value: "CTY_CHIK_NB3" },
                { label: "Chikhil", value: "CTY_CHIK_NB2" },
                { label: "Chikhli", value: "CTY_CHIK5364" },
                { label: "Chikhli", value: "CTY_CHIK24" },
                { label: "Chikkaballapur", value: "CTY_CHIK5247" },
                { label: "Chikmagalur", value: "CTY_CHIK_NB1" },
                { label: "Chikodi", value: "CTY_CHIK50" },
                { label: "Chilakaluripet", value: "CTY_CHIL" },
                { label: "Chilla Saroda Bangar", value: "CTY_CHSRDBGR" },
                { label: "Chimrajpalle", value: "CITY_Chim_8240" },
                { label: "Chimrajpalle", value: "CTY_CHIM_NB" },
                { label: "Chinchavali", value: "CTY_CHIN_NB" },
                { label: "Chinchinim", value: "CTY_CHIN_NB1" },
                { label: "Chinchwad", value: "CTY_CHWD" },
                { label: "Chinnamiram", value: "CTY_CHIN_NB2" },
                { label: "Chinnarangapuram ", value: "CTY_Chin_8427" },
                { label: "Chinnavarigudem ", value: "CTY_Chin_8445" },
                { label: "Chinsurah R S", value: "CTY_CHIN" },
                { label: "Chintada ", value: "CTY_Chin_8502" },
                { label: "Chintalajutur ", value: "CTY_Chin_8428" },
                { label: "Chintamani", value: "CTY_CHINT" },
                { label: "Chipari", value: "CTY_CHIP_NB" },
                { label: "Chiplun", value: "CTY_CHIP" },
                { label: "Chirala", value: "CTY_CHIR" },
                { label: "Chirang", value: "CTY_Chi_8867" },
                { label: "Chirawa", value: "CTY_CHIR_NB" },
                { label: "Chirgaon", value: "CTY_CHIR_NB1" },
                { label: "Chitakodur ", value: "CTY_Chit_8284" },
                { label: "Chithara", value: "CTY_CHIT" },
                { label: "Chithara -Shiv Nadar University", value: "CTY_CHIT_NB" },
                { label: "Chitili Putli ", value: "CTY_Chit_8604" },
                { label: "Chitradurga", value: "CTY_CHIT24" },
                { label: "Chitrakoot", value: "CTY_CHIT25" },
                { label: " Chittaranjon", value: "CTY_CHRNJ" },
                { label: "Chittivalasa ", value: "CTY_Chit_8503" },
                { label: "Chittoor", value: "CTY_CHIT26" },
                { label: "Chittorgarh", value: "CTY_CHIT27" },
                { label: "Chochra", value: "CTY_CHOC_NB" },
                { label: "Chodavaram ", value: "CTY_Chod_8489" },
                { label: "Chogawan", value: "CTY_CHOG_NB" },
                { label: "Chola Sahib", value: "CTY_CHOL_NB" },
                { label: "Chomu", value: "CTY_CHOM" },
                { label: "Chopda", value: "CTY_CHOP_NB" },
                { label: "Choutuppal", value: "CITY_Chou_8241" },
                { label: "Choutuppal", value: "CTY_CHOU_NB" },
                { label: "Chrari Sharief", value: "CTY_CHRA_NB" },
                { label: "Chukkapur ", value: "CTY_Chuk_8317" },
                { label: "Chunar", value: "CTY_CHUN_NB" },
                { label: "Chunati Gada", value: "CTY_CHUN_NB1" },
                { label: "Chunni Kalan", value: "CTY_CHUN_NB2" },
                { label: "Churachandpur", value: "CTY_CHUR28" },
                { label: "Chureb", value: "CTY_CHUR_NB" },
                { label: "Churu", value: "CTY_CHUR29" },
                { label: "Chwadi", value: "CTY_CHWA_NB" },
                { label: "Cochin", value: "CTY_COCH_NB" },
                { label: "Coimbatore", value: "CTY_COIM30" },
                { label: "Colaba", value: "CTY_CLBA" },
                { label: "Colachel", value: "CTY_CLH" },
                { label: "Colonelganj", value: "CTY_COLO_NB" },
                { label: "Colva", value: "CTY_COLV_NB" },
                { label: "Colvale", value: "CTY_COLV_NB1" },
                { label: "Contai", value: "CTY_CONT" },
                { label: "Cooch Behar", value: "CTY_COOC31" },
                { label: "Coonoor", value: "CTY_COON_NB" },
                { label: "Cuddalore", value: "CTY_CUDD32" },
                { label: "Cuddapah", value: "CTY_CUDD33" },
                { label: "Cumbum", value: "CTY_CMB" },
                { label: "Cuncolim", value: "CTY_CUNC_NB" },
                { label: "Curchorem", value: "CTY_CURC" },
                { label: "Curtorim", value: "CTY_CURT_NB" },
                { label: "Cuttack", value: "CTY_CUTT34" },
                { label: "Dabbaspet", value: "CTY_DABB_NB" },
                { label: "Dabgaon Maktha ", value: "CTY_Dabg_8643" },
                { label: "Dabhadi ", value: "CTY_Dabh_8605" },
                { label: "Dabhel", value: "CTY_DABH_NB" },
                { label: "Dabhoi", value: "CTY_DABH" },
                { label: "Dabra", value: "CTY_DABR" },
                { label: "Dabwali", value: "CTY_DABW5334" },
                { label: "Dadahur", value: "CTY_DADA_NB" },
                { label: "Dadra & Nagar Haveli", value: "CTY_DADR35" },
                { label: "Dadri", value: "CTY_DADR_NB" },
                { label: "Dagavadiya", value: "CTY_DAGA_NB" },
                { label: "Dahanu", value: "CTY_DAHANU" },
                { label: "Dahanu Road", value: "CTY_DAHA" },
                { label: "Dahej", value: "CTY_DAHE_NB" },
                { label: "Dahisar(W)", value: "CTY_DHS" },
                { label: "Dahivali", value: "CTY_DAHI_NB" },
                { label: "Dahod", value: "CTY_DAHO36" },
                { label: "Dakor", value: "CTY_DAKO" },
                { label: "Dakshina Kannada", value: "CTY_DAKS37" },
                { label: "Dalam", value: "CTY_DALA_NB" },
                { label: "Daleke ", value: "CTY_Dale_8671" },
                { label: "Dalhousie", value: "CTY_DALH" },
                { label: "Dalli Rajhara", value: "CTY_DALL5404" },
                { label: "Dallo Pura", value: "CTY_DLOPUR" },
                { label: "Dalsinghsarai", value: "CTY_DALS_NB" },
                { label: "Daltonganj", value: "CTY_DALT" },
                { label: "Daltongunj", value: "CTY_DALT_NB" },
                { label: "Daman", value: "CTY_DAMA" },
                { label: "Daman", value: "CTY_DAMA38" },
                { label: "Damoh", value: "CTY_DAMO39" },
                { label: "Danapur", value: "CTY_DINA_NB" },
                { label: "Dandgaon ", value: "CTY_Dand_8537" },
                { label: "Dankuni", value: "CTY_DANK_NB" },
                { label: "Dantewada", value: "CTY_DANT40" },
                { label: "Dapoli", value: "CTY_DAPO_NB" },
                { label: "Daporijo", value: "CTY_DAPO" },
                { label: "Dara", value: "CTY_DARA_NB" },
                { label: "Darbaribur", value: "CTY_DARB_NB" },
                { label: "Darbhanga", value: "CTY_DARB41" },
                { label: "Dared", value: "CTY_DARE_NB" },
                { label: "Darjeeling", value: "CTY_DARJ" },
                { label: "Darjiling", value: "CTY_DARJ42" },
                { label: "Darla", value: "CTY_DARL_NB" },
                { label: "Darrang", value: "CTY_DARR43" },
                { label: "Daryapur", value: "CTY_DARY_NB" },
                { label: "Dascroi", value: "CTY_DASC_NB" },
                { label: "Dashrath puri", value: "CTY_DASRTHPUR" },
                { label: "Dasna", value: "CTY_DASNA" },
                { label: "Dasuya", value: "CTY_DASU" },
                { label: "Datarpur", value: "CTY_DATA_NB" },
                { label: "Datia", value: "CTY_DATI44" },
                { label: "Dattawadi", value: "CTY_DATT_NB" },
                { label: "Daund", value: "CTY_DAUN_NB" },
                { label: "Dausa", value: "CTY_DAUS45" },
                { label: "Davangare", value: "CTY_DAVA46" },
                { label: "Davangere", value: "CTY_DAVA" },
                { label: "Davorlim", value: "CTY_DAVO_NB" },
                { label: "Dayal Pur", value: "CTY_DYPUR" },
                { label: "Dayalpura Mirza", value: "CTY_DAYA_NB" },
                { label: "Debagarh", value: "CTY_DEBA47" },
                { label: "Deesa", value: "CTY_DEES" },
                { label: "Degaon ", value: "CTY_Dega_8318" },
                { label: "Degloor", value: "CTY_DEG" },
                { label: "Dehgam", value: "CTY_DEHG" },
                { label: "Dehlon", value: "CTY_DEHL_NB" },
                { label: "Dehra", value: "CTY_DEHR_NB" },
                { label: "Dehradun", value: "CTY_DEHR48" },
                { label: "Dehri", value: "CTY_DEHR_NB1" },
                { label: "Dela", value: "CTY_DELA_NB" },
                { label: "Delad", value: "CTY_DELA_NB1" },
                { label: "Delegavan ", value: "CTY_Dele_8606" },
                { label: "Delhi", value: "CTY_DELH" },
                { label: "Delhi Cantonment", value: "CTY_DCTNMENT" },
                { label: "Deoband", value: "CTY_DEOB_NB" },
                { label: "Deogar", value: "CTY_DEOG_NB" },
                { label: "Deogarh", value: "CTY_DEOG5412" },
                { label: "Deoghar", value: "CTY_DEOG49" },
                { label: "Deola", value: "CTY_DEOL_NB" },
                { label: "Deolali", value: "CTY_DEOL_NB1" },
                { label: "Deoli", value: "CTY_DELI" },
                { label: "Deoli", value: "CTY_DEOL5410" },
                { label: "Deoria", value: "CTY_DEOR50" },
                { label: "Dera Baba Nanak", value: "CTY_DERA_NB" },
                { label: "Dera Bassi", value: "CTY_DERA5333" },
                { label: "Derabassi", value: "CTY_Dera_8848" },
                { label: "Derol", value: "CTY_DERO_NB" },
                { label: "DEULGAON RAJA", value: "CTY_DEUL_NB" },
                { label: "Deulgaon Raja", value: "CTY_DEUL" },
                { label: "Devakottai", value: "CTY_DEVA_NB" },
                { label: "Devanahalli", value: "CTY_DEVA_NB1" },
                { label: "Devanappli ", value: "CTY_Deva_8319" },
                { label: "Devigarh", value: "CTY_DEVI_NB" },
                { label: "Devulapally Calan ", value: "CTY_Devu_8353" },
                { label: "Dewada Khurd ", value: "CTY_Dewa_8644" },
                { label: "Dewas", value: "CTY_DEWA51" },
                { label: "Dewas", value: "CTY_DEVA" },
                { label: "Dhad", value: "CTY_DHAD_NB" },
                { label: "Dhakia ", value: "CTY_Dhak_8751" },
                { label: "Dhalai", value: "CTY_DHAL52" },
                { label: "Dhalle Ke ", value: "CTY_Dhal_8691" },
                { label: "Dhamangaon Railway", value: "CTY_DHAM_NB" },
                { label: "Dhamnod", value: "CTY_DHAM_NB1" },
                { label: "Dhamond", value: "CTY_DHAM125" },
                { label: "Dhamorah ", value: "CTY_Dham_8765" },
                { label: "Dhampur", value: "CTY_DHAM104" },
                { label: "Dhamtari", value: "CTY_DHAM53" },
                { label: "Dhanaula", value: "CTY_DHAN_NB" },
                { label: "Dhanauri", value: "CTY_DHANA" },
                { label: "Dhanbad", value: "CTY_DHAN54" },
                { label: "Dhand", value: "CTY_DHAN_NB1" },
                { label: "Dhandhuka", value: "CTY_DHND" },
                { label: "Dhaneli Uttari ", value: "CTY_Dhan_8766" },
                { label: "Dhanera", value: "CTY_DHAN" },
                { label: "Dhankansu Kalan ", value: "CTY_Dhan_8662" },
                { label: "Dhankot", value: "CTY_DHAN_NB2" },
                { label: "Dhannaram ", value: "CTY_Dhan_8404" },
                { label: "Dhanyavi", value: "CTY_DHAN_NB3" },
                { label: "Dhapai ", value: "CTY_Dhap_8717" },
                { label: "Dhar", value: "CTY_DHAR55" },
                { label: "Dharamkot", value: "CTY_DHAR5344" },
                { label: "Dharampur", value: "CTY_DHAR_NB4" },
                { label: "Dharampur", value: "CTY_DHAR5291" },
                { label: "Dharampur", value: "CTY_DHAR_NB3" },
                { label: "Dharamshala", value: "CTY_DHAR39" },
                { label: "Dharapuram", value: "CTY_DHAR5423" },
                { label: "Dhari", value: "CTY_DHAR25" },
                { label: "Dhariawad", value: "CTY_Dhar_8874" },
                { label: "Dhariwal", value: "CTY_DHAR5431" },
                { label: "Dharmaj", value: "CTY_DHAR_NB" },
                { label: "Dharmanagar", value: "CTY_DHAR_NB1" },
                { label: "Dharmapuri", value: "CTY_DHAR" },
                { label: "Dharmapuri", value: "CTY_DHAR56" },
                { label: "Dharnasagaram ", value: "CTY_Dhar_8467" },
                { label: "Dharoti Khurd", value: "CTY_DHRTKHRD" },
                { label: "Dharpur", value: "CTY_DHAR_NB2" },
                { label: "Dharuhera", value: "CTY_DHAR57" },
                { label: "Dharwad", value: "CTY_DHAR51" },
                { label: "Dharward", value: "CTY_DHAR58" },
                { label: "Dhaulimunha", value: "CTY_DHAU_NB" },
                { label: "Dhaura ", value: "CTY_Dhau_8781" },
                { label: "Dhaurra Mafi", value: "CTY_DHAU_NB1" },
                { label: "Dhemaji", value: "CTY_DHEM59" },
                { label: "Dhenkanal", value: "CTY_DHEN60" },
                { label: "Dhianpur", value: "CTY_DHIA_NB" },
                { label: "Dhilwan", value: "CTY_DHIL_NB" },
                { label: "Dhokata", value: "CTY_DHOK_NB" },
                { label: "Dholasan", value: "CTY_DHOL_NB" },
                { label: "Dholka", value: "CTY_DHOL_NB1" },
                { label: "Dholpur", value: "CTY_DHOL61" },
                { label: "Dhone", value: "CTY_DHON_NB" },
                { label: "Dhoraji", value: "CTY_DHOR_NB" },
                { label: "Dhotian", value: "CTY_DHOT_NB" },
                { label: "Dhoyanpur ", value: "CTY_Dhoy_8752" },
                { label: "Dhrangadhra", value: "CTY_DHRA" },
                { label: "Dhrol", value: "CTY_DHRO_NB" },
                { label: "Dhubri", value: "CTY_DHUB62" },
                { label: "Dhudhi ", value: "CTY_Dhud_8692" },
                { label: "Dhule", value: "CTY_DHUL63" },
                { label: "Dhunela", value: "CTY_DHUN_NB" },
                { label: "Dhupguri", value: "CTY_DHUP_NB" },
                { label: "Dhuri", value: "CTY_DHUR" },
                { label: "Dhurkot ", value: "CTY_Dhur_8693" },
                { label: "Dhuskiya ", value: "CTY_Dhus_8753" },
                { label: "Diamond Harbour", value: "CTY_DIAM_NB" },
                { label: "Dibang Valley", value: "CTY_DIBA64" },
                { label: "Dibiyapur", value: "CTY_DIBI_NB" },
                { label: "Dibrugarh", value: "CTY_DIBR65" },
                { label: "Digboi", value: "CTY_DIGB_NB" },
                { label: "Dighi ", value: "CTY_Digh_8564" },
                { label: "Dighori ", value: "CTY_Digh_8645" },
                { label: "Dihibatpur Panchanantala", value: "CTY_DIHI_NB" },
                { label: "Dildar Nagar", value: "CTY_DILD_NB" },
                { label: "Dilshad Garden", value: "CTY_DILSHGRDEN" },
                { label: "Dilsukhnagar", value: "CTY_DLSKNR" },
                { label: "Dimapur", value: "CTY_DIMA66" },
                { label: "Dinanagar", value: "CTY_DINA" },
                { label: "Dindigul", value: "CTY_DIND67" },
                { label: "Dindori", value: "CTY_DIND68" },
                { label: "Dindori", value: "CTY_DIND5254" },
                { label: "Ding", value: "CTY_DING_NB" },
                { label: "Dingrian", value: "CTY_DING_NB1" },
                { label: "Dingucha", value: "CTY_DING_NB2" },
                { label: "Dinhata", value: "CTY_DINH_NB" },
                { label: "Diphu", value: "CTY_DIPH_NB" },
                { label: "Dirba", value: "CTY_DIRB_NB" },
                { label: "Dispur", value: "CTY_DISP" },
                { label: "Dist Ahamadnagar", value: "CTY_DIST5288" },
                { label: "Diu", value: "CTY_DIU69" },
                { label: "Diu", value: "CTY_Diu_8865" },
                { label: "Doda", value: "CTY_DODA70" },
                { label: "Doddaballapur", value: "CTY_DODD_NB" },
                { label: "Doddahejjur ", value: "CTY_Dodd_8584" },
                { label: "Dogachia", value: "CTY_DOGA_NB" },
                { label: "Doha", value: "CTY_DOHA" },
                { label: "Domaraiganj", value: "CTY_DOMA_NB" },
                { label: "Dombivali", value: "CTY_DOMB" },
                { label: "Dombivli", value: "CTY_DOMB_NB" },
                { label: "Domjur", value: "CTY_DOMJ_NB" },
                { label: "Dona Paula", value: "CTY_DONA" },
                { label: "Donar", value: "CTY_DONA_NB" },
                { label: "Dongaon ", value: "CTY_Dong_8607" },
                { label: "DoomDooma", value: "CTY_DOOM_NB" },
                { label: "Doomdooma", value: "CTY_DOOM" },
                { label: "Dooni", value: "CTY_DOI" },
                { label: "Dooru Shahbad", value: "CTY_DOOR_NB" },
                { label: "Doraha", value: "CTY_DORA_NB" },
                { label: "Dosanjh ", value: "CTY_Dosa_8694" },
                { label: "Downuru ", value: "CTY_Down_8468" },
                { label: "Dramman", value: "CTY_DRAM_NB" },
                { label: "Dubai", value: "CTY_DUBA24" },
                { label: "Dubrajpur", value: "CTY_DUBR" },
                { label: "Duburi", value: "CTY_DUBU_NB" },
                { label: "Dudhad ", value: "CTY_Dudh_8608" },
                { label: "Duliajan", value: "CTY_DULI_NB" },
                { label: "Dum Dum", value: "CTY_DUMD_NB" },
                { label: "Dumad", value: "CTY_DUMA_NB" },
                { label: "Dumaria ", value: "CTY_Duma_8522" },
                { label: "Dumka", value: "CTY_DUMK71" },
                { label: "Dunavada", value: "CTY_DUNA_NB" },
                { label: "Dundahera", value: "CTY_DUNDHR" },
                { label: "Dungari", value: "CTY_DUNG_NB" },
                { label: "Dungarpur", value: "CTY_DUNG72" },
                { label: "Dunguripali", value: "CTY_Dung_8875" },
                { label: "Durg", value: "CTY_DURG73" },
                { label: "Durgapur", value: "CTY_DURG" },
                { label: "Duruhera", value: "CTY_DURU74" },
                { label: "Dwarka", value: "CTY_DWAR5359" },
                { label: "Dwarka", value: "CTY_DWAR" },
                { label: "Dwarka Sub City", value: "CITY_DWRKSBCTY" },
                { label: "East Champaran", value: "CTY_EAST75" },
                { label: "East Delhi", value: "CTY_EAST76" },
                { label: "East Garo Hills", value: "CTY_EAST77" },
                { label: "East Godavari", value: "CTY_EAST78" },
                { label: "East Kameng", value: "CTY_EAST79" },
                { label: "East Khasi Hills", value: "CTY_EAST80" },
                { label: "East Khasihill", value: "CTY_EAST5289" },
                { label: "East Midnapore", value: "CTY_EAST87" },
                { label: "East Nimar", value: "CTY_EAST5261" },
                { label: "East Siang", value: "CTY_EAST81" },
                { label: "East Sikkim", value: "CTY_EAST82" },
                { label: "East Singhbhum", value: "CTY_EAST83" },
                { label: "Edappal", value: "CTY_EDAP" },
                { label: "Edulapoosalapalli ", value: "CTY_Edul_8382" },
                { label: "Egra", value: "CTY_EGRA_NB" },
                { label: "Eklingpura", value: "CTY_EKLI_NB" },
                { label: "Ekmamidi ", value: "CTY_Ekma_8405" },
                { label: "Ellenabad", value: "CTY_ELLE" },
                { label: "Eluru", value: "CTY_ELUR" },
                { label: "Eramalloor", value: "CTY_ERAM_NB" },
                { label: "Ernakulam", value: "CTY_ERNA84" },
                { label: "Erode", value: "CTY_EROD85" },
                { label: "Erraballe ", value: "CTY_Erra_8429" },
                { label: "Erripalle ", value: "CTY_Erri_8430" },
                { label: "Eru", value: "CTY_ERU_NB" },
                { label: "Etah", value: "CTY_ETAH86" },
                { label: "Etawah", value: "CTY_ETAW87" },
                { label: "Etmadpur", value: "CTY_ETMA_NB" },
                { label: "Ettumanoor", value: "CTY_ETTU" },
                { label: "Faizabad", value: "CTY_FAIZ88" },
                { label: "Falna", value: "CTY_FALN_NB" },
                { label: "Faridabad", value: "CTY_FARI88" },
                { label: "Faridkot", value: "CTY_FARI89" },
                { label: "Faridnagar", value: "CTY_FRDNGR" },
                { label: "Faridpur", value: "CTY_FARI_NB" },
                { label: "Farrukhabad", value: "CTY_FARR90" },
                { label: "Farrukhnagar", value: "CTY_FARR_NB" },
                { label: "Fateghgarh Churian", value: "CTY_FATE" },
                { label: "Fatehabad", value: "CTY_FATE181" },
                { label: "Fatehabad", value: "CTY_FATE91" },
                { label: "Fatehgarh", value: "CTY_FATEH" },
                { label: "Fatehgarh - UP", value: "CTY_FATE_NB" },
                { label: "Fatehgarh Churian", value: "CTY_FATE5355" },
                { label: "Fatehgarh churian", value: "CTY_FATE_NB1" },
                { label: "Fatehgarh Punjtur", value: "CTY_FATE_NB2" },
                { label: "Fatehgarh Sabrah", value: "CTY_FATE_NB3" },
                { label: "Fatehgarh Sahib", value: "CTY_FATE92" },
                { label: "Fatehnagar", value: "CTY_FATE_NB4" },
                { label: "Fatehpur", value: "CTY_FATE_NB6" },
                { label: "Fatehpur", value: "CTY_FATE5265" },
                { label: "Fatehpur", value: "CTY_FATE89" },
                { label: "Fatehpur", value: "CTY_FATE_NB5" },
                { label: "Fatehpur Shekhawati", value: "CTY_FATE5434" },
                { label: "Fathegarh Shukarchak", value: "CTY_FATH_NB" },
                { label: "Fathepur", value: "CTY_FATH" },
                { label: "Fatorda", value: "CTY_FATO_NB" },
                { label: "Fazalwas", value: "CTY_FAZA_NB" },
                { label: "Fazilka", value: "CTY_FAZI" },
                { label: "Feroke", value: "CTY_FER" },
                { label: "Ferozabad", value: "CTY_FRZ" },
                { label: "Ferozepur", value: "CTY_FERO93" },
                { label: "Ferozepur Jhirka", value: "CTY_FERO94" },
                { label: "Ferozpur", value: "CTY_FERO" },
                { label: "Ferozpur Cantt", value: "CTY_FERO_NB" },
                { label: "Film Nagar", value: "CITY_Film_8242" },
                { label: "Film Nagar", value: "CTY_FILM_NB" },
                { label: "Firozabad", value: "CTY_FIRO95" },
                { label: "Firozpur", value: "CTY_FIRO96" },
                { label: "Forbesganj", value: "CTY_FORB5426" },
                { label: "Fujairah", value: "CTY_FUJAIRAH" },
                { label: "Futana ", value: "CTY_Futa_8646" },
                { label: "G.Pangidigudem ", value: "CTY_Gpan_8446" },
                { label: "Gabbada ", value: "CTY_Gabb_8469" },
                { label: "Gadag", value: "CTY_GADA97" },
                { label: "Gadarpur", value: "CTY_GADA_NB" },
                { label: "Gadarwara", value: "CTY_GADA5379" },
                { label: "Gadchiroli", value: "CTY_GADC90" },
                { label: "Gaddan Doab", value: "CTY_GADD_NB" },
                { label: "Gadhada", value: "CTY_GDH" },
                { label: "Gadhejalgaon ", value: "CTY_Gadh_8609" },
                { label: "Gadhinglaj", value: "CTY_GADH" },
                { label: "Gadmudshingi", value: "CTY_GADM" },
                { label: "Gadwal", value: "CITY_Gadw_8175" },
                { label: "Gadwal", value: "CTY_GADW" },
                { label: "Gaggo Mahal", value: "CTY_GAGG_NB" },
                { label: "Gagret", value: "CTY_GAGR_NB" },
                { label: "Gaham", value: "CTY_GAHA_NB" },
                { label: "Gahaur", value: "CTY_GAHA_NB1" },
                { label: "Gajapati", value: "CTY_GAJA98" },
                { label: "Gajraula", value: "CTY_GAJR_NB" },
                { label: "Gajuwaka", value: "CTY_GAJU_NB" },
                { label: "Ganapavaram ", value: "CTY_Gana_8447" },
                { label: "Ganaur", value: "CTY_GANA_NB" },
                { label: "Ganderbal", value: "CTY_GAND44" },
                { label: "Gandevi", value: "CTY_GAND_NB" },
                { label: "Gandhi Nagar", value: "CTY_GAND99" },
                { label: "Gandhi Padia", value: "CTY_GPD" },
                { label: "Gandhichowk", value: "CTY_Gand_8371" },
                { label: "Gandhidham", value: "CTY_GAND26" },
                { label: "Gandhinagar", value: "CTY_GAND27" },
                { label: "Ganeswarpur", value: "CTY_GANE_NB" },
                { label: "Gangakhed", value: "CTY_GANG_NB" },
                { label: "Ganganagar", value: "CTY_GANG11" },
                { label: "Gangapur City", value: "CTY_GANG_NB1" },
                { label: "Gangapur Purvi ", value: "CTY_Gang_8767" },
                { label: "Gangarampur", value: "CTY_GNGP" },
                { label: "Gangavati", value: "CTY_GANG52" },
                { label: "Gangawati", value: "CTY_GANG_NB2" },
                { label: "Gangoh", value: "CTY_GANG_NB3" },
                { label: "Gangtok", value: "CTY_GANG86" },
                { label: "Gangupahad ", value: "CTY_Gang_8285" },
                { label: "Ganj Basoda", value: "CTY_GANJ" },
                { label: "Ganjam", value: "CTY_GANJ12" },
                { label: "Gannavaram", value: "CTY_GANN_NB" },
                { label: "Gardhiwal", value: "CTY_GARD_NB" },
                { label: "Gargoti", value: "CTY_GARG_NB" },
                { label: "Garhshankar", value: "CTY_GARH" },
                { label: "Garhwa", value: "CTY_GARH13" },
                { label: "Garhwal", value: "CTY_GHW" },
                { label: "Gariaband", value: "CTY_GARI5280" },
                { label: "Gariyadhar", value: "CTY_GRY" },
                { label: "Garobadha", value: "CTY_Garo_7967" },
                { label: "Garobadha", value: "CTY_GRODHA" },
                { label: "Gauribidanur", value: "CTY_GAUR_NB" },
                { label: "Gautam Buddha Nagar", value: "CTY_GAUT14" },
                { label: "Gaya", value: "CTY_GAYA15" },
                { label: "GB NAGAR", value: "CTY_Gbna_8873" },
                { label: "GB Nagar", value: "CTY_GB_8866" },
                { label: "Genome Valley", value: "CITY_Geno_8243" },
                { label: "Genome Valley", value: "CTY_GENO_NB" },
                { label: "Georai Kuber ", value: "CTY_Geor_8610" },
                { label: "Ghallkhurd", value: "CTY_GHAL_NB" },
                { label: "Ghamroz", value: "CTY_GHAM_NB" },
                { label: "Ghanaur", value: "CTY_GHAN_NB" },
                { label: "Ghanpur ", value: "CTY_Ghan_8320" },
                { label: "Gharoli", value: "CTY_GHRLI" },
                { label: "Gharonda Neemka Bangar", value: "CTY_GHRD_NEMBANGR" },
                { label: "Ghatal", value: "CTY_GHT" },
                { label: "Ghatampur", value: "CTY_GHAT_NB" },
                { label: "Ghatshila", value: "CTY_GHAT_NB1" },
                { label: "Ghaziabad", value: "CTY_GHAZ16" },
                { label: "Ghaziabad Up", value: "CTY_GHAZ5286" },
                { label: "Ghazipur", value: "CTY_GHAZ17" },
                { label: "Gheora", value: "CTY_GHRA" },
                { label: "Ghilor Kalan", value: "CTY_GHIL_NB" },
                { label: "Ghitorni", value: "CTY_GHTRNI" },
                { label: "Ghodiyal", value: "CTY_GHOD_NB" },
                { label: "Ghoghla", value: "CTY_GHOG" },
                { label: "Ghogra", value: "CTY_GHOG_NB" },
                { label: "Ghosi", value: "CTY_GHOS_NB" },
                { label: "Ghoti Budruk", value: "CTY_GHOT_NB" },
                { label: "Ghudda", value: "CTY_GHUD_NB" },
                { label: "Ghugiana ", value: "CTY_Ghug_8695" },
                { label: "Ghuman", value: "CTY_GHUM_NB" },
                { label: "Giddalur", value: "CTY_GIDD_NB" },
                { label: "Giddarbaha", value: "CTY_GIDD" },
                { label: "Gidderbaha", value: "CTY_GIDD_NB1" },
                { label: "Giljewala", value: "CTY_GILJ_NB" },
                { label: "Gill", value: "CTY_GILL_NB" },
                { label: "Gill Road", value: "CTY_GILL" },
                { label: "Girgitpalle ", value: "CTY_Girg_8406" },
                { label: "Giridh", value: "CTY_GIRI18" },
                { label: "Giridih", value: "CTY_GIRI" },
                { label: "Goa", value: "CTY_GOA5338" },
                { label: "GOA Velha", value: "CTY_GOAV_NB" },
                { label: "Goa Velha", value: "CTY_GOAV" },
                { label: "Goalpara", value: "CTY_GOAL19" },
                { label: "Gobi", value: "CTY_GOBI5342" },
                { label: "Gobichettipalayam", value: "CTY_GOBI_NB" },
                { label: "Gobindgarh", value: "CTY_GOBI_NB1" },
                { label: "Gobordanga", value: "CTY_GOBO_NB" },
                { label: "Godamguda ", value: "CTY_Goda_8407" },
                { label: "Godavarikhani", value: "CITY_Goda_8246" },
                { label: "Godavarikhani", value: "CTY_GODA" },
                { label: "Godbolemala", value: "CTY_GODB" },
                { label: "Godda", value: "CTY_GODD20" },
                { label: "Godhavi", value: "CTY_GODH_NB" },
                { label: "Godhra", value: "CTY_GODH" },
                { label: "Godhra Bhuravav ", value: "CTY_Godh_8549" },
                { label: "GoGOAni", value: "CTY_GOGO_NB" },
                { label: "Gogoani", value: "CTY_GOGO" },
                { label: "Gohana", value: "CTY_GOHA21" },
                { label: "Goindwal", value: "CTY_GOIN_NB" },
                { label: "Gokak", value: "CTY_GOKA_NB" },
                { label: "Gokal Pur", value: "CTY_GKLPUR" },
                { label: "Gola", value: "CTY_GOLA_NB1" },
                { label: "Gola Gokaran Nath", value: "CTY_GOLA_NB" },
                { label: "Golaghat", value: "CTY_GOLA22" },
                { label: "Golatgaon ", value: "CTY_Gola_8611" },
                { label: "Golewala", value: "CTY_GOLE_NB" },
                { label: "Golewala ", value: "CTY_Gole_8696" },
                { label: "Gollalagudur ", value: "CTY_Goll_8431" },
                { label: "Gomati", value: "CTY_Goma_8872" },
                { label: "Gomti nagar", value: "CTY_GMT" },
                { label: "Gonda", value: "CTY_GOND23" },
                { label: "Gondal", value: "CTY_GOND" },
                { label: "Gondia", value: "CTY_GOND24" },
                { label: "Gondipakalau ", value: "CTY_Gond_8470" },
                { label: "Goniana", value: "CTY_GONI_NB" },
                { label: "Goniana Mandi", value: "CTY_GONI" },
                { label: "Gonikoppal", value: "CTY_GONI_NB1" },
                { label: "Gopalganj", value: "CTY_GOPA25" },
                { label: "Gopanapalle", value: "CITY_Gopa_8244" },
                { label: "Gopanapalle", value: "CTY_GOPA_NB" },
                { label: "Gopinagar", value: "CTY_GOPI_NB" },
                { label: "Gorakhpur", value: "CTY_GORA26" },
                { label: "Goraknath", value: "CTY_GORA_NB" },
                { label: "Goraya", value: "CTY_GORA" },
                { label: "Goregaon", value: "CTY_GORGN" },
                { label: "Gotibandh ", value: "CTY_Goti_8523" },
                { label: "Gottimukkala ", value: "CTY_Gott_8408" },
                { label: "Gotur ", value: "CTY_Gotu_8432" },
                { label: "Goudgop", value: "CTY_GOUD_NB" },
                { label: "Goverdhan", value: "CTY_GOVE_NB" },
                { label: "Govindpet", value: "CITY_Govi_8245" },
                { label: "Govindpet", value: "CTY_GOVI_NB" },
                { label: "Govindpet ", value: "CTY_Govi_8321" },
                { label: "Gowardhan ", value: "CTY_Gowa_8647" },
                { label: "Gowrisankara Puram ", value: "CTY_Gowr_8448" },
                { label: "Grand Cayman", value: "CTY_CAYMAN" },
                { label: "Greater Noida", value: "CTY_GREA_NB" },
                { label: "Gudah", value: "CTY_GUDA_NB" },
                { label: "Gudivada", value: "CTY_GUDI" },
                { label: "Gudiyatham", value: "CTY_GUD" },
                { label: "Gudli", value: "CTY_GUDL_NB" },
                { label: "Gudur", value: "CTY_GUDU" },
                { label: "Gudur ", value: "CTY_Gudu_8354" },
                { label: "Gulaothi", value: "CTY_GULA_NB" },
                { label: "Gulbarga", value: "CTY_GULB27" },
                { label: "Gumarwin", value: "CTY_GUMA_NB" },
                { label: "Gumia", value: "CTY_GUMI_NB" },
                { label: "Gumla", value: "CTY_GUML28" },
                { label: "Guna", value: "CTY_GUNA29" },
                { label: "Gundrathimadugu ", value: "CTY_Gund_8383" },
                { label: "Gundupala ", value: "CTY_Gund_8471" },
                { label: "Guntakal", value: "CTY_GUNT" },
                { label: "Guntur", value: "CTY_GUNT30" },
                { label: "Gurandarapalem ", value: "CTY_Gura_8472" },
                { label: "Gurdaspur", value: "CTY_GURD31" },
                { label: "Gurgaon", value: "CTY_GURG5380" },
                { label: "Gurgaon", value: "CTY_GURG32" },
                { label: "Gurne Kalan", value: "CTY_GURN_NB" },
                { label: "Gursarai", value: "CTY_GURS_NB" },
                { label: "Gursia ", value: "CTY_Gurs_8527" },
                { label: "Guruharsahai", value: "CTY_GURU63" },
                { label: "Guruvayoor", value: "CTY_GURU_NB" },
                { label: "Guruvayur", value: "CTY_GURU61" },
                { label: "Gurvaigudem ", value: "CTY_Gurv_8449" },
                { label: "Guskara", value: "CTY_GUSK" },
                { label: "Guskaraÿ", value: "CTY_GUSK_NB" },
                { label: "Guwahati", value: "CTY_GUWA" },
                { label: "Gwalior", value: "CTY_GWAL33" },
                { label: "Habra", value: "CTY_HABR_NB" },
                { label: "Hadamtala", value: "CTY_HADA_NB" },
                { label: "Hadgood", value: "CTY_HADG_NB" },
                { label: "Hadiyol", value: "CTY_HADI_NB" },
                { label: "Haibowal Kalan", value: "CTY_HAIB" },
                { label: "Haidergarh", value: "CTY_HAID_NB" },
                { label: "Hailakandi", value: "CTY_HAIL34" },
                { label: "Haileymandi", value: "CTY_HLYMND" },
                { label: "Hajipur", value: "CTY_HAJI_NB3" },
                { label: "Hajipur", value: "CTY_HAJI_NB" },
                { label: "Hajipur", value: "CTY_HAJI_NB2" },
                { label: "Hajira", value: "CTY_HAJI_NB1" },
                { label: "Haldia", value: "CTY_HALD115" },
                { label: "Haldwani", value: "CTY_HALD112" },
                { label: "Hally Mandi", value: "CTY_HALL_NB" },
                { label: "Halol", value: "CTY_HALO" },
                { label: "Halvad", value: "CTY_HALV" },
                { label: "Hambran", value: "CTY_HAMB_NB" },
                { label: "Hamirpur", value: "CTY_HAMI5266" },
                { label: "Hamirpur", value: "CTY_HAMI35" },
                { label: "Hamirpur(Hp)", value: "CTY_HAMI36" },
                { label: "Hanagodu ", value: "CTY_Hana_8585" },
                { label: "Handia", value: "CTY_HAND_NB" },
                { label: "Handwara", value: "CTY_HAND_NB1" },
                { label: "Hansi", value: "CTY_HANS" },
                { label: "Hanumakonda", value: "CITY_Hanu_8247" },
                { label: "Hanumakonda", value: "CTY_HAN" },
                { label: "Hanuman Junction", value: "CTY_HANU_NB" },
                { label: "Hanumangarh", value: "CTY_HANU37" },
                { label: "Hanumangarh Town", value: "CTY_HANU" },
                { label: "Hanumanthapur ", value: "CTY_Hanu_8286" },
                { label: "Hanumapur ", value: "CTY_Hanu_8301" },
                { label: "Hapur", value: "CTY_HAPU" },
                { label: "Harchowal", value: "CTY_HARC_NB" },
                { label: "Harda", value: "CTY_HARD38" },
                { label: "Hardoi", value: "CTY_HARD39" },
                { label: "Hari Ke Pattan", value: "CTY_HARI_NB" },
                { label: "Hariana", value: "CTY_HARI_NB1" },
                { label: "Haridwar", value: "CTY_HARI40" },
                { label: "Haridwar", value: "CTY_HARI" },
                { label: "Harij", value: "CTY_HARI_NB2" },
                { label: "Harindra Nagar (Faridkot) ", value: "CTY_Hari_8697" },
                { label: "Haripad", value: "CTY_HRPD" },
                { label: "Haripar Pal", value: "CTY_HARI_NB3" },
                { label: "Haripurdhar", value: "CTY_Hari_8555" },
                { label: "Harishnagar", value: "CTY_HRS" },
                { label: "Haryau", value: "CTY_HARY_NB" },
                { label: "Hasanpur", value: "CTY_HASA_NB" },
                { label: "Hassan", value: "CTY_HASS41" },
                { label: "Hassanpur", value: "CTY_HASS_NB" },
                { label: "Hastepimpalgaon ", value: "CTY_Hast_8612" },
                { label: "Hastsal", value: "CTY_HATSL" },
                { label: "Hata", value: "CTY_HATA_NB" },
                { label: "Hatabuzurg", value: "CTY_HATA_NB1" },
                { label: "Hathin", value: "CTY_HATH_NB" },
                { label: "Hathras", value: "CTY_HATH42" },
                { label: "Haveri", value: "CTY_HAVE43" },
                { label: "Hayatnagar", value: "CITY_Haya_8184" },
                { label: "Hayatnagar", value: "CTY_HAYA_NB" },
                { label: "Hayatpur", value: "CTY_HAYA_NB1" },
                { label: "Hazaribag", value: "CTY_HAZA44" },
                { label: "Hazaribagh", value: "CTY_HAZA_NB" },
                { label: "Herbertpur", value: "CTY_HERB_5452" },
                { label: "Hilsa", value: "CTY_HILS_NB" },
                { label: "Himatnagar", value: "CTY_HIMA_NB" },
                { label: "Himmatnagar", value: "CTY_HIMM" },
                { label: "Hindaun", value: "CTY_HIND_NB" },
                { label: "Hindmotor", value: "CTY_HINM" },
                { label: "Hindupur", value: "CTY_HIND" },
                { label: "Hinganghat", value: "CTY_HING" },
                { label: "Hingna", value: "CTY_HING_NB" },
                { label: "Hingoli", value: "CTY_HING45" },
                { label: "Hiranagar", value: "CTY_HRN" },
                { label: "Hiriyur", value: "CTY_HIRI_NB" },
                { label: "Hirpura", value: "CTY_HIRP_NB" },
                { label: "Hisar", value: "CTY_HISA46" },
                { label: "Hissar", value: "CTY_HISS" },
                { label: "Hodal", value: "CTY_HODA_NB" },
                { label: "Hojai", value: "CTY_HOJA" },
                { label: "Homnabad", value: "CTY_HOMN_NB" },
                { label: "Honavar", value: "CTY_HONA5343" },
                { label: "Honnenahalli ", value: "CTY_Honn_8586" },
                { label: "Hooghly", value: "CTY_HOOG47" },
                { label: "Hoshangabad", value: "CTY_HOSH48" },
                { label: "Hoshiarpur", value: "CTY_HOSH49" },
                { label: "Hospet", value: "CTY_HOSP" },
                { label: "Hosur", value: "CTY_HOSU" },
                { label: "Howrah", value: "CTY_HOWR50" },
                { label: "Hubli", value: "CTY_HUBL" },
                { label: "Hudli", value: "CTY_Hudl_8581" },
                { label: "Hugli", value: "CTY_HUGL_NB" },
                { label: "Hunasamaranahalli", value: "CTY_HUNA_NB" },
                { label: "Hunsa", value: "CITY_Huns_8185" },
                { label: "Hunsa", value: "CTY_HUNS_NB" },
                { label: "Hunsur", value: "CTY_HUNS_NB1" },
                { label: "Hupari", value: "CTY_HUPA_NB" },
                { label: "Husnabad", value: "CITY_Husn_8186" },
                { label: "Husnabad", value: "CTY_HUSN_NB" },
                { label: "Hussainpur", value: "CTY_HUSS" },
                { label: "Hussainpur (Ct)", value: "CTY_HUSS_NB" },
                { label: "Huzurabad", value: "CITY_Huzu_8187" },
                { label: "Huzurabad", value: "CTY_HUZU_NB" },
                { label: "Hyderabad", value: "CITY_Hyde_8149" },
                { label: "Hyderabad", value: "CTY_Hyde_7963" },
                { label: "Hyderabad", value: "CTY_HYDE51" },
                { label: "Ibban", value: "CTY_IBBA_NB" },
                { label: "Ibban ", value: "CTY_Ibba_8718" },
                { label: "Ichalkaranji", value: "CTY_ICHA" },
                { label: "Idar", value: "CTY_IDAR" },
                { label: "Idukki", value: "CTY_IDUK52" },
                { label: "Igatpuri", value: "CTY_IGAT_NB" },
                { label: "Igntu Amarkantak .", value: "CTY_Ignt_8597" },
                { label: "Ikauna", value: "CTY_IKAU_NB" },
                { label: "Ilampillai", value: "CTY_ILAM_NB" },
                { label: "Imalyaka", value: "CTY_IMAL_NB" },
                { label: "Imphal", value: "CTY_IMPH" },
                { label: "Imphal East", value: "CTY_IMPH_7147" },
                { label: "Imphal East", value: "CTY_IMPH53" },
                { label: "Imphal West", value: "CTY_IMPH54" },
                { label: "Indapur", value: "CTY_INDA_NB" },
                { label: "Indirapuram", value: "CTY_INDRPRM" },
                { label: "Indore", value: "CTY_INDO55" },
                { label: "Indrad", value: "CTY_INDR_NB" },
                { label: "Indrapura", value: "CTY_INDR_NB1" },
                { label: "Indri", value: "CTY_INDR_NB2" },
                { label: "Indupur", value: "CTY_INDU_NB" },
                { label: "Innadu ", value: "CTY_Inna_8733" },
                { label: "Ippatla ", value: "CTY_Ippa_8433" },
                { label: "Irinjalaguda", value: "CTY_IRIN_NB" },
                { label: "Irinjalakuda", value: "CTY_IRIN5414" },
                { label: "Islampur", value: "CTY_ILM" },
                { label: "Islampur", value: "CTY_ISLA_NB" },
                { label: "Ismailabad", value: "CTY_ISMA56" },
                { label: "Ispur", value: "CTY_ISPU_NB" },
                { label: "Israna", value: "CTY_ISRA_NB" },
                { label: "Issapalli ", value: "CTY_Issa_8322" },
                { label: "Itanagar", value: "CTY_ITAN" },
                { label: "Itarsi", value: "CTY_ITAR" },
                { label: "Itawa Kalan", value: "CTY_ITAW_NB" },
                { label: "J P Agraharam ", value: "CTY_JPAg_8473" },
                { label: "J.P.Nagar ", value: "CTY_JPNa_8587" },
                { label: "Jabalpur", value: "CTY_JABA57" },
                { label: "Jabbowal", value: "CTY_JABB_NB" },
                { label: "Jadcherla", value: "CITY_Jadc_8183" },
                { label: "Jadcherla", value: "CTY_JADC_NB" },
                { label: "Jadla", value: "CTY_JADL_NB" },
                { label: "Jaffrabad (Delhi)", value: "CTY_JFRBAD" },
                { label: "Jagadhari", value: "CTY_JAGA58" },
                { label: "Jagadhri", value: "CTY_JAGA36" },
                { label: "Jagadishpur", value: "CTY_JAGA_NB" },
                { label: "Jagatpur", value: "CTY_JAGA_NB1" },
                { label: "Jagatsinghapur", value: "CTY_JAGA59" },
                { label: "Jagatsinghpur", value: "CTY_JAGA154" },
                { label: "Jagdalpur", value: "CTY_JAGD" },
                { label: "Jaggayapet", value: "CTY_JAGG_NB" },
                { label: "Jaggayyapet", value: "CTY_JAGG" },
                { label: "Jagiroad", value: "CTY_JAGI_NB" },
                { label: "Jagityala", value: "CITY_Jagi_8182" },
                { label: "Jagityala", value: "CTY_JAGI_NB1" },
                { label: "Jagraon", value: "CTY_JAGR" },
                { label: "Jahangirabad", value: "CTY_JAHA_NB" },
                { label: "Jahanpur", value: "CTY_JAHA_NB1" },
                { label: "Jaidupalli ", value: "CTY_Jaid_8409" },
                { label: "Jaimal Wala ", value: "CTY_Jaim_8698" },
                { label: "Jaintia Hills", value: "CTY_JAIN60" },
                { label: "Jaipur", value: "CTY_JAIP61" },
                { label: "Jairampur", value: "CTY_JAIR" },
                { label: "Jais", value: "CTY_JAIS_NB" },
                { label: "Jaisalemer", value: "CTY_JAIS_NB1" },
                { label: "Jaisalmer", value: "CTY_JAIS62" },
                { label: "Jaisinghpur", value: "CTY_JAIS_NB2" },
                { label: "Jaitaran", value: "CTY_JTR" },
                { label: "Jaitu", value: "CTY_JAIT" },
                { label: "Jajapur", value: "CTY_JAJA63" },
                { label: "Jajpur", value: "CTY_JAJP_NB" },
                { label: "Jajpur Road", value: "CTY_JAJP155" },
                { label: "Jajpur Town", value: "CTY_JAJP5376" },
                { label: "Jakhal", value: "CTY_JAKH_NB" },
                { label: "Jalalabad", value: "CTY_JALA" },
                { label: "Jalalpur", value: "CTY_JALA_NB" },
                { label: "Jalandhar", value: "CTY_JALA64" },
                { label: "Jalandhar Cantt", value: "CTY_JALA_NB1" },
                { label: "Jalaun", value: "CTY_JALA65" },
                { label: "Jalgaon", value: "CTY_JALG66" },
                { label: "Jalke ", value: "CTY_Jalk_8528" },
                { label: "Jalmana", value: "CTY_JALM_NB" },
                { label: "Jalna", value: "CTY_JALN67" },
                { label: "Jalochi", value: "CTY_JALO_NB" },
                { label: "Jalor", value: "CTY_JALO68" },
                { label: "Jalore", value: "CTY_JALO" },
                { label: "Jalpaiguri", value: "CTY_JALP69" },
                { label: "Jam Jodhpur", value: "CTY_JAM5348" },
                { label: "Jamalpur", value: "CTY_JAMA_NB2" },
                { label: "Jamalpur", value: "CTY_JAMA_NB3" },
                { label: "Jamalpur", value: "CTY_JAMA_NB" },
                { label: "Jamalpur", value: "CTY_JAMA_NB1" },
                { label: "Jamandlapalli ", value: "CTY_Jama_8384" },
                { label: "Jambusar", value: "CTY_JAMB" },
                { label: "Jamdih ", value: "CTY_Jamd_8538" },
                { label: "Jamgaon", value: "CTY_JAMG_NB" },
                { label: "Jamiyatpura", value: "CTY_JAMI_NB" },
                { label: "Jamjodhpur", value: "CTY_JAMJ_NB" },
                { label: "Jamkhambhalia", value: "CTY_JAMK" },
                { label: "Jamkhandi", value: "CTY_JAMK_NB" },
                { label: "Jamkhed", value: "CTY_JAM" },
                { label: "Jammikunta", value: "CITY_Jamm_8171" },
                { label: "Jammikunta", value: "CTY_JAMM5368" },
                { label: "Jammu", value: "CTY_JAMM70" },
                { label: "Jamnagar", value: "CTY_JAMN71" },
                { label: "Jamner", value: "CTY_JAMN_NB" },
                { label: "Jamshedpur", value: "CTY_JAMS" },
                { label: "Jamsher", value: "CTY_JAMS_NB" },
                { label: "Jamtara", value: "CTY_JAMT72" },
                { label: "Jamtukam ", value: "CTY_Jamt_8648" },
                { label: "Jamui", value: "CTY_JAMU73" },
                { label: "Jamuria", value: "CTY_JAMU_NB" },
                { label: "Janakpuri", value: "CTY_JNKPRI" },
                { label: "Jandiala", value: "CTY_JAND_NB" },
                { label: "Jandiala Guru", value: "CTY_JAND" },
                { label: "Jandoli", value: "CTY_JAND_NB1" },
                { label: "Jandpur", value: "CTY_JAND_NB2" },
                { label: "Jandu Singha", value: "CTY_JAND_NB3" },
                { label: "Jangaon", value: "CITY_Jang_8172" },
                { label: "Jangaon", value: "CTY_JANG5320" },
                { label: "Jangaon Bazar ", value: "CTY_Jang_8287" },
                { label: "Jangareddygudem", value: "CTY_JANG_NB" },
                { label: "Jangipur", value: "CTY_JANG_NB1" },
                { label: "Janial", value: "CTY_JANI_NB" },
                { label: "Janjaon", value: "CITY_Janj_8178" },
                { label: "Janjaon", value: "CTY_JANJ" },
                { label: "Janjgir", value: "CTY_JANJ5398" },
                { label: "Janjgir-Champa", value: "CTY_JANJ74" },
                { label: "Jankampet", value: "CITY_Jank_8188" },
                { label: "Jankampet", value: "CTY_JANK_NB" },
                { label: "Jansui", value: "CTY_JANS_NB" },
                { label: "Jaora", value: "CTY_JAOR" },
                { label: "Jarg", value: "CTY_JARG_NB" },
                { label: "Jasdan", value: "CTY_JASD" },
                { label: "Jashpur", value: "CTY_JASH75" },
                { label: "Jasra", value: "CTY_JASR_NB" },
                { label: "Jassur", value: "CTY_JASS_NB" },
                { label: "Jaswant Nagar", value: "CTY_JASW_NB" },
                { label: "Jatani", value: "CTY_JATA_NB" },
                { label: "Jath", value: "CTY_JATH_NB" },
                { label: "Jatheri", value: "CTY_JATH_NB1" },
                { label: "Jatpar", value: "CTY_JATP_NB" },
                { label: "Jaunpur", value: "CTY_JAUN76" },
                { label: "Jaura Chittran", value: "CTY_JAUR_NB" },
                { label: "Javkheda BK ", value: "CTY_Javk_8613" },
                { label: "Jawahar", value: "CTY_JAWA_NB" },
                { label: "Jawalamukhi", value: "CTY_JWLM" },
                { label: "Jaygaon", value: "CTY_JAYG_NB" },
                { label: "Jaysingpur", value: "CTY_JAYS" },
                { label: "Jeelgul", value: "CITY_Jeel_8189" },
                { label: "Jeelgul", value: "CTY_JEEL_NB" },
                { label: "Jehanabad", value: "CTY_JEHA77" },
                { label: "Jeondan", value: "CTY_JEON_NB" },
                { label: "Jetpur", value: "CTY_JETP" },
                { label: "Jewar", value: "CTY_JEWA_NB" },
                { label: "Jeypore", value: "CTY_JEYP5328" },
                { label: "Jhabua", value: "CTY_JHAB78" },
                { label: "Jhajha", value: "CTY_JHAJ5425" },
                { label: "Jhajjar", value: "CTY_JHAJ79" },
                { label: "Jhalawar", value: "CTY_JHAL80" },
                { label: "Jhalrapatan", value: "CTY_JHAL" },
                { label: "Jhanjharpur", value: "CTY_JHAN_NB" },
                { label: "Jhanjheri", value: "CTY_JHAN_NB1" },
                { label: "Jhansi", value: "CTY_JHAN81" },
                { label: "Jharia", value: "CTY_JHAR5304" },
                { label: "Jharoda Majra Burari", value: "CTY_JHR_MJRBRI" },
                { label: "Jharsuguda", value: "CTY_JHAR82" },
                { label: "Jhujhunu", value: "CTY_JHUJ83" },
                { label: "Jhumila", value: "CTY_JHUM_NB" },
                { label: "Jhumri Telaiya", value: "CTY_JHUM" },
                { label: "Jhumri Tilaiya", value: "CTY_JHUM_NB1" },
                { label: "Jhunjhunu", value: "CTY_JHUN" },
                { label: "Jib", value: "CTY_JIB_NB" },
                { label: "Jigani", value: "CTY_JIGA_NB" },
                { label: "Jind", value: "CTY_JIND84" },
                { label: "Jitwarpur", value: "CTY_JITW_NB" },
                { label: "Jiwai Kadim ", value: "CTY_Jiwa_8768" },
                { label: "Jiwan Pur", value: "CTY_JWNPUR" },
                { label: "Joda", value: "CTY_JODA" },
                { label: "Jodhan", value: "CTY_JODH" },
                { label: "Jodhpur", value: "CTY_JODH85" },
                { label: "JogiNaka", value: "CTY_JOGI_NB" },
                { label: "Joginaka", value: "CTY_JOGI" },
                { label: "Jogindarnagar", value: "CTY_JOGI_NB1" },
                { label: "Jogipet", value: "CITY_Jogi_8190" },
                { label: "Jogipet", value: "CTY_JOGI_NB2" },
                { label: "Johal Raju Singh ", value: "CTY_Joha_8672" },
                { label: "Jolarpet", value: "CTY_JOLA5406" },
                { label: "Jolva ", value: "CTY_Jolv_8548" },
                { label: "Jona Pur", value: "CTY_JNPUR" },
                { label: "Jonnavarigudem ", value: "CTY_Jonn_8450" },
                { label: "Jorethang", value: "CTY_JORE_NB" },
                { label: "Jorhat", value: "CTY_JORH86" },
                { label: "Jowai", value: "CTY_JOWA" },
                { label: "Joya", value: "CTY_JOYA_NB" },
                { label: "Jugial", value: "CTY_JUGI_NB" },
                { label: "Jugsalai", value: "CTY_JUGS_NB" },
                { label: "Jui", value: "CTY_JUI_NB" },
                { label: "Jujomura", value: "CTY_JUJO_NB" },
                { label: "Jullana", value: "CTY_JULL_NB" },
                { label: "Junagadh", value: "CTY_JUNA87" },
                { label: "Jundla", value: "CTY_JUND_NB" },
                { label: "Junnar", value: "CTY_JUNN_NB" },
                { label: "Jyotiba Phule Nagar", value: "CTY_JYOT91" },
                { label: "K Yellavaram ", value: "CTY_Kyel_8474" },
                { label: "V.Ra_8148 K.V.Rangareddy", value: "CITY_K" },
                { label: "V.88 K.V.Rangareddy", value: "CTY_K" },
                { label: "Kachchh", value: "CTY_KACH89" },
                { label: "Kachigam", value: "CTY_KACH_NB" },
                { label: "Kadadhe", value: "CTY_KADA_NB" },
                { label: "Kadamgiri", value: "CTY_KADA5290" },
                { label: "Kadapa", value: "CTY_KAD" },
                { label: "Kadarpur", value: "CTY_KADA_NB1" },
                { label: "Kadayanallur", value: "CTY_KADA_NB2" },
                { label: "Kadcherla ", value: "CTY_Kadc_8410" },
                { label: "Kadgill ", value: "CTY_Kadg_8673" },
                { label: "Kadi", value: "CTY_KADI" },
                { label: "Kadiri", value: "CTY_KADI_NB" },
                { label: "Kadiyam", value: "CTY_Kadi_8876" },
                { label: "Kadodara", value: "CTY_KADO_NB" },
                { label: "Kagal", value: "CTY_KAGA_NB" },
                { label: "Kahalgaon", value: "CTY_KAHA" },
                { label: "Kahalgaonÿ", value: "CTY_KAHA_NB" },
                { label: "Kahnuwan", value: "CTY_KAHN_NB" },
                { label: "Kailash", value: "CTY_KAIL_NB" },
                { label: "Kaimla", value: "CTY_KAIM_NB" },
                { label: "Kaimur (Bhabua)", value: "CTY_KAIM90" },
                { label: "Kairon", value: "CTY_KAIR_NB" },
                { label: "Kaiserganj", value: "CTY_KAIS_NB" },
                { label: "Kaithal", value: "CTY_KAIT91" },
                { label: "Kaiyal", value: "CTY_KAIY_NB" },
                { label: "Kajaria ", value: "CTY_Kaja_8754" },
                { label: "Kakadev", value: "CTY_KAKA_NB" },
                { label: "Kakching", value: "CTY_KKCHI" },
                { label: "Kakching", value: "CTY_Kakc_7982" },
                { label: "Kakinada", value: "CTY_KAKI" },
                { label: "Kakkanad", value: "CTY_KAKK" },
                { label: "Kakod", value: "CTY_KAKO_NB" },
                { label: "Kakoda", value: "CTY_KAKO_NB1" },
                { label: "Kala Afgana", value: "CTY_KALA_NB" },
                { label: "Kala Amb", value: "CTY_KALA_NB1" },
                { label: "Kala Bakra", value: "CTY_KALA_NB2" },
                { label: "Kala Sangian", value: "CTY_KALA_NB3" },
                { label: "Kalady", value: "CTY_KALA_NB4" },
                { label: "Kalahandi", value: "CTY_KALA92" },
                { label: "Kalambi", value: "CTY_KALA_NB5" },
                { label: "Kalamboli", value: "CTY_KALA_NB6" },
                { label: "Kalamwadi", value: "CTY_KALA_NB7" },
                { label: "Kalanaur", value: "CTY_KALA_NB8" },
                { label: "Kalanwali", value: "CTY_KALA_NB9" },
                { label: "Kalasa", value: "CTY_KAL" },
                { label: "Kalaur", value: "CTY_KALA_NB10" },
                { label: "Kalavad", value: "CTY_KALA_NB11" },
                { label: "Kalavar", value: "CTY_KALA_NB12" },
                { label: "Kalawali", value: "CTY_KALA" },
                { label: "Kalayat", value: "CTY_KALA_NB13" },
                { label: "Kaliaganj", value: "CTY_KALI_NB" },
                { label: "Kaligote", value: "CITY_Kali_8191" },
                { label: "Kaligote", value: "CTY_KALI_NB1" },
                { label: "Kalimpong", value: "CTY_KALI_NB2" },
                { label: "Kalka", value: "CTY_KALK93" },
                { label: "Kallakkurichi", value: "CTY_KALL_NB" },
                { label: "Kallakurichi", value: "CTY_KALL5424" },
                { label: "Kalluthotti Junction", value: "CTY_KALL_NB1" },
                { label: "Kalmeshwar", value: "CTY_KALM" },
                { label: "Kalna", value: "CTY_KALN_NB" },
                { label: "Kalol", value: "CTY_KALO28" },
                { label: "Kaloor", value: "CTY_KALO62" },
                { label: "Kalpetta", value: "CTY_KALP" },
                { label: "Kalwakurthy", value: "CITY_Kalw_8192" },
                { label: "Kalwakurthy", value: "CTY_KALW_NB" },
                { label: "Kalwan Bk", value: "CTY_KALW_NB1" },
                { label: "Kalyan", value: "CTY_KALY92" },
                { label: "Kalyan - Dombivli", value: "CTY_KALY_NB" },
                { label: "Kalyan East Thane", value: "CTY_KALY5293" },
                { label: "Kalyandurg", value: "CTY_KALY_NB1" },
                { label: "Kalyani", value: "CTY_KALY" },
                { label: "Kamali", value: "CTY_KAMA_NB" },
                { label: "Kamareddy", value: "CITY_Kama_8193" },
                { label: "Kamareddy", value: "CTY_KAMA_NB1" },
                { label: "Kambalapalli ", value: "CTY_Kamb_8385" },
                { label: "Kambam", value: "CTY_KAMB_NB" },
                { label: "Kambil", value: "CTY_KAMB_NB1" },
                { label: "Kammiana ", value: "CTY_Kamm_8699" },
                { label: "Kamothe", value: "CTY_KAMO_NB" },
                { label: "Kamptee", value: "CTY_KAMP" },
                { label: "Kampur", value: "CTY_KAMP_NB" },
                { label: "Kamrej", value: "CTY_KAMR_NB" },
                { label: "Kamrup", value: "CTY_KAMR94" },
                { label: "Kamshet", value: "CTY_KAMS_NB" },
                { label: "Kanakapura", value: "CTY_KANA" },
                { label: "Kanchapada", value: "CTY_KANC_NB" },
                { label: "Kanchipuram", value: "CTY_KANC95" },
                { label: "Kanchrapara", value: "CTY_KANC_NB1" },
                { label: "Kandhamal", value: "CTY_KAND96" },
                { label: "Kandivali", value: "CTY_KNDVLI" },
                { label: "Kandla", value: "CTY_KAND_NB" },
                { label: "Kandukuru", value: "CTY_KAND_NB1" },
                { label: "Kangan", value: "CTY_KANG_5441" },
                { label: "Kangan", value: "CTY_KANG_NB" },
                { label: "Kangayam", value: "CTY_KANG5324" },
                { label: "Kangeyam", value: "CTY_KANG_NB1" },
                { label: "Kangra", value: "CTY_KANG97" },
                { label: "Kanhangad", value: "CTY_KANH" },
                { label: "Kanina", value: "CTY_KANI_NB" },
                { label: "Kanjhawala", value: "CTY_KJHWLA" },
                { label: "Kanjirapally", value: "CTY_KANJ" },
                { label: "Kankabati", value: "CTY_KANK_NB" },
                { label: "Kankavli", value: "CTY_KANK140" },
                { label: "Kankawli", value: "CTY_KANK_NB1" },
                { label: "Kanker", value: "CTY_KANK98" },
                { label: "Kankroli", value: "CTY_KANK82" },
                { label: "Kannad", value: "CTY_KANN_NB" },
                { label: "Kannauj", value: "CTY_KANN99" },
                { label: "Kannur", value: "CTY_KANN93" },
                { label: "Kanpur", value: "CTY_KANP" },
                { label: "Kanpur Dehat", value: "CTY_KANP11" },
                { label: "Kanpur Nagar", value: "CTY_KANP12" },
                { label: "Kantabanji", value: "CTY_KANT_NB" },
                { label: "Kanugulavalasa ", value: "CTY_Kanu_8504" },
                { label: "Kanwarsika", value: "CTY_KANW_NB" },
                { label: "Kanyakumari", value: "CTY_KANY13" },
                { label: "Kapadvanj", value: "CTY_KAPA5308" },
                { label: "Kapas Hera", value: "CTY_KPSHRA" },
                { label: "Kapasan", value: "CTY_KAPA_NB" },
                { label: "Kapra", value: "CITY_Kapr_8194" },
                { label: "Kapra", value: "CTY_KAPR_NB" },
                { label: "Kaptanganj", value: "CTY_KAPT_NB" },
                { label: "Kapurthala", value: "CTY_KAPU14" },
                { label: "Karad", value: "CTY_KARA141" },
                { label: "Karagounden Palayam", value: "CTY_KARA_NB" },
                { label: "Karahalli ", value: "CTY_Kara_8575" },
                { label: "Karaikal", value: "CTY_KRKL" },
                { label: "Karaikal", value: "CTY_KARA17" },
                { label: "Karaikudi", value: "CTY_KARA92" },
                { label: "Karakulam", value: "CTY_Kara_8868" },
                { label: "Karamadai", value: "CTY_KRMD" },
                { label: "Karanja", value: "CTY_KARA_NB1" },
                { label: "Karanjia", value: "CTY_KARA5285" },
                { label: "Karapakkam", value: "CTY_KARA_NB2" },
                { label: "Karauli", value: "CTY_KARA18" },
                { label: "Karawal Nagar", value: "CTY_KRWLNGR" },
                { label: "Karbi Anglong", value: "CTY_KARB19" },
                { label: "Kardi", value: "CTY_KARD_NB" },
                { label: "Karegaon", value: "CTY_KARE_NB" },
                { label: "Kargil", value: "CTY_KARG20" },
                { label: "Karim Nagar", value: "CITY_Kari_8146" },
                { label: "Karim Nagar", value: "CTY_KARI21" },
                { label: "Karimganj", value: "CTY_KARI22" },
                { label: "Karimnagar", value: "CTY_Kari_7964" },
                { label: "Karimnagar", value: "CITY_Kari_8168" },
                { label: "Karimnagar", value: "CTY_KARI" },
                { label: "Karivali", value: "CTY_KARIVALI" },
                { label: "Kariyalur ", value: "CTY_Kari_8734" },
                { label: "Karjan", value: "CTY_KARJ" },
                { label: "Karjat", value: "CTY_KARJ_NB" },
                { label: "Karkal", value: "CTY_KARK_NB" },
                { label: "Karkala", value: "CTY_KARK" },
                { label: "Karmad ", value: "CTY_Karm_8614" },
                { label: "Karnal", value: "CTY_KARN23" },
                { label: "Kartarpur", value: "CTY_KART" },
                { label: "Karunagapally", value: "CTY_KARU" },
                { label: "Karur", value: "CTY_KARU24" },
                { label: "Karuvannur", value: "CTY_KARU_NB" },
                { label: "Karwar", value: "CTY_KARW" },
                { label: "Karwi Mafi", value: "CTY_KARW_NB" },
                { label: "Kasara", value: "CTY_KASARA" },
                { label: "Kasaragod", value: "CTY_KASA" },
                { label: "Kasaramboli", value: "CTY_KASA_NB" },
                { label: "Kasargatta ", value: "CTY_Kasa_8649" },
                { label: "Kasargod", value: "CTY_KASA25" },
                { label: "Kasba", value: "CTY_KASB" },
                { label: "Kasganj", value: "CTY_KASG" },
                { label: "Kashipur", value: "CTY_KASH" },
                { label: "Kashmir", value: "CTY_KASM" },
                { label: "Kasia", value: "CTY_KASI_NB" },
                { label: "Kasibugga", value: "CITY_Kasi_8173" },
                { label: "Kasibugga", value: "CTY_KASI" },
                { label: "Kasimivalasa ", value: "CTY_Kasi_8505" },
                { label: "Katai", value: "CTY_KATAI" },
                { label: "Katch", value: "CTY_KATC5302" },
                { label: "Katgaon", value: "CTY_KATG_NB" },
                { label: "Kathgarh", value: "CTY_KATH_NB" },
                { label: "Kathlal", value: "CTY_KATH_NB1" },
                { label: "Kathua", value: "CTY_KATH26" },
                { label: "Katihar", value: "CTY_KATI27" },
                { label: "Katkar", value: "CTY_KTKAR" },
                { label: "Katlaheri", value: "CTY_KATL_NB" },
                { label: "Katni", value: "CTY_KATN28" },
                { label: "Katol", value: "CTY_KATO" },
                { label: "Katra", value: "CTY_KATR5409" },
                { label: "Katro", value: "CTY_KATR_NB" },
                { label: "Kattappana", value: "CTY_KATT" },
                { label: "Kattu", value: "CTY_KATT_NB" },
                { label: "Katwa", value: "CTY_KATW_NB" },
                { label: "Katwad", value: "CTY_KATW_NB1" },
                { label: "Kaushambi", value: "CTY_KAUS29" },
                { label: "Kavali", value: "CTY_KAVA" },
                { label: "Kaveripattinam", value: "CTY_Kave_8846" },
                { label: "Kawardha", value: "CTY_KAWA30" },
                { label: "Kayamkulam", value: "CTY_KAYA" },
                { label: "Kechery", value: "CTY_KECH_NB" },
                { label: "Kedarkheda", value: "CTY_KEDA_NB" },
                { label: "Kedarkheda ", value: "CTY_Keda_8615" },
                { label: "Kedma ", value: "CTY_Kedm_8539" },
                { label: "Keeladi ", value: "CTY_Keel_8729" },
                { label: "Keelakarai", value: "CTY_KEEL_NB" },
                { label: "Kekri", value: "CTY_KEKR" },
                { label: "Kelambakkam", value: "CTY_KELA_NB" },
                { label: "Kelomal", value: "CTY_KELO_NB" },
                { label: "Kelzar ", value: "CTY_Kelz_8650" },
                { label: "Kendrapara", value: "CTY_KEND31" },
                { label: "Kendujhar", value: "CTY_KEND32" },
                { label: "Kengari", value: "CTY_KENG" },
                { label: "Kengeri Sattelite Town", value: "CTY_KENG_NB" },
                { label: "Kenjakura", value: "CTY_KENJ_NB" },
                { label: "Keonjhar", value: "CTY_KEON" },
                { label: "Keorar ", value: "CTY_Keor_8769" },
                { label: "Keranga", value: "CTY_KERA_NB" },
                { label: "Kesarpura", value: "CTY_KESA_NB" },
                { label: "Keshavapuram ", value: "CTY_Kesh_8355" },
                { label: "Keshod", value: "CTY_KESH" },
                { label: "Keshpur", value: "CTY_KESH_NB" },
                { label: "Keshrisinghpur", value: "CTY_KESH_NB1" },
                { label: "Kesinga", value: "CTY_KESI_NB" },
                { label: "Kesori", value: "CTY_KESO_NB" },
                { label: "Kewala Kala ", value: "CTY_Kewa_8524" },
                { label: "Kewalpur", value: "CTY_KEWA_NB" },
                { label: "Keysanpalli ", value: "CTY_Keys_8325" },
                { label: "Khabedogran ", value: "CTY_Khab_8674" },
                { label: "Khadani", value: "CTY_KHAD_NB" },
                { label: "Khadauli ", value: "CTY_Khad_8663" },
                { label: "Khadaur Sahib", value: "CTY_KHAD_NB1" },
                { label: "Khadial", value: "CTY_KHAD_NB2" },
                { label: "Khadol", value: "CTY_KHAD_NB3" },
                { label: "Khadsupa", value: "CTY_KHAD_NB4" },
                { label: "Khagaria", value: "CTY_KHAG33" },
                { label: "Khailar", value: "CTY_KHAI_NB" },
                { label: "Khair", value: "CTY_KHAI_NB1" },
                { label: "Khaira Majha ", value: "CTY_Khai_8719" },
                { label: "Khairagarh", value: "CTY_KHAI_NB2" },
                { label: "Khairahniya Beerpur", value: "CTY_KHAI_NB3" },
                { label: "Khairav", value: "CTY_KHAI_NB4" },
                { label: "Khairha", value: "CTY_KHR" },
                { label: "Khairtal", value: "CTY_KHAI_NB5" },
                { label: "Khairthal", value: "CTY_KHAI5397" },
                { label: "Khajoori Khas", value: "CTY_KHJRIKHS" },
                { label: "Khajuri", value: "CTY_KHAJ_NB" },
                { label: "Khalchian", value: "CTY_KHAL_NB" },
                { label: "Khalilabad", value: "CTY_KHAL_NB1" },
                { label: "Khalra", value: "CTY_KHAL_NB2" },
                { label: "Khalumbre", value: "CTY_KHAL_NB3" },
                { label: "Khamano", value: "CTY_KHAM64" },
                { label: "Khamanon", value: "CTY_KHAM_NB" },
                { label: "Khamaria ", value: "CTY_Kham_8770" },
                { label: "Khambhalia", value: "CTY_KHAM_NB1" },
                { label: "Khambhat", value: "CTY_KHAM29" },
                { label: "Khamgaon", value: "CTY_KHAM142" },
                { label: "Khamgaon ", value: "CTY_Kham_8616" },
                { label: "Khamhariya ", value: "CTY_Kham_8540" },
                { label: "Khamla", value: "CTY_KHAM_NB2" },
                { label: "Khammam", value: "CITY_Kham_8152" },
                { label: "Khammam", value: "CTY_KHAM34" },
                { label: "Khanapara", value: "CTY_KHAN_NB" },
                { label: "Khanapur ", value: "CTY_Khan_8326" },
                { label: "Khanauri", value: "CTY_KHAN_NB1" },
                { label: "Khandauli", value: "CTY_KHAN_NB2" },
                { label: "Khandwa", value: "CTY_KHAN35" },
                { label: "Khanna", value: "CTY_KHAN" },
                { label: "Khanoor", value: "CTY_KHAN_NB3" },
                { label: "Khanowal ", value: "CTY_Khan_8720" },
                { label: "Khanpur", value: "CTY_KHAN_NB4" },
                { label: "Kharadaha", value: "CTY_KHAR_NB" },
                { label: "Kharagpur", value: "CTY_KHAR116" },
                { label: "Kharar", value: "CTY_KHAR65" },
                { label: "Kharghar", value: "CTY_KHRGHR" },
                { label: "Khargone", value: "CTY_KHAR36" },
                { label: "Kharkhoda", value: "CTY_KHAR37" },
                { label: "Kharkoda", value: "CTY_KHAR_NB1" },
                { label: "Kharsia", value: "CTY_KHAR17" },
                { label: "Khartuli", value: "CTY_KHAR_NB2" },
                { label: "Khatauli", value: "CTY_KHAT_NB" },
                { label: "Khategaon", value: "CTY_KHAT_NB1" },
                { label: "Khatima", value: "CTY_KTMA" },
                { label: "Khativali", value: "CTY_KHAT_NB2" },
                { label: "Khed", value: "CTY_KHE" },
                { label: "Kheda", value: "CTY_KHED38" },
                { label: "Khedbrahma", value: "CTY_KHED_NB" },
                { label: "Khemkaran", value: "CTY_KHEM_NB" },
                { label: "Khera Khurd", value: "CTY_KHRKHRD" },
                { label: "Khergam", value: "CTY_KHER_NB" },
                { label: "Kheri", value: "CTY_KHER5250" },
                { label: "Kheri", value: "CTY_KHER39" },
                { label: "Kheri Naudh Singh", value: "CTY_KHER_NB1" },
                { label: "Khijrabad", value: "CTY_KHIJ_NB" },
                { label: "Khliehriat", value: "CTY_KHLI_NB" },
                { label: "Khokhariya", value: "CTY_KHOK_NB" },
                { label: "Khoni, Maharashtra", value: "CTY_KHONI" },
                { label: "Khonsa", value: "CTY_KHON" },
                { label: "Khopoli", value: "CTY_KHOP" },
                { label: "Khorda", value: "CTY_KHOR40" },
                { label: "Khordha", value: "CTY_KHOR_NB" },
                { label: "Khorhansa", value: "CTY_KHOR_NB1" },
                { label: "Khowai", value: "CTY_Khow_8869" },
                { label: "Khuian Sarwar", value: "CTY_KHUI_NB" },
                { label: "Khumbhephal ", value: "CTY_Khum_8617" },
                { label: "Khunti", value: "CTY_KHUN5282" },
                { label: "Khurai", value: "CTY_KHUR128" },
                { label: "Khurda", value: "CTY_KHUR157" },
                { label: "Khurja", value: "CTY_KHUR105" },
                { label: "Khutar", value: "CTY_KHUT_NB" },
                { label: "Khutehna", value: "CTY_KHUT_NB1" },
                { label: "Kille Machindra Gad", value: "CTY_KILL_NB" },
                { label: "Killon Wali", value: "CTY_KILL_NB1" },
                { label: "Kim", value: "CTY_KIM" },
                { label: "Kinnaur", value: "CTY_KINN41" },
                { label: "Kinnigoli", value: "CTY_KINN_NB" },
                { label: "Kiphire", value: "CTY_KIPH42" },
                { label: "Kiramcha ", value: "CTY_Kira_8771" },
                { label: "Kiraoli", value: "CTY_KIRA_NB" },
                { label: "Kirari Suleman Nagar", value: "CTY_KIR_SLMNGR" },
                { label: "Kiratpur Sahib", value: "CTY_KIRA_NB1" },
                { label: "Kishan Garh", value: "CTY_KISH_NB" },
                { label: "Kishan Pura", value: "CTY_KISH_NB1" },
                { label: "Kishanganj", value: "CTY_KISH43" },
                { label: "Kishangarh", value: "CTY_KISH_NB3" },
                { label: "Kishangarh Renwal", value: "CTY_KISH_NB2" },
                { label: "Kishingarh", value: "CTY_KISH" },
                { label: "Kishtwar", value: "CTY_KISH_NB4" },
                { label: "Kithana", value: "CTY_KITH_NB" },
                { label: "Kitumula ", value: "CTY_Kitu_8475" },
                { label: "Kochi", value: "CTY_KOCH" },
                { label: "Kodad", value: "CITY_Koda_8195" },
                { label: "Kodad", value: "CTY_KODA_NB" },
                { label: "Kodada", value: "CITY_Koda_8163" },
                { label: "Kodada", value: "CTY_KODA" },
                { label: "Kodagu", value: "CTY_KODA44" },
                { label: "Kodaikanal", value: "CTY_KODA5307" },
                { label: "Kodakara", value: "CTY_KODA_NB1" },
                { label: "Koderma", value: "CTY_KODE45" },
                { label: "Kodigehalli ", value: "CTY_Kodi_8571" },
                { label: "Kodikurichi", value: "CTY_KODI_NB" },
                { label: "Kodinar", value: "CTY_KODI_NB1" },
                { label: "Kodumathi ", value: "CTY_Kodu_8735" },
                { label: "Kodungallur", value: "CTY_KODU" },
                { label: "Kodur", value: "CTY_KODU_NB" },
                { label: "Koduvayoor", value: "CTY_KODU_NB1" },
                { label: "Kohali", value: "CTY_KOHA_NB" },
                { label: "Kohara", value: "CTY_KOHA_NB1" },
                { label: "Kohima", value: "CTY_KOHI46" },
                { label: "Koira ", value: "CTY_Koir_8565" },
                { label: "Koira ", value: "CTY_Koir_8576" },
                { label: "Kokrajhar", value: "CTY_KOKR47" },
                { label: "Kolar", value: "CTY_KOLA48" },
                { label: "Kolasib", value: "CTY_KOLA5276" },
                { label: "Kolat", value: "CTY_KOLA_NB" },
                { label: "Kolenchery", value: "CTY_KOLE_NB" },
                { label: "Kolhapur", value: "CTY_KOLH49" },
                { label: "Kolkata", value: "CTY_KOLK50" },
                { label: "Kollam", value: "CTY_KOLL51" },
                { label: "Kollapur ", value: "CTY_Koll_8386" },
                { label: "Kolpak ", value: "CTY_Kolp_8327" },
                { label: "Komaripalem", value: "CTY_KOMA_NB" },
                { label: "Kommaghatta", value: "CTY_KOMM_NB" },
                { label: "Kompalle", value: "CITY_Komp_8196" },
                { label: "Kompalle", value: "CTY_KOMP_NB" },
                { label: "Kon", value: "CTY_KON" },
                { label: "Konda GOAn", value: "CTY_KOND_NB" },
                { label: "Konda Goan", value: "CTY_KOND" },
                { label: "Kondagattu", value: "CITY_Kond_8197" },
                { label: "Kondagattu", value: "CTY_KOND_NB1" },
                { label: "Kondapur", value: "CITY_Kond_8198" },
                { label: "Kondapur", value: "CTY_KOND_NB2" },
                { label: "Kondli", value: "CTY_KONDLI" },
                { label: "Kondotty", value: "CTY_KOND_NB3" },
                { label: "Konnagar", value: "CTY_KONN_NB" },
                { label: "Konthagai North ", value: "CTY_Kont_8730" },
                { label: "Koop ", value: "CTY_Koop_8772" },
                { label: "Koothanallur", value: "CTY_KOOT_NB" },
                { label: "Koothattukulam", value: "CTY_KOOT" },
                { label: "Koothuparamba", value: "CTY_KOOT_NB1" },
                { label: "Koovappady", value: "CTY_Koova_8877" },
                { label: "Kopar Khairane", value: "CTY_KPRKHRN" },
                { label: "Kopergaon", value: "CTY_KOPE_NB" },
                { label: "Koppa", value: "CTY_KOPP" },
                { label: "Koppal", value: "CTY_KOPP52" },
                { label: "Kopri", value: "CTY_KOPRI" },
                { label: "Korag ", value: "CTY_Kora_8556" },
                { label: "Koraput", value: "CTY_KORA53" },
                { label: "Koratla", value: "CITY_Kora_8199" },
                { label: "Koratla", value: "CTY_KORA_NB" },
                { label: "Korattur", value: "CTY_KORA_NB1" },
                { label: "Koratty", value: "CTY_KORA_NB2" },
                { label: "Korba", value: "CTY_KORB54" },
                { label: "Korbi ", value: "CTY_Korb_8529" },
                { label: "Koregaon", value: "CTY_KORE_NB" },
                { label: "Koriya", value: "CTY_KORI55" },
                { label: "Korlakota ", value: "CTY_Korl_8506" },
                { label: "Kosamba", value: "CTY_KOSA5300" },
                { label: "Kosikalan", value: "CTY_KOSI_NB" },
                { label: "Kosli", value: "CTY_KOSL_NB" },
                { label: "Kot Budha", value: "CTY_KOTB_NB" },
                { label: "Kot Isa Khan", value: "CTY_KOTI_NB" },
                { label: "Kot Ise Khan", value: "CTY_KOT" },
                { label: "Kota", value: "CTY_KOTA56" },
                { label: "Kotdwar", value: "CTY_KOTD_NB" },
                { label: "Kotdwara", value: "CTY_KOTD" },
                { label: "Koteshwar", value: "CTY_KOTE" },
                { label: "KotFatuhi", value: "CTY_KOTF_NB" },
                { label: "Kotfatuhi", value: "CTY_KOTF" },
                { label: "Kothagdi ", value: "CTY_Koth_8411" },
                { label: "Kothagudem", value: "CITY_Koth_8174" },
                { label: "Kothagudem", value: "CTY_KOTH" },
                { label: "Kothamangalam", value: "CTY_KOTH5346" },
                { label: "Kothapalle", value: "CITY_Koth_8200" },
                { label: "Kothapalle", value: "CTY_KOTH_NB" },
                { label: "Kothareddial ", value: "CTY_Koth_8387" },
                { label: "Kothuru ", value: "CTY_Koth_8490" },
                { label: "Kotkapura", value: "CTY_KOTK" },
                { label: "Kotkhai", value: "CTY_KOTK_NB" },
                { label: "Kotla ", value: "CTY_Kotl_8664" },
                { label: "Kotma", value: "CTY_KOTM" },
                { label: "Kotputli", value: "CTY_KOTP" },
                { label: "Kotra", value: "CTY_KOTR_NB" },
                { label: "Kotshamir", value: "CTY_KOTS_NB" },
                { label: "Kottakal", value: "CTY_KOTT_NB" },
                { label: "Kottakkal", value: "CTY_KOTT5402" },
                { label: "Kottapudur ", value: "CTY_Kott_8736" },
                { label: "Kottarakara", value: "CTY_KOTT" },
                { label: "Kottarakkara", value: "CTY_KOTT_NB1" },
                { label: "Kottayam", value: "CTY_KOTT57" },
                { label: "Kovilpatti", value: "CTY_KOVI5422" },
                { label: "Kovur", value: "CTY_KOVU_NB" },
                { label: "Kovvur", value: "CTY_KOVV_NB" },
                { label: "Koyali", value: "CTY_KOYA_NB" },
                { label: "Koyilandy", value: "CTY_KOYI_NB" },
                { label: "Kozhencherry", value: "CTY_KOZH" },
                { label: "Kozhenchery", value: "CTY_KOZH_NB" },
                { label: "Kozhikode", value: "CTY_KOZH58" },
                { label: "Krishna", value: "CTY_KRIS59" },
                { label: "Krishna Bazar(Narsipatnam) ", value: "CTY_Kris_8476" },
                { label: "Krishnagar", value: "CTY_KRIS" },
                { label: "Krishnagiri", value: "CTY_KRIS60" },
                { label: "Krishnajivadi ", value: "CTY_Kris_8328" },
                { label: "Kuba", value: "CTY_KUBA_NB" },
                { label: "Kuchaman City", value: "CTY_KUCH_NB" },
                { label: "Kuchinda", value: "CTY_KUCH_NB1" },
                { label: "Kudal", value: "CTY_KUDA" },
                { label: "Kudamaloor", value: "CTY_KUDA_NB" },
                { label: "Kudi Khera", value: "CTY_KUDI_NB" },
                { label: "Kudu", value: "CTY_KUDU_NB" },
                { label: "Kudumuluguma", value: "CTY_KUDU_NB1" },
                { label: "Kuiani ", value: "CTY_Kuia_8566" },
                { label: "Kujang", value: "CTY_KUJA_NB" },
                { label: "Kukar Pind", value: "CTY_KUKA_NB" },
                { label: "Kukarwada", value: "CTY_KUKA_NB1" },
                { label: "Kukatpally", value: "CITY_Kuka_8201" },
                { label: "Kukatpally", value: "CTY_KUKA_NB2" },
                { label: "Kukshi", value: "CTY_KUKS" },
                { label: "Kulathumuzhy", value: "CTY_KULA_NB" },
                { label: "Kulgam", value: "CTY_KULG5260" },
                { label: "Kulithalai", value: "CTY_KULI_NB" },
                { label: "Kullu", value: "CTY_KULL61" },
                { label: "Kum Kalan", value: "CTY_KUMK_NB" },
                { label: "Kumbakonam", value: "CTY_KUMB" },
                { label: "Kumta", value: "CTY_KUMT" },
                { label: "Kunda", value: "CTY_KUND106" },
                { label: "Kundapur", value: "CTY_KUND_NB" },
                { label: "Kundapura", value: "CTY_KUND53" },
                { label: "Kundli", value: "CTY_KUND_NB1" },
                { label: "Kunnamkulam", value: "CTY_KUNN" },
                { label: "Kupwara", value: "CTY_KUPW62" },
                { label: "Kuradiha", value: "CTY_KURA_NB" },
                { label: "Kurali", value: "CTY_KURA_NB1" },
                { label: "Kurichithanam", value: "CTY_KURI_NB" },
                { label: "Kurmapuram ", value: "CTY_Kurm_8491" },
                { label: "Kurnool", value: "CTY_KURN63" },
                { label: "Kurud", value: "CTY_KURU_NB" },
                { label: "Kurukshetra", value: "CTY_KURU64" },
                { label: "Kurung Kumey", value: "CTY_KURU65" },
                { label: "Kusarlapudi ", value: "CTY_Kusa_8477" },
                { label: "Kushinagar", value: "CTY_KUSH66" },
                { label: "Kutch", value: "CTY_KUTC5298" },
                { label: "Kutchery Chowk", value: "CTY_KUTC" },
                { label: "Kutchh", value: "CTY_KUTC5337" },
                { label: "Kuwait", value: "CTY_KUWA25" },
                { label: "Lachapet ", value: "CTY_Lach_8329" },
                { label: "Ladnun", value: "CTY_LDN" },
                { label: "Ladsawangi ", value: "CTY_Lads_8618" },
                { label: "Ladwa", value: "CTY_LADW_5442" },
                { label: "Lahul & Spiti", value: "CTY_LAHU67" },
                { label: "Lakhan Majra", value: "CTY_LAKH_NB" },
                { label: "Lakhimpur", value: "CTY_LAKH" },
                { label: "Lakhimpur", value: "CTY_LAKH68" },
                { label: "Lakhimpur kheri", value: "CTY_LKPR" },
                { label: "Lakhisarai", value: "CTY_LAKH69" },
                { label: "Lakhpat", value: "CTY_LKH" },
                { label: "Lakshadweep", value: "CTY_LAKS70" },
                { label: "Lal Kalan", value: "CTY_LALK_NB" },
                { label: "Lalganj", value: "CTY_LALG_NB" },
                { label: "Lalganj Katghar", value: "CTY_LALG" },
                { label: "Lalganj-Katghar", value: "CTY_LALG_NB1" },
                { label: "Lalgudi", value: "CTY_LALG5332" },
                { label: "Lalitpur", value: "CTY_LALI71" },
                { label: "Lalpur", value: "CTY_LALP_NB" },
                { label: "Lalru", value: "CTY_LALR_NB" },
                { label: "Lalsot", value: "CTY_LALS5381" },
                { label: "Lambi", value: "CTY_LAMB_NB" },
                { label: "Lambra", value: "CTY_LAMB_NB1" },
                { label: "Lammasingi ", value: "CTY_Lamm_8478" },
                { label: "Lande Ke ", value: "CTY_Land_8700" },
                { label: "Landran", value: "CTY_LAND" },
                { label: "Landranÿ", value: "CTY_LAND_NB" },
                { label: "Laojora ", value: "CTY_Laoj_8567" },
                { label: "Lapkaman", value: "CTY_LAPK_NB" },
                { label: "Lasalgaon", value: "CTY_LASA_NB" },
                { label: "Latehar", value: "CTY_LATE72" },
                { label: "Latur", value: "CTY_LATU73" },
                { label: "Lawngtlai", value: "CTY_LAWN5263" },
                { label: "Laxittipet", value: "CITY_Laxi_8202" },
                { label: "Laxittipet", value: "CTY_LAXI_NB" },
                { label: "Laxmangarh", value: "CTY_LXM" },
                { label: "Laxmangarh ", value: "CTY_Laxm_8541" },
                { label: "Laxmipur ", value: "CTY_Laxm_8388" },
                { label: "Leh", value: "CTY_LEH74" },
                { label: "Lehal", value: "CTY_LEHA_NB" },
                { label: "Lehragaga", value: "CTY_LEHR_NB" },
                { label: "Libas Pur", value: "CTY_LBSPUR" },
                { label: "Limbdi", value: "CTY_LIMB5331" },
                { label: "Lingapur ", value: "CTY_Ling_8330" },
                { label: "llkal", value: "CTY_LLK" },
                { label: "Loha ", value: "CTY_Loha_8773" },
                { label: "Lohardaga", value: "CTY_LOHA75" },
                { label: "Lohardih", value: "CTY_LOHA_NB" },
                { label: "Loharka", value: "CTY_LOHA_NB1" },
                { label: "Loharsi", value: "CTY_LOHA_NB2" },
                { label: "Loharu", value: "CTY_LOHA_NB3" },
                { label: "Lohian Khas", value: "CTY_LOHI" },
                { label: "Lohian khas", value: "CTY_LOHI_NB" },
                { label: "Lohit", value: "CTY_LOHI76" },
                { label: "Lohta", value: "CTY_LOHT_NB" },
                { label: "Loisinga", value: "CTY_LOIS_NB" },
                { label: "Lokamaleswaram", value: "CTY_LOKA_NB" },
                { label: "Lonand", value: "CTY_LONA_NB" },
                { label: "Lonavala", value: "CTY_LONA" },
                { label: "Longaon ", value: "CTY_Long_8619" },
                { label: "Longleng", value: "CTY_LONG77" },
                { label: "Longowal", value: "CTY_LONG" },
                { label: "Loni", value: "CTY_LONI_NB" },
                { label: "Loni", value: "CTY_LONI" },
                { label: "Lopoke", value: "CTY_LOPO_NB" },
                { label: "Lower Dibang Valley", value: "CTY_LOWE78" },
                { label: "Lower Subansiri", value: "CTY_LOWE79" },
                { label: "Lucknow", value: "CTY_LUCK80" },
                { label: "Ludhiana", value: "CTY_LUDH81" },
                { label: "Lukhi", value: "CTY_LUKH_NB" },
                { label: "Lunawada", value: "CTY_LUNA_NB" },
                { label: "Lunglei", value: "CTY_LUNG5262" },
                { label: "M K Patnam ", value: "CTY_MKPa_8479" },
                { label: "Machalipatnam", value: "CTY_MACH167" },
                { label: "Machareddy ", value: "CTY_Mach_8331" },
                { label: "Macherla", value: "CTY_MACH_NB" },
                { label: "Macherla ", value: "CTY_Mach_8332" },
                { label: "Machhiwara", value: "CTY_MACH66" },
                { label: "Machilipatnam", value: "CTY_MACH_NB1" },
                { label: "Madai ", value: "CTY_Mada_8530" },
                { label: "Madambakkam", value: "CTY_MADA_NB" },
                { label: "Madanapalle", value: "CTY_MADA_NB1" },
                { label: "Madanapalli", value: "CTY_MADA" },
                { label: "Madanapalli ", value: "CTY_Mada_8412" },
                { label: "Madanpur ", value: "CTY_Mada_8531" },
                { label: "Madaria", value: "CTY_MDR" },
                { label: "Madhapar", value: "CTY_MADH_NB" },
                { label: "Madhapur ", value: "CTY_Madh_8302" },
                { label: "Madhavpur ", value: "CTY_Madh_8568" },
                { label: "Madhepura", value: "CTY_MADH82" },
                { label: "Madhi", value: "CTY_MADH_NB1" },
                { label: "Madhopur", value: "CTY_MADH_NB2" },
                { label: "Madhubani", value: "CTY_MADH83" },
                { label: "Madhupur", value: "CTY_MADH_NB3" },
                { label: "Madhur ", value: "CTY_Madh_8737" },
                { label: "Madhyamgram", value: "CTY_MADH_NB4" },
                { label: "Madikeri", value: "CTY_MADI_NB" },
                { label: "Madippakkam", value: "CTY_MADI_NB1" },
                { label: "Madkeri", value: "CTY_MADK" },
                { label: "Madlauda", value: "CTY_MADL84" },
                { label: "Madloda", value: "CTY_MADL85" },
                { label: "Madurai", value: "CTY_MADU86" },
                { label: "Magam", value: "CTY_MAGA_NB" },
                { label: "Magdi ", value: "CTY_Magd_8333" },
                { label: "Mahabub Nagar", value: "CITY_Maha_8153" },
                { label: "Mahabub Nagar", value: "CTY_MAHA87" },
                { label: "Mahabubabad", value: "CITY_Maha_8203" },
                { label: "Mahabubabad", value: "CTY_MAHA_NB" },
                { label: "Mahabubnagar", value: "CITY_Maha_8158" },
                { label: "Mahabubnagar", value: "CTY_MAHA168" },
                { label: "Mahad", value: "CTY_MAHA143" },
                { label: "Mahagai ", value: "CTY_Maha_8542" },
                { label: "Mahal Kalan", value: "CTY_MAHA_NB1" },
                { label: "Maham", value: "CTY_MAHA_NB2" },
                { label: "Mahamaya Nagar", value: "CTY_MAHA_5451" },
                { label: "Mahammadpur", value: "CTY_MAHA_NB3" },
                { label: "Maharajganj", value: "CTY_MAHA88" },
                { label: "Maharajganj", value: "CTY_Maha_7543" },
                { label: "Maharajganj", value: "CTY_MAHA99" },
                { label: "Mahasamund", value: "CTY_MAHA89" },
                { label: "Mahatpur", value: "CTY_MAHA_NB4" },
                { label: "Mahe", value: "CTY_MAHE5272" },
                { label: "Mahe", value: "CTY_MAHE90" },
                { label: "Mahendargarh", value: "CTY_MAHE_NB" },
                { label: "Mahendraganj", value: "CTY_Mahe_7969" },
                { label: "Mahendraganj", value: "CTY_MAHNGANJ" },
                { label: "Mahendragarh", value: "CTY_MAHE91" },
                { label: "Mahendranagar", value: "CTY_MAHE_NB1" },
                { label: "Maheru", value: "CTY_MAHE_NB2" },
                { label: "Mahesana", value: "CTY_MAHE92" },
                { label: "Mahetpur", value: "CTY_Mahe_8847" },
                { label: "Mahidpur", value: "CTY_MAHI_NB" },
                { label: "Mahilpur", value: "CTY_MAHI_NB1" },
                { label: "Mahisagar", value: "CTY_MAHI_8" },
                { label: "Mahiyawali", value: "CTY_MAHI_NB2" },
                { label: "Mahoba", value: "CTY_MAHO93" },
                { label: "Mahuva", value: "CTY_MAHU" },
                { label: "Maihar", value: "CTY_MAIH_NB" },
                { label: "Mainpuri", value: "CTY_MAIN97" },
                { label: "Mairang", value: "CTY_MAIR_NB" },
                { label: "Maisammagudem ", value: "CTY_Mais_8451" },
                { label: "Majalgaon", value: "CTY_MAJA_NB" },
                { label: "Majari", value: "CTY_MAJA_NB1" },
                { label: "Majitha", value: "CTY_MAJI" },
                { label: "Majorda", value: "CTY_MAJO_NB" },
                { label: "Majra", value: "CTY_MAJR_NB" },
                { label: "Majri", value: "CTY_MAJR_NB1" },
                { label: "Majwind", value: "CTY_MAJW_NB" },
                { label: "Makhu", value: "CTY_MAKH_NB" },
                { label: "Makrana", value: "CTY_MAKR" },
                { label: "Mala", value: "CTY_MALA_NB" },
                { label: "Malakpur", value: "CTY_MALA_NB1" },
                { label: "Malappuram", value: "CTY_MALA94" },
                { label: "Malapuram", value: "CTY_MALA_NB2" },
                { label: "Malda", value: "CTY_MALD95" },
                { label: "Malegaon", value: "CTY_MALE144" },
                { label: "Malerkotla", value: "CTY_MALE67" },
                { label: "Malgaon", value: "CTY_MALG_NB" },
                { label: "Malibu Towne", value: "CTY_MLBTWN" },
                { label: "Maliyasan", value: "CTY_MALI_NB" },
                { label: "Malkangiri", value: "CTY_MALK96" },
                { label: "Malkapur", value: "CTY_MALK5357" },
                { label: "Malkhed", value: "CTY_MALK_NB" },
                { label: "Mallanwala", value: "CTY_MALL_NB" },
                { label: "Mallekan", value: "CTY_MALL_NB1" },
                { label: "Mallial ", value: "CTY_Mall_8389" },
                { label: "Maloud", value: "CTY_MALO_NB" },
                { label: "Malout", value: "CTY_MALO" },
                { label: "Malpur", value: "CTY_MLP030" },
                { label: "Malpura", value: "CTY_MALP_NB" },
                { label: "Malru", value: "CTY_MALR_NB" },
                { label: "Malsian", value: "CTY_MALS_NB" },
                { label: "Maluka", value: "CTY_MALU_NB" },
                { label: "Malur", value: "CTY_MALU_NB1" },
                { label: "Malvan", value: "CTY_MALV" },
                { label: "Malwardevrampalli ", value: "CTY_Malw_8413" },
                { label: "Mamdot Uttar", value: "CTY_MAMD_NB" },
                { label: "Mamidipalli ", value: "CTY_Mami_8334" },
                { label: "Mammit", value: "CTY_MAMM5277" },
                { label: "Manakkody", value: "CTY_MANA_NB" },
                { label: "Manali", value: "CTY_MANA40" },
                { label: "Mananthavady", value: "CTY_MANA117" },
                { label: "Manargudi", value: "CTY_MNRG" },
                { label: "Manauli", value: "CTY_MANA_NB1" },
                { label: "Manauri Bazar", value: "CTY_MANA_NB2" },
                { label: "Manavadar", value: "CTY_MANA_5438" },
                { label: "Manavadar", value: "CTY_MANA_NB3" },
                { label: "Manawala", value: "CTY_MANA_NB4" },
                { label: "Mancherial", value: "CITY_Manc_8156" },
                { label: "Mancherial", value: "CTY_MANC" },
                { label: "Mand", value: "CTY_MAND_NB" },
                { label: "Mandai Bujurg", value: "CTY_MAND_NB1" },
                { label: "Mandai Khurd", value: "CTY_MAND_NB2" },
                { label: "Mandapeta", value: "CTY_MAND169" },
                { label: "Mandi", value: "CTY_MAND" },
                { label: "Mandi", value: "CTY_MAND97" },
                { label: "Mandi Dabwali", value: "CTY_MAND37" },
                { label: "Mandi Gobindgarh", value: "CTY_MAND68" },
                { label: "Mandideep", value: "CTY_MAND5310" },
                { label: "Mandla", value: "CTY_MAND98" },
                { label: "Mandsaur", value: "CTY_MAND99" },
                { label: "Mandvi", value: "CTY_MAND30" },
                { label: "Mandya", value: "4225" },
                { label: "Manegaon ", value: "CTY_Mane_8620" },
                { label: "Manendragarh", value: "CTY_MANE" },
                { label: "Manesar", value: "CTY_MANE_NB" },
                { label: "Mangalagiri", value: "CTY_MANG_NB" },
                { label: "Mangaldoi", value: "CTY_MANG13" },
                { label: "Mangalore", value: "CTY_MANG55" },
                { label: "Mangalore Urban", value: "CTY_MANG" },
                { label: "Mangalore-Urban", value: "CTY_MANG_NB1" },
                { label: "Mangaon", value: "CTY_MANG_NB2" },
                { label: "Mangarh", value: "CTY_MANG_NB3" },
                { label: "Manglia", value: "CTY_MANG_NB4" },
                { label: "Mangrol", value: "CTY_MANG_NB5" },
                { label: "Mangrol", value: "CTY_MANG_5437" },
                { label: "Manigilla ", value: "CTY_Mani_8372" },
                { label: "Maninagar", value: "CTY_MNG" },
                { label: "Manipal", value: "CTY_MANI" },
                { label: "Maniyampalayam ", value: "CTY_Mani_8738" },
                { label: "Manjalpur", value: "CTY_MANJL" },
                { label: "Manjari", value: "CTY_MANJ_NB" },
                { label: "Manjeri", value: "CTY_MANJ" },
                { label: "Manjusar", value: "CTY_MANJ_NB1" },
                { label: "mankachar", value: "CTY_Mank_7968" },
                { label: "mankachar", value: "CTY_MANCHR" },
                { label: "Mankapur", value: "CTY_MANK_NB" },
                { label: "Mankara ", value: "CTY_Mank_8774" },
                { label: "Manko", value: "CTY_MANK_NB1" },
                { label: "Manmad", value: "CTY_MANM" },
                { label: "Mannarkkad", value: "CTY_MANN_NB" },
                { label: "Manneguda ", value: "CTY_Mann_8414" },
                { label: "Manochahal ", value: "CTY_Mano_8675" },
                { label: "Manor", value: "CTY_MANOR" },
                { label: "Manpur", value: "CTY_MANP_NB" },
                { label: "Mansa", value: "CTY_MANS" },
                { label: "Mansa", value: "CTY_MANS11" },
                { label: "Mansoorwal ", value: "CTY_Mans_8721" },
                { label: "Manthani", value: "CITY_Mant_8204" },
                { label: "Manthani", value: "CTY_MANT_NB" },
                { label: "Manthani ", value: "CTY_Mant_8335" },
                { label: "Mapusa", value: "CTY_MAPU" },
                { label: "Maraimalai", value: "CTY_MARA_NB" },
                { label: "Marathalli", value: "CTY_MARA" },
                { label: "Marcel", value: "CTY_MARC_NB" },
                { label: "Marcela", value: "CTY_MARC_NB1" },
                { label: "Mardanpur", value: "CTY_MARD_NB" },
                { label: "Margao", value: "CTY_MARG" },
                { label: "MarGOA", value: "CTY_MARG_NB" },
                { label: "Margoa", value: "CTY_MARGOA" },
                { label: "Marigadi ", value: "CTY_Mari_8288" },
                { label: "Marigaon", value: "CTY_MARI12" },
                { label: "Markapur", value: "CTY_MARK_NB" },
                { label: "Marlagudem ", value: "CTY_Marl_8452" },
                { label: "Marol", value: "CTY_MAR" },
                { label: "Maroli", value: "CTY_MARO_NB" },
                { label: "Marthandam", value: "CTY_MART5335" },
                { label: "Maryal ", value: "CTY_Mary_8303" },
                { label: "Maslandapur", value: "CTY_MSLNDPR" },
                { label: "Maslandapur", value: "CTY_Masl_7977" },
                { label: "Masor Khambh ", value: "CTY_Maso_8755" },
                { label: "Matanhail", value: "CTY_MATA_NB" },
                { label: "Mathura", value: "CTY_MATH13" },
                { label: "Matigara", value: "CTY_MATI_NB" },
                { label: "Matoor ", value: "CTY_Mato_8356" },
                { label: "Matra", value: "CTY_MATR" },
                { label: "Mattan", value: "CTY_MTN" },
                { label: "Mattannur", value: "CTY_MATT_NB" },
                { label: "Mattanur", value: "CTY_MATT" },
                { label: "Mattarakkal ", value: "CTY_Matt_8592" },
                { label: "Matwada ", value: "CTY_Matw_8390" },
                { label: "Mau", value: "CTY_MAU14" },
                { label: "Maudaha", value: "CTY_MAUD_NB" },
                { label: "Maujpuri ", value: "CTY_Mauj_8621" },
                { label: "Maunath Bhanjan", value: "CTY_MAUN_NB" },
                { label: "Maur", value: "CTY_MAUR_NB" },
                { label: "Maur Mandi", value: "CTY_MAUR69" },
                { label: "Mauranipur", value: "CTY_MAUR107" },
                { label: "Maurmandi", value: "CTY_MAUR_NB1" },
                { label: "Mavelikara", value: "CTY_MAVE" },
                { label: "Mavelikkara", value: "CTY_MAVE_NB" },
                { label: "Mawana", value: "CTY_MAWA_NB" },
                { label: "Mayapuri", value: "CTY_MAYPUR" },
                { label: "Mayiladuthurai", value: "CTY_MAYI" },
                { label: "Mayiladuthurai Town", value: "CTY_MAYI_NB" },
                { label: "Mayurbhanj", value: "CTY_MAYU15" },
                { label: "Medak", value: "CITY_Meda_8150" },
                { label: "Medak", value: "CTY_MEDA16" },
                { label: "Medchal", value: "CITY_Medc_8170" },
                { label: "Medchal", value: "CTY_MEDC" },
                { label: "Medinipur", value: "CTY_MEDI5274" },
                { label: "Medipally ", value: "CTY_Medi_8304" },
                { label: "Meerut", value: "CTY_MEER17" },
                { label: "Megh Chappar", value: "CTY_MEGH_NB" },
                { label: "Meghnagar", value: "CTY_MGN" },
                { label: "Meham", value: "CTY_MEHA99" },
                { label: "Mehatpur", value: "CTY_MEHA5383" },
                { label: "Mehbubnagar", value: "CITY_Mehb_8205" },
                { label: "Mehbubnagar", value: "CTY_MEHB_NB" },
                { label: "Mehdoodan", value: "CTY_MEHD_NB" },
                { label: "Meheli", value: "CTY_MEHE_NB" },
                { label: "Mehma Singhwala", value: "CTY_MEHM_NB" },
                { label: "Mehsana", value: "CTY_MEHS5389" },
                { label: "Mehta", value: "CTY_MEHT_NB" },
                { label: "Memadpur", value: "CTY_MEMA_NB" },
                { label: "Memari", value: "CTY_MEMA_NB1" },
                { label: "Memaura", value: "CTY_MEMA_NB2" },
                { label: "Memnagar", value: "CTY_MEMN_NB" },
                { label: "Mendhipathar", value: "CTY_MENDPTHR" },
                { label: "Mendhipathar", value: "CTY_Mend_7970" },
                { label: "Mendra Kalan", value: "CTY_MEND_NB" },
                { label: "Meramandali", value: "CTY_MERA_NB" },
                { label: "Merta City", value: "CTY_MERT5433" },
                { label: "Metoda", value: "CTY_METO_NB" },
                { label: "Metpalle", value: "CITY_Metp_8206" },
                { label: "Metpalle", value: "CTY_METP_NB" },
                { label: "Mettupalayam", value: "CTY_METT" },
                { label: "Mettur", value: "CTY_METT_NB" },
                { label: "Mettur Dam", value: "CTY_METT5370" },
                { label: "Mewat", value: "CTY_MWT" },
                { label: "Mhow", value: "CTY_MHOW" },
                { label: "Miani Khas", value: "CTY_MIAN_NB" },
                { label: "Midnapore", value: "CTY_MIDN" },
                { label: "Milak ", value: "CTY_Mila_8775" },
                { label: "Milkipur", value: "CTY_MILK_NB" },
                { label: "Mira Road", value: "CTY_MIRA5305" },
                { label: "Mira-Bhayandar", value: "CTY_MRBHYNDR" },
                { label: "Miraj", value: "CTY_MIRA5390" },
                { label: "Miran Kot Kalan", value: "CTY_MIRA_NB" },
                { label: "Mirdhapalli ", value: "CTY_Mird_8336" },
                { label: "Mirganj", value: "CTY_MIRG_NB" },
                { label: "Mirpur Turk", value: "CTY_MRPRTRK" },
                { label: "Miryalguda", value: "CITY_Miry_8159" },
                { label: "Miryalguda", value: "CTY_MIRY" },
                { label: "Mirzapur", value: "CTY_MIRZ18" },
                { label: "Mithapur", value: "CTY_MITH" },
                { label: "Mithe Pur", value: "CTY_MTHPUR" },
                { label: "Mithi Rohar", value: "CTY_MITH_NB" },
                { label: "Modasa", value: "CTY_MODA" },
                { label: "Modinagar", value: "CTY_MODI5429" },
                { label: "Moga", value: "CTY_MOGA19" },
                { label: "Moga Kty ", value: "CTY_Moga_8701" },
                { label: "Mogra Badshahpur", value: "CTY_MOGR_NB" },
                { label: "Mohali", value: "CTY_MOHA15" },
                { label: "Mohali", value: "CTY_MOHA" },
                { label: "Mohali Sas Nagar", value: "CTY_MOHA_NB" },
                { label: "Mohammadabad", value: "CTY_MOHA_NB1" },
                { label: "Mohammadi", value: "CTY_MOHA_NB2" },
                { label: "Mohan ", value: "CTY_Moha_8782" },
                { label: "Mohania", value: "CTY_MOHA_NB3" },
                { label: "Mohanlalganj", value: "CTY_MOHA_NB4" },
                { label: "Mohkhed ", value: "CTY_Mohk_8596" },
                { label: "Mohone", value: "CTY_MOHONE" },
                { label: "Mokama", value: "CTY_Moka_7542" },
                { label: "Mokama", value: "CTY_MOKA13" },
                { label: "Mokameh", value: "CTY_MOKA_NB" },
                { label: "Mokandpur", value: "CTY_MOKA_NB1" },
                { label: "Mokokchung", value: "CTY_MOKO16" },
                { label: "Molar Band", value: "CTY_MLRBAND" },
                { label: "Molkacharla ", value: "CTY_Molk_8357" },
                { label: "Mon", value: "CTY_MON17" },
                { label: "Moom", value: "CTY_MOOM_NB" },
                { label: "Moonak", value: "CTY_MOON_NB" },
                { label: "Moothakunnam", value: "CTY_MOOT5421" },
                { label: "Moradabad", value: "CTY_MORA18" },
                { label: "Moran", value: "CTY_MORA_NB" },
                { label: "Moranwali", value: "CTY_MORA_NB1" },
                { label: "Moranwali ", value: "CTY_Mora_8702" },
                { label: "Morbi", value: "CTY_MORB" },
                { label: "Morena", value: "CTY_MORE19" },
                { label: "Morga ", value: "CTY_Morg_8532" },
                { label: "Morinda", value: "CTY_MORI" },
                { label: "MormuGOA", value: "CTY_MORM_NB" },
                { label: "Mormugoa", value: "CTY_MORM" },
                { label: "Morvi", value: "CTY_MORV_NB" },
                { label: "Mota", value: "CTY_MOTA_NB1" },
                { label: "Mota Mava", value: "CTY_MOTA_NB" },
                { label: "Motala", value: "CTY_MOTA_NB2" },
                { label: "Motatimbla", value: "CTY_Mota_7988" },
                { label: "Motatimbla", value: "CTY_MTTBLA" },
                { label: "Motebennur ", value: "CTY_Mote_8583" },
                { label: "Moteeram Adda", value: "CTY_MOTE_NB" },
                { label: "Motihari", value: "CTY_MOTI" },
                { label: "Mubarakpur", value: "CTY_MUBA_NB" },
                { label: "Muchimilli ", value: "CTY_Much_8492" },
                { label: "Mudbidri", value: "CTY_MUDB_NB" },
                { label: "Mudgul Chittempalle ", value: "CTY_Mudg_8415" },
                { label: "Mudhal", value: "CTY_MUDH_NB" },
                { label: "Mudhol", value: "CTY_MUDH" },
                { label: "Mudki", value: "CTY_MUDK_NB" },
                { label: "Mudupugal ", value: "CTY_Mudu_8391" },
                { label: "Mughalsarai", value: "CTY_MUGH_NB" },
                { label: "Mugpal", value: "CITY_Mugp_8207" },
                { label: "Mugpal", value: "CTY_MUGP_NB" },
                { label: "Muhammadabad", value: "CTY_MUHA_NB" },
                { label: "Mukerian", value: "CTY_MUKE" },
                { label: "Mukhed", value: "CTY_MUKD" },
                { label: "Muktsar", value: "CTY_MUKT20" },
                { label: "Mul", value: "CTY_MUL_NB" },
                { label: "Mulagampally ", value: "CTY_Mula_8453" },
                { label: "Mulbagal", value: "CTY_MULB" },
                { label: "Mulki", value: "CTY_MULK" },
                { label: "Mullana", value: "CTY_MULL_NB" },
                { label: "Mullanpur", value: "CTY_MULL" },
                { label: "Mullanpur Dakha", value: "CTY_MULL_NB1" },
                { label: "Mullanpur Garibadas", value: "CTY_MULL_NB2" },
                { label: "Mumbai", value: "CTY_MUMB21" },
                { label: "Mumbra", value: "CTY_MUMB_NB" },
                { label: "Munak", value: "CTY_MUNA_NB" },
                { label: "Munda", value: "CTY_MUND_NB" },
                { label: "Mundali", value: "CTY_MUND_NB1" },
                { label: "Mundiyur ", value: "CTY_Mund_8739" },
                { label: "Mundka", value: "CTY_MUNDKA" },
                { label: "Mundra", value: "CTY_MUND" },
                { label: "Mungeli", value: "CTY_MUNG_NB" },
                { label: "Munger", value: "CTY_MUNG22" },
                { label: "Munnur Somaram ", value: "CTY_Munn_8416" },
                { label: "Munpalle", value: "CITY_Munp_8208" },
                { label: "Munpalle", value: "CTY_MUNP_NB" },
                { label: "Munpalli ", value: "CTY_Munp_8337" },
                { label: "Muradabad", value: "CTY_MURA_NB" },
                { label: "Muradnagar", value: "CTY_MURA_NB1" },
                { label: "Murarichintala ", value: "CTY_Mura_8434" },
                { label: "Murbad", value: "CTY_MURBD" },
                { label: "Murgud", value: "CTY_MURG_NB" },
                { label: "Murnal ", value: "CTY_Murn_8579" },
                { label: "Murshidabad", value: "CTY_MURS23" },
                { label: "Murtijapur", value: "CTY_MURT_NB" },
                { label: "Muscat", value: "CTY_MUSCAT" },
                { label: "Mussoorie", value: "CTY_MUSS_NB" },
                { label: "Mustafabad", value: "CTY_MUST_NB" },
                { label: "Mustafabad, Delhi", value: "CTY_MSTFBAD" },
                { label: "Muvattupuzha", value: "CTY_MUVA" },
                { label: "Muzaffarnagar", value: "CTY_Muzz_8863" },
                { label: "Muzaffarnagar", value: "CTY_MUZA24" },
                { label: "Muzaffarpur", value: "CTY_MUZA25" },
                { label: "Muzzafar Nagar", value: "CTY_MUZZ_NB" },
                { label: "Mysore", value: "CTY_MYSO26" },
                { label: "PA N.Paravur", value: "CTY_N" },
                { label: "Nabadwip", value: "CTY_NABA_NB" },
                { label: "Nabarangapur", value: "CTY_NABA27" },
                { label: "Nabha", value: "CTY_NABH" },
                { label: "Nadala", value: "CTY_NADA_NB" },
                { label: "Nadapuram", value: "CTY_NADA_NB1" },
                { label: "Nadaun", value: "CTY_NADA_NB2" },
                { label: "Nadavaramba P.O", value: "CTY_NADA" },
                { label: "Nadepalle", value: "CITY_Nade_8209" },
                { label: "Nadepalle", value: "CTY_NADE_NB" },
                { label: "Nadia", value: "CTY_NADI28" },
                { label: "Nadiad", value: "CTY_NADI" },
                { label: "Nadiwada ", value: "CTY_Nadi_8392" },
                { label: "Nagaon", value: "CTY_NAGA29" },
                { label: "Nagapattinam", value: "CTY_NAGA30" },
                { label: "Nagaram", value: "CTY_Naga_7601" },
                { label: "Nagaur", value: "CTY_NAGA31" },
                { label: "Nagda", value: "CTY_NAGD_NB" },
                { label: "Nagdhara", value: "CTY_NAGD_NB1" },
                { label: "Nagercoil", value: "CTY_NAGE" },
                { label: "Nagina", value: "CTY_NAGI_NB" },
                { label: "Nagpur", value: "CTY_NAGP32" },
                { label: "Nagrota Bagwan", value: "CTY_NAGR_NB" },
                { label: "Nahan", value: "CTY_NAHA5405" },
                { label: "Naharlagun", value: "CTY_NAHA5388" },
                { label: "Naharpur", value: "CTY_NAHA_NB1" },
                { label: "Naharpur Kasan", value: "CTY_NAHA_NB" },
                { label: "Nahur", value: "CTY_NAHUR" },
                { label: "Naigavhan ", value: "CTY_Naig_8622" },
                { label: "Naihati", value: "CTY_NAIH_NB" },
                { label: "Nainital", value: "CTY_NAIN33" },
                { label: "Naira ", value: "CTY_Nair_8507" },
                { label: "Naisi", value: "CTY_NAIS_NB" },
                { label: "Najafgarh", value: "CTY_NJG" },
                { label: "Najibabad", value: "CTY_NAJI_NB" },
                { label: "Nakatrana", value: "CTY_NAKA_NB" },
                { label: "Nakodar", value: "CTY_NAKO" },
                { label: "Nakrekal", value: "CITY_Nakr_8210" },
                { label: "Nakrekal", value: "CTY_NAKR_NB" },
                { label: "Nalagarh", value: "CTY_NALA" },
                { label: "Nalanda", value: "CTY_NALA34" },
                { label: "Nalbari", value: "CTY_NALB35" },
                { label: "Nalgonda", value: "CITY_Nalg_8154" },
                { label: "Nalgonda", value: "CTY_NALG36" },
                { label: "Nallapureddypalle ", value: "CTY_Nall_8435" },
                { label: "Nallasopara", value: "CTY_NALL" },
                { label: "Nallasopara- West", value: "CTY_NALL5430" },
                { label: "Nalni ", value: "CTY_Naln_8623" },
                { label: "Namakkal", value: "CTY_NAMA37" },
                { label: "Namchi", value: "CTY_NAMC_7148" },
                { label: "Namchi", value: "CTY_NAMC_NB" },
                { label: "Nampur", value: "CTY_NAMP_NB" },
                { label: "Namsai", value: "CTY_NAMS" },
                { label: "Nanaksar Kaleran", value: "CTY_NANA_NB" },
                { label: "Nanded", value: "CTY_NAND38" },
                { label: "Nandgaon", value: "CTY_NAND_NB" },
                { label: "Nandgaon ", value: "CTY_Nand_8651" },
                { label: "Nandipahad ", value: "CTY_Nand_8358" },
                { label: "Nandura", value: "CTY_NAND_NB1" },
                { label: "Nandurbar", value: "CTY_NAND39" },
                { label: "Nandyal", value: "CTY_NAND23" },
                { label: "Nangal", value: "CTY_NANG5321" },
                { label: "Nangal Dewat", value: "CTY_NAGLDWT" },
                { label: "Nangal Kalan", value: "CTY_NANG_NB" },
                { label: "Nangal Kheri", value: "CTY_NANG_NB1" },
                { label: "Nangal Thakran", value: "CTY_NAGLTHKRN" },
                { label: "Nangloi Jat", value: "CTY_NGLJAT" },
                { label: "Nanjangud", value: "CTY_NANJ" },
                { label: "Nanpara", value: "CTY_NANP_NB" },
                { label: "Napara", value: "CTY_Napa_7975" },
                { label: "Napara", value: "CTY_NPRA" },
                { label: "Naraina", value: "CTY_NARAINA" },
                { label: "Naraingarh", value: "CTY_NARA40" },
                { label: "Narasampet", value: "CITY_Nara_8211" },
                { label: "Narasampet", value: "CTY_NARA_NB" },
                { label: "Narasapur", value: "CTY_NARA_NB1" },
                { label: "Narasapurapupeta ", value: "CTY_Nara_8493" },
                { label: "Narasaraopet", value: "CTY_NARA170" },
                { label: "Narasaraopeta", value: "CTY_NARA_NB2" },
                { label: "Narayangaon", value: "CTY_NARA145" },
                { label: "Narayanpet", value: "CITY_Nara_8212" },
                { label: "Narayanpet", value: "CTY_NARA_NB3" },
                { label: "Narayanpur", value: "CTY_NARA41" },
                { label: "Narayanpur", value: "CTY_NARA5255" },
                { label: "Nareda", value: "CTY_NARE_NB" },
                { label: "Narmada", value: "CTY_NARM42" },
                { label: "Narmetta ", value: "CTY_Narm_8289" },
                { label: "Narnaud", value: "CTY_NARN43" },
                { label: "Narnaul", value: "CTY_NARN5326" },
                { label: "Narnaund", value: "CTY_NARN_NB" },
                { label: "Narsampet", value: "CITY_Nars_8169" },
                { label: "Narsampet", value: "CTY_NARS171" },
                { label: "Narsapur", value: "CTY_NARS172" },
                { label: "Narsapura", value: "CTY_NARS_NB" },
                { label: "Narsinghpur", value: "CTY_NARS44" },
                { label: "Narsinghpur", value: "CTY_NARS" },
                { label: "Narsipatnam", value: "CTY_NARS_NB1" },
                { label: "Narugram", value: "CTY_NARU_NB" },
                { label: "Narwana", value: "CTY_NARW_NB" },
                { label: "Nashik", value: "CTY_NASH45" },
                { label: "Nasik", value: "CTY_NASI146" },
                { label: "Nasirabad", value: "CTY_NASI83" },
                { label: "Nasvadi", value: "CTY_Nasv_7602" },
                { label: "Natepute", value: "CTY_NTP" },
                { label: "Natgram", value: "CTY_NATG_NB" },
                { label: "Nathana", value: "CTY_NATH_NB" },
                { label: "Nathdwara", value: "CTY_NATH" },
                { label: "Nathu Chahal ", value: "CTY_Nath_8722" },
                { label: "Naugachhia", value: "CTY_NAUG_NB" },
                { label: "Naugarh", value: "CTY_NAUG_NB1" },
                { label: "Naura", value: "CTY_NAUR_NB" },
                { label: "Naurangabad ", value: "CTY_Naur_8676" },
                { label: "Naushera", value: "CTY_NAUS_NB2" },
                { label: "Naushera Maja Singh", value: "CTY_NAUS_NB" },
                { label: "Naushera Pannuan", value: "CTY_NAUS_NB1" },
                { label: "Nautanwa", value: "CTY_NAUT_NB" },
                { label: "Navelim", value: "CTY_NAVE_NB" },
                { label: "Navi Mumbai", value: "CTY_NAVI46" },
                { label: "Navsari", value: "CTY_NAVS47" },
                { label: "Nawabganj", value: "CTY_NAWA_NB" },
                { label: "Nawada", value: "CTY_NAWA48" },
                { label: "Nawagarh", value: "CTY_NAWA_NB1" },
                { label: "Nawalgarh", value: "CTY_NAWA_NB2" },
                { label: "Nawan Pind", value: "CTY_NAWA_NB3" },
                { label: "Nawanagar", value: "CTY_NAWA_NB4" },
                { label: "Nawanshahar", value: "CTY_NAWA_NB5" },
                { label: "Nawanshahar", value: "CTY_NAWA49" },
                { label: "Nawanshahr", value: "CTY_Nawa_8791" },
                { label: "Nawapur", value: "CTY_NAWA_NB6" },
                { label: "Naya Nangal", value: "CTY_NAYA" },
                { label: "Nayagarh", value: "CTY_NAYA50" },
                { label: "Nazira", value: "CTY_NZRA" },
                { label: "Nazira", value: "CTY_Nazi_7974" },
                { label: "Nedumangad", value: "CTY_NEDU" },
                { label: "Neelapalle", value: "CTY_NEEL_NB" },
                { label: "Neem Ka Thana", value: "CTY_NEEM5387" },
                { label: "Neem ka Thana", value: "CTY_NEEM_NB" },
                { label: "Neemrana", value: "CTY_NEEM_NB1" },
                { label: "Neemuch", value: "CTY_NEEM51" },
                { label: "Nellore", value: "CTY_NELL52" },
                { label: "Nellutla ", value: "CTY_Nellu_8290" },
                { label: "Ner", value: "CTY_NER_NB" },
                { label: "Ner ", value: "CTY_Ner_8624" },
                { label: "Neral", value: "CTY_NERA_NB" },
                { label: "Neralakuppe ", value: "CTY_Nera_8588" },
                { label: "Nerul", value: "CTY_NERUL" },
                { label: "Nesargi", value: "CTY_NESA_NB" },
                { label: "Netramali", value: "CTY_NETR_NB" },
                { label: "Nettoor", value: "CTY_NETT5384" },
                { label: "Nevalganj ", value: "CTY_Neva_8783" },
                { label: "New Abadi ", value: "CTY_NewA_8393" },
                { label: "New Delhi", value: "CTY_NEW53" },
                { label: "New Delhi Municipal Council", value: "CTY_NDMC" },
                { label: "New Dudhai", value: "CTY_NewD_8547" },
                { label: "Newai", value: "CTY_NEWA5330" },
                { label: "Neyveli", value: "CTY_NEYV" },
                { label: "NEYYATTINKARA", value: "CTY_NEYY_NB" },
                { label: "Neyyattinkara", value: "CTY_NEYY" },
                { label: "Nhava Sheva", value: "CTY_NHAV_NB" },
                { label: "Nicobar", value: "CTY_NICO54" },
                { label: "Nidadavole", value: "CTY_NIDA_NB" },
                { label: "Nidhipur", value: "CTY_NIDH_NB" },
                { label: "Nigdi", value: "CTY_NIGD_NB" },
                { label: "Nighoje", value: "CTY_NIGH_NB" },
                { label: "Nihal Singh Wala", value: "CTY_NIHA" },
                { label: "Nihalsingh Wala", value: "CTY_NIHA_NB" },
                { label: "Nijhota ", value: "CTY_Nijh_8756" },
                { label: "Nilambur", value: "CTY_NILA" },
                { label: "Nilanga", value: "CTY_NLG" },
                { label: "Nilgiris", value: "CTY_NILG55" },
                { label: "Nilokheri", value: "CTY_NILO_NB" },
                { label: "Nimapara", value: "CTY_NIMA_NB" },
                { label: "Nimbahera", value: "CTY_NIMB5411" },
                { label: "Nimetha", value: "CTY_NIME_NB" },
                { label: "Nindugonda ", value: "CTY_Nind_8480" },
                { label: "Niphad", value: "CTY_NIPH_NB" },
                { label: "Nippani", value: "CTY_NIPP_NB" },
                { label: "Nira", value: "CTY_NIRA" },
                { label: "Nirmal", value: "CITY_Nirm_8176" },
                { label: "Nirmal", value: "CTY_NIRM" },
                { label: "Nirmali", value: "CTY_NIRM_NB" },
                { label: "Nisaraya", value: "CTY_NISA_NB" },
                { label: "Nissing", value: "CTY_NISS_NB" },
                { label: "Nivali", value: "CTY_Nila_7987" },
                { label: "Nivali", value: "CTY_NIVALI" },
                { label: "Niwai", value: "CTY_NIWA_NB" },
                { label: "Niwari", value: "CTY_NWARI" },
                { label: "Niwaroo", value: "CTY_NIWA_NB1" },
                { label: "Nizamabad", value: "CITY_Niza_8145" },
                { label: "Nizamabad", value: "CTY_NIZA56" },
                { label: "Nochimedu ", value: "CTY_Noch_8740" },
                { label: "Nohar", value: "CTY_NHR" },
                { label: "NOIDA", value: "CTY_NOIDA" },
                { label: "Noida", value: "CTY_NOID67" },
                { label: "Nokha", value: "CTY_NOKH" },
                { label: "Nongpoh", value: "CTY_NONG_NB" },
                { label: "Nongstoin", value: "CTY_NONG_NB1" },
                { label: "Noonmati", value: "CTY_NOON_NB" },
                { label: "Noorpur", value: "CTY_NORP" },
                { label: "North 24 Parganas", value: "CTY_NORT57" },
                { label: "North And Middle Andaman", value: "CTY_NORT58" },
                { label: "North Cachar Hills", value: "CTY_NORT59" },
                { label: "North Delhi", value: "CTY_NORT60" },
                { label: "North Dinajpur", value: "CTY_NORT61" },
                { label: "North Dumdum", value: "CTY_NORT_NB" },
                { label: "North East Delhi", value: "CTY_NORT5251" },
                { label: "North Goa", value: "CTY_NORT62" },
                { label: "North Guwahati", value: "CTY_NORT_NB1" },
                { label: "North Lakhimpur", value: "CTY_NORT" },
                { label: "North Sikkim", value: "CTY_NORT63" },
                { label: "North Tripura", value: "CTY_NORT64" },
                { label: "North West Delhi", value: "CTY_NORT65" },
                { label: "Nowgong", value: "CTY_NOWG_NB" },
                { label: "NSP Camp Miryalaguda ", value: "CTY_NSPC_8359" },
                { label: "Nuapada", value: "CTY_NUAP66" },
                { label: "Nuh", value: "CTY_NUH_NB" },
                { label: "Numaligarh", value: "CTY_NUMA_NB" },
                { label: "Nuna Majra", value: "CTY_NUNA_NB" },
                { label: "Nurmahal", value: "CTY_NURM" },
                { label: "Nurpur Bedi", value: "CTY_NURP_NB" },
                { label: "Nuvem", value: "CTY_NUVE_NB" },
                { label: "Nuzvid", value: "CTY_NUZV5427" },
                { label: "Obra", value: "CTY_OBRA_NB" },
                { label: "Oddanchatram", value: "CTY_ODDA_NB" },
                { label: "Odwara", value: "CTY_ODWA_NB" },
                { label: "Old GOA", value: "CTY_OLDG_NB" },
                { label: "Old Goa", value: "CTY_OLDG" },
                { label: "Old Kanksa Road", value: "CTY_KNSARD" },
                { label: "Ongole", value: "CTY_ONGO" },
                { label: "Ooty", value: "CTY_OOTY" },
                { label: "Opivada Venkampeta ", value: "CTY_Opiv_8508" },
                { label: "Opp R K Marble Hazaribagh", value: "CTY_OPP5292" },
                { label: "Oragadam", value: "CTY_ORAG_NB" },
                { label: "Orai", value: "CTY_ORAI" },
                { label: "Oravackal", value: "CTY_ORAV_NB" },
                { label: "Osmanabad", value: "CTY_OSMA68" },
                { label: "Othian", value: "CTY_OTHI_NB" },
                { label: "Ottapalam", value: "CTY_OTTA" },
                { label: "Ozar", value: "CTY_OZAR_NB" },
                { label: "Pachaat", value: "CTY_PACH_NB" },
                { label: "Pachchunur", value: "CITY_Pach_8213" },
                { label: "Pachchunur", value: "CTY_PACH_NB1" },
                { label: "Pachora", value: "CTY_PACH" },
                { label: "Pachra ", value: "CTY_Pach_8533" },
                { label: "Padagha", value: "CTY_PDGHA" },
                { label: "Padampur", value: "CTY_PADA_NB" },
                { label: "Paddi Jagir", value: "CTY_PADD_NB" },
                { label: "Padra", value: "CTY_PADR31" },
                { label: "Padrauna", value: "CTY_PADR108" },
                { label: "Paharpur ", value: "CTY_Paha_8569" },
                { label: "Pai", value: "CTY_PAI_NB" },
                { label: "Paithan", value: "CTY_PAIT_NB" },
                { label: "Pakalapadu ", value: "CTY_Paka_8481" },
                { label: "Pakharpur", value: "CTY_PAKH_NB" },
                { label: "Pakhi Kalan ", value: "CTY_Pakh_8703" },
                { label: "Pakri Baramad Deobari", value: "CTY_PAKR_NB" },
                { label: "Pakur", value: "CTY_PAKU69" },
                { label: "Pala", value: "CTY_PALA118" },
                { label: "Palacole", value: "CTY_PALA_NB" },
                { label: "Palakkad", value: "CTY_PALA70" },
                { label: "Palakol", value: "CTY_PALA1" },
                { label: "Palamau", value: "CTY_PALA71" },
                { label: "Palamner", value: "CTY_PALA_NB1" },
                { label: "Palampur", value: "CTY_PALA5284" },
                { label: "Palani", value: "CTY_PLI" },
                { label: "Palanpur", value: "CTY_PALA32" },
                { label: "Palarivattom", value: "CTY_PALA5363" },
                { label: "PALASA KASIBUGGA", value: "CTY_PALK" },
                { label: "Palasaur ", value: "CTY_Pala_8677" },
                { label: "Palavakkam", value: "CTY_PALA_NB2" },
                { label: "Palda", value: "CTY_PALD_NB" },
                { label: "Paldi", value: "CTY_PALD_NB1" },
                { label: "Palej", value: "CTY_PALE_NB" },
                { label: "Palghar", value: "CTY_PLG" },
                { label: "Pali", value: "CTY_PALI72" },
                { label: "Pali ", value: "CTY_Pali_8534" },
                { label: "Palia Kalan", value: "CTY_PALI_NB" },
                { label: "Palitana", value: "CTY_PALI5313" },
                { label: "Palkhanda", value: "CTY_PALK_NB" },
                { label: "Palladam", value: "CTY_PALL5322" },
                { label: "Pallavaram", value: "CTY_PALL_NB" },
                { label: "Pallepahad ", value: "CTY_Pall_8305" },
                { label: "Pallikanai", value: "CTY_PALL_NB1" },
                { label: "Pallipalayam", value: "CTY_PALL_NB2" },
                { label: "Pallippuram", value: "CTY_PALL_NB3" },
                { label: "Palluruthy", value: "CTY_PALL_NB4" },
                { label: "Palpur", value: "CTY_PALP_NB" },
                { label: "Palus", value: "CTY_PALU_NB" },
                { label: "Palvancha ", value: "CTY_Palv_8338" },
                { label: "Palwal", value: "CTY_PALW" },
                { label: "Palwancha", value: "CITY_Palw_8214" },
                { label: "Palwancha", value: "CTY_PALW_NB" },
                { label: "Pamal", value: "CTY_PAMA_NB" },
                { label: "Pamgarh", value: "CTY_PAMG_NB" },
                { label: "Pamol", value: "CTY_PAMO_NB" },
                { label: "Pampore", value: "CTY_PAMP_NB" },
                { label: "Panagarh", value: "CTY_PANA_NB" },
                { label: "Panaji", value: "CTY_Pana_7980" },
                { label: "Panaji", value: "CTY_PNJI" },
                { label: "Panch Mahals", value: "CTY_PANC73" },
                { label: "Panchkula", value: "CTY_PANC74" },
                { label: "Panchkula", value: "CTY_PANC5318" },
                { label: "Pandalam", value: "CTY_PAND_NB" },
                { label: "Pandharkaoda", value: "CTY_PAND_NB1" },
                { label: "Pandharkawada", value: "CTY_PAND" },
                { label: "Pandharpur", value: "CTY_PAND_NB2" },
                { label: "Pandhurna", value: "CTY_PAND5385" },
                { label: "Pandori Gola ", value: "CTY_Pand_8678" },
                { label: "Pandori Ransingh ", value: "CTY_Pand_8679" },
                { label: "Pandori Takhtmal ", value: "CTY_Pand_8680" },
                { label: "Pandua", value: "CTY_PAND_NB3" },
                { label: "Panduka", value: "CTY_PAND_NB4" },
                { label: "Panipat", value: "CTY_PANI75" },
                { label: "Panjabari", value: "CTY_Panj_8282" },
                { label: "Panje Ke", value: "CTY_PANJ_NB" },
                { label: "Panjgrain Kalan", value: "CTY_PANJ_NB1" },
                { label: "Panjim", value: "CTY_PANJ" },
                { label: "Panna", value: "CTY_PANN76" },
                { label: "Panskura", value: "CTY_PNSK" },
                { label: "Panvel", value: "CTY_PANV" },
                { label: "Paonta Sahib", value: "CTY_PAON" },
                { label: "Papum Pare", value: "CTY_PAPU77" },
                { label: "Para Bazar", value: "CTY_PARA_NB" },
                { label: "Para Saray", value: "CTY_PARA_NB1" },
                { label: "Parabhani", value: "CTY_PARA_NB2" },
                { label: "Paradeep", value: "CTY_PARA_NB3" },
                { label: "Paradip Port", value: "CTY_PARA" },
                { label: "Param ", value: "CTY_Para_8776" },
                { label: "Parasia", value: "CTY_PRS" },
                { label: "Parasiya ", value: "CTY_Para_8757" },
                { label: "Paratwada", value: "CTY_PRTW" },
                { label: "Paravur", value: "CTY_PARA_NB4" },
                { label: "Parbhani", value: "CTY_PARB78" },
                { label: "Pardi", value: "CTY_PARD_NB" },
                { label: "Parganas", value: "CTY_Parg_7597" },
                { label: "Parkal", value: "CITY_Park_8215" },
                { label: "Parkal", value: "CTY_PARK_NB" },
                { label: "Parlakhemundi", value: "CTY_PARL_NB" },
                { label: "Parli", value: "CTY_PARL_NB1" },
                { label: "Parmanand", value: "CTY_PARM_NB" },
                { label: "Parner", value: "CTY_PARN_NB" },
                { label: "Parshupura ", value: "CTY_Pars_8777" },
                { label: "Parsottampur", value: "CTY_PARS_NB" },
                { label: "Parthala Khanjarpur", value: "CTY_PART_NB" },
                { label: "Parvathipuram", value: "CTY_PARV" },
                { label: "Parwanoo", value: "CTY_PARW_NB" },
                { label: "Paschimi Singhbhum", value: "CTY_PasS_8789" },
                { label: "Paschimmidnapur", value: "CTY_PASC" },
                { label: "Pasighat", value: "CTY_PASI9" },
                { label: "Pasthal", value: "CTY_PSTHL" },
                { label: "Patala", value: "CTY_PTLA" },
                { label: "Patalganga", value: "CTY_PATA_NB" },
                { label: "Patan", value: "CTY_PATA79" },
                { label: "Patancheru", value: "CITY_Pata_8164" },
                { label: "Patancheru", value: "CTY_PATA" },
                { label: "Pataudi", value: "CTY_PATAUDI" },
                { label: "Pathalgaon", value: "CTY_PATH_NB" },
                { label: "Pathanamthitta", value: "CTY_PATH80" },
                { label: "Pathankot", value: "CTY_PATH" },
                { label: "Pathankot", value: "CTY_PATH5358" },
                { label: "Pathrud ", value: "CTY_Path_8625" },
                { label: "Pathsala", value: "CTY_PATH_NB1" },
                { label: "Pathwal", value: "CTY_PATH_NB2" },
                { label: "Patiala", value: "CTY_PATI81" },
                { label: "PATIYALI", value: "CTY_PATI_8878" },
                { label: "Patna", value: "CTY_PATN82" },
                { label: "Patran", value: "CTY_PATR" },
                { label: "Patratu", value: "CTY_PATR_NB" },
                { label: "Pattambi", value: "CTY_PATT_NB" },
                { label: "Pattamundai", value: "CTY_PATT158" },
                { label: "Pattan", value: "CTY_PATT_NB1" },
                { label: "Pattenapalem ", value: "CTY_Patt_8454" },
                { label: "Patti", value: "CTY_PATT70" },
                { label: "Patti Basantpur ", value: "CTY_Patt_8778" },
                { label: "Patti Pratapgarh", value: "CTY_PATT" },
                { label: "Patti,Pratapgarh", value: "CTY_PATT_NB2" },
                { label: "Pattukottai", value: "CTY_PATT93" },
                { label: "Patwdh", value: "CTY_PATW_NB" },
                { label: "Pauri Garhwal", value: "CTY_PAUR83" },
                { label: "Payal", value: "CTY_PAYA_NB" },
                { label: "Payyanur", value: "CTY_PAYY" },
                { label: "Pedamanddadi ", value: "CTY_Peda_8373" },
                { label: "Pedda Boddepalli ", value: "CTY_Pedd_8482" },
                { label: "Peddajutur ", value: "CTY_Pedd_8436" },
                { label: "Peddapahad ", value: "CTY_Pedd_8291" },
                { label: "Peddapalle", value: "CITY_Pedd_8216" },
                { label: "Peddapalle", value: "CTY_PEDD_NB" },
                { label: "Peddapur", value: "CITY_Pedd_8217" },
                { label: "Peddapur", value: "CTY_PEDD_NB1" },
                { label: "Peddapuram", value: "CTY_PEDD" },
                { label: "Peddarangapuram ", value: "CTY_Pedd_8437" },
                { label: "Peedampalli", value: "CTY_PEED_NB" },
                { label: "Pegadapalli", value: "CITY_Pega_8218" },
                { label: "Pegadapalli", value: "CTY_PEGA_NB" },
                { label: "Pehlad Pur Bangar", value: "CTY_PHLDPRBNGR" },
                { label: "Pehowa", value: "CTY_PEHO" },
                { label: "Pen", value: "CTY_PEN_NB" },
                { label: "Pendra", value: "CTY_PEND_NB" },
                { label: "Penugonda ", value: "CTY_Penu_8394" },
                { label: "Perambalur", value: "CTY_PERA84" },
                { label: "Perampeta ", value: "CTY_Pera_8455" },
                { label: "Peren", value: "CTY_PERE85" },
                { label: "Peringanam", value: "CTY_PERI_NB" },
                { label: "Perinthalmanna", value: "CTY_PERI" },
                { label: "Periyakulam", value: "CTY_PERI_NB1" },
                { label: "Periyanaikenpalayam", value: "CTY_PERI_NB2" },
                { label: "Perkit ", value: "CTY_Perk_8339" },
                { label: "Pernem", value: "CTY_PERN_NB" },
                { label: "Perumbavoor", value: "CTY_PERU" },
                { label: "Perundurai", value: "CTY_PERU5360" },
                { label: "Perungalathur", value: "CTY_PERU_NB" },
                { label: "Perungudi", value: "CTY_PERGDI" },
                { label: "Peth Kasba", value: "CTY_PETH_NB" },
                { label: "Petlad", value: "CTY_PETL" },
                { label: "Phagwara", value: "CTY_PHAG" },
                { label: "Phalodi", value: "CTY_PHAL_NB" },
                { label: "Phaltan", value: "CTY_PHAL" },
                { label: "Phaphrana", value: "CTY_PHAP_NB" },
                { label: "Phek", value: "CTY_PHEK86" },
                { label: "Phillaur", value: "CTY_PHIL" },
                { label: "Phul", value: "CTY_PHUL_NB" },
                { label: "Phulabani", value: "CTY_PHUL_NB1" },
                { label: "Phulera", value: "CTY_PHUL" },
                { label: "Phullanwala", value: "CTY_PHUL_NB2" },
                { label: "Phulmaddi ", value: "CTY_Phul_8417" },
                { label: "Phulpur", value: "CTY_PHULPUR" },
                { label: "Phurso", value: "CTY_PHUR_NB" },
                { label: "Piduguralla", value: "CTY_PIDU" },
                { label: "Pilani", value: "CTY_PNI" },
                { label: "Pileru", value: "CTY_PILE_NB" },
                { label: "Pilibanga", value: "CTY_PILI" },
                { label: "Pilibhit", value: "CTY_PILI87" },
                { label: "PILKHUWA", value: "CTY_PLKWA" },
                { label: "Pillayarkuppam ", value: "CTY_Pill_8726" },
                { label: "Pilu Khera", value: "CTY_PILU_NB" },
                { label: "Pimpri", value: "CTY_PIMP_NB" },
                { label: "Pindi Balochan", value: "CTY_PIND_NB" },
                { label: "Pinjore", value: "CTY_PINJ" },
                { label: "Pipariya", value: "CTY_PIPA" },
                { label: "Pipli", value: "CTY_PIPL_NB" },
                { label: "Pipli ", value: "CTY_Pipl_8704" },
                { label: "Pipra ", value: "CTY_Pipr_8525" },
                { label: "Pipraich", value: "CTY_PIPR_NB" },
                { label: "Piprali road", value: "CTY_PRL" },
                { label: "Pipri ", value: "CTY_Pipr_8340" },
                { label: "Piravom", value: "CTY_PIRA" },
                { label: "Pithampur", value: "CTY_PITH5347" },
                { label: "Pithapuram", value: "CTY_PITH_NB" },
                { label: "Pithla", value: "CTY_PITH_NB1" },
                { label: "Pithoragarh", value: "CTY_PITH94" },
                { label: "Poata", value: "CTY_POAT_NB" },
                { label: "Podili", value: "CTY_PODI_NB" },
                { label: "Pogathota", value: "CTY_POGA" },
                { label: "Pojewal", value: "CTY_POJE_NB" },
                { label: "Pokharia ", value: "CTY_Pokh_8570" },
                { label: "Pokhran", value: "CTY_PKH" },
                { label: "Pola ", value: "CTY_Pola_8665" },
                { label: "Police Lines Tarn Taran SO", value: "CTY_Poli_8681" },
                { label: "Pollachi", value: "CTY_POLL" },
                { label: "Pombhurna ", value: "CTY_Pomb_8652" },
                { label: "Ponata Sahib", value: "CTY_PONA" },
                { label: "Ponata sahib", value: "CTY_PONA_NB" },
                { label: "Ponda", value: "CTY_POND" },
                { label: "Pondicherry", value: "CTY_POND187" },
                { label: "Pondicherry", value: "CTY_POND88" },
                { label: "Pondi-Uproda ", value: "CTY_Pond_8535" },
                { label: "Ponnam ", value: "CTY_Ponn_8509" },
                { label: "Ponnani", value: "CTY_PONN" },
                { label: "Ponnur", value: "CTY_PONN_NB" },
                { label: "Poonch", value: "CTY_POON89" },
                { label: "Poondi", value: "CTY_POON_NB" },
                { label: "Poongamoodu", value: "CTY_POON_NB1" },
                { label: "Pooth Kalan", value: "CTY_PTHKLN" },
                { label: "Pooth Khurd", value: "CTY_PTHKHD" },
                { label: "Por", value: "CTY_POR_NB" },
                { label: "Poranki", value: "CTY_PORA_NB" },
                { label: "Porbandar", value: "CTY_PORB90" },
                { label: "Porbander", value: "CTY_PORB5392" },
                { label: "Port Blair", value: "CTY_PORT5350" },
                { label: "Porvorim", value: "CTY_PORV_NB" },
                { label: "Post Loni", value: "CTY_POST_NB" },
                { label: "Post Surajpur", value: "CTY_POST_NB1" },
                { label: "Pottapalayam ", value: "CTY_Pott_8731" },
                { label: "Powai", value: "CTY_POWAI" },
                { label: "Powayan", value: "CTY_POWA_NB" },
                { label: "Prakasam", value: "CTY_PRAK91" },
                { label: "Prantij", value: "CTY_PRAN_NB" },
                { label: "Prasadraopeta", value: "CTY_PRAS" },
                { label: "Pratapgarh", value: "CTY_PRAT" },
                { label: "Pratapgarh", value: "CTY_PRAT92" },
                { label: "Proddattur", value: "CTY_PROD_NB" },
                { label: "Proddatur", value: "CTY_PROD" },
                { label: "PTU Campus Kapurthala ", value: "CTY_PTUC_8723" },
                { label: "Pudhupakkam", value: "CTY_PUDH_7146" },
                { label: "Puducherry", value: "CTY_PUDU_NB" },
                { label: "Pudukkottai", value: "CTY_PUDU93" },
                { label: "Pudukottai", value: "CTY_PUDU" },
                { label: "Pudur ", value: "CTY_Pudu_8418" },
                { label: "Pukhrayan", value: "CTY_PUKH_NB" },
                { label: "Pul Pehlad", value: "CTY_PLPHLD" },
                { label: "Pulapalli ", value: "CTY_Pula_8419" },
                { label: "Pulivendla Bus-stand ", value: "CTY_Pedd_8438" },
                { label: "Pulivendlakutcherry ", value: "CTY_Puli_8439" },
                { label: "Pulivendula", value: "CTY_PULI_NB" },
                { label: "Pulusmamidi ", value: "CTY_Pulu_8420" },
                { label: "Pulwama", value: "CTY_PULW95" },
                { label: "Punalur", value: "CTY_PUNA" },
                { label: "Pundri", value: "CTY_PUND_NB" },
                { label: "Pune", value: "CTY_PUNE94" },
                { label: "Punganur", value: "CTY_PUNG_NB" },
                { label: "Punjabari", value: "CTY_Punj_8281" },
                { label: "Puraina ", value: "CTY_Pura_8758" },
                { label: "Purana Moga ", value: "CTY_Pura_8705" },
                { label: "Puranpur", value: "CTY_PURA_NB" },
                { label: "Purba Medinipur", value: "CTY_PURB5303" },
                { label: "Puri", value: "CTY_PURI95" },
                { label: "Purmandal", value: "CTY_PMD" },
                { label: "Purnea", value: "CTY_PURN" },
                { label: "Purnia", value: "CTY_PURN96" },
                { label: "Purquazi", value: "CTY_PURQ_NB" },
                { label: "Purulia", value: "CTY_PURU" },
                { label: "Puruliya", value: "CTY_PURU97" },
                { label: "Purvi Champaran", value: "CTY_PURV_5443" },
                { label: "Purwa", value: "CTY_PURW_NB" },
                { label: "Pusad", value: "CTY_PUSA_NB" },
                { label: "Pushparajgarh", value: "CTY_PUSHPRJ" },
                { label: "Puthenchira", value: "CTY_PUTH_NB" },
                { label: "Puttaparthi", value: "CTY_PUTT_NB" },
                { label: "Puttaparthy", value: "CTY_PUTT2" },
                { label: "Puttur", value: "CTY_PUTT_NB1" },
                { label: "Puttur", value: "CTY_PUTT56" },
                { label: "Pyararam ", value: "CTY_Pyar_8306" },
                { label: "Pynursla", value: "CTY_PYNU_NB" },
                { label: "Qadian", value: "CTY_QADI" },
                { label: "Qazigund", value: "CTY_QZG" },
                { label: "Qilla Kavi Santokh ", value: "CTY_Qill_8682" },
                { label: "Quammruddin Nagar", value: "CTY_QMRDNGR" },
                { label: "Quepem", value: "CTY_QUEP_NB" },
                { label: "Quila Nau ", value: "CTY_Quil_8706" },
                { label: "Quilandi", value: "CTY_QUIL" },
                { label: "R Thummalapalle ", value: "CTY_Rthu_8440" },
                { label: "Radaur", value: "CTY_RADA_NB" },
                { label: "Radhanpur", value: "CTY_RADH_NB" },
                { label: "Rae Bareli", value: "CTY_RAE" },
                { label: "Raebareli", value: "CTY_RAEB98" },
                { label: "Raekot", value: "CTY_RAEK" },
                { label: "Rafiganj", value: "CTY_RFG" },
                { label: "Ragadapa ", value: "CTY_Raga_8360" },
                { label: "Raghavapet", value: "CITY_Ragh_8219" },
                { label: "Raghavapet", value: "CTY_RAGH_NB" },
                { label: "Raghudevapuram", value: "CTY_Ragh_7544" },
                { label: "Rahata", value: "CTY_RAHA" },
                { label: "Rahiad", value: "CTY_RAHI_NB" },
                { label: "Rahon", value: "CTY_RAHO_NB" },
                { label: "Rahuri", value: "CTY_RAHU" },
                { label: "Raichur", value: "CTY_RAIC99" },
                { label: "Raigad", value: "CTY_RAIG5420" },
                { label: "Raiganj", value: "CTY_RAIG_NB" },
                { label: "Raigarh", value: "CTY_RAIG96" },
                { label: "Raigarh(Mh)", value: "CTY_RAIG11" },
                { label: "Raikot", value: "CTY_RAIK5395" },
                { label: "Railmajra", value: "CTY_RAIL_NB" },
                { label: "Raipur", value: "CTY_RAIP12" },
                { label: "Raipur Rani", value: "CTY_RAIP_NB" },
                { label: "Rair Kalan", value: "CTY_RAIR_NB" },
                { label: "Rairangpur", value: "CTY_RAIR_NB1" },
                { label: "Raisar", value: "CTY_RAIS_NB" },
                { label: "Raisen", value: "CTY_RAIS13" },
                { label: "Raisinghnagar", value: "CTY_RAIS" },
                { label: "Raja Sansi", value: "CTY_RAJA_NB" },
                { label: "Rajagangapur", value: "CTY_RAJA_NB1" },
                { label: "Rajahmundry", value: "CTY_RAJA3" },
                { label: "Rajam", value: "CTY_RAJA4" },
                { label: "Rajampet", value: "CTY_RAJA_NB2" },
                { label: "Rajapalayam", value: "CTY_RAJA94" },
                { label: "Rajarhat Gopalpur", value: "CTY_RAJA_NB3" },
                { label: "Rajasmand", value: "CTY_RAJA_NB4" },
                { label: "Rajaund", value: "CTY_RAJA_NB5" },
                { label: "Rajauri", value: "CTY_RAJA14" },
                { label: "Rajballabhpur", value: "CTY_RAJB_NB" },
                { label: "Rajgarh", value: "CTY_RAJG15" },
                { label: "Rajgarh", value: "CTY_RAJG5268" },
                { label: "Rajgarh", value: "CTY_RAJG5371" },
                { label: "Rajghar", value: "CTY_RAJG_NB3" },
                { label: "Rajghar", value: "CTY_RAJG_NB1" },
                { label: "Rajghar", value: "CTY_RAJG_NB2" },
                { label: "Rajgir", value: "CTY_RAJG_NB" },
                { label: "Rajia", value: "CTY_RAJI_NB" },
                { label: "Rajim", value: "CTY_RAJI" },
                { label: "Rajkot", value: "CTY_RAJK16" },
                { label: "Rajnadgaon", value: "CTY_RAJN_NB" },
                { label: "Rajnandgaon", value: "CTY_RAJN26" },
                { label: "Rajokri", value: "CTY_RAJOKRI" },
                { label: "Rajole ", value: "CTY_Rajo_8395" },
                { label: "Rajosana", value: "CTY_RAJO_NB" },
                { label: "Rajouri", value: "CTY_RAJO_NB1" },
                { label: "Rajowala ", value: "CTY_Rajo_8707" },
                { label: "Rajpipla", value: "CTY_RAJP33" },
                { label: "Rajpur Bhaian", value: "CTY_RAJP_NB" },
                { label: "Rajpura", value: "CTY_RAJP71" },
                { label: "Rajpura Township ", value: "CTY_Rajp_8666" },
                { label: "Rajsamand", value: "CTY_RAJS27" },
                { label: "Rajula", value: "CTY_RAJU" },
                { label: "Rajupakalu ", value: "CTY_Raju_8483" },
                { label: "Rajur", value: "CTY_Raju_8626" },
                { label: "Rajura", value: "CTY_RAJU_NB" },
                { label: "Rakanpur", value: "CTY_RAKA_NB" },
                { label: "Ramachandrapuram", value: "CTY_RAMA_NB" },
                { label: "Ramachandrapuram Old Town ", value: "CTY_Rama_8494" },
                { label: "Ramagundam", value: "CITY_Rama_8220" },
                { label: "Ramagundam", value: "CTY_RAMA_NB1" },
                { label: "Raman", value: "CTY_RAMA" },
                { label: "Ramanagar", value: "CTY_RAMA28" },
                { label: "Ramanagaram", value: "CTY_RAMA_NB2" },
                { label: "Ramanathapuram", value: "CTY_RAMA29" },
                { label: "Ramanathgudpally ", value: "CTY_Rama_8421" },
                { label: "Ramanujganj", value: "CTY_RAMA_NB3" },
                { label: "Ramapuram", value: "CTY_RAMA_NB4" },
                { label: "Ramdas", value: "CTY_RAMD" },
                { label: "Ramdas (M Cl)", value: "CTY_RAMD_NB" },
                { label: "Rameswarpur", value: "CTY_RAME_NB" },
                { label: "Ramganj Mandi", value: "CTY_RAMG_NB" },
                { label: "Ramgarh", value: "CTY_RAMG5270" },
                { label: "Ramgarh", value: "CTY_RAMG30" },
                { label: "Ramgarh Cantt", value: "CTY_RAMG" },
                { label: "Ramgarh Cantt.", value: "CTY_RAMG5297" },
                { label: "Ramnagar", value: "CTY_RAMN_NB" },
                { label: "Ramnagar ", value: "CTY_Ramn_8627" },
                { label: "Rampur", value: "CTY_RAMP190" },
                { label: "Rampur", value: "CTY_RAMP31" },
                { label: "Rampur (Motihari)", value: "CTY_RMPR" },
                { label: "Rampur Jagir", value: "CTY_RAMP_NB" },
                { label: "Rampura", value: "CTY_RAMP_NB1" },
                { label: "Rampura Phul", value: "CTY_RAMP" },
                { label: "Rampurhat", value: "CTY_RAMP_NB2" },
                { label: "Ramtek", value: "CTY_RAMT" },
                { label: "Ranaghat", value: "CTY_RANA_NB" },
                { label: "Ranbir Singh Pora", value: "CTY_RANB_NB" },
                { label: "Ranchi", value: "CTY_RANC32" },
                { label: "Ranebennur", value: "CTY_RANE" },
                { label: "Rangareddi", value: "CITY_Rang_8180" },
                { label: "Rangareddi", value: "CTY_RANG_NB" },
                { label: "Rangareddy", value: "CITY_Rang_8181" },
                { label: "Rangareddy", value: "CTY_RANG_NB1" },
                { label: "Rangia", value: "CTY_RANG_NB2" },
                { label: "Rangian", value: "CTY_RANG_NB3" },
                { label: "Rangibasan", value: "CTY_RANG_NB4" },
                { label: "Rangpo", value: "CTY_RANG_NB5" },
                { label: "Rania", value: "CTY_RANI" },
                { label: "Ranibennur", value: "CTY_RANI_NB" },
                { label: "Raniganj", value: "CTY_RANI_NB1" },
                { label: "Ranikhet", value: "CTY_RNI" },
                { label: "Ranipet", value: "CTY_RANI5341" },
                { label: "Ranipettai", value: "CTY_RANI_NB2" },
                { label: "Ranipool", value: "CTY_RANI_NB3" },
                { label: "Ranipur", value: "CTY_RANI_NB4" },
                { label: "Ranjangaon", value: "CTY_RANJ_NB" },
                { label: "Ranni", value: "CTY_RANN_NB" },
                { label: "Ranny", value: "CTY_RANN" },
                { label: "Rapalle", value: "CTY_RAPA" },
                { label: "Rasipuram", value: "CTY_RASI_NB" },
                { label: "Rasulpur", value: "CTY_RASU_NB" },
                { label: "Rasupur Bankiya ", value: "CTY_Rasu_8784" },
                { label: "Ratangarh", value: "CTY_RTG" },
                { label: "Rath", value: "CTY_RATH_NB" },
                { label: "Rathonda ", value: "CTY_Rath_8779" },
                { label: "Ratia", value: "CTY_RATI_NB" },
                { label: "Ratlam", value: "CTY_RATL33" },
                { label: "Ratnagiri", value: "CTY_RATN34" },
                { label: "Ratnari", value: "CTY_RATN_NB" },
                { label: "Rattewal", value: "CTY_RATT_NB" },
                { label: "Rau", value: "CTY_RAU" },
                { label: "Rauli ", value: "CTY_Raul_8708" },
                { label: "Raver", value: "CTY_RAVE_NB" },
                { label: "Ravulapalem", value: "CTY_RAVU" },
                { label: "Rawat Bhata", value: "CTY_RAWA" },
                { label: "Rawatsar", value: "CTY_RAWA_NB" },
                { label: "Raxaul Bazar", value: "CTY_RAXA_NB" },
                { label: "Raya", value: "CTY_RAYA_NB" },
                { label: "Rayachoti", value: "CTY_RAYA_NB1" },
                { label: "Rayadurg", value: "CTY_RAYA_NB2" },
                { label: "Rayagada", value: "CTY_RAYA35" },
                { label: "Rayini Palem ", value: "CTY_Rayi_8361" },
                { label: "Rayya", value: "CTY_RAYY_NB" },
                { label: "Reasi", value: "CTY_REAS5269" },
                { label: "Red Hills", value: "CTY_REDH_NB" },
                { label: "Reddial ", value: "CTY_Redd_8396" },
                { label: "Reddichavadi ", value: "CTY_Redd_8727" },
                { label: "Reddipalle", value: "CITY_Redd_8221" },
                { label: "Reddipalle", value: "CTY_REDD_NB" },
                { label: "Rehana Jattan", value: "CTY_REHA_NB" },
                { label: "Rekang Peo", value: "CTY_REKA_NB" },
                { label: "Rengali", value: "CTY_RENG_NB" },
                { label: "Renukoot", value: "CTY_RENU" },
                { label: "Repalle", value: "CTY_REPA5361" },
                { label: "Rewa", value: "CTY_REWA36" },
                { label: "Rewari", value: "CTY_REWA37" },
                { label: "Ri Bhoi", value: "CTY_RIB38" },
                { label: "Rishikesh", value: "CTY_RISH" },
                { label: "Rishra", value: "CTY_RISH5315" },
                { label: "Robertsganj", value: "CTY_ROBE" },
                { label: "Rodha", value: "CTY_RODH39" },
                { label: "Roha Ashtami", value: "CTY_ROHA_NB" },
                { label: "Roherianwali", value: "CTY_ROHE_NB" },
                { label: "Rohru", value: "CTY_ROHR" },
                { label: "Rohtak", value: "CTY_ROHT40" },
                { label: "Rohtas", value: "CTY_ROHT41" },
                { label: "Roing", value: "CTY_ROIN" },
                { label: "Roorkee", value: "CTY_ROK" },
                { label: "Roorkee", value: "CTY_ROOR" },
                { label: "Ropar", value: "CTY_ROPA42" },
                { label: "Roranwala", value: "CTY_RORA_NB" },
                { label: "Roshan Pura", value: "CTY_RSHNPUR" },
                { label: "Rourkela", value: "CTY_ROUR" },
                { label: "Rsara", value: "CTY_RSAR_NB" },
                { label: "Rudauli", value: "CTY_RUDA_NB" },
                { label: "Rudel", value: "CTY_RUDE_NB" },
                { label: "Rudraprayag", value: "CTY_RUDR43" },
                { label: "Rudrapur", value: "CTY_RUDR" },
                { label: "Rudraram ", value: "CTY_Rudr_8362" },
                { label: "Rupana", value: "CTY_RUPA_NB" },
                { label: "Rupnagar", value: "CTY_RUPN5279" },
                { label: "Rupnarayanpur", value: "CTY_RPY" },
                { label: "Rustapur ", value: "CTY_Rust_8307" },
                { label: "Sabarkantha", value: "CTY_SABA44" },
                { label: "Sabhra", value: "CTY_SABH_NB" },
                { label: "Sabour", value: "CTY_SABO_NB" },
                { label: "Sadabad", value: "CTY_SADA_NB" },
                { label: "Sadat Pur Gujran", value: "CTY_SDTPURGJRN" },
                { label: "Sadhaura", value: "CTY_SADH_NB" },
                { label: "Sadi", value: "CTY_SADI_NB" },
                { label: "Sadiq", value: "CTY_SADI_NB1" },
                { label: "Sadulsahar", value: "CTY_SADU_NB" },
                { label: "Safidon", value: "CTY_SAFI_NB" },
                { label: "Sagar", value: "CTY_SAGA" },
                { label: "Sagar", value: "CTY_SAGA45" },
                { label: "Sagwara", value: "CTY_SAGW_NB" },
                { label: "Saha", value: "CTY_SAHA_NB" },
                { label: "Sahabuddinpur", value: "CTY_SAHA_NB1" },
                { label: "Saharanpur", value: "CTY_SAHA46" },
                { label: "Saharsa", value: "CTY_SAHA47" },
                { label: "Sahauran", value: "CTY_SAHA_NB2" },
                { label: "Sahibabad", value: "CTY_SAHI5299" },
                { label: "Sahibabad Daulat Pur", value: "CTY_SHDAUPUR" },
                { label: "Sahibganj", value: "CTY_SAHI48" },
                { label: "SAHIBZADA AJIT SINGH NAGAR", value: "CTY_SAHI_NB" },
                { label: "Sahibzada Ajit Singh Nagar", value: "CTY_SAHI" },
                { label: "Sahjanwa", value: "CTY_SAHJ_NB" },
                { label: "Sahnewal", value: "CTY_SAHN_NB" },
                { label: "Saidul Ajaib", value: "CTY_SAIAJB" },
                { label: "Saiha", value: "CTY_SAIH5258" },
                { label: "Saila Khurd", value: "CTY_SAIL_NB" },
                { label: "Sailu", value: "CTY_SAIL_NB1" },
                { label: "Saini Majra", value: "CTY_SAIN_NB" },
                { label: "Sainj", value: "CTY_SAIN_NB1" },
                { label: "Sainthia", value: "CTY_SAIN5296" },
                { label: "Sakat Sanala", value: "CTY_SAKA_NB" },
                { label: "Salairani", value: "CTY_SALA_NB" },
                { label: "Salbari ", value: "CTY_Salb_8788" },
                { label: "Salcette", value: "CTY_SALC" },
                { label: "Salem", value: "CTY_SALE49" },
                { label: "Salempur", value: "CTY_SALE_NB" },
                { label: "Salepur", value: "CTY_SALE_NB1" },
                { label: "Saligao", value: "CTY_SALI_NB" },
                { label: "Salka ", value: "CTY_Salk_8543" },
                { label: "Salon", value: "CTY_SALO_NB" },
                { label: "Saluni", value: "CTY_SALU_NB" },
                { label: "Salur", value: "CTY_SALU" },
                { label: "Samada", value: "CTY_SAMA_NB" },
                { label: "Samadpur Hardas ", value: "CTY_Sama_8785" },
                { label: "Samaipur", value: "CTY_SAMA_NB1" },
                { label: "Samalkha", value: "CTY_SAMA50" },
                { label: "Samana", value: "CTY_SAMA" },
                { label: "Samastipur", value: "CTY_SAMA51" },
                { label: "Samba", value: "CTY_SAMB_NB" },
                { label: "Samba", value: "CTY_SAMB_5449" },
                { label: "Sambalpur", value: "CTY_SAMB52" },
                { label: "Sambhal", value: "CTY_SAMB_NB1" },
                { label: "Sambhalka", value: "CTY_SMBHLK" },
                { label: "Sambhar", value: "CTY_SAMB_NB2" },
                { label: "Samgauli", value: "CTY_SAMG_NB" },
                { label: "Samlod", value: "CTY_SAML_NB" },
                { label: "Samou", value: "CTY_SAMO_NB" },
                { label: "Sampla", value: "CTY_SAMP_NB" },
                { label: "Sampura", value: "CTY_SAMP_NB1" },
                { label: "Sampurna Nagar", value: "CTY_SAMP_NB2" },
                { label: "Samrala", value: "CTY_SAMR5311" },
                { label: "Sanand", value: "CTY_SANA_NB" },
                { label: "Sanathal", value: "CTY_SANA_NB1" },
                { label: "Sanaur", value: "CTY_SANA_NB2" },
                { label: "Sanawad", value: "CTY_SANA_NB3" },
                { label: "Sanchore", value: "CTY_SANC_NB" },
                { label: "Sandhai", value: "CTY_SAND_NB" },
                { label: "Sandhaur", value: "CTY_SAND_NB1" },
                { label: "Sandila", value: "CTY_SAND_NB2" },
                { label: "Sandur", value: "CTY_SAND" },
                { label: "Sanehwal", value: "CTY_SANE" },
                { label: "Saneta", value: "CTY_SANE_NB" },
                { label: "Sangamner", value: "CTY_SANG147" },
                { label: "Sangana ", value: "CTY_Sang_8557" },
                { label: "Sanganer", value: "CTY_SANG84" },
                { label: "Sangareddy", value: "CTY_SNGR" },
                { label: "Sangaria", value: "CTY_SANG5329" },
                { label: "Sangat", value: "CTY_SANG_NB" },
                { label: "Sangli", value: "CTY_SANG53" },
                { label: "Sangli Miraj Kupwad", value: "CTY_SMIK" },
                { label: "SANGLI-MIRAJ-KUPWAD", value: "CTY_SANG_NB1" },
                { label: "Sangmeshwar", value: "CTY_SGMW" },
                { label: "Sangrur", value: "CTY_SANG54" },
                { label: "Sanguem", value: "CTY_SANG_NB2" },
                { label: "Sanivaram ", value: "CTY_Sani_8484" },
                { label: "Sanjali", value: "CTY_SANJ_NB" },
                { label: "Sanjan", value: "CTY_SANJ_NB1" },
                { label: "Sanjauli", value: "CTY_SANJ_NB2" },
                { label: "Sanjay Nagar ", value: "CTY_Sanj_8363" },
                { label: "Sankagiri", value: "CTY_SANK_NB" },
                { label: "Sankeshwar", value: "CTY_SANK_NB1" },
                { label: "Sanoth", value: "CTY_SANOTH" },
                { label: "Sanpada", value: "CTY_SNPDA" },
                { label: "Sanpera", value: "CTY_SANP_NB" },
                { label: "Sanquelim", value: "CTY_SANQ_NB" },
                { label: "Sant Kabir Nagar", value: "CTY_SANT55" },
                { label: "Sant Ravidas Nagar", value: "CTY_SANT56" },
                { label: "Santa Cruz", value: "CTY_SANTACZ" },
                { label: "Santa Cruz", value: "CTY_SANT_NB" },
                { label: "Saoner", value: "CTY_SAON" },
                { label: "Saphale", value: "CTY_SAPH_NB" },
                { label: "Sarai mir", value: "CTY_SARA_NB" },
                { label: "Saraidhela", value: "CTY_SARA_NB1" },
                { label: "Saraikela", value: "CTY_SARA_NB2" },
                { label: "Saran", value: "CTY_SARA57" },
                { label: "Saravanampatti", value: "CTY_SARA_NB3" },
                { label: "Sardar Shahar", value: "CTY_SARD_NB" },
                { label: "Sardhana", value: "CTY_SARD_NB1" },
                { label: "Sardulgarh", value: "CTY_SARD_NB2" },
                { label: "Sarigam", value: "CTY_SARI_NB" },
                { label: "Sarkaghat", value: "CTY_SARK_NB" },
                { label: "Sarna", value: "CTY_SARN_NB" },
                { label: "Sarthana ", value: "CTY_Sart_8550" },
                { label: "Sas Nagar ( Mohali)", value: "CTY_SASN_NB" },
                { label: "Sasaram", value: "CTY_SASA_NB" },
                { label: "Satana", value: "CTY_SATA" },
                { label: "Satara", value: "CTY_SATA58" },
                { label: "Sathupalle", value: "CITY_Sath_8223" },
                { label: "Sathupalle", value: "CTY_SATH_NB" },
                { label: "Sathyamangalam", value: "CTY_SATH" },
                { label: "Satlasana", value: "CTY_SATL_NB" },
                { label: "Satna", value: "CTY_SATN59" },
                { label: "Sattenapalle", value: "CTY_SATT_NB" },
                { label: "Sattenapalli", value: "CTY_SATT" },
                { label: "Saunaha ", value: "CTY_Saun_8759" },
                { label: "Sauri", value: "CTY_SAUR_NB" },
                { label: "Savaigudem ", value: "CTY_Sava_8374" },
                { label: "Savardari", value: "CTY_SAVA_NB" },
                { label: "Savarkundla", value: "CTY_SAVA" },
                { label: "Savda", value: "CTY_SAVD" },
                { label: "Savner", value: "CTY_SAVN_NB" },
                { label: "Sawai Madhopur", value: "CTY_SAWA60" },
                { label: "Sawantwadi", value: "CTY_SAWA" },
                { label: "Sawargaon Hadap ", value: "CTY_Sawa_8628" },
                { label: "Sayar ", value: "CTY_Saya_8544" },
                { label: "Sayla", value: "CTY_SAYL_NB" },
                { label: "Secunderabad", value: "CITY_Secu_8161" },
                { label: "Secunderabad", value: "CTY_SECU20" },
                { label: "Secunderabad Cantt.", value: "CITY_Secu_8224" },
                { label: "Secunderabad Cantt.", value: "CTY_SECU_NB" },
                { label: "Sedam", value: "CTY_SEDA_NB" },
                { label: "Sedariya", value: "CTY_SEDA_NB1" },
                { label: "Sehjra", value: "CTY_SEHJ_NB" },
                { label: "Sehore", value: "CTY_SEHO61" },
                { label: "Selud", value: "CTY_SELU_NB" },
                { label: "Semara Chandrauli", value: "CTY_SEMA_NB" },
                { label: "Senapati", value: "CTY_SENA62" },
                { label: "Sendhwa", value: "CTY_SEND_NB" },
                { label: "Sendri", value: "CTY_SEND_NB1" },
                { label: "Seoli", value: "CTY_SEOL_NB" },
                { label: "Seoli ", value: "CTY_Seol_8629" },
                { label: "Seoni", value: "CTY_SEON63" },
                { label: "Seoni Malwa", value: "CTY_SEON_NB" },
                { label: "Seppa", value: "CTY_SEPP" },
                { label: "Seraikela", value: "CTY_SERA_NB" },
                { label: "Seraikela-Kharsawan", value: "CTY_SERA64" },
                { label: "Serampore", value: "CTY_SERA" },
                { label: "Seraulim", value: "CTY_SERA_NB1" },
                { label: "Serchhip", value: "CTY_SERC5278" },
                { label: "Sevoor", value: "CTY_SEVO_NB" },
                { label: "Sewarhi", value: "CTY_SEWA_NB" },
                { label: "Shadnagar", value: "CITY_Shad_8225" },
                { label: "Shadnagar", value: "CTY_SHAD_NB" },
                { label: "Shahabad", value: "CTY_SHAH38" },
                { label: "Shahad", value: "CTY_SHAD" },
                { label: "Shahada", value: "CTY_SHD" },
                { label: "Shahade", value: "CTY_SHAH_NB" },
                { label: "Shahapur", value: "CTY_SHAH_NB1" },
                { label: "Shahbad", value: "CTY_SHAH_NB2" },
                { label: "Shahdol", value: "CTY_SHAH65" },
                { label: "Shahganj", value: "CTY_SHAH_NB3" },
                { label: "Shahid Udham Singh Nagar", value: "CTY_SHAH73" },
                { label: "Shahjahanpur", value: "CTY_SHAH66" },
                { label: "Shahkot", value: "CTY_SHAH74" },
                { label: "Shahpur", value: "CTY_SHAH_NB7" },
                { label: "Shahpur", value: "CTY_SHAH_NB8" },
                { label: "Shahpur", value: "CTY_SHAH_NB5" },
                { label: "Shahpur", value: "CTY_SHAH_NB9" },
                { label: "Shahpur Govardhanpur Bangar", value: "CTY_SHAH_NB4" },
                { label: "Shahpur Tonda ", value: "CTY_Shah_8786" },
                { label: "Shahpura", value: "CTY_SHAH85" },
                { label: "Shahzadpur", value: "CTY_SHAH_NB6" },
                { label: "Shaikhpara .", value: "CTY_Shai_8787" },
                { label: "Shajapur", value: "CTY_SHAJ67" },
                { label: "Shalimar Bagh", value: "CTY_SHLMRBGH" },
                { label: "Shamalaji", value: "CTY_SHAM_NB" },
                { label: "Shamashpur", value: "CTY_SHAM_NB1" },
                { label: "Shamchurasi", value: "CTY_SHAM_NB2" },
                { label: "Shamdoo ", value: "CTY_Sham_8667" },
                { label: "Shamirpet ", value: "CTY_Sham_8292" },
                { label: "Shamli", value: "CTY_SHAM110" },
                { label: "Shamsabad", value: "CTY_SHAM_NB5" },
                { label: "Shamsabad", value: "CTY_SHAM_NB3" },
                { label: "Shamshabad", value: "CITY_Sham_8167" },
                { label: "Shamshabad", value: "CTY_SHAM5" },
                { label: "Shankar", value: "CTY_SHAN_NB" },
                { label: "Shankargarh", value: "CTY_SHAN_NB1" },
                { label: "Shankarpally", value: "CITY_Shan_8226" },
                { label: "Shankarpally", value: "CTY_SHAN_NB2" },
                { label: "Shanpung", value: "CTY_SHAN_NB3" },
                { label: "Shapar", value: "CTY_SHAP_NB" },
                { label: "Sharjah", value: "CTY_SHARJAH" },
                { label: "Shegaon", value: "CTY_SHEG_NB" },
                { label: "Sheikhpura", value: "CTY_SHEI68" },
                { label: "Shekta ", value: "CTY_Shek_8630" },
                { label: "Shelar", value: "CTY_SHELAR" },
                { label: "Shendra MIDC Area ", value: "CTY_Shen_8631" },
                { label: "Sheoga ", value: "CTY_Sheo_8632" },
                { label: "Sheohar", value: "CTY_SHEO69" },
                { label: "Sheopur", value: "CTY_SHEO70" },
                { label: "Sherkot", value: "CTY_SHER_NB" },
                { label: "Sherpur", value: "CTY_SHP" },
                { label: "Sherpura", value: "CTY_SHER_NB1" },
                { label: "Shertally", value: "CTY_SHER" },
                { label: "Shiholi Moti", value: "CTY_SHIH_NB" },
                { label: "Shikohabad", value: "CTY_SHIK" },
                { label: "Shikohpur", value: "CTY_SHIK_NB" },
                { label: "Shikrapur", value: "CTY_SHIK_NB1" },
                { label: "Shilaru", value: "CTY_SHIL_NB" },
                { label: "Shillong", value: "CTY_SHIL" },
                { label: "Shimla", value: "CTY_SHIM71" },
                { label: "Shimoga", value: "CTY_SHIM72" },
                { label: "Shindekelgaon ", value: "CTY_Shin_8633" },
                { label: "Shirdi", value: "CTY_SHIR5375" },
                { label: "Shirpur", value: "CTY_SHIR5351" },
                { label: "Shirur", value: "CTY_SHIR149" },
                { label: "Shirval", value: "CTY_SHIR150" },
                { label: "Shirwal", value: "CTY_SHIR_NB" },
                { label: "Shivpur ", value: "CTY_Shiv_8558" },
                { label: "Shivpuri", value: "CTY_SHIV73" },
                { label: "Shohratgarh", value: "CTY_SHOH_NB" },
                { label: "Shopian", value: "CTY_SHOP5271" },
                { label: "Shoranur", value: "CTY_SHOR5365" },
                { label: "Shrawasti", value: "CTY_SHRA74" },
                { label: "Shrigonda", value: "CTY_SHRI_NB" },
                { label: "Shrirampur", value: "CTY_SHRI" },
                { label: "Shujalpur", value: "CTY_SHUJ" },
                { label: "Shukartal Bangar", value: "CTY_SHUK_NB" },
                { label: "Shuklaganj", value: "CTY_SHUK_NB1" },
                { label: "Sibsagar", value: "CTY_SIBS75" },
                { label: "Sibsagar", value: "CTY_Sibs_7972" },
                { label: "Siddaloor ", value: "CTY_Sidd_8422" },
                { label: "Siddharthnagar", value: "CTY_SIDD76" },
                { label: "Siddipet", value: "CITY_Sidd_8177" },
                { label: "Siddipet", value: "CTY_SIDD" },
                { label: "Sidhi", value: "CTY_SIDH77" },
                { label: "Sidhpur", value: "CTY_SIDH_NB" },
                { label: "Sidhwan Bet", value: "CTY_SIDH_NB1" },
                { label: "Sidsar", value: "CTY_SIDS_NB" },
                { label: "Signal Village", value: "CTY_Sign_8655" },
                { label: "Sihor", value: "CTY_SIHO34" },
                { label: "Sihora", value: "CTY_SIHO132" },
                { label: "Sikanderpur Badha", value: "CTY_SIKA_NB" },
                { label: "Sikandrabad", value: "CTY_SIKA_NB1" },
                { label: "Sikar", value: "CTY_SIKA78" },
                { label: "Sikhwala", value: "CTY_SIKH_NB" },
                { label: "Silai Bara Gaon ", value: "CTY_Sila_8780" },
                { label: "Silchar", value: "CTY_SILC" },
                { label: "Siliguri", value: "CTY_SILI" },
                { label: "Sillod", value: "CTY_SILL" },
                { label: "Silvassa", value: "CTY_SILV" },
                { label: "Silvassa", value: "CTY_SIL" },
                { label: "Simaliya", value: "CTY_SIMA_NB" },
                { label: "Simdega", value: "CTY_SIMD79" },
                { label: "Simikeri ", value: "CTY_Simi_8580" },
                { label: "Siminoi", value: "CTY_SIMI_NB" },
                { label: "Sindhanur", value: "CTY_SIND" },
                { label: "Sindhudurg", value: "CTY_SIND80" },
                { label: "Singhaur", value: "CTY_SING_NB" },
                { label: "Singhe Wala ", value: "CTY_Sing_8709" },
                { label: "Singrauli", value: "CTY_SING5249" },
                { label: "Singtam", value: "CTY_SING_NB1" },
                { label: "Singur", value: "CTY_SNGUR" },
                { label: "Sinnar", value: "CTY_SINN_NB" },
                { label: "Siolim", value: "CTY_SIOL_NB" },
                { label: "Sipahijala", value: "CTY_Sipa_8871" },
                { label: "Siras Pur", value: "CTY_SRSPUR" },
                { label: "Sirathu", value: "CTY_SIRA_NB" },
                { label: "Sirathu", value: "CTY_SIRA_NB1" },
                { label: "Sircaim", value: "CTY_SIRC_NB" },
                { label: "Sircilla", value: "CITY_Sirc_8227" },
                { label: "Sircilla", value: "CTY_SIRC_NB1" },
                { label: "Sirhind", value: "CTY_SIRH" },
                { label: "Sirmaur", value: "CTY_SIRM81" },
                { label: "Sirmina ", value: "CTY_Sirm_8536" },
                { label: "Sirmour", value: "CTY_SIRM5372" },
                { label: "Sirohi", value: "CTY_SIRO82" },
                { label: "Sironj", value: "CTY_SIRO" },
                { label: "Sirsa", value: "CTY_SIRS83" },
                { label: "Sirsi", value: "CTY_SIRS" },
                { label: "Sisodara", value: "CTY_SISO_NB" },
                { label: "Siswa", value: "CTY_SISW_NB" },
                { label: "Sitamarhi", value: "CTY_SITA84" },
                { label: "SITAPUR", value: "CTY_SITA" },
                { label: "Sitapur", value: "CTY_SITA85" },
                { label: "Sitarganj", value: "CTY_SITA_NB" },
                { label: "Sito Gunno", value: "CTY_SITO_NB" },
                { label: "Sivaganga", value: "CTY_SIVA86" },
                { label: "Sivakasi", value: "CTY_SIVA" },
                { label: "Sivareddypet ", value: "CTY_Siva_8423" },
                { label: "Sivasagar", value: "CTY_SIVSGR" },
                { label: "Siwan", value: "CTY_SIWA87" },
                { label: "Siwan", value: "CTY_SIWA_NB1" },
                { label: "Siwan", value: "CTY_SIWA_NB2" },
                { label: "Siwani", value: "CTY_SIWA_NB" },
                { label: "Sohana", value: "CTY_SOHA_NB" },
                { label: "Sohanpur", value: "CTY_SOHA_NB1" },
                { label: "Sohna", value: "CTY_SOHNA" },
                { label: "Sojat City", value: "CTY_SOJA5367" },
                { label: "Solaiya", value: "CTY_SOLA_NB" },
                { label: "Solan", value: "CTY_SOLA88" },
                { label: "Solapur", value: "CTY_SOLA89" },
                { label: "Somatane", value: "CTY_SOMA_NB" },
                { label: "Sonapur", value: "CTY_SONA90" },
                { label: "Sonarpur", value: "CTY_SNRPR" },
                { label: "Sonawari", value: "CTY_SNW" },
                { label: "Sonbhadra", value: "CTY_SONB91" },
                { label: "Sonepat", value: "CTY_SONE" },
                { label: "Sonepur", value: "CTY_SONE_NB" },
                { label: "Sonipat", value: "CTY_SONI92" },
                { label: "Sonitpur", value: "CTY_SONI93" },
                { label: "Sonjamb", value: "CTY_SONJ_NB" },
                { label: "Sonpur", value: "CTY_SONP_NB" },
                { label: "Sopore", value: "CTY_SOPO5403" },
                { label: "Soraon", value: "CTY_SORA_NB" },
                { label: "Sorumona Poria", value: "CTY_SORU_NB" },
                { label: "South 24 Parganas", value: "CTY_SOUT94" },
                { label: "South Andaman", value: "CTY_SOUT95" },
                { label: "South Delhi", value: "CTY_SOUT96" },
                { label: "South Dinajpur", value: "CTY_SOUT97" },
                { label: " South Dumdum", value: "CTY_SDM" },
                { label: "South Garo Hills", value: "CTY_SOUT98" },
                { label: "South Goa", value: "CTY_SOUT99" },
                { label: "South Parur", value: "CTY_SOUT_NB" },
                { label: "South Sikkim", value: "CTY_SOUT11" },
                { label: "South Tripura", value: "CTY_SOUT14" },
                { label: "South West Delhi", value: "CTY_SOUT12" },
                { label: "Sri Ganganagar", value: "CTY_SRIG_NB" },
                { label: "Sri Hargobindpur Sahib", value: "CTY_SRIH_NB" },
                { label: "Sri Vijaynagar", value: "CTY_SRIV_NB" },
                { label: "Srikakulam", value: "CTY_SRIK13" },
                { label: "Srikalahasti", value: "CTY_SRIK_NB" },
                { label: "Srimadhopur", value: "CTY_SRIM_NB" },
                { label: "Srinagar", value: "CTY_SRIN_NB" },
                { label: "Srinagar", value: "CTY_SRIN14" },
                { label: "Srinivaspuram ", value: "CTY_Srin_8456" },
                { label: "Sriperumbudur", value: "CTY_SRIP_NB" },
                { label: "Srirangam", value: "CTY_SRIR_NB" },
                { label: "Stn. Gundrathimadugu ", value: "CTY_StnG_8397" },
                { label: "Sualkuchi", value: "CTY_SUAL_NB" },
                { label: "Subhashnagar ", value: "CTY_Subh_8640" },
                { label: "Sudhail", value: "CTY_SUDH_NB" },
                { label: "Sudhar", value: "CTY_SUDH_NB1" },
                { label: "Sughad", value: "CTY_SUGH_NB" },
                { label: "Suhron ", value: "CTY_Suhr_8668" },
                { label: "Sujangarh", value: "CTY_SUJA_NB" },
                { label: "Sujanpur", value: "CTY_SUJA_NB1" },
                { label: "Sukhan Wala ", value: "CTY_Sukh_8710" },
                { label: "Sukhdevpura", value: "CTY_SUKH_NB" },
                { label: "Sukher", value: "CTY_SUKH_NB1" },
                { label: "Sukhrali", value: "CTY_SKHRLI" },
                { label: "Sulah", value: "CTY_SULA_NB" },
                { label: "Sullia", value: "CTY_SULL" },
                { label: "Sultan Battery", value: "CTY_SULT120" },
                { label: "Sultan Pur", value: "CTY_SLTPUR" },
                { label: "Sultan Pur Majra", value: "CTY_SLTPURMJR" },
                { label: "Sultanabad", value: "CITY_Sult_8230" },
                { label: "Sultanabad", value: "CTY_SULT_NB" },
                { label: "Sultanpur", value: "CTY_SULT15" },
                { label: "Sultanpur", value: "CTY_SULT" },
                { label: "Sultanpur Lodhi", value: "CTY_SULT75" },
                { label: "Sulthan Bathery", value: "CTY_SULT_NB2" },
                { label: "Sulya", value: "CTY_SULY_NB" },
                { label: "Sumerpur", value: "CTY_SUME" },
                { label: "Sunabeda", value: "CTY_SUNA159" },
                { label: "Sunam", value: "CTY_SUNA76" },
                { label: "Sunda ", value: "CTY_Sund_8760" },
                { label: "Sundarapuram", value: "CTY_SUND_NB" },
                { label: "Sundargarh", value: "CTY_SUND_NB1" },
                { label: "Sundarnagar", value: "CTY_SUND_NB2" },
                { label: "Sunderbani", value: "CTY_SUND45" },
                { label: "Sundergarh", value: "CTY_SUND16" },
                { label: "Sundernagar", value: "CTY_SUND43" },
                { label: "Sundli Jubbal", value: "CTY_SUND" },
                { label: "Sundli -Jubbal (Jai-Pidi)", value: "CTY_SUND_NB3" },
                { label: "Supasi", value: "CTY_SUPA_NB" },
                { label: "Supaul", value: "CTY_SUPA15" },
                { label: "Supela", value: "CTY_SUPE" },
                { label: "Sur Singh", value: "CTY_SURS_NB" },
                { label: "Surajpur", value: "CTY_SURA_NB4" },
                { label: "Surajpur", value: "CTY_SURA_NB1" },
                { label: "Surajpur", value: "CTY_SURA_NB" },
                { label: "Surajpur", value: "CTY_SURA_NB3" },
                { label: "Surankote", value: "CTY_SRK" },
                { label: "Surat", value: "CTY_SURA17" },
                { label: "Suratgarh", value: "CTY_SURA" },
                { label: "Surathkal", value: "CTY_SURA_NB2" },
                { label: "Surendra Nagar", value: "CTY_SURE18" },
                { label: "Surendranagar", value: "CTY_SURE" },
                { label: "Surguja", value: "CTY_SURG19" },
                { label: "Suri", value: "CTY_SURI_NB" },
                { label: "Surla ", value: "CTY_Surl_8653" },
                { label: "Surma ", value: "CTY_Surm_8761" },
                { label: "Suryapet", value: "CITY_Sury_8166" },
                { label: "Suryapet", value: "CTY_SURY" },
                { label: "Susari", value: "CTY_SUSA_NB" },
                { label: "Swarnavarigudem ", value: "CTY_Swar_8457" },
                { label: "Tada", value: "CTY_TADA_NB" },
                { label: "Tadegaon ", value: "CTY_Tade_8634" },
                { label: "Tadepalligudem", value: "CTY_TADE" },
                { label: "Tadipatri", value: "CTY_TADI_NB" },
                { label: "Tadlapoosalapalli ", value: "CTY_Tadl_8398" },
                { label: "Tadpatri", value: "CTY_TADP" },
                { label: "Taduvai ", value: "CTY_Tadu_8458" },
                { label: "Tahliwal ", value: "CTY_Tahl_8559" },
                { label: "Tahliwala Jattan", value: "CTY_TAHL_NB" },
                { label: "Taj Pul", value: "CTY_TAJPUL" },
                { label: "Tajangi ", value: "CTY_Taja_8485" },
                { label: "Takalimiya", value: "CTY_TAKA_NB" },
                { label: "TAKAVE BUDRUK", value: "CTY_TAKA_NB1" },
                { label: "Takave Budruk", value: "CTY_TAKA" },
                { label: "Takhatpur", value: "CTY_TAKH_NB" },
                { label: "Takhu Chak", value: "CTY_TAKH" },
                { label: "Takhu chak", value: "CTY_TAKH_NB1" },
                { label: "Takkella Pahad ", value: "CTY_Takk_8364" },
                { label: "Talaja", value: "CTY_TALA" },
                { label: "Talala", value: "CTY_TALA_NB" },
                { label: "Talala  Gir", value: "CTY_TLG" },
                { label: "Talcher", value: "CTY_TALC" },
                { label: "Talegaon", value: "CTY_TALE" },
                { label: "Talegaon Dabhade", value: "CTY_TALE_NB" },
                { label: "Talekode", value: "CTY_Tale_8593" },
                { label: "Talekode West ", value: "CTY_Tale_8594" },
                { label: "Talhan", value: "CTY_TALH_NB" },
                { label: "Talikulam", value: "CTY_TALI_NB" },
                { label: "Taliparamba", value: "CTY_TALI" },
                { label: "Tallapolam ", value: "CTY_Tall_8495" },
                { label: "Talod", value: "CTY_TALO_NB" },
                { label: "Taloje Majkur", value: "CTY_TALO_NB1" },
                { label: "Talwan", value: "CTY_TALW_NB" },
                { label: "Talwandi Bhai", value: "CTY_TALW_NB1" },
                { label: "Talwandi Chaudrian", value: "CTY_TALW_NB2" },
                { label: "Talwandi Sabo", value: "CTY_TALW77" },
                { label: "Talwara", value: "CTY_TALW_NB3" },
                { label: "Talwara Township", value: "CTY_TALW78" },
                { label: "Tamenglong", value: "CTY_TAME21" },
                { label: "Tamluk", value: "CTY_TAML_NB" },
                { label: "Tanda", value: "CTY_TAND5382" },
                { label: "Tanda", value: "CTY_TND" },
                { label: "Tandemvalasa ", value: "CTY_Tand_8510" },
                { label: "Tandur", value: "CITY_Tand_8162" },
                { label: "Tandur", value: "CTY_TAND" },
                { label: "Tangi", value: "CTY_TANG_NB" },
                { label: "Tangra", value: "CTY_TANG_NB1" },
                { label: "Tankara", value: "CTY_TANK_NB" },
                { label: "Tankara", value: "CTY_TANK_5439" },
                { label: "Tankua", value: "CTY_TANK_NB1" },
                { label: "Tanuku", value: "CTY_TANU" },
                { label: "Taoru", value: "CTY_TAOR_NB" },
                { label: "Tapa", value: "CTY_TAPA_NB" },
                { label: "Tapa Mandi", value: "CTY_TAPA5354" },
                { label: "Tapi", value: "CTY_TAPI5256" },
                { label: "Tapokda", value: "CTY_TAPO_NB" },
                { label: "Tara ", value: "CTY_Tara_8545" },
                { label: "Taragarh", value: "CTY_TARA_NB" },
                { label: "Tarakeswar", value: "CTY_TARA_NB1" },
                { label: "Taraori", value: "CTY_TARA16" },
                { label: "Tarapur", value: "CTY_TARA_NB2" },
                { label: "Tarewala ", value: "CTY_Tare_8711" },
                { label: "Tarn Taran", value: "CTY_TARN" },
                { label: "Tarsikka", value: "CTY_TARS_NB" },
                { label: "Tarsod", value: "CTY_TARS_NB1" },
                { label: "Tasgaon", value: "CTY_TASG_NB" },
                { label: "Tattekere ", value: "CTY_Tatt_8589" },
                { label: "Tawang", value: "CTY_TAWA22" },
                { label: "Teen Batti", value: "CTY_TENB" },
                { label: "Tehna ", value: "CTY_Tehn_8712" },
                { label: "Tehri", value: "CTY_TEHR_NB" },
                { label: "Tehri Garhwal", value: "CTY_TEHR23" },
                { label: "Tehsil Dadri", value: "CTY_TEHS_NB" },
                { label: "Tehsil Jewar", value: "CTY_TEHS_NB1" },
                { label: "Tehsil Phillaur", value: "CTY_TEHS_NB2" },
                { label: "Tehsil Sadar", value: "CTY_TEHS_NB3" },
                { label: "Tekriyal ", value: "CTY_Tekr_8341" },
                { label: "Tenali", value: "CTY_TENA" },
                { label: "Tenkasi", value: "CTY_TENK5378" },
                { label: "Tezpur", value: "CTY_TEZP" },
                { label: "Tezu", value: "CTY_TEZU" },
                { label: "Thadikal", value: "CITY_Thad_8231" },
                { label: "Thadikal", value: "CTY_THAD_NB" },
                { label: "Thalassery", value: "CTY_THAL" },
                { label: "Thamarassery", value: "CTY_THAM_NB" },
                { label: "Thana Chappar", value: "CTY_THAN_NB" },
                { label: "Thane", value: "CTY_THAN24" },
                { label: "Thanesar", value: "CTY_THAN_NB1" },
                { label: "Thangadh", value: "CTY_THAN" },
                { label: "Thanjavur", value: "CTY_THAN25" },
                { label: "Thanneermukkam", value: "CTY_THAN_NB2" },
                { label: "Tharaj", value: "CTY_THAR_NB" },
                { label: "Tharu ", value: "CTY_Thar_8683" },
                { label: "Thaska Khadar", value: "CTY_THAS_NB" },
                { label: "Thathi Khara ", value: "CTY_That_8684" },
                { label: "The Dangs", value: "CTY_THE26" },
                { label: "Theni", value: "CTY_THEN27" },
                { label: "Theog", value: "CTY_THEO_NB" },
                { label: "Thirkkakara", value: "CTY_THIR_NB" },
                { label: "Thirthahalli", value: "CTY_THIR_NB1" },
                { label: "Thirumangalam", value: "CTY_THIR_NB2" },
                { label: "Thiruvallur", value: "CTY_THIR_NB3" },
                { label: "Thiruvananthapuram", value: "CTY_THIR28" },
                { label: "Thiruvarur", value: "CTY_THIR_NB4" },
                { label: "Thiruverambur", value: "CTY_THIR_NB5" },
                { label: "Thiruvilwamala", value: "CTY_THIR_NB6" },
                { label: "Thiruvottiyur", value: "CTY_THIR_NB7" },
                { label: "Thisayanvilai", value: "CTY_THIS_NB" },
                { label: "Thodupuzha", value: "CTY_THOD" },
                { label: "Thorrur", value: "CTY_THOR" },
                { label: "Thoubal", value: "CTY_THOU29" },
                { label: "Thripunithura", value: "CTY_THRI_NB" },
                { label: "Thrissur", value: "CTY_THRI30" },
                { label: "Throadipattu ", value: "CTY_Thro_8741" },
                { label: "Thuckalay", value: "CTY_THUC_NB" },
                { label: "Thumboor", value: "CTY_THUM_NB" },
                { label: "Thungapahad ", value: "CTY_Thun_8365" },
                { label: "Tibri", value: "CTY_TIBR_NB" },
                { label: "Tigaon", value: "CTY_TIGA_NB" },
                { label: "Tigrana", value: "CTY_TIGR_NB" },
                { label: "Tikamgarh", value: "CTY_TIKA31" },
                { label: "Tilda", value: "CTY_TILD" },
                { label: "Tilhar", value: "CTY_TILH_NB" },
                { label: "Tilpat", value: "CTY_TILPAT" },
                { label: "Tinidvanam", value: "CTY_TINI_NB" },
                { label: "Tinsukia", value: "CTY_TINS32" },
                { label: "Tiptur", value: "CTY_TIPT" },
                { label: "Tirap", value: "CTY_TIRA33" },
                { label: "Tiruchendur", value: "CTY_TIRU5323" },
                { label: "Tiruchengode", value: "CTY_TIRU5309" },
                { label: "Tiruchirapalli", value: "CTY_TIRU_NB" },
                { label: "Tiruchirappalli", value: "CTY_TIRU34" },
                { label: "Tirukollur", value: "CTY_TIRU5401" },
                { label: "Tirunelveli", value: "CTY_TIRU35" },
                { label: "Tirupathur", value: "CTY_TIRU_NB1" },
                { label: "Tirupati", value: "CTY_TIRU6" },
                { label: "Tirupattur", value: "CTY_TIRU96" },
                { label: "Tiruppur", value: "CTY_TIRU_NB2" },
                { label: "Tirupur", value: "CTY_TIRU97" },
                { label: "Tirur", value: "CTY_TIRU121" },
                { label: "Tiruvalla", value: "CTY_TIRU122" },
                { label: "Tiruvallur", value: "CTY_TIRU36" },
                { label: "Tiruvannamalai", value: "CTY_TIRU37" },
                { label: "Tiruvarur", value: "CTY_TIRU38" },
                { label: "Tiruvilla", value: "CTY_TIRU_NB3" },
                { label: "Tiruvur", value: "CTY_TIRU_NB4" },
                { label: "Tiruvuru", value: "CTY_TIRU7" },
                { label: "Tirwaganj", value: "CTY_TIRW_NB" },
                { label: "Titlagarh", value: "CTY_TITL_NB" },
                { label: "Tohana", value: "CTY_TOHA" },
                { label: "Tonk", value: "CTY_TONK39" },
                { label: "Torlikonda ", value: "CTY_Torl_8342" },
                { label: "Tosham", value: "CTY_TOSH40" },
                { label: "Totu", value: "CTY_TOTU_NB" },
                { label: "Town Bhongiri ", value: "CTY_Town_8308" },
                { label: "Tral", value: "CTY_TRAL_NB" },
                { label: "Tranol", value: "CTY_TRAN_NB" },
                { label: "Trichur", value: "CTY_TRIC123" },
                { label: "Trichy", value: "CTY_TRIC100" },
                { label: "Trimbakeshwar", value: "CTY_TRIM_NB" },
                { label: "Triprayar", value: "CTY_TRIP_NB" },
                { label: "Tripunithura", value: "CTY_TRIP" },
                { label: "Tripura", value: "CTY_Trip_7983" },
                { label: "Tripuraram ", value: "CTY_Trip_8366" },
                { label: "Trivandrum", value: "CTY_TRIV" },
                { label: "Trombay", value: "CTY_TRMBAY" },
                { label: "Tuensang", value: "CTY_TUEN41" },
                { label: "Tulsipur", value: "CTY_TULS_NB" },
                { label: "Tumkur", value: "CTY_TUMK42" },
                { label: "Tumsar", value: "CTY_TUMS" },
                { label: "Tundla", value: "CTY_TUND_NB" },
                { label: "Tuni", value: "CTY_TUNI5418" },
                { label: "Tura", value: "CTY_TURA" },
                { label: "Turakapeta ", value: "CTY_Tura_8511" },
                { label: "Turbhe", value: "CTY_TURBHE" },
                { label: "Tuticorin", value: "CTY_TUTI43" },
                { label: "U.T of DADRA & NAGAR HAVELI", value: "CTY_UTOF_NB" },
                { label: "U.T of Dadra & Nagar Haveli", value: "CTY_UTDA" },
                { label: "Uchana Kalan", value: "CTY_UCHA_NB" },
                { label: "Udaipur", value: "CTY_UDAI_NB" },
                { label: "Udaipur", value: "CTY_UDAI44" },
                { label: "Udaipur ", value: "CTY_Udai_8546" },
                { label: "Udalguri", value: "CTY_UDAL_5447" },
                { label: "Udgir", value: "CTY_UDGI_NB" },
                { label: "Udham Singh Nagar", value: "CTY_UDHA45" },
                { label: "Udhampur", value: "CTY_UDHA46" },
                { label: "Udumalaipettai", value: "CTY_UDUM_NB" },
                { label: "Udumalpet", value: "CTY_UDUM" },
                { label: "Udupi", value: "CTY_UDUP47" },
                { label: "Udwada", value: "CTY_UDWA_NB" },
                { label: "Ugani ", value: "CTY_Ugan_8669" },
                { label: "Uggi", value: "CTY_UGGI_NB" },
                { label: "Ugra Kheri", value: "CTY_UGRA_NB" },
                { label: "Ugrawai ", value: "CTY_Ugra_8343" },
                { label: "Ujhani", value: "CTY_UJHA_NB" },
                { label: "Ujire", value: "CTY_UJIR" },
                { label: "Ujjain", value: "CTY_UJJA48" },
                { label: "Ukhrul", value: "CTY_UKHR49" },
                { label: "Uklana Mandi", value: "CTY_UKLA" },
                { label: "Ulhasnagar", value: "CTY_ULHA5295" },
                { label: "Ulimella ", value: "CTY_Ulim_8441" },
                { label: "Ulluberia", value: "CTY_ULLU_NB" },
                { label: " Uluberia", value: "CTY_ULU" },
                { label: "Umaria", value: "CTY_UMAR50" },
                { label: "UmberGOAn", value: "CTY_UMBE_NB" },
                { label: "Umbergoan", value: "CTY_UMBE" },
                { label: "Umbraj", value: "CTY_UMBR_NB" },
                { label: "Umred", value: "CTY_UMRE" },
                { label: "Una", value: "CTY_UNA" },
                { label: "Una", value: "CTY_UNA51" },
                { label: "Unakoti", value: "CTY_Unak_8870" },
                { label: "Unchahar", value: "CTY_UNCH_NB" },
                { label: "Unjha", value: "CTY_UNJH" },
                { label: "Unnao", value: "CTY_UNNA52" },
                { label: "Untsal", value: "CTY_UNTS_NB" },
                { label: "Upleta", value: "CTY_UPLE_NB" },
                { label: "Upper Siang", value: "CTY_UPPE53" },
                { label: "Upper Subansiri", value: "CTY_UPPE54" },
                { label: "Uppinivalasa ", value: "CTY_Uppi_8512" },
                { label: "Uppur ", value: "CTY_Uppu_8742" },
                { label: "Urali Kanchan", value: "CTY_URAL_NB" },
                { label: "Uran", value: "CTY_URAN_NB1" },
                { label: "Uran Islampur", value: "CTY_URAN_NB" },
                { label: "Urmar Tanda", value: "CTY_URMA" },
                { label: "Urse", value: "CTY_URSE_NB" },
                { label: "Usgao", value: "CTY_USGA_NB" },
                { label: "Utlapally ", value: "CTY_Utla_8367" },
                { label: "Utraula", value: "CTY_UTRA_NB" },
                { label: "Uttar Dinajpur", value: "CTY_UTTA_9" },
                { label: "Uttara Kannada", value: "CTY_UTTA55" },
                { label: "Uttarkashi", value: "CTY_UTTA56" },
                { label: "Uttarpara", value: "CTY_UTPR" },
                { label: "Utwad ", value: "CTY_Utwa_8635" },
                { label: "Vadakara", value: "CTY_VADA_NB" },
                { label: "Vadakkancheri", value: "CTY_VADA_NB1" },
                { label: "Vadali", value: "CTY_VADA" },
                { label: "Vadavalli", value: "CTY_VADA_NB2" },
                { label: "Vaddipa ", value: "CTY_Vadd_8486" },
                { label: "Vadlakonda ", value: "CTY_Vadl_8293" },
                { label: "Vadodara", value: "CTY_VADO57" },
                { label: "Vaduj", value: "CTY_VADUJ" },
                { label: "Vaduj", value: "CTY_Vadu_7979" },
                { label: "Vaijapur", value: "CTY_VAIJ_NB" },
                { label: "Vaikom", value: "CTY_VAIK" },
                { label: "Vaishali", value: "CTY_VAIS58" },
                { label: "Vaitarna", value: "CTY_VTRNA" },
                { label: "Vajreshwari", value: "CTY_VJRSWR" },
                { label: "Valanchery", value: "CTY_VALA_NB" },
                { label: "Vallabh Vidhya Nagar", value: "CTY_VALL_NB" },
                { label: "Vallabhipur", value: "CTY_VBP" },
                { label: "Vallioor", value: "CTY_VALL5377" },
                { label: "Valpoi", value: "CTY_VALP_NB" },
                { label: "Valsad", value: "CTY_VALS59" },
                { label: "Valsarvakkam", value: "CTY_VALS_NB" },
                { label: "Vanagaram", value: "CTY_VANA_NB" },
                { label: "Vanasgaon", value: "CTY_VANA_NB1" },
                { label: "Vangani", value: "CTY_VANGNI" },
                { label: "Vaniyambadi", value: "CTY_VANI_NB" },
                { label: "Vannankulam", value: "CTY_VANN_NB" },
                { label: "Vannel K", value: "CITY_Vann_8235" },
                { label: "Vannel K", value: "CTY_VANN_NB1" },
                { label: "Vansda", value: "CTY_VANS_NB" },
                { label: "Vapi", value: "CTY_VAPI" },
                { label: "Varanasi", value: "CTY_VARA60" },
                { label: "Varca", value: "CTY_VARC_NB" },
                { label: "Varkala", value: "CTY_VARK" },
                { label: "Varsamedi", value: "CTY_VARS_NB" },
                { label: "Vasad", value: "CTY_VASA_NB" },
                { label: "Vasai", value: "CTY_VASA" },
                { label: "Vasai Road", value: "CTY_VASRD" },
                { label: "Vasco", value: "CTY_VASC" },
                { label: "Vashi", value: "CTY_VASHI" },
                { label: "Vashind", value: "CTY_VSHND" },
                { label: "Vasna", value: "CTY_VSN" },
                { label: "Vatakara", value: "CTY_VATA" },
                { label: "Vatanappally", value: "CTY_VATA_NB" },
                { label: "Vatanappilly", value: "CTY_VATA5317" },
                { label: "Vavdi", value: "CTY_VAVD_NB" },
                { label: "Vavilala ", value: "CTY_Vavi_8399" },
                { label: "Vazhapadi ", value: "CTY_Vazh_8743" },
                { label: "Veerannapalem ", value: "CTY_Veer_8459" },
                { label: "Veerlapalem ", value: "CTY_Veer_8368" },
                { label: "Vegavaram ", value: "CTY_Vega_8460" },
                { label: "VEJALPUR", value: "CTY_VEJA_NB" },
                { label: "Vejalpur", value: "CTY_VEJA" },
                { label: "Veliyanad", value: "CTY_VELI_NB" },
                { label: "Vella ", value: "CTY_Vell_8496" },
                { label: "Vellimalai ", value: "CTY_Vell_8744" },
                { label: "Vellore", value: "CTY_VELL61" },
                { label: "Vemnoor ", value: "CTY_Vemn_8400" },
                { label: "Vemulakurthi", value: "CITY_Vemu_8236" },
                { label: "Vemulakurthi", value: "CTY_VEMU_NB" },
                { label: "Vemulawada", value: "CITY_Vemu_8237" },
                { label: "Vemulawada", value: "CTY_VEMU_NB1" },
                { label: "Vengala Rao Colony ", value: "CTY_Veng_8513" },
                { label: "Vengurla", value: "CTY_VNG" },
                { label: "Venkatadri Palem ", value: "CTY_Venk_8369" },
                { label: "Venkatagiri", value: "CTY_VENK" },
                { label: "Venkatagirikote ", value: "CTY_Venk_8577" },
                { label: "Venkatapuram ", value: "CTY_Venk_8442" },
                { label: "Venturu ", value: "CTY_Vent_8497" },
                { label: "Veraval", value: "CTY_VERA" },
                { label: "Verem", value: "CTY_VERE_NB" },
                { label: "Verna", value: "CTY_VERNA" },
                { label: "Verna", value: "CTY_Vern_7984" },
                { label: "Versova", value: "CTY_VRSVA" },
                { label: "Vicarabad", value: "CITY_Vica_8238" },
                { label: "Vicarabad", value: "CTY_VICA_NB" },
                { label: "Vidisha", value: "CTY_VIDI62" },
                { label: "Vijainagar", value: "CTY_VIJA_NB" },
                { label: "Vijaipur", value: "CTY_VIJA_NB1" },
                { label: "Vijapur", value: "CTY_VIJA35" },
                { label: "Vijayanagar III stage ", value: "CTY_Vija_8590" },
                { label: "Vijayawada", value: "CTY_VIJA_NB2" },
                { label: "Vijaypura", value: "CTY_VIJA_NB3" },
                { label: "Vijaywada", value: "CTY_VIJA8" },
                { label: "Vikasnagar", value: "CTY_VIKA_NB" },
                { label: "Vikaspuri", value: "CTY_VKSPUR" },
                { label: "Vikramgad", value: "CTY_VIKR_NB" },
                { label: "Villupuram", value: "CTY_VILL63" },
                { label: "Viman Nagar", value: "CTY_VMNGR" },
                { label: "Vinukonda", value: "CTY_VINU_NB" },
                { label: "Virajpet", value: "CTY_VIRA_NB" },
                { label: "Viramgam", value: "CTY_VIRA" },
                { label: "Virar", value: "CTY_VIRA5294" },
                { label: "Virudhunagar", value: "CTY_VIRU64" },
                { label: "Visakhapatnam", value: "CTY_VISA65" },
                { label: "Visavadar", value: "CTY_VISA_NB" },
                { label: "Visnagar", value: "CTY_VISN" },
                { label: "Vita", value: "CTY_VITA" },
                { label: "Vithalwadi", value: "CTY_VTHLWD" },
                { label: "Vizianagaram", value: "CTY_VIZI66" },
                { label: "Vrindavan", value: "CTY_VRIN" },
                { label: "Vuyyuru", value: "CTY_VUYY_NB" },
                { label: "Vyara", value: "CTY_VYAR_NB" },
                { label: "Wada", value: "CTY_WADA_NB" },
                { label: "Wadakanchery", value: "CTY_WADA5386" },
                { label: "Wadakkanchery", value: "CTY_WADA_NB1" },
                { label: "Wadala Kalan ", value: "CTY_Wada_8724" },
                { label: "Wadaparthy ", value: "CTY_Wada_8309" },
                { label: "Wadhwan", value: "CTY_WADH_NB" },
                { label: "Wadi", value: "CTY_WADI_NB" },
                { label: "Wadrafnagar", value: "CTY_WADR_NB" },
                { label: "Waghodia", value: "CTY_WGH" },
                { label: "Wahabwala", value: "CTY_WAHA_NB" },
                { label: "Wai", value: "CTY_WAI" },
                { label: "Waidhan", value: "CTY_WAID" },
                { label: "Waidhan - Singrauli", value: "CTY_WAID_NB" },
                { label: "Wakulni ", value: "CTY_Waku_8636" },
                { label: "Walajapet", value: "CTY_WALA5340" },
                { label: "Walsa Khalsa ", value: "CTY_Wals_8637" },
                { label: "Wanaparthy", value: "CITY_Wana_8160" },
                { label: "Wanaparthy", value: "CTY_WANA" },
                { label: "Wani", value: "CTY_WANI5356" },
                { label: "Wankaner", value: "CTY_WANK" },
                { label: "Wanowrie", value: "CTY_WNWRI" },
                { label: "Warananagar", value: "CTY_WNG" },
                { label: "Warangal", value: "CITY_Wara_8151" },
                { label: "Warangal", value: "CTY_WARA67" },
                { label: "Wardha", value: "CTY_WARD68" },
                { label: "Warora", value: "CTY_WARO" },
                { label: "Warud", value: "CTY_WARU" },
                { label: "Warudkazi ", value: "CTY_Waru_8638" },
                { label: "Waryam Khera", value: "CTY_WARY_NB" },
                { label: "Warzadi ", value: "CTY_Warz_8639" },
                { label: "Washim", value: "CTY_WASH69" },
                { label: "Wayanad", value: "CTY_WAYA70" },
                { label: "West Champaran", value: "CTY_WEST71" },
                { label: "West Delhi", value: "CTY_WEST72" },
                { label: "West Garo Hills", value: "CTY_WEST73" },
                { label: "West Godavari", value: "CTY_WEST74" },
                { label: "West Kameng", value: "CTY_WEST75" },
                { label: "West Khasi Hills", value: "CTY_WEST76" },
                { label: "West Midnapore", value: "CTY_WEST77" },
                { label: "West Nimar", value: "CTY_WEST5248" },
                { label: "West Siang", value: "CTY_WEST78" },
                { label: "West Sikkim", value: "CTY_WEST79" },
                { label: "West Singhbhum", value: "CTY_WEST80" },
                { label: "West Tripura", value: "CTY_WEST81" },
                { label: "Williamnagar", value: "CTY_WILL_NB" },
                { label: "Wokha", value: "CTY_WOKH82" },
                { label: "Y G Peta ", value: "CTY_YGPe_8487" },
                { label: "Yadgarpally ", value: "CTY_Yadg_8370" },
                { label: "Yadgir", value: "CTY_YADG5267" },
                { label: "Yairipok", value: "CTY_YAIR_NB" },
                { label: "Yamuna Nagar", value: "CTY_YAMU5362" },
                { label: "Yamuna Nagar", value: "CTY_YAMU83" },
                { label: "Yamunanagar", value: "CTY_YAMU_NB" },
                { label: "Yanam", value: "CTY_YANA" },
                { label: "Yavatmal", value: "CTY_YAVA84" },
                { label: "Yellappagonda ", value: "CTY_Yell_8344" },
                { label: "Yellapur", value: "CTY_YLLPR" },
                { label: "Yellapur", value: "CTY_Yell_7985" },
                { label: "Yemmiganur", value: "CTY_YEMM_NB" },
                { label: "Yenkiryal ", value: "CTY_Yenk_8294" },
                { label: "Yerragolla Pahad ", value: "CTY_Yerr_8295" },
                { label: "Yeswantapur ", value: "CTY_Yesw_8296" },
                { label: "Yevla", value: "CTY_YEVL_NB" },
                { label: "Yevola", value: "CTY_YEVO" },
                { label: "Yingkiong", value: "CTY_YING11" },
                { label: "Zahirabad", value: "CITY_Zahi_8239" },
                { label: "Zahirabad", value: "CTY_ZAHI_NB" },
                { label: "Zainakote", value: "CTY_ZAIN_NB" },
                { label: "Ziauddin Pur", value: "CTY_ZUDNPUR" },
                { label: "Zinkibhadra", value: "CTY_ZINK_NB" },
                { label: "Zira", value: "CTY_ZIRA" },
                { label: "Zirakpur", value: "CTY_ZIRA_NB" },
                { label: "Zirayathnagar ", value: "CTY_Zira_8345" },
                { label: "Ziro", value: "CTY_ZIRO" },
                { label: "Zuarinagar", value: "CTY_ZUAR_NB" },
                { label: "Zunhebotto", value: "CTY_ZUNH85" }
              ]}
              onChange={handleforFnl}
              validationSpan={
                errors.city ? (
                  <span
                    style={{
                      color: "#EE4854",
                      marginTop: "-10px",
                      fontSize: "13px",
                    }}
                    className="d-block mb-3 px-3"
                  >
                    {errors.city}
                  </span>
                ) : null
              }
            />
          </div>

          <div className="col-md-6 col-sm-12 ">
            <div className="d-flex flex-row">
              <label>Resident Status</label>
            </div>
            <SelectDropdown
              name="residentstatus"
              options={[
                { label: "NRI", value: "RESS_NRI" },
                { label: "PIO", value: "RESS_PIO" },
                { label: "Resident Indian", value: "RESS_RI" },
                { label: "Foreign National", value: "RESS_FN" },
                { label: "OCI", value: "RESS_OCI" },
              ]}
              onChange={handleforFnl}
              validationSpan={
                errors.residentstatus ? (
                  <span
                    style={{
                      color: "#EE4854",
                      marginTop: "-10px",
                      fontSize: "13px",
                    }}
                    className="d-block mb-3 px-3"
                  >
                    {errors.residentstatus}
                  </span>
                ) : null
              }
            />
          </div>

          {/* <------------------------add the date----------------------->  */}

          {/* <div className="col-md-6 col-sm-12 " style={{ marinBottom: "10px" }}>
            <div className="d-flex flex-row">
              <label>Date</label>
            </div>
            <SelectDropdown
              name="date"
              // value={selectdate}
              options={[
                { label: 1, value: 1 },
                { label: 2, value: 2 },
              ]}
              onChange={handleChange}
              validationSpan={
                errors.date ? (
                  <span
                    style={{
                      color: "#EE4854",
                      marginTop: "-10px",
                      fontSize: "13px",
                    }}
                    className="d-block mb-3 px-3"
                  >
                    {errors.date}
                  </span>
                ) : null
              }
            />
          </div> */}

          <div className="col-md-12 col-sm-12 col-xs-12 mt-3">
            <Button
              variant="contained"
              size="large"
              onClick={handleSubmit}
              disabled={_.isEmpty(formData)}
              fullWidth
            >
              Get Quote
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermPlanForm;
