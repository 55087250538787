import { createTheme } from "@mui/material";

export const themeConfig = createTheme({
  typography: {
    fontFamily: "DM Sans",
  },
  palette: {
    primary: {
      main: "#EE4854",
    },
  },
});
