import React, { useRef } from "react";

function FormCheckbox({ label = "" }) {
  const checkbox = useRef(null);
  return (
    <>
      <input
        ref={checkbox}
        name="cssCheckbox"
        type="checkbox"
        class="css-checkbox"
      />
      <label onClick={() => checkbox.current.click()}>{label}</label>
    </>
  );
}

export default FormCheckbox;
