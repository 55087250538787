import React from "react";

const FormInput = React.forwardRef(
  ({ label, name, type, onChange, required, classProp, placeholder, readOnly = false, validationSpan }, ref) => {
    return (
      <div className={`input_content ${classProp}`}>
        <label>
          {label} {required ? <span className="text-danger">*</span> : null}
        </label>
        <input
          type={type}
          placeholder={placeholder}
          onChange={(e) => onChange(name, e.target.value)}
          ref={ref}
          readOnly={readOnly}
        />
        {validationSpan}
        <img
          src={`${process.env.PUBLIC_URL}assets/images/verified_svg.svg`}
          alt=""
        />
      </div>
    );
  }
);

export default FormInput;
