import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL } from "../config";
import { FormInput } from "./common/form";

const LoginForm = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({});

  const login = async () => {
    // console.log(formData);
    const url = `${BASE_URL}/login`;
    const res = await axios.post(url, formData);
    localStorage.setItem("token", res.data.token);
    // alert(res.data)
    navigate('/lead')
    // console.log(res.data);
  }

  const handleChange = (label, value) => {
    setFormData({ ...formData, [label]: value });
    // console.log(formData);
  };
  return (
    <>
      <div className="login-form-box">
        <h3 className="text-center">Login to your Account!</h3>
        {/* <p className="text-center">
          If you have an account, sign in with your mobile number.
        </p> */}
        <div>
          <FormInput
            label="Email"
            name="email"
            type="email"
            onChange={handleChange}
          />
          <FormInput
            label="Password"
            name="password"
            type="password"
            onChange={handleChange}
          />
          {/* <ul className="password_strength_checker">
            <li className="checked"></li>
            <li className="checked"></li>
            <li className="checked"></li>
            <li className="checked"></li>
            <li className="checked"></li>
            <li className=""></li>
          </ul> */}
          {/* <div className="form-check mb-3">
            <input
              className="form-check-input me-2"
              type="checkbox"
              value=""
              id="form2Example3"
            />
            <label className="form-check-label" htmlFor="form2Example3">
              You agree to our Term and Conditions
            </label>
          </div> */}

          <div className="text-center text-lg-start pt-2">
            <button onClick={login} className="login-btn">
              Login
            </button>
          </div>
        </div>
      </div>
      {/* <p className="text-center">
        Already have an account ?{" "}
        <Link to="/registration" className="link">
          Sign in
        </Link>
      </p> */}
    </>
  );
};

export default LoginForm;
